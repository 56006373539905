/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import moment from "moment";
import _ from "lodash";
import Draggable from "react-draggable";
import Swal from "sweetalert2";

import { useSelector, useDispatch } from "react-redux";
import { Select, Modal, Tabs } from "antd";

import type { DraggableData, DraggableEvent } from "react-draggable";

import { selectUser } from "reducers/userReducer";
import {
  selectMapMonitoring,
  setButtonGroupValue,
} from "reducers/mapMonitoring";

import { THS_API } from "../../../constants";

import "./index.css";

const FindEmpModal = ({
  view,
  pastView,
  isFindEmpModal,
  setIsFindEmpModal,
  setIsFocusEmp,
  setSideBarFilter,
  setIsModalVisible,
  chosenMap,
  selectedWorkpermit,
  setSelectedWorkpermit,
  startDatePast,
  isClear,
  setIsClear,
  empModalCurrentTab,
  setEmpModalCurrentTab,
}: any) => {
  const [workpermit, setWorkpermit] = React.useState("");

  const [workpermitSelectBox, setWorkpermitSelectBox] = React.useState([]);
  const [supervisor, setSupervisor] = React.useState("");
  const [supervisorSelectBox, setSupervisorSelectBox] = React.useState([]);
  const [locationSelectBox, setLocationSelectBox] = React.useState([]);

  const [location, setLocation] = React.useState("");
  const [locationTxt, setLocationTxt] = React.useState("");
  const [subLocation, setSubLocation] = React.useState("");
  const [subLocationTxt, setSubLocationTxt] = React.useState("");

  const [subLocationSelectBox, setSubLocationSelectBox] = React.useState([]);

  const [company, setCompany] = React.useState("");
  const [companySelectBox, setCompanySelectBox] = React.useState([]);

  const [pttEmpCurrent, setPttEmpCurrent] = React.useState([]);
  const [pttEmpPast, setPttEmpPast] = React.useState([]);
  const [bounds, setBounds] = React.useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [deviceSelectBox, setDeviceSelectBox] = React.useState("");

  const draggleRef = React.useRef<HTMLDivElement>(null);

  const userData = useSelector(selectUser);
  const dispatch = useDispatch<any>();

  const {
    pttEmployee,
    pttEmployeePast,
    buttonGroupValue,
    isSaveMonitoringLoaded,
  } = useSelector(selectMapMonitoring);

  const handleCancel = () => {
    setIsFindEmpModal(false);
  };

  const searchDeviceID = (value: string) => {
    const valueData = value.split("|")[0];

    if (chosenMap === "time")
      view.goTo({
        center: JSON.parse(valueData),
        zoom: 20,
      });
    else if (chosenMap === "past")
      pastView.goTo({
        center: JSON.parse(valueData),
        zoom: 20,
      });

    setSideBarFilter(false);
    setIsModalVisible(false);

    //for focus employee
    setIsFocusEmp(true);
    setTimeout(() => {
      setIsFocusEmp(false);
    }, 3000);
    setIsFindEmpModal(false);

    setDeviceSelectBox("");
    dispatch(
      setButtonGroupValue({
        ...buttonGroupValue,
        userId: userData.id,
        chosenMap,
        selectedWorkpermit,
        empModalCurrentTab,
      })
    );
  };

  const setSelectBox = async () => {
    const params: any = {
      type: chosenMap,
      start_date: moment(startDatePast).format("YYYY-MM-DD"),
    };

    const res = THS_API.get("/work-permit/filterWorkpermit", {
      params,
    });

    const res2 = THS_API.get("/work-permit/filterWorkpermit/supervisor", {
      params,
    });

    const res3 = THS_API.get("/map-monitoring/filter");

    const res4 = THS_API.get("work-permit/filterWorkpermit/company", {
      params,
    });

    const [
      workPermitPayload,
      supervisorSelectBoxPayload,
      locationSelectBoxPayload,
      companySelectBoxPayload,
    ] = await Promise.all([res, res2, res3, res4]);

    const { location } = _.filter(
      locationSelectBoxPayload.data.data,
      (o: any) => "พื้นที่กระบวนการผลิต" === o.name
    )[0];

    setWorkpermitSelectBox(workPermitPayload.data.data.workPermit);

    setSupervisorSelectBox(supervisorSelectBoxPayload.data.data.data);

    setLocationSelectBox(location);

    setCompanySelectBox(companySelectBoxPayload.data.data.data);
  };
  const resetVal = async () => {
    setWorkpermit("");
    setSupervisor("");
    setLocation("");
    setLocationTxt("");
    setSubLocation("");
    setSubLocationTxt("");
    setCompany("");
    setSelectedWorkpermit({
      workpermit: "",
      supervisor: "",
      location: { value: "", text: "" },
      subLocation: { value: "", text: "" },
      company: "",
    });

    Swal.showLoading();

    setSelectBox();

    Swal.close();
  };

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  React.useEffect(() => {
    if (isSaveMonitoringLoaded) {
      const { selectedWorkpermit, empModalCurrentTab } = buttonGroupValue;

      setEmpModalCurrentTab(empModalCurrentTab);
      setWorkpermit(selectedWorkpermit?.workpermit);
      setSupervisor(selectedWorkpermit?.supervisor);
      setLocation(selectedWorkpermit?.location?.value);
      setLocationTxt(selectedWorkpermit?.location?.text);
      setSubLocation(selectedWorkpermit?.subLocation?.value);
      setSubLocationTxt(selectedWorkpermit?.subLocation?.text);
      setCompany(selectedWorkpermit?.company);
    }
  }, [isSaveMonitoringLoaded]);

  React.useEffect(() => {
    if (isClear) {
      resetVal();
      setEmpModalCurrentTab("dev_eui");
      setIsClear(false);
    }
  }, [isClear]);

  React.useEffect(() => {
    setSelectBox();
  }, [chosenMap, startDatePast]);

  React.useEffect(() => {
    let pttEmpCurrent = pttEmployee;
    let pttEmpPast = pttEmployeePast;
    if (
      selectedWorkpermit?.supervisor &&
      selectedWorkpermit?.supervisor !== ""
    ) {
      pttEmpCurrent = pttEmpCurrent.filter(
        (emp: any) => emp.wpm_supervisor === selectedWorkpermit?.supervisor
      );
      pttEmpPast = pttEmpPast.filter(
        (emp: any) => emp.wpm_supervisor === selectedWorkpermit?.supervisor
      );
    }
    if (
      selectedWorkpermit?.workpermit &&
      selectedWorkpermit?.workpermit !== ""
    ) {
      pttEmpCurrent = pttEmpCurrent.filter((emp: any) => {
        if (emp.work_permit_no)
          return emp.work_permit_no.includes(selectedWorkpermit?.workpermit);
        return false;
      });
      pttEmpPast = pttEmpPast.filter((emp: any) => {
        if (emp.work_permit_no)
          return emp.work_permit_no.includes(selectedWorkpermit?.workpermit);
        return false;
      });
    }
    if (
      selectedWorkpermit?.location?.value &&
      selectedWorkpermit?.location?.value !== ""
    ) {
      pttEmpCurrent = pttEmpCurrent.filter((emp: any) =>
        emp.wpm_unitid.includes(selectedWorkpermit?.location?.value)
      );
      pttEmpPast = pttEmpPast.filter((emp: any) =>
        emp.wpm_unitid.includes(selectedWorkpermit?.location?.value)
      );
    }
    if (
      selectedWorkpermit?.subLocation?.value &&
      selectedWorkpermit?.subLocation?.value !== ""
    ) {
      pttEmpCurrent = pttEmpCurrent.filter((emp: any) =>
        emp.wpm_subunitid.includes(selectedWorkpermit?.subLocation?.value)
      );
      pttEmpPast = pttEmpPast.filter((emp: any) =>
        emp.wpm_subunitid.includes(selectedWorkpermit?.subLocation?.value)
      );
    }
    if (selectedWorkpermit?.company && selectedWorkpermit?.company !== "") {
      pttEmpCurrent = pttEmpCurrent.filter((emp: any) => {
        if (emp.wpm_company_name)
          return emp.wpm_company_name.includes(selectedWorkpermit?.company);
        return false;
      });
      pttEmpPast = pttEmpPast.filter((emp: any) => {
        if (emp.wpm_company_name)
          return emp.wpm_company_name.includes(selectedWorkpermit?.company);
        return false;
      });
    }
    setPttEmpCurrent(pttEmpCurrent);
    setPttEmpPast(pttEmpPast);
  }, [selectedWorkpermit, pttEmployee, pttEmployeePast]);

  React.useEffect(() => {
    const callApi = async () => {
      if (workpermit) {
        Swal.showLoading();
        const params = {
          workpermit_no: workpermit,
          type: "time",
        };

        const supervisorRes = THS_API.get(
          "/work-permit/filterWorkpermit/supervisor",
          {
            params,
          }
        );

        const locationRes = THS_API.get(
          "/work-permit/filterWorkpermit/getlocation",
          {
            params,
          }
        );

        const companyRes = THS_API.get(
          "/work-permit/filterWorkpermit/company",
          {
            params,
          }
        );

        const [supervisorPayload, locationPayload, companyPayload] =
          await Promise.all([supervisorRes, locationRes, companyRes]);

        const locationSelectBox: any = locationPayload.data.data.workPermit.map(
          (obj: any) => {
            return {
              unitname: obj.location,
              unitid: obj.location_id,
              sub_location: obj.sub_location,
            };
          }
        );

        setSupervisor(supervisorPayload.data.data.data[0].wpm_supervisor || "");
        setLocation(locationSelectBox[0].unitid);
        setLocationTxt(locationSelectBox[0].unitname);
        setSubLocation(locationSelectBox[0].sub_location[0].subunitid);
        setSubLocationTxt(locationSelectBox[0].sub_location[0].subunitname);
        setCompany(companyPayload.data.data.data[0].company_name);

        Swal.close();
      }
    };
    callApi();
  }, [workpermit]);

  React.useEffect(() => {
    // const callApi = async () => {
    //   if (supervisor) {
    //     Swal.showLoading();
    //     const params = {
    //       workpermit_no: workpermit,
    //       type: "time",
    //     };
    //     const locationRes = await THS_API.get(
    //       "/work-permit/filterWorkpermit/getlocation",
    //       {
    //         params,
    //       }
    //     );
    //     const locationSelectBox = locationRes.data.data.workPermit.map(
    //       (obj: any) => {
    //         return {
    //           unitname: obj.location,
    //           unitid: obj.location_id,
    //           sub_location: obj.sub_location,
    //         };
    //       }
    //     );
    //     setLocationSelectBox(locationSelectBox);
    //     setLocation("");
    //     setLocationTxt("");
    //     setSubLocation("");
    //     setSubLocationTxt("");
    //     Swal.close();
    //   }
    // };
    // callApi();
  }, [supervisor]);

  // React.useEffect(() => {
  //   console.log("locationTxt");
  //   console.log(locationTxt);
  // }, [locationTxt]);

  // React.useEffect(() => {
  //   console.log("subLocationTxt");
  //   console.log(subLocationTxt);
  // }, [subLocationTxt]);

  const funcLocation = (text: any) => {
    const value = _.filter(
      locationSelectBox,
      (o: any) => text === o.unitname
    )[0]?.unitid;

    if (!value) setSubLocationSelectBox([]);
    else {
      const subLocation = _.filter(
        locationSelectBox,
        (o: any) => value === o.unitid
      )[0]?.sub_location;

      setSubLocation("");
      setSubLocationTxt("");
      setSubLocationSelectBox(subLocation);
    }

    setLocation(value);
    setLocationTxt(text);
  };

  const funcSubLocation = (text: any) => {
    const value = _.filter(
      subLocationSelectBox,
      (o: any) => text === o.subunitname
    )[0]?.subunitid;

    setSubLocation(value);
    setSubLocationTxt(text);
  };

  const handleWorkpermitFilter = () => {
    setSelectedWorkpermit({
      workpermit,
      supervisor,
      location: {
        value: location,
        text: locationTxt,
      },
      subLocation: {
        value: subLocation,
        text: subLocationTxt,
      },
      company,
    });
    setIsFindEmpModal(false);
    dispatch(
      setButtonGroupValue({
        ...buttonGroupValue,
        userId: userData.id,
        selectedWorkpermit: {
          workpermit,
          supervisor,
          location: {
            value: location,
            text: locationTxt,
          },
          subLocation: {
            value: subLocation,
            text: subLocationTxt,
          },
          company,
        },
      })
    );
  };

  const handleTab = (empModalCurrentTab: any) => {
    resetVal();
    setEmpModalCurrentTab(empModalCurrentTab);
    dispatch(
      setButtonGroupValue({
        ...buttonGroupValue,
        userId: userData.id,
        chosenMap,
        selectedWorkpermit,
        empModalCurrentTab,
      })
    );
  };

  return (
    <div className="find-employee-modal">
      <Modal
        style={{ top: 50, left: 50 }}
        title={
          <div
            style={{
              width: "500px",
              cursor: "move",
            }}
          />
        }
        open={isFindEmpModal}
        onCancel={handleCancel}
        modalRender={(modal) => (
          <Draggable
            disabled={false}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <h3 className="search-modal">ค้นหาคน</h3>

        <Tabs activeKey={empModalCurrentTab} onChange={handleTab}>
          <Tabs.TabPane tab="โฟกัสไปที่หมายเลขอุปกรณ์" key="dev_eui">
            <div className="form-group">
              <Select
                className="form-control"
                showSearch
                placeholder="เลือกบุลคลที่ถืออุปกรณ์"
                value={deviceSelectBox}
                onChange={searchDeviceID}
              >
                <Select.Option value="">เลือกผู้ถืออุปกรณ์</Select.Option>
                {chosenMap === "time" &&
                  pttEmpCurrent.length > 0 &&
                  pttEmpCurrent?.map((opt: any, key: number) => (
                    <Select.Option
                      key={key}
                      value={`[${opt.long}, ${opt.lat}]|${opt.first_name} ${opt.last_name}|${opt.dev_eui} `}
                    >
                      {opt.first_name} {opt.last_name} ( {opt.dev_eui} )
                    </Select.Option>
                  ))}

                {chosenMap === "past" &&
                  pttEmpPast?.map((opt: any, key: number) => (
                    <Select.Option
                      key={key}
                      value={`[${opt.long}, ${opt.lat}]|${opt.first_name} ${opt.last_name}|${opt.dev_eui} `}
                    >
                      {opt.first_name} {opt.last_name} ( {opt.dev_eui} )
                    </Select.Option>
                  ))}
              </Select>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="คัดกรองคน" key="work_permit_no">
            {(chosenMap === "time" || chosenMap === "past") && (
              <>
                <div className="form-group">
                  <Select
                    className="form-control"
                    showSearch
                    placeholder="เลือกใบงาน"
                    onChange={setWorkpermit}
                    value={workpermit}
                  >
                    <Select.Option value="">เลือกใบงาน</Select.Option>
                    {workpermitSelectBox?.length > 0 &&
                      workpermitSelectBox?.map((opt: any, key: number) => (
                        <Select.Option key={key} value={opt.work_permit_no}>
                          {opt.work_permit_no}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <div className="form-group">
                  <Select
                    className="form-control"
                    showSearch
                    placeholder="เลือกผู้คุมงาน"
                    onChange={setSupervisor}
                    value={supervisor}
                  >
                    <Select.Option value="">เลือกผู้คุมงาน</Select.Option>
                    {supervisorSelectBox?.length > 0 &&
                      supervisorSelectBox?.map((opt: any, key: number) => (
                        <Select.Option key={key} value={opt.wpm_supervisor}>
                          {opt.wpm_supervisor}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <div className="form-group">
                  <Select
                    className="form-control"
                    showSearch
                    placeholder="เลือก พื้นที่"
                    onChange={funcLocation}
                    value={locationTxt}
                  >
                    <Select.Option value="">เลือก พื้นที่</Select.Option>
                    {locationSelectBox?.map((opt: any, key: number) => {
                      return (
                        <Select.Option key={key} value={opt.unitname}>
                          {opt.unitname}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
                <div className="form-group">
                  <Select
                    className="form-control"
                    showSearch
                    placeholder="เลือก พื้นที่ย่อย"
                    onChange={funcSubLocation}
                    value={subLocationTxt}
                  >
                    <Select.Option value="">เลือก พื้นที่ย่อย</Select.Option>
                    {subLocationSelectBox?.map((opt: any, key: number) => (
                      <Select.Option key={key} value={opt.subunitname}>
                        {opt.subunitname}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="form-group">
                  <Select
                    className="form-control"
                    showSearch
                    placeholder="เลือก บริษัท"
                    onChange={setCompany}
                    value={company}
                  >
                    <Select.Option value="">เลือก บริษัท</Select.Option>
                    {companySelectBox
                      ?.filter((obj: any) => obj.company_name)
                      ?.map((opt: any, key: number) => (
                        <Select.Option key={key} value={opt.company_name}>
                          {opt.company_name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <button
                  type="button"
                  className="btn btn-save"
                  style={{ marginTop: "3px" }}
                  onClick={handleWorkpermitFilter}
                >
                  ค้นหา
                </button>
                <button
                  type="button"
                  className="btn btn-save"
                  style={{ marginTop: "3px" }}
                  onClick={resetVal}
                >
                  ล้าง
                </button>
              </>
            )}
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};
export default FindEmpModal;
