import moment from "moment";
import Swal from "sweetalert2";
import ExcelJS from "exceljs";

import * as FileSaver from "file-saver";

import { THS_API } from "../../../constants";

const handleExportFile = async ({
  startDate,
  endDate,
  reportTypeQuery,
  record,
  description,
  userData,
}: any) => {
  let dataStartDate = moment(startDate).format("YYYY-MM-DD");
  let dataEndDate = moment(endDate).format("YYYY-MM-DD");

  const { cid, full_name, dev_eui, work_permit_id, work_permit_no } = record;

  const urlObj: any = {
    people: `/report/move_tracking/cid/${cid}/xlsx`,
    dev_eui: `/report/move_tracking/dev_eui/${dev_eui}/xlsx`,
    workpermit_id: `/report/move_tracking/workpermit-id/${work_permit_id}/xlsx`,
  };

  const params = {
    start_date: dataStartDate,
    end_date: dataEndDate,
  };
  Swal.showLoading();
  const res: any = await THS_API.get(urlObj[reportTypeQuery], { params });

  //  const { data: apiData, status } = res;
  // console.log("obj.date", res.data.data.data);
  // console.log("reportTypeQuery", reportTypeQuery);

  let workbook = new ExcelJS.Workbook(); //creating workbook
  const worksheet = workbook.addWorksheet("User");
  // workbook.getCell("A1").value = "ffffff" // ชี้ไปยัง Cell ที่เลือกเพื่อใส่ค่าเข้าไปใน Cell
  const rowUser = worksheet.getRow(3);
  //rowUser.values = header
  rowUser.height = 25;
  const rowHeaderName = worksheet.getColumn(8);
  rowHeaderName.header = "รายงานสรุปผู้ใช้งาน Application ";
  rowHeaderName.width = 40;
  worksheet.getColumn(8).font = {
    family: 4,
    name: "Calibri (Body)",
    size: 16,
    bold: true,
  };
  const rowNameReport = worksheet.getRow(4);
  rowNameReport.getCell(2).value = `ผู้ออกรายงาน : '${userData.name}`;
  const rowRoleReport = worksheet.getRow(5);
  rowRoleReport.getCell(2).value = `สิทธิ์ผู้ออกรายงาน : '${description}`;
  const rowtimeReport = worksheet.getRow(6);
  rowtimeReport.getCell(2).value = `วันที่ออกรรายงาน : '${moment(
    new Date()
  ).format("DD-MM-YYYY")}`;
  const rowtime = worksheet.getRow(7);
  rowtime.getCell(2).value = `เวลาเริ่ม : '${moment(dataStartDate).format(
    "DD-MM-YYYY"
  )}`;
  rowtime.getCell(4).value = `เวลาสิ้นสุด : '${moment(dataEndDate).format(
    "DD-MM-YYYY"
  )}`;
  const rowType = worksheet.getRow(8);
  if (reportTypeQuery === "people") {
    rowType.getCell(2).value = `ประเภทการค้นหา : บุคคล`;
  } else if (reportTypeQuery === "workpermit_id") {
    rowType.getCell(2).value = `ประเภทการค้นหา : ใบอนุญาตทำงาน`;
  } else if (reportTypeQuery === "dev_eui") {
    rowType.getCell(2).value = `ประเภทการค้นหา : หมายเลขอุปกรณ์`;
  }

  const rows = worksheet.getRow(11);
  const columns = [
    "ลำดับ",
    "วัน",
    "เวลา",
    "ชื่อ-นามสกุล",
    "ละติจูด",
    "ลองติจูด",
    "อาคาร",
    "พื้นที่ปฏิบัติงาน",
    "เขตปฏิบัติงาน",
    "ผู้ควบคุมงาน",
    "ใบอนุญาตทำงาน",
    "หมายเลขอุปกรณ์",
  ];
  rows.values = columns;
  const worksheetColumn = [
    { key: "no", width: 5 },
    { key: "dateDate", width: 10 },
    { key: "time", width: 10 },
    { key: "name", width: 15 },
    { key: "lat", width: 20, outlineLevel: 1 },
    { key: "long", width: 20 },
    { key: "building", width: 20 },
    { key: "plant", width: 20 },
    { key: "zone", width: 20 },
    { key: "supervisor", width: 15 },
    { key: "workpermit", width: 40 },
    { key: "dev_eui", width: 20 },
  ];
  worksheet.columns = worksheetColumn;
  rows.eachCell(function (cell) {
    cell.alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    cell.font = { color: { argb: "FFFFFFFF" }, name: "Calibri (Body)" };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "121b1464" },
    };
  });

  const dataReport = res.data.data.data.map((obj: any, index: number) => {
    const date = moment(obj.datetime).format("DD-MM-YYYY");
    const time = moment(obj.datetime).format("HH:mm");
    return {
      ...obj,
      dateDate: date,
      time: time,
      no: index + 1,
    };
  });

  worksheet.addRows(dataReport);
  const buffer = await workbook.xlsx.writeBuffer();

  const title: any = {
    people: full_name,
    dev_eui,
    workpermit_id: work_permit_no,
  };
  FileSaver.saveAs(
    new Blob([buffer]),
    `${title[reportTypeQuery]}_${moment(new Date()).format("DD-MM-YYYY")}.xlsx`
  );

  Swal.close();

  // if (status === 200 && apiData) {
  //   const blob = new Blob([apiData], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  //   });

  //   const fileType: any = {
  //     people: "people",
  //     dev_eui: "device-use",
  //     workpermit_id: "workpermit",
  //   };
  //   const name = full_name || work_permit_no || dev_eui;

  //   const fileName = `report-${fileType[reportTypeQuery]}-${name}_${dataStartDate}-${dataEndDate}.xlsx`;

  //   FileSaver.saveAs(blob, fileName);
  // }
};

export const defaultColumns = ({
  startDate,
  endDate,
  reportTypeQuery,
  description,
  userData,
}: any) => {
  let typeTitle = "ข้อมูลรายบุคคล";
  if (reportTypeQuery === "people") {
    typeTitle = "ข้อมูลรายบุคคล";
  } else if (reportTypeQuery === "workpermit_id") {
    typeTitle = "ข้อมูลหมายเลขใบอนุญาตทำงาน";
  } else if (reportTypeQuery === "dev_eui") {
    typeTitle = "ข้อมูลอุปกรณ์";
  }
  return [
    {
      title: typeTitle,
      sorter: (recordA: any, recordB: any) => {
        if (recordA.work_permit_no)
          return recordA.work_permit_no - recordB.work_permit_no;
        else if (recordA.device_track_human_no)
          return recordA.device_track_human_no - recordB.device_track_human_no;
        else if (recordA.DeviceTrackHumanNo)
          return recordA.DeviceTrackHumanNo - recordB.DeviceTrackHumanNo;
        return recordA.full_name.localeCompare(recordB.full_name);
      },
      render: (record: any) => {
        const { full_name, work_permit_no, dev_eui } = record;
        return (
          <>
            {full_name}
            {work_permit_no}
            {dev_eui}
          </>
        );
      },
    },

    {
      title: "ดาวน์โหลด",
      render: (record: any) => {
        return (
          <img
            className="xls-file"
            src="/images/icon/xls.svg"
            alt="xls"
            onClick={() =>
              handleExportFile({
                startDate,
                endDate,
                reportTypeQuery,
                record,
                description,
                userData,
              })
            }
          />
        );
      },
    },
  ];
};
