import moment from "moment";
import Swal from "sweetalert2";
import _ from "lodash";

import { loadModules } from "esri-loader";

import { setMap } from "reducers/mapBoundaryReducers";

import { THS_API } from "../../constants";

export const addGeometry = async ({
  setStatusSave,
  setIsSubmit,
  setDataGeometry,
  setBlockPickerColor,
  setRemark,
  setLocationName,
  setCheckStatus,
  setCheckStatusTime,
  setStartDate,
  setEndDate,
  setStartTime,
  setEndTime,
  setOpenAddGoemetry,
  dispatch,

  dataGeometry,
  geoMetryData,
  locationName,
  startDate,
  endDate,
  startTime,
  endTime,
  checkStatus,
  checkStatusTime,
  blockPickerColor,
  remark,
  polygonArr,
  iconBoundary,
}: any) => {
  // try{
  setStatusSave("loading");
  const [webMercatorUtils] = await loadModules([
    "esri/geometry/support/webMercatorUtils",
  ]);
  const coordinate = geoMetryData?.map((number: any) => {
    const latLong = webMercatorUtils.xyToLngLat(number[0], number[1]);
    return [latLong[0], latLong[1]];
  });

  if (dataGeometry.isIntersect) {
    setStatusSave("success");
    Swal.fire({
      imageUrl: "assets/img/icon/remove.svg",
      title: "ห้ามวาดเขตซ้อนทับกัน",
    });
    return;
  }
  // if (!dataGeometry.isContained) {
  //   Swal.fire({
  //     imageUrl: "assets/img/icon/remove.svg",
  //     title: "ห้ามวาดพื้นที่นอกขอบเขต",
  //   });
  //   return;
  // }
  if (dataGeometry.id) {
    setIsSubmit(true);

    try {
      if (locationName === "") {
        return;
      }
      let dataEndDate = moment(endDate).format("YYYY-MM-DD");
      let dataStartDate = moment(startDate).format("YYYY-MM-DD");
      let dataStartTime = moment(startDate).format("HH:mm");
      let dataEndTime = moment(endDate).format("HH:mm");
      let dailyStartTime = moment(startTime).format("HH:mm");
      let dailyEndTime = moment(endTime).format("HH:mm");
      if (checkStatusTime === "daily") {
        dataEndDate = "";
        dataStartDate = "";
      }

      const params: any = {
        name: locationName,
        type: checkStatus,
        color: blockPickerColor,
        polygon_id: "123",
        coordinate,
        remark,
        description: remark,
        access_type: checkStatusTime,
        centroid: dataGeometry.centroid,
      };
      if (checkStatus === "restricted_area") {
        if (checkStatusTime === "daily") {
          const hourStart = startTime.getHours() * 60 + startTime.getMinutes();
          const hourEnd = endTime.getHours() * 60 + endTime.getMinutes();

          if (hourStart >= hourEnd) {
            setStatusSave("success");
            Swal.fire({
              icon: "info",
              title: "Please Time Start",
            });
            return;
          }
          params.restrict_time_start = dailyStartTime;
          params.restrict_time_end = dailyEndTime;
          params.access_type = checkStatusTime;
        } else {
          params.restrict_date_start = dataStartDate;
          params.restrict_date_end = dataEndDate;
          params.restrict_time_start = dataStartTime;
          params.restrict_time_end = dataEndTime;
          //  params.access_type = checkStatusTime;
        }
      }
      if (checkStatus === "safety_mustering") {
        params.restrict_date_start = dataStartDate;
        params.restrict_date_end = dataEndDate;
        params.restrict_time_start = dataStartTime;
        params.restrict_time_end = dataEndTime;
        params.access_type = checkStatusTime;
      }
      const url = `/map-boundarys/${dataGeometry.id}`;

      const res = await THS_API.put(url, params);

      if (res.data.statusCode === 201) {
        Swal.fire({
          text: "แก้ไขข้อมูลสำเร็จ",
          imageUrl: "correct.svg",
          imageHeight: 70,
          confirmButtonColor: "#1b1464",
          confirmButtonText: "ตกลง",
        }).then((result) => {
          if (result.isConfirmed) {
            const editIndex = _.findIndex(
              polygonArr,
              (o: any) => o.id === dataGeometry.id
            );
            const newArr = [...polygonArr];
            newArr.splice(editIndex, 1, {
              ...res.data.data,
              checked: true,
            });
            dispatch(setMap(newArr));
            setTimeout(() => {
              setDataGeometry({ id: "", uid: "", ring: [] });
            }, 2000);
          }
          setBlockPickerColor("#428bca");
          setRemark("");
          setIsSubmit(false);
          setLocationName("");
          setCheckStatus("safety_mustering");
          setCheckStatusTime("one_time");
          setStartDate(new Date());
          setEndDate(new Date());
          setStartTime(moment().format("HH:mm"));
          setEndTime(moment().format("HH:mm"));
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "info",
        title: "เกิดข้อผิดพลาด " + error,
      });
    }
  }
  // add polygon
  else {
    if (dataGeometry.isIntersect) {
      setStatusSave("success");
      Swal.fire({
        imageUrl: "assets/img/icon/remove.svg",
        title: "ห้ามวาดเขตซ้อนทับกัน",
      });
      return;
    }
    // if (!dataGeometry.isContained) {
    //   Swal.fire({
    //     imageUrl: "assets/img/icon/remove.svg",
    //     title: "ห้ามวาดพื้นที่นอกขอบเขต",
    //   });
    //   return;
    // }
    if (!dataGeometry.ring.length) {
      Swal.fire({
        icon: "info",
        title: "Please create geometry",
      });
      return;
    }

    // let diff= dailyEndTime.diff(dailyStartTime);

    try {
      setIsSubmit(true);
      let dataEndDate = moment(endDate).format("YYYY-MM-DD");
      let dataStartDate = moment(startDate).format("YYYY-MM-DD");
      let dataStartTime = moment(startDate).format("HH:mm");
      let dataEndTime = moment(endDate).format("HH:mm");
      let dailyStartTime: any = moment(startTime).format("HH:mm");
      let dailyEndTime: any = moment(endTime).format("HH:mm");
      if (locationName === "") {
        setStatusSave("success");
        return;
      }

      const params: any = {
        name: locationName,
        type: checkStatus,
        color: blockPickerColor,
        polygon_id: "123",
        coordinate,
        remark: remark,
        description: remark,
        access_type: checkStatusTime,
        centroid: dataGeometry.centroid,
        area_type: iconBoundary,
      };
      if (checkStatus === "restricted_area") {
        if (checkStatusTime === "daily") {
          const hourStart = startTime.getHours() * 60 + startTime.getMinutes();
          const hourEnd = endTime.getHours() * 60 + endTime.getMinutes();

          if (hourStart >= hourEnd) {
            setStatusSave("success");
            Swal.fire({
              icon: "info",
              title: "Please Time Start",
            });
            return;
          }
          params.restrict_time_start = dailyStartTime;
          params.restrict_time_end = dailyEndTime;
          params.access_type = checkStatusTime;
        } else {
          // const d = new Date().getHours();
          // let month = endTime.getHours();

          // //   let hhhh = startDate.getMonth();
          // console.log("startDate");
          // console.log(endTime);
          // console.log("d");
          // console.log(d);
          params.restrict_date_start = dataStartDate;
          params.restrict_date_end = dataEndDate;
          params.restrict_time_start = dataStartTime;
          params.restrict_time_end = dataEndTime;
          //  params.access_type = checkStatusTime;
        }
      }
      if (checkStatus === "safety_mustering") {
        params.restrict_date_start = dataStartDate;
        params.restrict_date_end = dataEndDate;
        params.restrict_time_start = dataStartTime;
        params.restrict_time_end = dataEndTime;
        params.access_type = checkStatusTime;
        params.area_type = "";
      }
      const res = await THS_API.post("/map-boundarys", params);
      if (res.data.statusCode === 201) {
        Swal.fire({
          text: "บันทึกข้อมูลสำเร็จ",
          imageUrl: "correct.svg",
          imageHeight: 70,
          confirmButtonColor: "#1b1464",
          confirmButtonText: "ตกลง",
        }).then((result) => {
          if (result.isConfirmed) {
            const newArr = [
              ...polygonArr,
              {
                ...res.data.data,
                checked: true,
              },
            ];

            dispatch(setMap(newArr));
            setTimeout(() => {
              setDataGeometry({ id: "", uid: "", ring: [] });
            }, 2000);
            return;
          }
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "info",
        title: "เกิดข้อผิดพลาด " + error,
      });
    }

    setBlockPickerColor("#428bca");
    setRemark("");
    setIsSubmit(false);
    setLocationName("");
    setCheckStatus("safety_mustering");
    setCheckStatusTime("one_time");
    setStartDate(new Date());
    setEndDate(new Date());
    setStartTime(moment().format("HH:mm"));
    setEndTime(moment().format("HH:mm"));
  }
  setOpenAddGoemetry(false);
  // }
  // finally{
  //   setStatusSave("success")
  // }
};
