import axios from "axios";

export const THS_TOKEN_KEY = "refine-auth";
export const THS_REFRESH = "refresh-refine-auth";
export const THS_EXPIRED = "expired-key-time";
export const THS_API_URL = process.env.REACT_APP_API;

export const THS_API = axios.create({
  baseURL: process.env.REACT_APP_API,
  timeout: 0,
  headers: {
    "Content-Type": "application/json",
  },
});

export const allowMenu = (pathname: any, roleData: any) => {
  let header = [];

  if (roleData.data?.viewDashboard) {
    header.push({
      link: "/dashboard",
      text: "Dashboard",
      active: pathname === "/" || pathname === "/dashboard",
    });
  }
  if (roleData.data?.viewWorkPermit) {
    header.push({
      link: "work-permit",
      text: "คนและอุปกรณ์",
      active: pathname === "/work-permit",
    });
  }
  if (roleData.data?.viewMapMonitoring) {
    header.push({
      link: "/map-monitoring",
      text: "People Monitoring",
      active: pathname === "/map-monitoring",
    });
  }
  if (roleData.data?.viewMapBoundary) {
    header.push({
      link: "/map-boundary",
      text: "สร้างขอบเขตการทำงาน",
      active: pathname === "/map-boundary",
    });
  }
  if (roleData.data?.viewDeviceManager) {
    header.push({
      link: "/devices",
      text: "รายการอุปกรณ์",
      active: pathname === "/devices" || pathname === "/import-device",
    });
  }

  if (roleData.data?.viewRoles) {
    header.push({
      link: "role",
      text: "สิทธิ์การเข้าใช้งาน",
      active: pathname === "/role",
    });
  }

  if (roleData.data?.viewEventLog) {
    header.push({
      link: "/event-log",
      text: "บันทึกการใช้งานระบบ",
      active: pathname === "/event-log",
    });
  }

  if (roleData.data?.viewReport) {
    header.push({
      link: "/report",
      text: "รายงานการใช้งานระบบ",
      active: pathname === "/report",
    });
  }

  return header;

  // if (userRole === "admin")
  //   return [
  //     {
  //       link: "/dashboard",
  //       text: "Dashboard",
  //       active: pathname === "/" || pathname === "/dashboard",
  //     },
  //     {
  //       link: "/map-monitoring",
  //       text: "Map Monitoring",
  //       active: pathname === "/map-monitoring",
  //     },
  //     {
  //       link: "/map-boundary",
  //       text: "Map Boundary",
  //       active: pathname === "/map-boundary",
  //     },
  //     {
  //       link: "/devices",
  //       text: "Device Manager",
  //       active: pathname === "/devices" || pathname === "/import-device",
  //     },
  //     {
  //       link: "/report",
  //       text: "Report",
  //       active: pathname === "/report",
  //     },
  //     {
  //       link: "role",
  //       text: "Role",
  //       active: pathname === "/role",
  //     },
  //     {
  //       link: "/event-log",
  //       text: "Event Log",
  //       active: pathname === "/event-log",
  //     },
  //     {
  //       link: "work-permit",
  //       text: "Work Permit",
  //       active: pathname === "/work-permit",
  //     },
  //   ];
  // else if (userRole === "supervisor" || userRole === "landowner")
  //   return [
  //     {
  //       link: "/dashboard",
  //       text: "Dashboard",
  //       active: pathname === "/" || pathname === "/dashboard",
  //     },
  //     {
  //       link: "/map-monitoring",
  //       text: "Map Monitoring",
  //       active: pathname === "/map-monitoring",
  //     },
  //     {
  //       link: "/devices",
  //       text: "Device Manager",
  //       active: pathname === "/devices" || pathname === "/import-device",
  //     },
  //     {
  //       link: "/report",
  //       text: "Report",
  //       active: pathname === "/report",
  //     },
  //     {
  //       link: "/event-log",
  //       text: "Event Log",
  //       active: pathname === "/event-log",
  //     },
  //     {
  //       link: "work-permit",
  //       text: "Work Permit",
  //       active: pathname === "/work-permit",
  //     },
  //   ];
  // return [
  //   {
  //     link: "/dashboard",
  //     text: "Dashboard",
  //     active: pathname === "/" || pathname === "/dashboard",
  //   },
  //   {
  //     link: "/map-monitoring",
  //     text: "Map Monitoring",
  //     active: pathname === "/map-monitoring",
  //   },
  //   {
  //     link: "/devices",
  //     text: "Device Manager",
  //     active: pathname === "/devices" || pathname === "/import-device",
  //   },
  //   {
  //     link: "work-permit",
  //     text: "Work Permit",
  //     active: pathname === "/work-permit",
  //   },
  // ];
};
