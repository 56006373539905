import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    data: []
}
  
export const mapDeviceSlice = createSlice({
    name: "mapdevice",
    initialState,
    reducers: {
      setMapDevice: (state, action) => {
        state.data = action.payload;
      },
    },
  });
  
  export const selectMapDevice = (state) => state.mapdevice.data;
  export const { setMapDevice } = mapDeviceSlice.actions;
  export default mapDeviceSlice.reducer;