import React from "react";
import { Table, Checkbox } from "antd";
import type { ColumnsType } from "antd/es/table";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import { THS_API } from "../../constants";
import { CrudFilters, useTable } from "@pankod/refine-core";
import "./index.css";
import en from "date-fns/locale/en-GB";
const dateSet = (dataDate: any) => {
  const date = new Date(dataDate);

  return date.valueOf();
};

export const EventLog: React.FC = () => {
  const [userNameInput, setUserNameInput] = React.useState("");
  const [roleLists, setRoleList] = React.useState<any>([]);
  const [role, setRole] = React.useState<any>("");
  const [startDate, setStartDate] = React.useState<Date | null>(new Date());
  const [endDate, setEndDate] = React.useState<Date | null>(new Date());

  const [isDateFilter, setIsDateFilter] = React.useState(false);
  const {
    setCurrent,
    setFilters,
    tableQueryResult: { data, status },
  } = useTable({
    resource: "event-log",
    initialCurrent: 1,
    initialPageSize: Number.POSITIVE_INFINITY,
  });

  React.useEffect(() => {
    funcRoleList();
  }, []);

  const funcRoleList = async () => {
    const res = await THS_API.get("/roles");
    setRoleList(res.data.data);
    // const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
  };

  const handleDeviceListFilter = () => {
    const deviceListFilter: CrudFilters = [];

    deviceListFilter.push({
      field: "username",
      operator: "eq",
      value: userNameInput,
    });
    deviceListFilter.push({
      field: "role",
      operator: "eq",
      value: role,
    });
    if (isDateFilter) {
      const dataStartDate = moment(startDate).format("YYYY-MM-DD");
      const dataEndDate = moment(endDate).format("YYYY-MM-DD");

      deviceListFilter.push({
        field: "start_date",
        operator: "eq",
        value: dataStartDate,
      });
      deviceListFilter.push({
        field: "end_date",
        operator: "eq",
        value: dataEndDate,
      });
    }

    setFilters(deviceListFilter, "replace");
    setCurrent(1);
  };
  interface DataType {}
  const columEventlog: ColumnsType<DataType> = [
    {
      title: "ชื่อผู้ใช้",
      sorter: (a: any, b: any) => {
        return a.username.localeCompare(b.username);
      },
      render: (a: any) => {
        return <div style={{ textAlign: "left" }}>{a.username}</div>;
      },
      align: "center",
    },
    {
      title: "ชื่อ-นามสกุล",
      sorter: (a: any, b: any) => {
        return a.firstname.localeCompare(b.firstname);
      },
      render: (a: any) => {
        return (
          <div style={{ textAlign: "left" }}>
            {a.firstname} {a.lastname}
          </div>
        );
      },
      align: "center",
    },
    {
      title: "อีเมล์",
      sorter: (a: any, b: any) => {
        const emailA = a.email || "";
        const emailB = b.email || "";
        return emailA.localeCompare(emailB);
      },
      render: (a: any) => {
        return <div style={{ textAlign: "left" }}>{a.email}</div>;
      },
      align: "center",
    },
    {
      title: "เบอร์ติดต่อ",
      sorter: (a: any, b: any) => {
        return a.phonenumber.localeCompare(b.phonenumber);
      },
      render: (a: any) => {
        return <div style={{ textAlign: "center" }}>{a.phonenumber}</div>;
      },
      align: "center",
    },
    {
      title: "คำอธิบาย",
      sorter: (a: any, b: any) => {
        return a.description.localeCompare(b.description);
      },
      render: (a: any) => {
        return <div style={{ textAlign: "left" }}>{a.description}</div>;
      },
      align: "center",
    },
    {
      title: "สิทธิ์",
      sorter: (a: any, b: any) => {
        const first = a.role_description || "";
        const second = b.role_description || "";
        return first.localeCompare(second);
      },
      render: (a: any) => (
        <div style={{ textAlign: "center" }}>{a.role_description}</div>
      ),
      align: "center",
    },
    {
      title: "วัน",
      sorter: (a: any, b: any) => {
        return dateSet(a.created) - dateSet(b.created);
      },
      render: (a: any) => {
        return (
          <div>
            <div className="form-check">
              {moment(a.created).format("DD/MM/YYYY")}
            </div>
          </div>
        );
      },
      align: "center",
    },
    {
      title: "เวลา",
      sorter: (a: any, b: any) => {
        return dateSet(a.created) - dateSet(b.created);
      },
      render: (a: any) => {
        return (
          <div>
            <div className="form-check">
              {moment(a.created).format("HH:mm:ss")}
            </div>
          </div>
        );
      },
      align: "center",
    },
  ];
  const handleResetFilter = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setIsDateFilter(false);
    setRole("");
    setUserNameInput("");
    const deviceListFilter: CrudFilters = [];

    deviceListFilter.push({
      field: "username",
      operator: "eq",
      value: "",
    });
    deviceListFilter.push({
      field: "role",
      operator: "eq",
      value: "",
    });
    setFilters(deviceListFilter, "replace");
  };
  registerLocale("el", en);
  return (
    <section className="event-log-page">
      <div>
        <div className="header-topic">
          <h1>บันทึกการใช้งานระบบ</h1>
        </div>
        <div className="card-body search-bar">
          <form>
            <div className="row">
              <div className="form-group col-sm-4">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  ></label>
                  <input
                    type="text"
                    className="form-control"
                    id="#"
                    value={userNameInput}
                    placeholder="ชื่อผู้ใช้งาน"
                    onChange={(e) => setUserNameInput(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group col-sm-4">
                <div className="mb-3">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="form-label"
                  ></label>
                  <select
                    className="form-control"
                    value={role}
                    onChange={(event) => setRole(event.target.value)}
                  >
                    <option value="">สิทธิ์</option>
                    {roleLists?.map((role: any, key: any) => {
                      const { name, description } = role;

                      return (
                        <option value={name} key={key}>
                          {description}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group col-sm-2">
                {" "}
                <div
                  className="btn btn-save "
                  style={{ marginTop: "17px" }}
                  onClick={handleDeviceListFilter}
                >
                  ค้นหา
                </div>
              </div>
              <div className="form-group col-sm-2">
                {" "}
                <div
                  className="btn btn-save"
                  style={{ marginTop: "17px" }}
                  onClick={handleResetFilter}
                >
                  ล้าง
                </div>
              </div>

              <div className="form-group col-sm-1 mt-2">
                <Checkbox
                  checked={isDateFilter}
                  onChange={() => setIsDateFilter(!isDateFilter)}
                />
              </div>
              <div className="form-group col-sm-1">
                <div className="mt-3 mb-3">
                  <label className="form-check-label" htmlFor="startDate">
                    เริ่มต้น
                  </label>
                </div>
              </div>
              <div className="form-group col-sm-2">
                <div className="mb-3">
                  <DatePicker
                    id="startDate"
                    disabled={!isDateFilter}
                    className="form-control date"
                    selected={startDate}
                    onChange={(date: any) => {
                      const start = moment(date);
                      const end = moment(endDate);
                      setStartDate(date);
                      //   setStartDateWholeReport(date);
                      if (start.diff(end) > 0) setEndDate(date);
                    }}
                    maxDate={endDate}
                    locale="el"
                    dateFormat="P"
                  />
                </div>
              </div>
              <div className="form-group col-sm-1">
                <div className="mt-3 mb-3">
                  <label className="form-check-label" htmlFor="startDate">
                    สิ้นสุด
                  </label>
                </div>
              </div>
              <div className="form-group col-sm-2">
                <div className="mb-3">
                  <DatePicker
                    id="endDate"
                    disabled={!isDateFilter}
                    className="form-control date"
                    selected={endDate}
                    onChange={(date: any) => {
                      setEndDate(date);
                    }}
                    maxDate={new Date()}
                    locale="el"
                    dateFormat="P"
                    minDate={startDate}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        {status === "loading" && (
          <div className="report-table loading">
            <img src="/images/icon/loading-icon.gif" alt="loading" />
          </div>
        )}
        {status === "success" && (
          <Table
            className="event-log-table"
            columns={columEventlog}
            dataSource={data?.data}
          />
        )}
      </div>
    </section>
  );
};
