/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Swal from "sweetalert2";
import moment from "moment-timezone";
import _ from "lodash";

import { loadModules } from "esri-loader";
import { notification } from "antd";

import {
  getPTTGeometryInit,
  getPTTGeometryPastInit,
  setEmployeePast,
  setIsFilterList,
} from "reducers/mapMonitoring";

import { pttGeometryAddGraphic as pttGeometryPastAddGraphic } from "../EmpPastMap";

import { initSearchGeometry } from "../EsriMapWithNoSSR/_functions";
import { THS_API } from "../../../constants";

export const useFuncFilterWorkPermit = ({
  startDate,
  setDataWorkPermit,
  setDataNoWorkpermit,
  isAllowStateDateChange,
  setIsAllowStateDateChange,
  setIsStartDateLoading,
}: any) => {
  React.useEffect(() => {
    const funcFilterWorkPermit = async () => {
      if (isAllowStateDateChange) {
        setIsStartDateLoading(true);
        let dataStartDate = moment(startDate).format("YYYY-MM-DD");

        const params = {
          type: "period",
          start_date: dataStartDate,
        };

        const res = THS_API.get("/work-permit/filterWorkpermit", {
          params,
        });

        const res2 = THS_API.get("map-monitoring/movetracking-filter/no-wpm", {
          params: {
            date: dataStartDate,
          },
        });

        const [dataWorkPermitPayload, dataNoWorkpermitPayload] =
          await Promise.all([res, res2]);

        setDataWorkPermit(dataWorkPermitPayload?.data?.data?.workPermit);
        setDataNoWorkpermit(dataNoWorkpermitPayload.data.data);

        setIsAllowStateDateChange(false);
        setIsStartDateLoading(false);
      }
    };

    funcFilterWorkPermit();
  }, [isAllowStateDateChange]);
};

export const useFuncFilter = ({ setLocationSelectBox }: any) => {
  React.useEffect(() => {
    const funcFilter = async () => {
      const res = await THS_API.get("/map-monitoring/filter");

      const { location } = _.filter(
        res.data.data,
        (o: any) => "พื้นที่กระบวนการผลิต" === o.name
      )[0];

      setLocationSelectBox(location);
    };

    funcFilter();
  }, []);
};

export const useGoToMapFunctionStart = ({
  view,
  pttGeometry,
  isFilterList,
  mapZoom,
  isMapFinishGoTo,
  setIsMapFinishGoTo,
  buttonGroupValue,
  isSaveMonitoringLoaded,
  mapInitApiStatus,
}: any) => {
  React.useEffect(() => {
    const callApi = async () => {
      if (isSaveMonitoringLoaded && mapInitApiStatus === "done" && view) {
        if (
          pttGeometry.length &&
          !isFilterList &&
          view &&
          !isMapFinishGoTo &&
          buttonGroupValue?.filterboxPriority
        ) {
          const target = await initSearchGeometry({
            pttGeometry,
            mapZoom,
          });
          view?.goTo({
            target,
          });
        }
        setIsMapFinishGoTo(true);
      }
    };
    callApi();
  }, [view, pttGeometry, isFilterList, buttonGroupValue?.filterboxPriority]);
};

export const useGoToMapFunction = ({
  pttGeometry,
  pttGeometryPast,
  isFilterList,
  mapZoom,
  pastView,
  view,
  chosenMap,
  isFilterBoxSubmit,
}: any) => {
  React.useEffect(() => {
    const callApi = async () => {
      if (!isFilterList) {
        if (chosenMap === "past" && pttGeometryPast.length) {
          const target = await pttGeometryPastAddGraphic({
            pttGeometry: pttGeometryPast,
            mapZoom,
          });
          pastView?.goTo({
            target,
          });
        } else if (
          chosenMap === "time" &&
          pttGeometry.length &&
          isFilterBoxSubmit
        ) {
          const target = await initSearchGeometry({
            pttGeometry,
            mapZoom,
          });
          view?.goTo({
            target,
          });
        }
      }
    };
    callApi();
  }, [pttGeometry, pttGeometryPast, isFilterList, pastView, view]);
};

export const useAddPttGeometryMap = ({
  view,
  map,
  mapZoom,
  pttGeometry,
}: any) => {
  React.useEffect(() => {
    const callApi = async () => {
      const polygonSearchLayer = map?.findLayerById(
        "ptt-geometry-search-layer"
      );
      const polygonSearchTextLayer = map?.findLayerById(
        "ptt-geometry-search-layer-text"
      );
      polygonSearchLayer?.removeAll();
      polygonSearchTextLayer?.removeAll();
      if (view && mapZoom >= 14) {
        const [Graphic] = await loadModules(["esri/Graphic"]);

        pttGeometryAddGraphic({
          polygonSearchLayer,
          Graphic,
          pttGeometry,
          mapZoom,
        });
        pttGeometryAddText({
          polygonSearchTextLayer,
          Graphic,
          pttGeometry,
          mapZoom,
        });
      }
    };
    callApi();
  }, [view, map, pttGeometry]);
};

export const useSaveMonitoringAPI = ({
  isSaveMonitoringLoaded,
  filterBoxValue,
  setLocationSelectBox,
  setLocationSelectVal,
  setChosenLocationBoxName,
  setSubLocationSelectBox,
  setSubLocationSelectVal,
  setChosenSubLocationBoxName,
  setStartDate,
  setStartDatePast,
  setStartTime,
  setEndTime,
  setDateType,
  setChosenMap,
  setDeviceSearchType,
  setCheckWorkpermit,
  setCheckWorkpermitdata,
  setCheckDevice,
  setWorkCid,
  setDeviceEUI,
  setDataWorkPermit,
  setFirstName,
  setLastName,
  setDataMapDevice,
  dispatch,
}: any) => {
  React.useEffect(() => {
    const callApi = async () => {
      if (isSaveMonitoringLoaded && Object.keys(filterBoxValue).length) {
        const {
          dateType,
          locationSelectVal,
          subLocationSelectVal,
          chosenLocationBoxName,
          chosenSubLocationBoxName,
          startDate,
          startTime,
          endTime,
          deviceSearchType,
          cid,
          workCid,
          deviceEUI,
          firstName,
          lastName,
          checkWorkpermitdata,
          hasPttGeometry,
          hasPttGeometryPast,
        } = filterBoxValue;

        const res = await THS_API.get("/map-monitoring/filter");

        const { location: locationSelectBox } = _.filter(
          res.data.data,
          (o: any) => "พื้นที่กระบวนการผลิต" === o.name
        )[0];

        setLocationSelectBox(locationSelectBox);
        setLocationSelectVal(locationSelectVal);
        setChosenLocationBoxName(chosenLocationBoxName);

        const subLocation = _.filter(
          locationSelectBox,
          (o: any) => locationSelectVal === o.unitid
        )[0]?.sub_location;

        setSubLocationSelectBox(subLocation);
        setSubLocationSelectVal(subLocationSelectVal);
        setChosenSubLocationBoxName(chosenSubLocationBoxName);

        if (startDate) {
          setStartDate(new Date(startDate));
          setStartDatePast(new Date(startDate));
        }
        if (startTime) setStartTime(new Date(startTime));
        if (endTime) setEndTime(new Date(endTime));

        setDateType(dateType);
        setChosenMap(dateType);
        setDeviceSearchType(deviceSearchType);

        setCheckWorkpermit(true);
        setCheckWorkpermitdata(true);

        setCheckDevice(true);
        if (
          cid === "" &&
          deviceEUI === "" &&
          workCid === "" &&
          firstName === ""
        )
          setCheckDevice(false);
        else setCheckDevice(true);

        setWorkCid(workCid);

        setDeviceEUI(deviceEUI);
        let dataStartDate = moment(startDate).format("YYYY-MM-DD");
        const params = {
          type: dateType,
          start_date: dataStartDate,
        };
        const movetrackingFilterRes = await THS_API.get(
          "/work-permit/filterWorkpermit",
          {
            params,
          }
        );

        setDataWorkPermit(movetrackingFilterRes?.data?.data?.workPermit);

        setFirstName(firstName);
        setLastName(lastName);

        //set map value

        if (dateType === "time" && hasPttGeometry) {
          const input: any = {
            zone: "พื้นที่กระบวนการผลิต",
            unitid: locationSelectVal,
            subunitid: subLocationSelectVal,
          };
          await dispatch(getPTTGeometryInit(input));
          dispatch(setIsFilterList(false));
        } else if (dateType === "past") {
          if (hasPttGeometryPast) {
            const input: any = {
              unitid: locationSelectVal,
              subunitid: subLocationSelectVal,
              startDate: moment(startDate)?.format("YYYY-MM-DD"),
              startTime: moment(startTime)?.format("HH:mm"),
            };

            await dispatch(getPTTGeometryPastInit(input));
            dispatch(setIsFilterList(false));
          }
          const params: any = {};
          if (startDate) {
            let dataStartDate = moment(startDate).format("YYYY-MM-DD");
            params.date = dataStartDate;
          }

          if (startTime) {
            let dataStartTime = moment(startTime).format("HH:mm");
            params.time = dataStartTime;
          }
          const response2 = await THS_API.get("/map-monitoring/by-time", {
            params,
          });
          const pttEmployee = response2.data.data;

          dispatch(setEmployeePast(pttEmployee));
          if (!response2.data.data.length) {
            setTimeout(() => {
              notification.open({
                message: "ไม่พบผลการค้นหาของพนักงาน",
              });
            }, 800);
          }
        } else if (dateType === "period") {
          setCheckWorkpermit(checkWorkpermitdata);

          if (
            cid === "" &&
            deviceEUI === "" &&
            workCid === "" &&
            firstName === ""
          ) {
            setCheckDevice(false);
            return;
          }
          setChosenMap(dateType);
          let dataStartDate = moment(startDate).format("YYYY-MM-DD");
          let dataStartTime = moment(startTime).format("HH:mm");
          let dataEndTime = moment(endTime).format("HH:mm");

          const datadevice = async () => {
            const params = {
              start_date: dataStartDate,
              end_date: dataStartDate,
              start_time: dataStartTime,
              end_time: dataEndTime,
            };

            const res = await THS_API.get(
              `/map-monitoring/dev_eui/${deviceEUI}`,
              {
                params,
              }
            );

            setDataMapDevice(res.data.data.data);
            if (!res.data.data.data.length) {
              setTimeout(() => {
                notification.open({
                  message: "ไม่พบเส้นทางการเดินของบุคคลตามช่วงเวลา",
                  // description: "New description.",
                });
              }, 800);
            }
          };
          const dataIDCard = async () => {
            const params = {
              start_date: dataStartDate,
              end_date: dataStartDate,
              start_time: dataStartTime,
              end_time: dataEndTime,
            };

            const res = await THS_API.get(`/map-monitoring/cid/${workCid}`, {
              params,
            });

            setDataMapDevice(res.data.data.data);
            if (!res.data.data.data.length) {
              setTimeout(() => {
                notification.open({
                  message: "ไม่พบเส้นทางการเดินของบุคคลตามช่วงเวลา",
                });
              }, 800);
            }
          };
          const dataName = async () => {
            const params = {
              start_date: dataStartDate,
              end_date: dataStartDate,
              start_time: dataStartTime,
              end_time: dataEndTime,
              first_name: firstName,
              last_name: lastName,
            };

            const res = await THS_API.get("/map-monitoring/full-name", {
              params,
            });

            setDataMapDevice(res.data.data.data);
            if (!res.data.data.data.length) {
              setTimeout(() => {
                notification.open({
                  message: "ไม่พบเส้นทางการเดินของบุคคลตามช่วงเวลา",
                  // description: "New description.",
                });
              }, 800);
            }
          };

          if (dateType === "period") {
            if (deviceSearchType === "deviceNo") {
              datadevice();
            } else if (deviceSearchType === "idCard") {
              dataIDCard();
            } else {
              dataName();
            }
          }
        }

        // end set map value

        Swal.close();
      }
    };
    callApi();
  }, [isSaveMonitoringLoaded]);
};

const orderedPttGeometry = (pttGeometry: any) => {
  return pttGeometry
    .filter((polygon: any) => polygon.display)
    .map((polygon: any) => {
      const arrOrder: any = {
        AREA: 1,
        BUILDING: 2,
        PLANT: 0,
      };
      return { ...polygon, order: arrOrder[polygon.layer_name] };
    })

    .sort((a: any, b: any) => a.order - b.order);
};
const createGeometry = ({ vertices }: any) => {
  return {
    type: "polygon",
    rings: vertices,
  };
};
const createSymbol = ({ color, style, width, outlineColor }: any) => {
  return {
    type: "simple-fill",
    style,
    color,
    outline: {
      color: outlineColor,
      width,
    },
  };
};
const pttGeometryAddGraphic = ({
  polygonSearchLayer,
  Graphic,
  pttGeometry,
  mapZoom,
}: any) => {
  const ordered = orderedPttGeometry(pttGeometry);

  ordered.map(async (polygon: any) => {
    const { layer_name, shape_geometry } = polygon;
    const vertices = shape_geometry;
    const arrColor: any = {
      AREA: [0, 255, 0, 0.3],
      BUILDING: "transparent",
      PLANT: [4, 188, 255, 0.3],
    };

    let color = "transparent";

    if (
      (mapZoom >= 14 && mapZoom <= 19 && layer_name === "PLANT") ||
      (mapZoom > 17 && mapZoom <= 21 && layer_name === "AREA") ||
      (mapZoom > 18 && layer_name === "BUILDING")
    )
      color = arrColor[layer_name];

    const geometry = createGeometry({ vertices });

    const symbol = createSymbol({
      color,
      style: "solid",
      width: 1,
      outlineColor: color,
    });
    const newDevelopmentGraphic = new Graphic({
      geometry,
      symbol,
    });

    polygonSearchLayer?.add(newDevelopmentGraphic);
    return null;
  });
};

const pttGeometryAddText = ({
  polygonSearchTextLayer,
  Graphic,
  pttGeometry,
  mapZoom,
}: any) => {
  orderedPttGeometry(pttGeometry).map(async (polygon: any) => {
    const { shape_geometry, layer_name, unitname, subunitname, active } =
      polygon;

    const vertices = shape_geometry;

    let polygonTxt;
    if (layer_name === "PLANT") polygonTxt = unitname;
    else if (layer_name === "AREA" || layer_name === "BUILDING")
      polygonTxt = subunitname;

    let fontSize;
    let total = 0;

    if (
      (mapZoom >= 14 && mapZoom <= 19 && layer_name === "PLANT") ||
      (mapZoom > 17 && mapZoom <= 21 && layer_name === "AREA") ||
      (mapZoom > 18 && layer_name === "BUILDING")
    ) {
      fontSize = 10;
      const backgroundtext = polygonTxt.length;
      total = backgroundtext * 10 + 20;
    }

    const textSymbol = {
      type: "text", // autocasts as new TextSymbol()
      color: "white",
      haloColor: "black",
      haloSize: "5px",
      text: `${polygonTxt} : ${active}`,
      xoffset: 0,
      yoffset: -3,
      font: {
        // autocast as new Font()
        size: fontSize,
        family: "sans-serif",
        weight: "bold",
      },
    };
    let symbolbackground = {
      type: "picture-marker", // autocasts as new PictureFillSymbol()
      url: "./images/black-color.jpg",
      width: `${total}px`,
      height: "25px",
      outline: {
        style: "solid",
      },
    };
    const geometry = createGeometry({ vertices });
    const blackground = new Graphic({
      geometry,
      symbol: symbolbackground,
    });

    const newDevelopmentGraphicText = new Graphic({
      geometry,
      symbol: textSymbol,
    });

    polygonSearchTextLayer?.addMany([blackground, newDevelopmentGraphicText]);
    return null;
  });
};
