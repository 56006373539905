import React from "react";

import Draggable from "react-draggable";

import { useSelector, useDispatch } from "react-redux";

import { Checkbox } from "@pankod/refine-antd";

import { selectMap, setMap } from "reducers/mapBoundaryReducers";

import "./index.css";

export const FilterList = ({ isOpen, setIsFilter, setSideBarFilter }: any) => {
  const [deltaPosition, setDeltaPosition] = React.useState({
    x: 0,
    y: 0,
  });
  const [activeDrags, setActiveDrags] = React.useState(0);
  const dispatch = useDispatch();
  const polygonArr = useSelector(selectMap);

  const editPolygonArr = (list: any, key: number) => {
    const newArr = [...polygonArr];
    newArr.splice(key, 1, {
      ...list,
      checked: !list.checked,
    });
    dispatch(setMap(newArr));
  };

  const handleDrag = (e: any, ui: any) => {
    const { x, y } = deltaPosition;
    setDeltaPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    });
  };
  const onStart = () => {
    const newVal = activeDrags + 1;
    setActiveDrags(newVal);
  };

  const onStop = () => {
    const newVal = activeDrags - 1;
    setActiveDrags(newVal);
  };
  if (isOpen)
    return (
      <Draggable onDrag={handleDrag} onStart={onStart} onStop={onStop}>
        <div className="map-boundary-filter">
          <h2 className="accordion-header">กรองการค้นหา</h2>
          <img
            className="close-modal"
            src="assets/img/icon/close-menu.png"
            onClick={() => setIsFilter(false)}
            alt="close"
          />
          <div className="accordion-body">
            <ul>
              {polygonArr?.map((list: any, key: number) => (
                <li key={key}>
                  <Checkbox
                    onClick={() => editPolygonArr(list, key)}
                    checked={list.checked}
                  >
                    {list.name}
                  </Checkbox>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Draggable>
    );
  return <></>;
};

export default FilterList;
