/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import classnames from "classnames";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { setMap, selectMap } from "reducers/mapBoundaryReducers";
import moment from "moment-timezone";
import { THS_API } from "../../constants";
import "./index.css";

const Gsp = ({
  txt,
  color,
  id,
  restrict_date_end,
  restrict_date_start,
  access_type,
  updated,
  restrict_time_end,
  restrict_time_start,
  remark,
  created_by,
  type,
  ...rest
}: any) => {
  const [isGsp, setIsGsp] = React.useState(false);
  const dispatch = useDispatch();
  const polygonArr = useSelector(selectMap);
  const handleCollapse = () => {
    setIsGsp(!isGsp);
  };

  const deletegeometry = async (id: any) => {
    Swal.fire({
      title: "คุณแน่ใจที่จะลบ?",

      showCancelButton: true,

      confirmButtonColor: "#d33",
      cancelButtonColor: "#ABB2B9 ",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          const res = await THS_API.delete(`/map-boundarys/${id}`);

          if (res.data.statusCode === 200) {
            Swal.fire({
              iconColor: "#F71A0C",
              icon: "success",
              color: "#F71A0C",
              title: "Delete Done!",
              showConfirmButton: false,
              timer: 1500,
            });

            const newArray = polygonArr.filter((a: any) => a.id !== id);

            dispatch(setMap(newArray));
          }
        } catch (error) {
          Swal.fire({
            icon: "info",
            title: "เกิดข้อผิดพลาด " + error,
          });
        }
      }
    });
  };

  return (
    <div className={`gsp accordion-item ${rest.key}`}>
      <h2 className="accordion-header" id="headingTwo">
        <button
          className={classnames("accordion-button", {
            collapsed: isGsp,
          })}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseTwo"
          aria-expanded="false"
          aria-controls="collapseTwo"
          onClick={handleCollapse}
        >
          <div className="color-box" style={{ background: color }} />
          <h1>{txt}</h1>
        </button>
      </h2>
      <div
        className={classnames(
          "accordion-collapse collapse",
          {
            show: isGsp,
          },
          {
            collapseShowBoundary: isGsp,
          }
        )}
        aria-labelledby="headingTwo"
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body">
          <div className="check">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name={`flexRadioDefault${id}`}
                id={`flexRadioDefault1${id}`}
                checked={type === "restricted_area"}
              />
              <label
                className="form-check-label"
                htmlFor={`flexRadioDefault1${id}`}
                style={{ fontSize: "15px" }}
              >
                พื้นที่ห้ามเข้า
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name={`flexRadioDefault${id}`}
                id={`flexRadioDefault2${id}`}
                checked={type === "safety_mustering"}
              />
              <label
                className="form-check-label"
                htmlFor="flexRadioDefault2"
                style={{ fontSize: "15px" }}
              >
                จุดรวมพล
              </label>
            </div>
          </div>
          <div className="grid-story2">
            {type === "restricted_area" && (
              <div className="check">
                <div className="form-check ">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={access_type === "one_time"}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault3"
                    style={{ fontSize: "18px" }}
                  >
                    พื้นที่หวงห้ามชั่วคราว
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={access_type === "daily"}
                  />
                  <label
                    className="form-check-label "
                    htmlFor="flexRadioDefault4"
                    style={{ fontSize: "18px" }}
                  >
                    พื้นที่หวงห้ามถาวร
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="grid-story2">
            {type === "restricted_area" && (
              <div className="form-group row">
                <label htmlFor="campaign" className="col-sm-2 col-form-label">
                  เริ่มต้น
                </label>
                {access_type === "one_time" && (
                  <div className="col-sm-8 datatime">
                    {moment(restrict_date_start).format("DD/MM/YYYY")}:
                    {restrict_time_start}
                  </div>
                )}
                {access_type === "daily" && (
                  <div className="col-sm-8 datatime">{restrict_time_start}</div>
                )}
              </div>
            )}
            {type === "restricted_area" && (
              <div className="form-group row">
                <label htmlFor="campaign" className="col-sm-2 col-form-label">
                  สิ้นสุด
                </label>
                {access_type === "one_time" && (
                  <div className="col-sm-8 datatime">
                    {moment(restrict_date_end).format("DD/MM/YYYY")}:
                    {restrict_time_end}
                  </div>
                )}
                {access_type === "daily" && (
                  <div className="col-sm-8 datatime">{restrict_time_end}</div>
                )}
              </div>
            )}
          </div>
          <div className="edit">
            <a style={{ marginTop: "10px" }} onClick={() => deletegeometry(id)}>
              <img
                src="/images/icon/bin.svg"
                width={20}
                height={20}
                alt="bin"
              />{" "}
            </a>
            {/* <a style={{ marginTop: "10px" }}>
              <img
                src="/images/icon/edit.svg"
                width={20}
                height={20}
                alt="edit"
              />
            </a> */}
          </div>
          <div className="grid-story2 point-location">
            <div>
              <h1 style={{ fontWeight: "bold", fontSize: "18px" }}>หมายเหตุ</h1>
            </div>
          </div>
          <div className="grid-story2 point-location">
            <div>
              <div
                className="form-group"
                style={{
                  marginRight: "5px",
                  width: "100%",
                }}
              >
                <textarea
                  id="remark"
                  name="remark"
                  value={remark}
                  rows={4}
                  cols={50}
                />
              </div>
            </div>
          </div>
          <div className="grid-story2 point-location">
            <div>
              <div
                className="form-group"
                style={{ marginRight: "5px", marginTop: "20px" }}
              >
                ผู้ที่สร้าง {created_by}
              </div>
              <div
                className="form-group"
                style={{ marginRight: "5px", marginTop: "20px" }}
              >
                วันที่และเวลาที่สร้าง{" "}
                {moment(updated).format("DD/MM/YYYY HH.mm")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Gsp;
