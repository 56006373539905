/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment-timezone";

import { loadModules } from "esri-loader";
import { notification } from "antd";

import {
  getPTTGeometry,
  getPTTGeometryPast,
  setEmployeePast,
  setIsFilterList,
  setFilterBoxValue,
  setButtonGroupValue,
} from "reducers/mapMonitoring";

import { THS_API } from "../../../constants";

export const handleSearchMapMonitoringFunc = async ({
  dateType,
  cid,
  deviceEUI,
  workCid,
  firstName,
  lastName,
  startDate,
  startTime,
  endTime,
  locationSelectVal,
  subLocationSelectVal,
  userData,
  filterBoxValue,
  buttonGroupValue,
  chosenLocationBoxName,
  chosenSubLocationBoxName,
  deviceSearchType,
  setSideBarFilter,
  setIsApiLoading,
  setChosenMap,
  setCheckWorkpermit,
  checkWorkpermitdata,
  setCheckDevice,
  setDataMapDevice,
  setIsModalVisible,
  setCid,
  setFirstName,
  setLastName,
  setWorkPermitType,
  setDeviceEUI,
  setWorkCid,
  setIsSubmit,
  dispatch,
}: any) => {
  setSideBarFilter(false);

  setIsApiLoading(true);
  let pttGeometryResponse;
  let pttGeometryPastResponse;
  try {
    if (dateType === "time") {
      const input: any = {
        zone: "พื้นที่กระบวนการผลิต",
        unitid: locationSelectVal,
        subunitid: subLocationSelectVal,
      };
      pttGeometryResponse = await dispatch(getPTTGeometry(input));
    } else if (dateType === "past") {
      const params: any = {};

      if (startDate) {
        let dataStartDate = moment(startDate).format("YYYY-MM-DD");
        params.date = dataStartDate;
      }

      if (startTime) {
        let dataStartTime = moment(startTime).format("HH:mm");
        params.time = dataStartTime;
      }
      const response2 = THS_API.get("/map-monitoring/by-time", {
        params,
      });

      const input: any = {
        unitid: locationSelectVal,
        subunitid: subLocationSelectVal,
        startDate: moment(startDate)?.format("YYYY-MM-DD"),
        startTime: moment(startTime)?.format("HH:mm"),
      };

      const response3 = dispatch(getPTTGeometryPast(input));

      const [mapMonitoringByTimePayload, mapMonitoringPTTGeometryPastPayload] =
        await Promise.all([response2, response3]);

      pttGeometryPastResponse = mapMonitoringPTTGeometryPastPayload;

      const pttEmployee = mapMonitoringByTimePayload.data.data;

      dispatch(setEmployeePast(pttEmployee));

      if (!pttEmployee.length) {
        setTimeout(() => {
          notification.open({
            message: "ไม่พบผลการค้นหาของพนักงาน",
          });
        }, 800);
      }
    } else if (dateType === "period") {
      setCheckWorkpermit(checkWorkpermitdata);

      if (
        cid === "" &&
        deviceEUI === "" &&
        workCid === "" &&
        firstName === ""
      ) {
        setCheckDevice(false);
        return;
      }
      let dataStartDate = moment(startDate).format("YYYY-MM-DD");
      let dataStartTime = moment(startTime).format("HH:mm");
      let dataEndTime = moment(endTime).format("HH:mm");

      const datadevice = async () => {
        const params = {
          start_date: dataStartDate,
          end_date: dataStartDate,
          start_time: dataStartTime,
          end_time: dataEndTime,
        };

        const res = await THS_API.get(`/map-monitoring/dev_eui/${deviceEUI}`, {
          params,
        });

        setDataMapDevice(res.data.data.data);
        if (!res.data.data.data.length) {
          setTimeout(() => {
            notification.open({
              message: "ไม่พบเส้นทางการเดินของบุคคลตามช่วงเวลา",
              // description: "New description.",
            });
          }, 800);
        }
      };
      const dataIDCard = async () => {
        const params = {
          start_date: dataStartDate,
          end_date: dataStartDate,
          start_time: dataStartTime,
          end_time: dataEndTime,
        };

        const res = await THS_API.get(`/map-monitoring/cid/${workCid}`, {
          params,
        });

        setDataMapDevice(res.data.data.data);
        if (!res.data.data.data.length) {
          setTimeout(() => {
            notification.open({
              message: "ไม่พบเส้นทางการเดินของบุคคลตามช่วงเวลา",
            });
          }, 800);
        }
      };
      const dataName = async () => {
        const params = {
          start_date: dataStartDate,
          end_date: dataStartDate,
          start_time: dataStartTime,
          end_time: dataEndTime,
          first_name: firstName,
          last_name: lastName,
        };

        const res = await THS_API.get("/map-monitoring/full-name", {
          params,
        });

        setDataMapDevice(res.data.data.data);
        if (!res.data.data.data.length) {
          setTimeout(() => {
            notification.open({
              message: "ไม่พบเส้นทางการเดินของบุคคลตามช่วงเวลา",
              // description: "New description.",
            });
          }, 800);
        }
      };

      if (deviceSearchType === "deviceNo") {
        datadevice();
      } else if (deviceSearchType === "idCard") {
        dataIDCard();
      } else {
        dataName();
      }
    }

    setChosenMap(dateType);
    setIsModalVisible(false);
    setCid("");
    setFirstName("");
    setLastName("");
    setWorkPermitType("workPermit");
    setDeviceEUI("");
    setWorkCid("");
    setCheckWorkpermit(true);
    setIsSubmit(true);

    dispatch(setIsFilterList(false));
  } finally {
    setIsApiLoading(false);
  }

  await dispatch(
    setButtonGroupValue({
      ...buttonGroupValue,
      userId: userData.id,
      filterboxPriority: true,
      filterBoxBoundaryPriority: false,
    })
  );

  await dispatch(
    setFilterBoxValue({
      ...filterBoxValue,
      userId: userData.id,
      dateType,
      locationSelectVal,
      subLocationSelectVal,
      chosenLocationBoxName,
      chosenSubLocationBoxName,
      checkWorkpermitdata,
      startDate,
      startTime,
      endTime,
      deviceSearchType,
      cid,
      workCid,
      deviceEUI,
      firstName,
      lastName,
      hasPttGeometry: pttGeometryResponse?.payload?.length > 0,
      hasPttGeometryPast: pttGeometryPastResponse?.payload?.length > 0,
    })
  );
};

export const shapeGeometry = (shapeGeometryArr: any) => {
  const { length } = shapeGeometryArr;
  return JSON.stringify(
    shapeGeometryArr
      .reduce(
        (total: any, num: any) => [total[0] + num[0], total[1] + num[1]],
        [0, 0]
      )
      .map((a: any) => a / length)
  );
};

const pttEmployeeAddGraphic = ({
  employeeLayer,
  Graphic,
  pttEmployee,
}: any) => {
  pttEmployee.map((emp: any) => {
    const {
      lat,
      long,
      first_name,
      last_name,
      people_type,
      warning_status,
      dev_eui,
      workpermit_no,
      is_notify_on_position,
      wpm_company_name,
      created,
      people_type_id,
      card_type,
    } = emp;
    const geometry = {
      //Create a point
      type: "point",
      longitude: long,
      latitude: lat,
    };

    let imageurl;

    if (card_type === 1 || card_type === 2) {
      imageurl = "/images/icon/visitor.png";
    } else {
      if (people_type_id === "P001") {
        imageurl = "/images/icon/safety.png";
      } else if (people_type_id === "P002") {
        imageurl = "/images/icon/fire-watcher.png";
      } else if (people_type_id === "P056") {
        imageurl = "/images/icon/พนักงาน-PTT.png";
      } else if (people_type_id === "P095" || people_type_id === "P096") {
        imageurl = "/images/icon/visitor.png";
      } else {
        imageurl = "/images/icon/Contractor.png";
      }
    }
    const symbol = {
      type: "picture-marker",
      url: imageurl,
      width: "24px",
      height: "24px",
    };
    const newGraphic = new Graphic({
      geometry,
      symbol,
      attributes: {
        workpermit_no,
        dev_eui,
        first_name,
        last_name,
        position: people_type,
        company: wpm_company_name,
        time: moment(created).format("DD/MM/YYYY:HH.mm"),
      },
      popupTemplate: {
        title: "ข้อมูลผู้ถืออุปกรณ์",
        content: [
          {
            type: "fields",
            fieldInfos: [
              {
                fieldName: "workpermit_no",
                label: "รหัสใบอนุญาต",
              },
              {
                fieldName: "dev_eui",
                label: "หมายเลขอุปกรณ์",
              },
              {
                fieldName: "first_name",
                label: "ชื่อ",
              },
              {
                fieldName: "last_name",
                label: "นามสกุล",
              },
              {
                fieldName: "position",
                label: "ตำแหน่ง",
              },
              {
                fieldName: "company",
                label: "บริษัท",
              },
              {
                fieldName: "time",
                label: "วันที่และเวลา",
              },
            ],
          },
        ],
      },
    });

    const geometry2 = {
      //Create a point
      type: "point",
      longitude: long,
      latitude: lat,
    };

    const symbol2 = {
      type: "picture-marker",
      url: "./images/icon/exclamation.png",
      width: "10px",
      height: "10px",
    };
    const newGraphic2 = new Graphic({
      geometry: geometry2,
      symbol: symbol2,
    });

    if (warning_status.length && is_notify_on_position)
      employeeLayer?.addMany([newGraphic, newGraphic2]);
    else employeeLayer?.add(newGraphic);
    return null;
  });
};

export const callEmployee = async ({ map, pttEmployee }: any) => {
  if (map) {
    const [Graphic, GraphicsLayer] = await loadModules([
      "esri/Graphic",
      "esri/layers/GraphicsLayer",
    ]);

    let employeeLayer = map.findLayerById("employee-layer");
    if (!employeeLayer) {
      const employeeLayer = new GraphicsLayer({
        id: "employee-layer",
      });
      map.add(employeeLayer);
    }

    if (pttEmployee.length)
      pttEmployeeAddGraphic({ employeeLayer, Graphic, pttEmployee });
  }
};
