import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { THS_API } from "../../../constants";
export default function ChartDevice() {
  require("highcharts/modules/exporting")(Highcharts);

  React.useEffect(() => {
    funcTotalDeviceList();
  }, []);
  const [deviceTotalList, setDeviceTotalList] = React.useState([]);
  const funcTotalDeviceList = async () => {
    const res = await THS_API.get("/dashboard/summary-device");

    setDeviceTotalList(res.data.data);
  };

  const chartOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      height: 300,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: "สถานะอุปกรณ์ทั้งหมด",
      style: {
        fontSize: 16,
      },
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.y}</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        colors: ["#10C82C ", "#F9FC1B", "#889195 ", "#EF0202", "#EF9E07"],
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.point.y === 0 ? "" : this.point.y;
          },
          distance: -40,
          style: {
            fontSize: 14,
          },
        },
      },
    },
    exporting: {
      enabled: false,
    },
    series: [
      {
        name: "จำนวน",
        data: [
          {
            name: "กำลังใช้งาน",
            y: deviceTotalList?.device_occupied,
          },
          {
            name: "พร้อมใช้งาน",
            y: deviceTotalList?.device_online,
          },
          {
            name: "ไม่พร้อมใช้งาน",
            y: deviceTotalList?.device_offline,
          },
          {
            name: "อุปกรณ์เสีย",
            y: deviceTotalList?.device_broken,
          },
          {
            name: "อุปกรณ์สูญหาย",
            y: deviceTotalList?.device_lost,
          },
        ],
        showInLegend: true,
      },
    ],
  };
  return (
    <div>
      <HighchartsReact options={chartOptions} highcharts={Highcharts} />
    </div>
  );
}
