import React from "react";

import "./index.css";

const Measurement = ({ view, measurement }: any) => {
  const [scroll, setScroll] = React.useState<number>(0);

  const measureLine = () => {
    const type = view.type;
    measurement.activeTool =
      type.toUpperCase() === "2D" ? "distance" : "direct-line";
  };
  const measureArea = () => {
    measurement.activeTool = "area";
  };
  const clearMeasure = () => {
    measurement.clear();
  };

  window.addEventListener("scroll", () => {
    setScroll(window.pageYOffset);
  });

  return (
    <div
      className="map-measurement"
      style={{
        top: 20 + scroll,
      }}
    >
      <button
        id="distance"
        className="esri-widget--button esri-interactive esri-icon-measure-line"
        title="Distance Measurement Tool"
        onClick={measureLine}
      />
      <button
        id="area"
        className="esri-widget--button esri-interactive esri-icon-measure-area"
        title="Area Measurement Tool"
        onClick={measureArea}
      />
      <button
        id="clear"
        className="esri-widget--button esri-interactive esri-icon-trash"
        title="Clear Measurements"
        onClick={clearMeasure}
      />
    </div>
  );
};
export default Measurement;
