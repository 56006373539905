/* eslint-disable jsx-a11y/alt-text */
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectUser } from "reducers/userReducer";

import "./index.css";
export default function Profile() {
  const { email, role } = useSelector(selectUser);

  return (
    <section className="profile">
      <div>
        <div className="header-topic">
          <h1>Profile</h1>
        </div>
        <div className="card-body search-bar">
          <form>
            <div className="grid-story">
              <div className="form-group row profile-row">
                <label className="col-sm-2 col-form-label profile-font">
                  E-mail
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    id="LocationName"
                    value={email}
                    // placeholder="Location Name"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="grid-story">
              <div className="form-group row profile-row">
                <label className="col-sm-2 col-form-label profile-font">
                  Role Name
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    id="LocationName"
                    value={role}
                    // placeholder="Location Name"
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="grid-story">
              <div className="form-group row profile-row">
                <label className="col-sm-2 col-form-label profile-font">
                  First Name
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    id="LocationName"
                    // placeholder="Location Name"
                  />
                </div>
              </div>
            </div>
            <div className="grid-story">
              <div className="form-group row profile-row">
                <label className="col-sm-2 col-form-label profile-font">
                  Last Name
                </label>
                <div className="col-sm-7">
                  <input
                    type="text"
                    className="form-control"
                    id="LocationName"
                    // placeholder="Location Name"
                  />
                </div>
              </div>
            </div>

            <div className="grid-story2">
              <Link to="/devices">
                <div className="btn btn-back">
                  {/* <a href="#"> */}
                  Back
                  {/* </a> */}
                </div>
              </Link>
              <div className="btn btn-save">
                {/* <a href="#"> */}
                Save
                {/* </a> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
