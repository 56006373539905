import React from "react";

import { useSelector } from "react-redux";

import { selectDashboard } from "reducers/dashboard";

import { THS_API } from "../../../constants";

const useSocketChartZone = ({
  workPermitData,
  setCharLocation,
  setDataZone,
}: any) => {
  const dataDashboard: any = useSelector(selectDashboard);
  const socketDetailZone = async () => {
    const params = {
      zone: "พื้นที่กระบวนการผลิต",
    };
    const res = await THS_API.get("dashboard/map-graph-activeuser", {
      params,
    });

    return res.data.data.chart.series[0].data;
  };
  React.useEffect(() => {
    const socketChartZone = async () => {
      const detail = await socketDetailZone();
      setCharLocation(detail);
      const newArray = detail.filter(
        (a: any) => a.drilldown === dataDashboard?.dataDrilldown
      );

      const params = {
        unitid: dataDashboard?.dataDrilldown,
      };
      const res = await THS_API.get("dashboard/map-graph-activeuser/plant", {
        params,
      });
      const dataZone = res.data.data;

      let payload = dataZone?.chart?.series[0].data;
      let counter = 0;
      for (let i = 0; i < payload.length; i++) {
        counter = counter + payload[i].y;
      }

      let counttotal = newArray[0].y - counter;
      const plant = {
        name: dataDashboard?.dataPlantName,
        y: counttotal,
      };
      dataZone?.chart?.series[0].data.push(plant);
      setDataZone(dataZone);
    };
    socketChartZone();
  }, [
    dataDashboard?.dataDrilldown,
    dataDashboard?.dataPlantName,
    dataDashboard?.dataPlantTotal,
    setCharLocation,
    setDataZone,
    workPermitData,
  ]);
};
export default useSocketChartZone;
