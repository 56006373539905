import moment from "moment-timezone";

import { loadModules } from "esri-loader";

const levelBattery = (battery: any) => {
  if (battery >= 1 && battery <= 30) return "ระดับต่ำ";
  if (battery >= 31 && battery <= 60) return "ระดับกลาง";
  if (battery >= 61 && battery <= 100) return "ระดับสูง ";
};

const pttEmployeeAddGraphic = async ({
  employeeLayer,
  Graphic,
  pttEmployee,
  selectedWorkpermit,
}: any) => {
  let pttEmp = pttEmployee;

  if (selectedWorkpermit?.supervisor && selectedWorkpermit?.supervisor !== "") {
    pttEmp = pttEmp.filter((emp: any) => {
      if (emp?.superviser_full_name_monitoring?.length)
        return emp.superviser_full_name_monitoring.includes(
          selectedWorkpermit?.supervisor
        );
      return false;
    });
  }
  if (selectedWorkpermit?.workpermit && selectedWorkpermit?.workpermit !== "") {
    pttEmp = pttEmp.filter((emp: any) => {
      if (emp.work_permit_no)
        return emp.work_permit_no.includes(selectedWorkpermit?.workpermit);
      return false;
    });
  }
  if (
    selectedWorkpermit?.location?.value &&
    selectedWorkpermit?.location?.value !== ""
  ) {
    pttEmp = pttEmp.filter((emp: any) =>
      emp.wpm_unitid.includes(selectedWorkpermit?.location?.value)
    );
  }
  if (
    selectedWorkpermit?.subLocation?.value &&
    selectedWorkpermit?.subLocation?.value !== ""
  ) {
    pttEmp = pttEmp.filter((emp: any) =>
      emp.wpm_subunitid.includes(selectedWorkpermit?.subLocation?.value)
    );
  }
  if (selectedWorkpermit?.company && selectedWorkpermit?.company !== "") {
    pttEmp = pttEmp.filter(
      (emp: any) => emp.wpm_company_name === selectedWorkpermit?.company
    );
  }

  pttEmp.map((emp: any) => {
    const {
      lat,
      long,
      first_name,
      last_name,
      people_type,
      warning_status,
      dev_eui,
      work_permit_no,
      wpm_company_name,
      created,
      people_type_id,
      card_type,
      cid,
      status,
      message_type,
      battery_level,
      battery_status,
      sos_flag,
      position_subunitid,
      position_subunitname,
      position_unitid,
      position_unitname,
      position_zone,
      people_image,
      wpm_job,
      wpm_work_type_id,
      wpm_work_type,
      wpm_work_start_date,
      wpm_work_start_time,
      wpm_work_end_date,
      wpm_work_end_time,
      wpm_company_id,
      wpm_status,
      wpm_status_id,
      superviser_email_monitoring,
      superviser_full_name_monitoring,
      wpm_supervisor_code,
      wpm_unitname,
      wpm_unitid,
      wpm_subunitname,
    } = emp;

    const geometry = {
      //Create a point
      type: "point",
      longitude: long,
      latitude: lat,
    };

    let imageurl = "/images/icon/visitor.png";
    let width = "24px";
    let height = "24px";

    const alertImgWidth = "68px";
    const alertImgHeight = "71px";

    if (card_type === "1" || card_type === "2") {
      if (emp.warning_status_id?.length && emp.sos_flag === true) {
        imageurl = "/images/icon/visitor-sos.png";

        width = alertImgWidth;
        height = alertImgHeight;
      } else if (emp.warning_status_id?.length) {
        imageurl = "/images/icon/visitor-warning.png";

        width = alertImgWidth;
        height = alertImgHeight;
      } else {
        imageurl = "/images/icon/visitor.png";
      }
    } else {
      if (people_type_id === "P001") {
        if (emp.warning_status_id?.length && emp.sos_flag === true) {
          imageurl = "/images/icon/safety-sos.png";
          width = alertImgWidth;
          height = alertImgHeight;
        } else if (emp.warning_status_id?.length) {
          imageurl = "/images/icon/safety-warning.png";

          width = alertImgWidth;
          height = alertImgHeight;
        } else {
          imageurl = "/images/icon/safety.png";
        }
      } else if (people_type_id === "P002") {
        if (emp.warning_status_id?.length && emp.sos_flag === true) {
          imageurl = "/images/icon/fire-watcher-sos.png";
          width = alertImgWidth;
          height = alertImgHeight;
        } else if (emp.warning_status_id?.length) {
          imageurl = "/images/icon/fire-watcher-warning.png";

          width = alertImgWidth;
          height = alertImgHeight;
        } else {
          imageurl = "/images/icon/fire-watcher.png";
        }
      } else if (people_type_id === "P056") {
        if (emp.warning_status_id?.length && emp.sos_flag === true) {
          imageurl = "/images/icon/Contractor-sos.png";
          width = alertImgWidth;
          height = alertImgHeight;
        } else if (emp.warning_status_id?.length) {
          imageurl = "/images/icon/Contractor-warning.png";

          width = alertImgWidth;
          height = alertImgHeight;
        } else {
          imageurl = "/images/icon/Contractor.png";
        }
      } else if (people_type_id === "P035") {
        if (emp.warning_status_id?.length && emp.sos_flag === true) {
          imageurl = "/images/icon/พนักงาน-PTT-sos.png";
          width = alertImgWidth;
          height = alertImgHeight;
        } else if (emp.warning_status_id?.length) {
          imageurl = "/images/icon/พนักงาน-PTT-warning.png";

          width = alertImgWidth;
          height = alertImgHeight;
        } else {
          imageurl = "/images/icon/พนักงาน-PTT.png";
        }
      } else if (people_type_id === "P095" || people_type_id === "P096") {
        if (emp.warning_status_id?.length && emp.sos_flag === true) {
          imageurl = "/images/icon/visitor-sos.png";
          width = alertImgWidth;
          height = alertImgHeight;
        } else if (emp.warning_status_id?.length) {
          imageurl = "/images/icon/visitor-warning.png";

          width = alertImgWidth;
          height = alertImgHeight;
        } else {
          imageurl = "/images/icon/visitor.png";
        }
      } else {
        if (emp.warning_status_id?.length && emp.sos_flag === true) {
          imageurl = "/images/icon/Contractor-sos.png";
          width = alertImgWidth;
          height = alertImgHeight;
        } else if (emp.warning_status_id?.length) {
          imageurl = "/images/icon/Contractor-warning.png";

          width = alertImgWidth;
          height = alertImgHeight;
        } else {
          imageurl = "/images/icon/Contractor.png";
        }
      }

      // else if (people_type_id === "") {
      //   imageurl = "/images/icon/visitor.png";
      // }
    }
    let contentlist;
    if (warning_status?.length) {
      contentlist = [
        {
          type: "fields",
          fieldInfos: [
            {
              fieldName: "work_permit_no",
              label: "รหัสใบอนุญาต",
            },
            {
              fieldName: "dev_eui",
              label: "หมายเลขอุปกรณ์",
            },
            {
              fieldName: "first_name",
              label: "ชื่อ",
            },
            {
              fieldName: "last_name",
              label: "นามสกุล",
            },
            {
              fieldName: "position",
              label: "ตำแหน่ง",
            },
            {
              fieldName: "company",
              label: "บริษัท",
            },
            {
              fieldName: "time",
              label: "วันที่และเวลา",
            },
            {
              fieldName: "cid",
              label: "เลขบัตรประชาชน",
            },
            {
              fieldName: "battery_level",
              label: "สถานะแบตเตอรี่",
            },
            {
              fieldName: "wpm_status",
              label: "สถานะใบงาน",
            },

            {
              fieldName: "wpm_unitname",
              label: "ชื่อสถานที่ปฏิบัติงานตามใบงาน",
            },
            {
              fieldName: "wpm_subunitname",
              label: "ชื่อสถานที่ปฏิบัติงานย่อยตามใบงาน",
            },
            {
              fieldName: "position_unitname",
              label: "ชื่อสถานที่ปฏิบัติงานหลักตามGPS",
            },

            {
              fieldName: "position_subunitname",
              label: "ชื่อสถานที่ปฏิบัติงานย่อยตามGPS",
            },
            {
              fieldName: "people_type",
              label: "ประเภทเจ้าหน้าที่",
            },

            {
              fieldName: "wpm_supervisor",
              label: "ชื่อผู้ควบคุมงาน",
            },
            {
              fieldName: "wpm_supervisor_email",
              label: "อิเมลผู้ควบคุมงาน",
            },
            {
              fieldName: "warning_status",
              label: "สถานะแจ้งเตือน",
            },
            {
              fieldName: "lat",
              label: "ละติจูด",
            },
            {
              fieldName: "long",
              label: "ลองจิจูด",
            },
          ],
        },
      ];
    } else {
      contentlist = [
        {
          type: "fields",
          fieldInfos: [
            {
              fieldName: "work_permit_no",
              label: "รหัสใบอนุญาต",
            },
            {
              fieldName: "dev_eui",
              label: "หมายเลขอุปกรณ์",
            },
            {
              fieldName: "first_name",
              label: "ชื่อ",
            },
            {
              fieldName: "last_name",
              label: "นามสกุล",
            },
            {
              fieldName: "position",
              label: "ตำแหน่ง",
            },
            {
              fieldName: "company",
              label: "บริษัท",
            },
            {
              fieldName: "time",
              label: "วันที่และเวลา",
            },
            {
              fieldName: "cid",
              label: "เลขบัตรประชาชน",
            },
            {
              fieldName: "battery_level",
              label: "สถานะแบตเตอรี่",
            },
            {
              fieldName: "wpm_status",
              label: "สถานะใบงาน",
            },

            {
              fieldName: "wpm_unitname",
              label: "ชื่อสถานที่ปฏิบัติงานตามใบงาน",
            },
            {
              fieldName: "wpm_subunitname",
              label: "ชื่อสถานที่ปฏิบัติงานย่อยตามใบงาน",
            },
            {
              fieldName: "position_unitname",
              label: "ชื่อสถานที่ปฏิบัติงานหลักตามGPS",
            },

            {
              fieldName: "position_subunitname",
              label: "ชื่อสถานที่ปฏิบัติงานย่อยตามGPS",
            },
            {
              fieldName: "people_type",
              label: "ประเภทเจ้าหน้าที่",
            },

            {
              fieldName: "wpm_supervisor",
              label: "ชื่อผู้ควบคุมงาน",
            },
            {
              fieldName: "wpm_supervisor_email",
              label: "อิเมลผู้ควบคุมงาน",
            },
            {
              fieldName: "lat",
              label: "ละติจูด",
            },
            {
              fieldName: "long",
              label: "ลองจิจูด",
            },
          ],
        },
      ];
    }
    const symbol = {
      type: "picture-marker",
      url: imageurl,
      width: width,
      height: height,
      // yoffset:
      //   emp.warning_status_id?.length ||
      //   (emp.warning_status_id?.length && emp.sos_flag)
      //     ? 0
      //     : 0,
    };
    const newGraphic = new Graphic({
      geometry,
      symbol,
      attributes: {
        lat,
        long,
        work_permit_no,
        dev_eui,
        first_name,
        last_name,
        position: people_type,
        company: wpm_company_name,
        time: moment(created).format("DD/MM/YYYY HH.mm"),
        cid,
        status,
        message_type,
        battery_level: levelBattery(battery_level),
        battery_status,
        sos_flag,
        position_subunitid,
        position_subunitname,
        position_unitid,
        position_unitname,
        position_zone,
        people_image,
        wpm_job,
        wpm_work_type_id,
        wpm_work_type,
        wpm_work_start_date,
        wpm_work_start_time,
        wpm_work_end_date,
        wpm_work_end_time,
        wpm_company_id,
        wpm_status,
        wpm_status_id,
        wpm_supervisor_email: superviser_email_monitoring?.toString(),
        wpm_supervisor: superviser_full_name_monitoring?.toString(),
        wpm_supervisor_code,
        wpm_unitname: wpm_unitname?.toString(),
        wpm_unitid: wpm_unitid?.toString(),
        wpm_subunitname: wpm_subunitname?.toString(),
        warning_status: warning_status?.toString(),
      },
      popupTemplate: {
        title: "ข้อมูลผู้ถืออุปกรณ์",
        content: contentlist,
      },
    });

    employeeLayer?.add(newGraphic);
    return null;
  });
};

const initEmployee = async ({
  employeeLayer,
  pttEmployee,
  selectedWorkpermit,
}: any) => {
  const [Graphic] = await loadModules(["esri/Graphic"]);

  if (pttEmployee?.length)
    pttEmployeeAddGraphic({
      employeeLayer,
      Graphic,
      pttEmployee,
      selectedWorkpermit,
    });
};
export default initEmployee;
