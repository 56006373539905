import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import "./index.css";

const ButtonGroup = ({
  polygonArr,
  setIsRestrictDrawer,
  viewMap,
  setModalBaseMap,
  setIsModalVisibleSearch,
  setIsSubmit,
  isFilter,
  setIsFilter,
  mapShipPTT,
  setMapShipPTT,
  isGoToBoundary,
  setIsGoToBoundary,
  setIsClear,
}: any) => {
  const [scroll, setScroll] = React.useState<number>(0);
  window.addEventListener("scroll", () => {
    setScroll(window.pageYOffset);
  });
  return (
    <>
      <style>
        {`
        .esri-zoom .esri-widget {
          position:absolute;
        }
        .esri-zoom .esri-widget:first-child {
          top: ${scroll >= 88 ? 88 : scroll}px;
        }
        .esri-zoom .esri-widget:last-child {
          top: ${32 + scroll >= 120 ? 120 : 32 + scroll}px;
        }
        .map-boundary-map .esri-ui-top-right {
          top: ${50 + scroll >= 290 ? 290 : 50 + scroll}px;
        }
        `}
      </style>
      {polygonArr.length > 0 && (
        <button
          title="เลือกประเภทการแสดงแผนที่"
          className="show-restrict-list esri-button"
          onClick={() => setIsRestrictDrawer(true)}
        >
          <FontAwesomeIcon icon={solid("bars")} />
        </button>
      )}
      <button
        className="toggle esri-button"
        onClick={() => {
          setModalBaseMap(true);
        }}
      >
        <FontAwesomeIcon
          icon={viewMap ? solid("toggle-on") : solid("toggle-off")}
        />
      </button>
      <button
        title="ค้นหาบุคคลหรืออุปกรณ์"
        style={{ top: 90 + scroll >= 170 ? 170 : 90 + scroll }}
        className="search esri-button"
        onClick={() => {
          setIsModalVisibleSearch(true);
          setIsSubmit(false);
        }}
      >
        <FontAwesomeIcon icon={solid("magnifying-glass")} />
      </button>
      <button
        title="ค้นหาพื้นที่ห้ามเข้าหรือพื้นที่อพยพ"
        style={{ top: 123 + scroll >= 203 ? 203 : 123 + scroll }}
        className="search esri-button"
        onClick={() => {
          setIsGoToBoundary(!isGoToBoundary);
        }}
      >
        <FontAwesomeIcon icon={solid("map-pin")} />
      </button>

      {polygonArr.length > 0 && (
        <button
          title="แสดงรายละเอียดพื้นที่ห้ามเข้าและจุดรวมพล"
          className="button-search esri-button"
          // className={classnames("filter-button", {
          //   active: !sideBarFilter && !isModalVisible,
          // })}
          onClick={() => setIsFilter(!isFilter)}
        >
          <FontAwesomeIcon icon={solid("filter")} />
        </button>
      )}
      <button
        title="แผนที่ ปตท"
        style={{ top: 240 + scroll >= 420 ? 420 : 240 + scroll }}
        className="ship-ptt esri-button"
        onClick={() => setMapShipPTT(!mapShipPTT)}
      >
        <FontAwesomeIcon icon={solid("map")} />
      </button>
      <button
        title="ล้างข้อมูลการค้นหา"
        style={{ top: 290 + scroll >= 370 ? 370 : 290 + scroll }}
        className="resetMap esri-button"
        onClick={() => setIsClear(true)}
      >
        <FontAwesomeIcon icon={solid("arrow-rotate-left")} />
      </button>
    </>
  );
};

export default ButtonGroup;
