import React from "react";
import moment from "moment";

import { setDefaultOptions, loadModules } from "esri-loader";

import "./index.css";

setDefaultOptions({ css: true });
const WorkPermitMap = ({
  dataMapDevice,
  viewMap,
  setPeriodView,
  setListPeriodShipPTT,
}) => {
  const mapDiv = React.useRef(null);
  const levelbattery = (battery) => {
    if (battery >= 1 && battery <= 30) return "ระดับต่ำ";
    if (battery >= 31 && battery <= 60) return "ระดับกลาง";
    if (battery >= 61 && battery <= 100) return "ระดับสูง ";
  };
  React.useEffect(() => {
    const callMap = async () => {
      loadModules([
        "esri/Map",
        "esri/views/MapView",
        "esri/Graphic",
        "esri/layers/GraphicsLayer",
        "esri/core/urlUtils",
        "esri/layers/MapImageLayer",
        "esri/layers/FeatureLayer",
        "esri/widgets/LayerList",
      ])
        .then(
          ([
            Map,
            MapView,
            Graphic,
            GraphicsLayer,
            urlUtils,
            MapImageLayer,
            FeatureLayer,
            LayerList,
          ]) => {
            const graphicsLayer = new GraphicsLayer({
              title: "PTT Point People",
              id: "ptt-point-people",
            });
            const url = process.env.REACT_APP_MAP_API;
            const proxy = process.env.REACT_APP_MAP_PROXY;
            let layerList;
            urlUtils.addProxyRule({
              urlPrefix: url,
              proxyUrl: `${proxy}/${btoa(
                "user=trackingmove&system=map"
              )}/api/AppProxy`,
            });
            let mapImageLayerIntegrate = new MapImageLayer({
              url: `${url}/PTT_SHIP/PTT_SHIP_MAP/MapServer`,
              sublayers: [
                { title: "Plant", id: 9, visible: true },
                {
                  title: "Area",
                  id: 8,
                  visible: true,
                },
                {
                  title: "อาคาร",
                  id: 7,
                  visible: true,
                },
                { title: "จุดจอดรถ", id: 6, visible: true },
                // {
                //   id: 5,
                //   visible: true,
                // },
                { title: "pipe Rack", id: 4, visible: true },
                { title: "pipe Bridge", id: 3, visible: true },
                {
                  title: "ถนน",
                  id: 2,
                  visible: true,
                },
                {
                  title: "ป้ายจราจร",
                  id: 1,
                  visible: true,
                },
                {
                  title: "อุปกรณ์",
                  id: 0,
                  visible: true,
                },
              ],
            });

            let dataCenter = [101.14640753162908, 12.719241117286579];

            if (dataMapDevice.length) {
              const { lat, long } = dataMapDevice[0];

              dataCenter = [long, lat];
            }

            let baseMap = "satellite";
            let minZoom = 17;

            if (viewMap === "satellite") {
              baseMap = "satellite";
              minZoom = 17;
            } else if (viewMap === "streets-vector") {
              baseMap = "streets-vector";
              minZoom = 16;
            } else {
              baseMap = "streets-night-vector";
              minZoom = 16;
            }
            const map = new Map({
              basemap: baseMap,
              layers: [mapImageLayerIntegrate, graphicsLayer],
            });

            const view = new MapView({
              map,
              container: mapDiv.current,
              zoom: minZoom,
              center: dataCenter,
              constraints: {
                minZoom: minZoom,
              },
              rotation: 58,
            });

            view.on("click", (event) => {
              // console.log("gggg", event);
            });
            layerList = new LayerList({
              view,
            });
            view.when(() => {
              addGraphics();
            });

            const addGraphics = async () => {
              //  const params={
              //     start_date:deviceEUI.startDate,
              //     end_date:deviceEUI.endDate
              //   }

              //     const res = await THS_API.get(`/map-monitoring/dev_eui/${deviceEUI.deviceEUI}`,{params});

              let line = [];

              let workpermitData = dataMapDevice ? dataMapDevice : [];

              const workpermit = workpermitData.map((a) => {
                let createdDate = moment(a.created).format("DD/MM/YYYY:HH.mm");

                return { ...a, created: createdDate };
              });

              for (let i in workpermit) {
                let subLine = [workpermit[i].long, workpermit[i].lat];
                line = [...line, subLine];
              }

              const polyline = {
                type: "polyline", // autocasts as new Polyline()
                paths: line,
              };
              // const lineSymbol = {
              //   type: "simple-line", // autocasts as SimpleLineSymbol()
              //   color: [226, 119, 40],
              //   width: 4,
              // };

              const lineSymbol = {
                type: "cim", // autocasts as CIMSymbol
                data: {
                  type: "CIMSymbolReference",
                  symbol: {
                    type: "CIMLineSymbol",
                    symbolLayers: [
                      {
                        // arrow symbol
                        type: "CIMVectorMarker",
                        enable: true,
                        size: 20,
                        markerPlacement: {
                          type: "CIMMarkerPlacementAtExtremities",
                          // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
                          extremityPlacement: "JustEnd",
                        },
                        frame: {
                          xmin: -5,
                          ymin: -5,
                          xmax: 0,
                          ymax: 5,
                        },
                        markerGraphics: [
                          {
                            type: "CIMMarkerGraphic",
                            geometry: {
                              rings: [
                                [
                                  [-8, -5.47],
                                  [-8, 5.6],
                                  [1.96, -0.03],
                                  [-8, -5.47],
                                ],
                              ],
                            },
                            symbol: {
                              // red fill for the arrow symbol
                              type: "CIMPolygonSymbol",
                              symbolLayers: [
                                {
                                  type: "CIMSolidFill",
                                  enable: true,
                                  color: [255, 0, 0, 255],
                                },
                              ],
                            },
                          },
                        ],
                      },

                      {
                        // black 1px line symbol
                        type: "CIMSolidStroke",
                        enable: true,
                        width: 3,
                        color: [0, 0, 0, 255],
                      },
                    ],
                  },
                },
              };
              let polylineGraphic = [];
              polylineGraphic = new Graphic({
                geometry: polyline,
                symbol: lineSymbol,
              });
              const layer = new FeatureLayer({
                // url: "https://nonpttarcgisserver.pttplc.com/arcgis/rest/services/PTT_SHIP/PTT_SHIP_MAP/MapServer",
                source: [polylineGraphic],
                id: "draw-device-line",
                title: "Draw Device Line",
                fields: [
                  {
                    name: "ObjectID",
                    type: "oid",
                  },
                ],
                renderer: {
                  type: "simple",
                  symbol: {
                    type: "cim",
                    data: {
                      type: "CIMSymbolReference",
                      symbol: {
                        // CIM polygon symbol
                        type: "CIMPolygonSymbol",
                        symbolLayers: [
                          {
                            // light blue outline around the polygon
                            type: "CIMSolidStroke",
                            enable: true,
                            width: 3,
                            color: [0, 92, 230, 255],
                          },
                          {
                            // arrow symbol
                            type: "CIMVectorMarker",
                            enable: true,
                            size: 10,
                            markerPlacement: {
                              type: "CIMMarkerPlacementAlongLineSameSize", // places same size markers along the line
                              endings: "WithMarkers",
                              placementTemplate: [50], // determines space between each arrow
                              angleToLine: true, // symbol will maintain its angle to the line when map is rotated
                            },
                            frame: {
                              xmin: -15,
                              ymin: -5,
                              xmax: 5,
                              ymax: 5,
                            },
                            markerGraphics: [
                              {
                                type: "CIMMarkerGraphic",
                                geometry: {
                                  rings: [
                                    [
                                      [-8, -5.47],
                                      [-8, 5.6],
                                      [1.96, -0.03],
                                      [-8, -5.47],
                                    ],
                                  ],
                                },
                                symbol: {
                                  // black fill for the arrow symbol
                                  type: "CIMPolygonSymbol",
                                  symbolLayers: [
                                    {
                                      type: "CIMSolidFill",
                                      enable: true,
                                      color: [0, 0, 0, 255],
                                    },
                                  ],
                                },
                              },
                            ],
                          },
                        ],
                      },
                    },
                  },
                },
              });

              map.add(layer);
              for (let i in workpermit) {
                let subLine = [workpermit[i].long, workpermit[i].lat];
                line = [...line, subLine];
                let markerSymbol = {};

                let imageurl = "/images/icon/visitor.png";
                let width = "24px";
                let height = "24px";

                const alertImgWidth = "68px";
                const alertImgHeight = "71px";

                // if (mapZoom < 20) {
                //   width = "0px";
                //   height = "0px";
                // }

                if (
                  workpermit[i].card_type === "1" ||
                  workpermit[i].card_type === "2"
                ) {
                  if (
                    workpermit[i].warning_status_id.length &&
                    workpermit[i].sos_flag === true
                  ) {
                    imageurl = "/images/icon/visitor-sos.png";

                    width = alertImgWidth;
                    height = alertImgHeight;
                  } else if (workpermit[i].warning_status_id.length) {
                    imageurl = "/images/icon/visitor-warning.png";

                    width = alertImgWidth;
                    height = alertImgHeight;
                  } else {
                    imageurl = "/images/icon/visitor.png";
                  }
                } else {
                  if (workpermit[i].people_type_id === "P001") {
                    if (
                      workpermit[i].warning_status_id.length &&
                      workpermit[i].sos_flag === true
                    ) {
                      imageurl = "/images/icon/safety-sos.png";
                      width = alertImgWidth;
                      height = alertImgHeight;
                    } else if (workpermit[i].warning_status_id.length) {
                      imageurl = "/images/icon/safety-warning.png";

                      width = alertImgWidth;
                      height = alertImgHeight;
                    } else {
                      imageurl = "/images/icon/safety.png";
                    }
                  } else if (workpermit[i].people_type_id === "P002") {
                    if (
                      workpermit[i].warning_status_id.length &&
                      workpermit[i].sos_flag === true
                    ) {
                      imageurl = "/images/icon/fire-watcher-sos.png";
                      width = alertImgWidth;
                      height = alertImgHeight;
                    } else if (workpermit[i].warning_status_id.length) {
                      imageurl = "/images/icon/fire-watcher-warning.png";

                      width = alertImgWidth;
                      height = alertImgHeight;
                    } else {
                      imageurl = "/images/icon/fire-watcher.png";
                    }
                  } else if (workpermit[i].people_type_id === "P035") {
                    if (
                      workpermit[i].warning_status_id.length &&
                      workpermit[i].sos_flag === true
                    ) {
                      imageurl = "/images/icon/พนักงาน-PTT-sos.png";
                      width = alertImgWidth;
                      height = alertImgHeight;
                    } else if (workpermit[i].warning_status_id.length) {
                      imageurl = "/images/icon/พนักงาน-PTT-warning.png";

                      width = alertImgWidth;
                      height = alertImgHeight;
                    } else {
                      imageurl = "/images/icon/พนักงาน-PTT.png";
                    }
                  } else if (workpermit[i].people_type_id === "P056") {
                    if (
                      workpermit[i].warning_status_id.length &&
                      workpermit[i].sos_flag === true
                    ) {
                      imageurl = "/images/icon/Contractor-sos.png";
                      width = alertImgWidth;
                      height = alertImgHeight;
                    } else if (workpermit[i].warning_status_id.length) {
                      imageurl = "/images/icon/Contractor-warning.png";

                      width = alertImgWidth;
                      height = alertImgHeight;
                    } else {
                      imageurl = "/images/icon/Contractor.png";
                    }
                  } else if (
                    workpermit[i].people_type_id === "P095" ||
                    workpermit[i].people_type_id === "P096"
                  ) {
                    if (
                      workpermit[i].warning_status_id.length &&
                      workpermit[i].sos_flag === true
                    ) {
                      imageurl = "/images/icon/visitor-sos.png";
                      width = alertImgWidth;
                      height = alertImgHeight;
                    } else if (workpermit[i].warning_status_id.length) {
                      imageurl = "/images/icon/visitor-warning.png";

                      width = alertImgWidth;
                      height = alertImgHeight;
                    } else {
                      imageurl = "/images/icon/visitor.png";
                    }
                  } else {
                    if (
                      workpermit[i].warning_status_id.length &&
                      workpermit[i].sos_flag === true
                    ) {
                      imageurl = "/images/icon/Contractor-sos.png";
                      width = alertImgWidth;
                      height = alertImgHeight;
                    } else if (workpermit[i].warning_status_id.length) {
                      imageurl = "/images/icon/Contractor-warning.png";

                      width = alertImgWidth;
                      height = alertImgHeight;
                    } else {
                      imageurl = "/images/icon/Contractor.png";
                    }
                  }
                  // else if (people_type_id === "") {
                  //   imageurl = "/images/icon/visitor.png";
                  // }
                }

                markerSymbol = {
                  type: "picture-marker",
                  url: imageurl,
                  width: width,
                  height: height,
                  // yoffset:
                  //   emp.warning_status_id.length ||
                  //   (emp.warning_status_id.length && emp.sos_flag)
                  //     ? 0
                  //     : 0,
                };
                let contentlist;
                if (workpermit[i].warning_status.length) {
                  contentlist = [
                    {
                      type: "fields",
                      fieldInfos: [
                        {
                          fieldName: "workpermit_no",
                          label: "รหัสใบอนุญาต",
                        },
                        {
                          fieldName: "dev_eui",
                          label: "หมายเลขอุปกรณ์",
                        },
                        {
                          fieldName: "first_name",
                          label: "ชื่อ",
                        },
                        {
                          fieldName: "last_name",
                          label: "นามสกุล",
                        },
                        {
                          fieldName: "position",
                          label: "ตำแหน่ง",
                        },
                        {
                          fieldName: "company",
                          label: "บริษัท",
                        },
                        {
                          fieldName: "time",
                          label: "วันที่และเวลา",
                        },
                        {
                          fieldName: "cid",
                          label: "เลขบัตรประชาชน",
                        },
                        {
                          fieldName: "battery_level",
                          label: "สถานะแบตเตอรี่",
                        },
                        {
                          fieldName: "wpm_status",
                          label: "สถานะใบงาน",
                        },

                        {
                          fieldName: "wpm_unitname",
                          label: "ชื่อสถานที่ปฏิบัติงานตามใบงาน",
                        },
                        {
                          fieldName: "wpm_subunitname",
                          label: "ชื่อสถานที่ปฏิบัติงานย่อยตามใบงาน",
                        },
                        {
                          fieldName: "position_unitname",
                          label: "ชื่อสถานที่ปฏิบัติงานหลักตามGPS",
                        },

                        {
                          fieldName: "position_subunitname",
                          label: "ชื่อสถานที่ปฏิบัติงานย่อยตามGPS",
                        },
                        {
                          fieldName: "people_type",
                          label: "ประเภทเจ้าหน้าที่",
                        },

                        {
                          fieldName: "wpm_supervisor",
                          label: "ชื่อผู้ควบคุมงาน",
                        },
                        {
                          fieldName: "wpm_supervisor_email",
                          label: "อิเมลผู้ควบคุมงาน",
                        },
                        {
                          fieldName: "warning_status",
                          label: "สถานะแจ้งเตือน",
                        },
                        {
                          fieldName: "lat",
                          label: "ละติจูด",
                        },
                        {
                          fieldName: "long",
                          label: "ลองจิจูด",
                        },
                      ],
                    },
                  ];
                } else {
                  contentlist = [
                    {
                      type: "fields",
                      fieldInfos: [
                        {
                          fieldName: "workpermit_no",
                          label: "รหัสใบอนุญาต",
                        },
                        {
                          fieldName: "dev_eui",
                          label: "หมายเลขอุปกรณ์",
                        },
                        {
                          fieldName: "first_name",
                          label: "ชื่อ",
                        },
                        {
                          fieldName: "last_name",
                          label: "นามสกุล",
                        },
                        {
                          fieldName: "position",
                          label: "ตำแหน่ง",
                        },
                        {
                          fieldName: "company",
                          label: "บริษัท",
                        },
                        {
                          fieldName: "time",
                          label: "วันที่และเวลา",
                        },
                        {
                          fieldName: "cid",
                          label: "เลขบัตรประชาชน",
                        },
                        {
                          fieldName: "battery_level",
                          label: "สถานะแบตเตอรี่",
                        },
                        {
                          fieldName: "wpm_status",
                          label: "สถานะใบงาน",
                        },

                        {
                          fieldName: "wpm_unitname",
                          label: "ชื่อสถานที่ปฏิบัติงานตามใบงาน",
                        },
                        {
                          fieldName: "wpm_subunitname",
                          label: "ชื่อสถานที่ปฏิบัติงานย่อยตามใบงาน",
                        },
                        {
                          fieldName: "position_unitname",
                          label: "ชื่อสถานที่ปฏิบัติงานหลักตามGPS",
                        },

                        {
                          fieldName: "position_subunitname",
                          label: "ชื่อสถานที่ปฏิบัติงานย่อยตามGPS",
                        },
                        {
                          fieldName: "people_type",
                          label: "ประเภทเจ้าหน้าที่",
                        },

                        {
                          fieldName: "wpm_supervisor",
                          label: "ชื่อผู้ควบคุมงาน",
                        },
                        {
                          fieldName: "wpm_supervisor_email",
                          label: "อิเมลผู้ควบคุมงาน",
                        },
                        {
                          fieldName: "lat",
                          label: "ละติจูด",
                        },
                        {
                          fieldName: "long",
                          label: "ลองจิจูด",
                        },
                      ],
                    },
                  ];
                }

                let point = {
                  type: "point",
                  x: workpermit[i].long,
                  y: workpermit[i].lat,
                };
                const pointGraphic = new Graphic({
                  geometry: point,
                  symbol: markerSymbol,
                  attributes: {
                    lat: workpermit[i].lat,
                    long: workpermit[i].long,
                    workpermit_no: workpermit[i].work_permit_no,
                    dev_eui: workpermit[i].dev_eui,
                    first_name: workpermit[i].first_name,
                    last_name: workpermit[i].last_name,
                    position: workpermit[i].people_type,
                    company: workpermit[i].wpm_company_name,
                    time: workpermit[i].created,
                    cid: workpermit[i].cid,
                    message_type: workpermit[i].message_type,
                    battery_level: levelbattery(workpermit[i].battery_level),
                    battery_status: workpermit[i].battery_status,
                    sos_flag: workpermit[i].sos_flag,
                    position_subunitid: workpermit[i].position_subunitid,
                    position_subunitname: workpermit[i].position_subunitname,
                    position_unitid: workpermit[i].position_unitid,
                    position_unitname: workpermit[i].position_unitname,
                    position_zone: workpermit[i].position_zone,
                    people_image: workpermit[i].people_image,
                    wpm_job: workpermit[i].wpm_job,
                    wpm_work_type_id: workpermit[i].wpm_work_type_id,
                    wpm_work_type: workpermit[i].wpm_work_type,
                    wpm_work_start_date: workpermit[i].wpm_work_start_date,
                    wpm_work_start_time: workpermit[i].wpm_work_start_time,
                    wpm_work_end_date: workpermit[i].wpm_work_end_date,
                    wpm_work_end_time: workpermit[i].wpm_work_end_time,
                    wpm_company_id: workpermit[i].wpm_company_id,
                    wpm_status: workpermit[i].wpm_status,
                    wpm_status_id: workpermit[i].wpm_status_id,
                    wpm_supervisor_email:
                      workpermit[i].superviser_email_monitoring?.toString(),
                    wpm_supervisor:
                      workpermit[i].superviser_full_name_monitoring?.toString(),
                    wpm_supervisor_code: workpermit[i].wpm_supervisor_code,
                    wpm_subunitname: workpermit[i].wpm_subunitname?.toString(),
                    wpm_unitname: workpermit[i].wpm_unitname?.toString(),
                    wpm_unitid: workpermit[i].wpm_unitid?.toString(),
                    warning_status: workpermit[i].warning_status?.toString(),
                  },
                  popupTemplate: {
                    title: "รายละเอียด ของพนักงาน",
                    content: contentlist,
                  },
                });
                const poinPeople = graphicsLayer.add(pointGraphic);
                map.add(poinPeople);
                // graphicsLayer.graphics.items.forEach((graphic) => {
                //   console.log(graphic.symbol);
                // });
              }
            };

            setPeriodView(view);
            setListPeriodShipPTT(layerList);
          }
        )
        .catch((err) => {
          // handle any script or module loading errors
          console.error(err);
        });
    };
    callMap();
  }, [dataMapDevice, setListPeriodShipPTT, setPeriodView, viewMap]);

  return (
    <div className="mapDiv work-permit" ref={mapDiv}>
      <title>Mark something</title>
    </div>
  );
};

export default WorkPermitMap;
