/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import moment from "moment";
import classnames from "classnames";

import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  // setInitialFilters,
  useLogout,
} from "@pankod/refine-core";
import { AntdLayout } from "@pankod/refine-antd";
import { useMsal } from "@azure/msal-react";

import {
  THS_API,
  THS_TOKEN_KEY,
  THS_EXPIRED,
  allowMenu,
} from "../../../constants";

import { roleAPI, selectRole, selectDescription } from "reducers/rolesReducer";
import { getUserProfile, selectUser } from "reducers/userReducer";
import {
  selectNotification,
  setNotification,
  setChartNotification,
} from "reducers/notificationReducers";

// import { SocketContext } from "context/socket";

import "./index.css";

function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState({
    width: 0,
    height: 0,
  });

  React.useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window?.innerWidth,
        height: window?.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

const ResetMenu = ({ pathname, setSubmenu, setIsHamburger }: any) => {
  React.useEffect(() => {
    setSubmenu("none");
    setIsHamburger(false);
  }, [pathname, setSubmenu, setIsHamburger]);
};

export const Header: React.FC = () => {
  const token = localStorage.getItem(THS_TOKEN_KEY) || "";
  // const socket = React.useContext(SocketContext);
  const usaPorUrl = `${process.env.REACT_APP_USA_POR}?token=${token}`;

  const dispatch: any = useDispatch();

  const [submenu, setSubmenu] = React.useState("none");
  const [isNotification, setIsNotification] = React.useState(true);
  const [isHamburger, setIsHamburger] = React.useState(false);
  const [menu, setMenu] = React.useState<any>([]);

  const { pathname } = useLocation();
  const { mutate: logout } = useLogout();
  const { instance, accounts } = useMsal();

  const size = useWindowSize();

  const roleData = useSelector(selectRole);
  const userData = useSelector(selectUser);
  const description = useSelector(selectDescription);
  const notiNum = useSelector(selectNotification);

  const isSpaceBetween = menu.length > 3;
  const changeMenu = () => {
    setIsHamburger(false);
    if (submenu === "none") setSubmenu("block");
    else setSubmenu("none");
  };

  const toggleHamburger = () => {
    setSubmenu("none");
    setIsHamburger(!isHamburger);
  };

  ResetMenu({
    pathname,
    setSubmenu,
    setIsHamburger,
  });

  const notiGetAll = async () => {
    let dataEndDate = moment(new Date()).format("YYYY-MM-DD");
    let dataStartDate = moment(new Date()).format("YYYY-MM-DD");
    const params: any = {
      check_acknowledge: false,
      start_date: dataStartDate,
      end_date: dataEndDate,
    };

    const res = await THS_API.get("/notification/countNotification", {
      params,
    });

    const { data, total } = res.data.data;

    dispatch(
      setChartNotification([
        Number(data.notiHigh),
        Number(data.notiMedium),
        Number(data.notiLow),
      ])
    );
    dispatch(setNotification(total));
  };
  React.useEffect(() => {
    if (userData.apiStatus === "success") dispatch(roleAPI(userData?.role));
  }, [dispatch, userData.apiStatus, userData?.role]);

  React.useEffect(() => {
    if (roleData?.statusCode === 200) setMenu(allowMenu(pathname, roleData));
  }, [roleData, pathname]);

  React.useEffect(() => {
    notiGetAll();
  }, []);
  React.useEffect(() => {
    const userInfoAPI = async () => dispatch(getUserProfile());
    userInfoAPI();
  }, [dispatch]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      notiGetAll();
    }, 1000 * 60);

    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      clearInterval(interval);
    };
  }, []);

  return (
    <AntdLayout.Header className="header-page fixed-in">
      <div className="header-menu">
        <h1 id="logo">
          <a href={usaPorUrl}>PEOPLE &amp; MOTION TRACKING</a>
        </h1>
        <nav id="main-navigation" role="navigation">
          <ul
            className={classnames(
              "main-menu",
              {
                expand: isHamburger,
              },
              {
                "is-space-between": isSpaceBetween,
              }
            )}
          >
            {menu.map((m: any, key: number) => (
              <li key={key}>
                <Link
                  to={m.link}
                  className={classnames({
                    active: m.active,
                  })}
                >
                  {m.text}
                </Link>
              </li>
            ))}
          </ul>
          <div className="right-menu">
            <ul className="user-menu">
              {isNotification && (
                <li className="is-notification">
                  <FontAwesomeIcon
                    icon={solid("toggle-on")}
                    onClick={() => setIsNotification(false)}
                  />
                </li>
              )}
              {!isNotification && (
                <li className="is-notification off">
                  <FontAwesomeIcon
                    icon={solid("toggle-off")}
                    onClick={() => setIsNotification(true)}
                  />
                </li>
              )}
              {isNotification && (
                <li>
                  <div className="noti" title="การแจ้งเตือน">
                    <Link className="noti-link" to="/notification">
                      <img src="/images/icon/noti.svg" alt="noti" />

                      <div className="noti-num">{notiNum}</div>
                    </Link>
                  </div>
                </li>
              )}

              <li>
                <div className="login-user">
                  <img src="/images/icon/user.svg" alt="user" />
                </div>
              </li>
              <li>
                <div className="username">
                  <div className="details">
                    <p>{userData?.name}</p>
                    <p>{description}</p>
                  </div>
                  <div className="logout-menu" onClick={changeMenu}>
                    <img src="/images/icon/newlogout.svg" alt="logout" />
                  </div>
                </div>
              </li>
              <li>
                <ul className="humberger">
                  <li>
                    <img
                      className="open-menu menu hamburger-menu"
                      src="/images/icon/open-menu.png"
                      style={{
                        display:
                          !isHamburger && size.width <= 1200 ? "block" : "none",
                      }}
                      onClick={toggleHamburger}
                      alt="hamburger-menu"
                    />
                  </li>
                  <li>
                    <img
                      className="close-menu close menu-close hamburger-menu"
                      src="/images/icon/close-menu.png"
                      style={{
                        display:
                          isHamburger && size.width <= 1200 ? "block" : "none",
                      }}
                      onClick={toggleHamburger}
                      alt="hamburger-close-menu"
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div className="submenu card-body" style={{ display: submenu }}>
        <div className="sub-menu">
          <a
            href="#"
            onClick={() => {
              logout();
              window.location.href = usaPorUrl;
            }}
          >
            <p>PTT SHIP Portal</p>
          </a>
        </div>
        <div
          className="sub-menu"
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (accounts.length) {
              localStorage.removeItem(THS_TOKEN_KEY);
              localStorage.removeItem(THS_EXPIRED);
              delete THS_API.defaults.headers.common.Authorization;
              instance.logoutRedirect({
                postLogoutRedirectUri: "/",
              });
            } else {
              logout();
              window.location.href = window.location.origin;
            }
          }}
        >
          <hr />

          <p className="red logout">
            <img src="/images/icon/newlogout.svg" alt="logout" /> Log Out
          </p>
        </div>
      </div>
    </AntdLayout.Header>
  );
};
