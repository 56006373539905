/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import classnames from "classnames";
import Swal from "sweetalert2";

import { loadModules } from "esri-loader";
import { useSelector, useDispatch } from "react-redux";

import { AntdLayout } from "@pankod/refine-antd";

import FilterBaseMap from "components/mapBoundary/filterBaseMap";

import {
  setEmployee,
  selectMapMonitoring,
  setPttGeometry,
  getMapMonitoring,
} from "reducers/mapMonitoring";
import { selectUser } from "reducers/userReducer";
import {
  EsriMapWithNoSSR,
  EmpPastMap,
  WorkPermitMap,
  FilterBox,
  FilterBoxBoundary,
  FindEmpModal,
  ButtonGroup,
} from "components/mapMonitoring";

import { THS_API } from "../../constants";
import { SocketContext } from "../../context/socket";

import "./index.css";

export const MapMonitoringV2: React.FC = () => {
  const socket = React.useContext(SocketContext);
  const dispatch = useDispatch<any>();

  const [chosenMap, setChosenMap] = React.useState<"time" | "past" | "period">(
    "time"
  );

  const [scroll, setScroll] = React.useState<number>(0);

  const [positionType, setPositionType] = React.useState({
    data: [],
    status: "idle",
  });
  const [map, setMap] = React.useState<any>(null);
  const [view, setView] = React.useState<any>(null);

  const [pastMap, setPastMap] = React.useState<any>(null);
  const [pastView, setPastView] = React.useState<any>(null);
  const [periodView, setPeriodView] = React.useState<any>(null);
  const [listShipPTT, setListShipPTT] = React.useState<any>(null);
  const [listPastShipPTT, setListPastShipPTT] = React.useState<any>(null);
  const [listPeriodShipPTT, setListPeriodShipPTT] = React.useState<any>(null);
  const [mapZoom, setMapZoom] = React.useState<number>(17);
  const [zoomLvl, setZoomLvl] = React.useState("PLANT");
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
  const [mapShipPTT, setMapShipPTT] = React.useState<boolean>(false);
  const [modalSearchBounary, setModalSearchBounary] =
    React.useState<boolean>(false);
  // const [sideBarFilter, setSideBarFilter] = React.useState<boolean>(false);

  const [, setSideBarFilter] = React.useState<boolean>(false);
  const [dataMapDevice, setDataMapDevice] = React.useState<any>([]);

  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const [viewMap, setViewMap] = React.useState("satellite");
  const [modalBaseMap, setModalBaseMap] = React.useState(false);
  const [isFocusEmp, setIsFocusEmp] = React.useState(false);

  const [restrictArr, setRestrictArr] = React.useState<any>([]);
  const [responsibleArea, setResponsibleArea] = React.useState<any>();

  //for find employee modalbox
  const [isFindEmpModal, setIsFindEmpModal] = React.useState(false);
  const [selectedWorkpermit, setSelectedWorkpermit] = React.useState({
    workpermit: "",
    supervisor: "",
    location: { value: "", text: "" },
    subLocation: { value: "", text: "" },
  });

  const [startDatePast, setStartDatePast] = React.useState(new Date());
  const [isFilterBoxBoundaryActive, setIsFilterBoxBoundaryActive] =
    React.useState(false);
  const [isClear, setIsClear] = React.useState(false);
  const [empModalCurrentTab, setEmpModalCurrentTab] = React.useState("dev_eui");
  const [isMapFinishGoTo, setIsMapFinishGoTo] = React.useState(false);

  const { pttEmployee, buttonGroupValue, isSaveMonitoringLoaded } =
    useSelector(selectMapMonitoring);
  const userData = useSelector(selectUser);

  const clearMapResult = async () => {
    let mapZoom = 17;
    let mapCenter = [101.14640753162908, 12.719241117286579];

    const polygonSearchLayer = map?.findLayerById("ptt-geometry-search-layer");
    const polygonSearchTextLayer = map?.findLayerById(
      "ptt-geometry-search-layer-text"
    );

    polygonSearchLayer?.removeAll();
    polygonSearchTextLayer?.removeAll();
    Swal.showLoading();
    const response2 = await THS_API.get("/map-monitoring");

    const pttEmployee = response2.data.data;

    dispatch(setEmployee(pttEmployee));

    if (userData?.role === "ths silom") {
      mapZoom = 20;
      mapCenter = [100.52375650452704, 13.724526696491676];
    }
    view
      ?.goTo({
        center: mapCenter,
        zoom: mapZoom,
      })
      .then(() => {
        dispatch(setPttGeometry([]));
      });
    setIsSubmit(false);
    setChosenMap("time");
    Swal.close();
  };
  window.addEventListener("scroll", () => {
    setScroll(window.pageYOffset);
  });
  React.useEffect(() => {
    const callApi = async () => {
      if (view && isSaveMonitoringLoaded) {
        if (
          Object.keys(buttonGroupValue).length &&
          !isMapFinishGoTo &&
          buttonGroupValue?.filterBoxBoundaryPriority
        ) {
          const { center } = buttonGroupValue;
          if (center && center.length) {
            const fillSymbol = {
              type: "simple-fill", // autocasts as new SimpleFillSymbol()
              color: [227, 139, 79, 0.8],
              outline: {
                // autocasts as new SimpleLineSymbol()
                color: [255, 255, 255],
                width: 1,
              },
            };
            const [Graphic] = await loadModules(["esri/Graphic"]);
            const polygonGraphic = new Graphic({
              geometry: {
                type: "polygon",
                rings: center,
              },
              symbol: fillSymbol,
            });

            view?.goTo({
              target: [polygonGraphic],
            });
          }
        }
        setIsMapFinishGoTo(true);
      }
    };
    callApi();
  }, [
    view,
    isSaveMonitoringLoaded,
    buttonGroupValue?.filterBoxBoundaryPriority,
  ]);

  React.useEffect(() => {
    if (mapShipPTT) {
      view?.ui.add(listShipPTT, "bottom-left");
      pastView?.ui.add(listPastShipPTT, "bottom-left");
      periodView?.ui.add(listPeriodShipPTT, "bottom-left");
    } else {
      view?.ui.remove(listShipPTT);
      pastView?.ui.remove(listPastShipPTT);
      periodView?.ui.remove(listPeriodShipPTT);
    }
  }, [
    mapShipPTT,
    view?.ui,
    pastView?.ui,
    periodView?.ui,
    listShipPTT,
    listPastShipPTT,
    listPeriodShipPTT,
  ]);

  React.useEffect(() => {
    const positionTypeCallBack = () => {
      const positionArr: any = [
        { name: "เจ้าหน้าที่ จป.", img: "/images/icon/safety.png" },
        { name: "ผู้เฝ้าระวังไฟ", img: "/images/icon/fire-watcher.png" },
        {
          name: "พนักงาน ปตท.",
          img: "/images/icon/พนักงาน-PTT.png",
        },
        { name: "ผู้เยี่ยมชม", img: "/images/icon/visitor.png" },
        { name: "ผู้รับเหมา", img: "/images/icon/Contractor.png" },
      ];
      setPositionType({
        data: positionArr,
        status: "success",
      });
    };

    positionTypeCallBack();
  }, []);

  React.useEffect(() => {
    const interval = setInterval(async () => {
      const response2 = await THS_API.get("/map-monitoring");

      const pttEmployee = response2.data.data;

      dispatch(setEmployee(pttEmployee));
    }, 1000 * 10);
    return () => clearInterval(interval);
  }, []);

  // React.useEffect(() => {
  //   // as soon as the component is mounted, do the following tasks:
  //   socket.on("device_location", (device: any) => {
  //     // console.log("device_location");
  //     // console.log(device);
  //     // console.log(new Date());
  //     const index = _.findIndex(
  //       pttEmployee,
  //       (o: any) => o.dev_eui === device.data.dev_eui
  //     );

  //     if (index > -1) {
  //       const newPttEmployee = [...pttEmployee];
  //       newPttEmployee.splice(index, 1, {
  //         ...device.data,
  //         from_web_hook: true,
  //       });

  //       dispatch(setEmployee(newPttEmployee));
  //     } else {
  //       const newPttEmployee = [...pttEmployee, device.data];

  //       dispatch(setEmployee(newPttEmployee));
  //     }
  //   });

  //   return () => {
  //     // before the component is destroyed
  //     // unbind all event handlers used in this component
  //     socket.off("device_location");
  //   };
  // }, [dispatch, pttEmployee, socket]);
  React.useEffect(() => {
    socket.on("boundary", ({ data: polygonArr }: any) => {
      // console.log("boundary");
      // console.log(polygonArr);
      const mapBoundary = polygonArr.filter(
        (po: any) => po.coordinate.length && !po.expired
      );

      setRestrictArr(mapBoundary);
    });

    return () => {
      socket.off("boundary");
    };
  }, [restrictArr, socket]);

  React.useEffect(() => {
    const dataMapBoundary = async () => {
      const res = await THS_API.get("/map-boundarys/active");

      const polygonArr = res?.data?.data.length ? res?.data?.data : [];

      const mapboundary = polygonArr.filter(
        (po: any) => po.coordinate.length && !po.expired
      );

      setRestrictArr(mapboundary);
    };
    dataMapBoundary();
  }, []);
  React.useEffect(() => {
    const callApi = async () => {
      const usersProfileRes = await THS_API.get("/users/profile");

      const userProfileInfo =
        usersProfileRes.data.data.user.data.responsible_area;

      if (userProfileInfo) {
        const callApi = async ({ layer_name, unitid, subunitid }: any) => {
          const params: any = {
            layer_name,
          };
          if (layer_name === "PLANT") params.unitid = unitid;
          else if (layer_name === "AREA") params.subunitid = subunitid;
          const res = await THS_API.get("/map-monitoring/map", {
            params,
          });
          return res.data.data;
        };
        const promiseArr = userProfileInfo.map(callApi);
        const val = await Promise.all(promiseArr);
        const responsibleArea: any = [];
        val.map((v: any) => {
          responsibleArea.push(...v);
          return null;
        });
        setResponsibleArea(responsibleArea);
      }
    };
    callApi();
  }, []);

  React.useEffect(() => {
    const callApi = async () => {
      if (!isSaveMonitoringLoaded && userData.id) {
        dispatch(getMapMonitoring({ userid: userData.id }));
      }
    };
    callApi();
  }, [isSaveMonitoringLoaded, userData.id]);

  React.useEffect(() => {
    if (isSaveMonitoringLoaded && Object.keys(buttonGroupValue).length) {
      const {
        isSubmit,
        chosenMap,
        selectedWorkpermit,
        modalBaseMap,
        viewMap,
        isFilterBoxBoundaryActive,
        sideBarFilter,
        empModalCurrentTab,
      } = buttonGroupValue;

      setIsSubmit(isSubmit);
      setChosenMap(chosenMap);
      setSelectedWorkpermit(selectedWorkpermit);
      setModalBaseMap(modalBaseMap);
      setViewMap(viewMap);
      setIsFilterBoxBoundaryActive(isFilterBoxBoundaryActive);
      setSideBarFilter(sideBarFilter);
      setEmpModalCurrentTab(empModalCurrentTab);
    }
  }, [isSaveMonitoringLoaded]);

  return (
    <AntdLayout className="layout map-monitoring">
      <div className="size-map">
        <div className="map">
          <div className="map-point">
            <div className="card-body" style={{ padding: 3 }}>
              {chosenMap === "time" && isSaveMonitoringLoaded && (
                <>
                  <EsriMapWithNoSSR
                    restrictArr={restrictArr}
                    pttEmployee={pttEmployee}
                    mapZoom={mapZoom}
                    setMapZoom={setMapZoom}
                    setZoomLvl={setZoomLvl}
                    map={map}
                    setMap={setMap}
                    view={view}
                    setView={setView}
                    viewMap={viewMap}
                    responsibleArea={responsibleArea}
                    setListShipPTT={setListShipPTT}
                    selectedWorkpermit={selectedWorkpermit}
                    isFindEmpModal={isFindEmpModal}
                    isModalVisible={isModalVisible}
                    modalBaseMap={modalBaseMap}
                    modalSearchBounary={modalSearchBounary}
                  />

                  <div
                    className={classnames("focus-emp", {
                      focus: isFocusEmp,
                    })}
                  >
                    <img
                      className="icon"
                      src="/images/icon/lock-on.gif"
                      alt="lock-on"
                    />
                  </div>
                </>
              )}

              {chosenMap === "past" && isSaveMonitoringLoaded && (
                <>
                  <EmpPastMap
                    mapZoom={mapZoom}
                    setMapZoom={setMapZoom}
                    setZoomLvl={setZoomLvl}
                    viewMap={viewMap}
                    pastMap={pastMap}
                    pastView={pastView}
                    setPastMap={setPastMap}
                    setPastView={setPastView}
                    setListPastShipPTT={setListPastShipPTT}
                    selectedWorkpermit={selectedWorkpermit}
                  />

                  <div
                    className={classnames("focus-emp", {
                      focus: isFocusEmp,
                    })}
                  >
                    <img
                      className="icon"
                      src="/images/icon/lock-on.gif"
                      alt="lock-on"
                    />
                  </div>
                </>
              )}
              {chosenMap === "period" && isSaveMonitoringLoaded && (
                <WorkPermitMap
                  viewMap={viewMap}
                  dataMapDevice={dataMapDevice}
                  setPeriodView={setPeriodView}
                  setListPeriodShipPTT={setListPeriodShipPTT}
                />
              )}
            </div>
            <ButtonGroup
              setIsSubmit={setIsSubmit}
              setIsModalVisible={setIsModalVisible}
              modalBaseMap={modalBaseMap}
              setModalBaseMap={setModalBaseMap}
              viewMap={viewMap}
              setViewMap={setViewMap}
              modalSearchBounary={modalSearchBounary}
              setModalSearchBounary={setModalSearchBounary}
              zoomLvl={zoomLvl}
              isSubmit={isSubmit}
              // sideBarFilter={sideBarFilter}
              // setSideBarFilter={setSideBarFilter}
              isFindEmpModal={isFindEmpModal}
              setIsFindEmpModal={setIsFindEmpModal}
              isModalVisible={isModalVisible}
              map={map}
              mapZoom={mapZoom}
              mapShipPTT={mapShipPTT}
              setMapShipPTT={setMapShipPTT}
              chosenMap={chosenMap}
              selectedWorkpermit={selectedWorkpermit}
              setSelectedWorkpermit={setSelectedWorkpermit}
              isFilterBoxBoundaryActive={isFilterBoxBoundaryActive}
              setIsFilterBoxBoundaryActive={setIsFilterBoxBoundaryActive}
              setIsClear={setIsClear}
              clearMapResult={clearMapResult}
            />

            <FilterBoxBoundary
              view={view}
              modalSearchBounary={modalSearchBounary}
              setModalSearchBounary={setModalSearchBounary}
              restrictArr={restrictArr}
              setIsFilterBoxBoundaryActive={setIsFilterBoxBoundaryActive}
              isClear={isClear}
            />
          </div>

          <ul
            className="worker"
            style={{ top: 18 + scroll >= 88 ? 88 : 18 + scroll }}
          >
            {positionType.status === "success" &&
              positionType.data.map((obj: any, key: number) => (
                <li key={key}>
                  <img src={obj.img} width={55} height={55} alt={obj.name} />
                  {obj.name}
                </li>
              ))}
          </ul>
        </div>

        <FilterBaseMap
          isOpen={modalBaseMap}
          setModalBaseMap={setModalBaseMap}
          viewMap={viewMap}
          setViewMap={setViewMap}
        />

        <FilterBox
          map={map}
          view={view}
          pastView={pastView}
          mapZoom={mapZoom}
          setIsSubmit={setIsSubmit}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          setSideBarFilter={setSideBarFilter}
          chosenMap={chosenMap}
          setChosenMap={setChosenMap}
          setDataMapDevice={setDataMapDevice}
          setStartDatePast={setStartDatePast}
          isClear={isClear}
          clearMapResult={clearMapResult}
        />
        <FindEmpModal
          view={view}
          pastView={pastView}
          isFindEmpModal={isFindEmpModal}
          setIsFindEmpModal={setIsFindEmpModal}
          setIsFocusEmp={setIsFocusEmp}
          setSideBarFilter={setSideBarFilter}
          setIsModalVisible={setIsModalVisible}
          chosenMap={chosenMap}
          selectedWorkpermit={selectedWorkpermit}
          setSelectedWorkpermit={setSelectedWorkpermit}
          startDatePast={startDatePast}
          isClear={isClear}
          setIsClear={setIsClear}
          empModalCurrentTab={empModalCurrentTab}
          setEmpModalCurrentTab={setEmpModalCurrentTab}
        />
      </div>
    </AntdLayout>
  );
};
