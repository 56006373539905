import React from "react";
import Swal from "sweetalert2";

import { useDispatch } from "react-redux";

import { AntdLayout } from "@pankod/refine-antd";

import { setDashboard } from "reducers/dashboard";

import CharActiveUser from "./charActiveUser";
import CharNotification from "./charNotification";
import CharActiveDevice from "./charActiveDevice";
import ChartArea from "./chartArea";
import ChartSubLocation from "./chartSubLocation";
import ChartHistory from "./chartHistory";

import { useChartZone, useSocketChartZone } from "./hooks/";

import { SocketContext } from "../../context/socket";
import { THS_API } from "../../constants";

import "./index.css";

export const Dashboard: React.FC = () => {
  const [workPermitData, setWorkPermitData] = React.useState<any>([]);
  const [workPermitDataStatus, setWorkPermitDataStatus] =
    React.useState<string>("idle");

  const [allowChartUpdate, setAllowChartUpdate] =
    React.useState<boolean>(false);

  const [activeUserStatus, setActiveUserStatus] =
    React.useState<string>("idle");
  const [dataZone, setDataZone] = React.useState<any>([]);
  const [dataZoneStatus, setDataZoneStatus] = React.useState<string>("idle");
  const [dataColor, setDataColor] = React.useState<any>("#434348");
  const [dataPlantName, setDataPlantName] = React.useState<string>("GPPP");
  const [charLocation, setCharLocation] = React.useState([]);
  const [charLocationStatus, setCharLocationStatus] =
    React.useState<string>("idle");

  const [chartHistory, setChartHostory] = React.useState({});
  const [chartHistoryData, setChartHistoryData] = React.useState([]);

  const dispatch = useDispatch();
  const socket = React.useContext(SocketContext);
  React.useEffect(() => {
    const chartZone = async () => {
      setDataZoneStatus("loading");
      try {
        const zone = await detailZone();

        const params = {
          unitid: zone.drilldown,
        };
        const res = await THS_API.get("dashboard/map-graph-activeuser/plant", {
          params,
        });
        const workPermit = res.data.data;
        let workPermitData = workPermit?.chart?.series[0].data;
        let counter = 0;
        for (let i = 0; i < workPermitData.length; i++) {
          counter = counter + workPermitData[i].y;
        }
        let counttotal = zone.y - counter;

        const plant = { name: zone.name, y: counttotal };

        workPermit?.chart?.series[0].data.push(plant);

        dispatch(
          setDashboard({
            dataDrilldown: zone.drilldown,
            dataPlantName: zone.name,
            dataPlantTotal: zone.y,
            isClick: false,
          })
        );
        setDataZone(workPermit);
      } finally {
        setDataZoneStatus("loaded");
      }
    };
    chartZone();
  }, [dispatch]);

  React.useEffect(() => {
    setWorkPermitDataStatus("loading");
    workPermitCount();

    setWorkPermitDataStatus("loaded");
  }, []);

  const detailZone = async () => {
    setCharLocationStatus("loading");
    try {
      const params = {
        zone: "พื้นที่กระบวนการผลิต",
      };
      const res = await THS_API.get("dashboard/map-graph-activeuser", {
        params,
      });
      setCharLocation(res.data.data.chart.series[0].data);
      return res.data.data.chart.series[0].data[1];
    } finally {
      setCharLocationStatus("loaded");
    }
  };

  useChartZone({ dispatch, setDataZone });
  useSocketChartZone({
    workPermitData,
    setCharLocation,
    setDataZone,
  });

  const workPermitCount = async () => {
    try {
      const res = await THS_API.get("dashboard/summary-report");

      const workPermit = res.data.data;

      setWorkPermitData(workPermit);
    } finally {
    }
  };

  React.useEffect(() => {
    // as soon as the component is mounted, do the following tasks:
    socket.on("dashboard", (workPermit: any) => {
      setAllowChartUpdate(true);
      const { activeUser, summaryReport } = workPermit.data;
      const newArr = activeUser?.datasets[0].data.map(Number);
      setWorkPermitData(summaryReport);

      setChartHostory(activeUser);
      setChartHistoryData(newArr);
    });

    return () => {
      socket.off("dashboard");
    };
  }, [socket]);

  //loading

  React.useEffect(() => {
    if (
      workPermitDataStatus === "loading" ||
      activeUserStatus === "loading" ||
      charLocationStatus === "loading" ||
      dataZoneStatus === "loading"
    )
      Swal.showLoading();
    else if (
      workPermitDataStatus === "loaded" &&
      activeUserStatus === "loaded" &&
      charLocationStatus === "loaded" &&
      dataZoneStatus === "loaded"
    )
      Swal.close();
  }, [
    workPermitDataStatus,
    activeUserStatus,
    charLocationStatus,
    dataZoneStatus,
  ]);

  return (
    <AntdLayout className="dashboard">
      <div>
        <div className="header-topic">
          <h1>Dashboard</h1>
        </div>
        <div className="dashboard">
          <div className="graph-grid-container">
            <div className="card-body no-padding">
              <div className="doughnut-graph-grid-container chart-active">
                <div className="graph-container">
                  <h6>จำนวนอุปกรณ์ที่มีการแลก</h6>
                  {workPermitDataStatus === "loaded" &&
                    workPermitData.length > 0 && (
                      <CharActiveUser
                        workPermitData={workPermitData}
                        allowChartUpdate={allowChartUpdate}
                      />
                    )}
                </div>
                <div className="graph-container">
                  <h6>จำนวนบุคคลในพื้นที่การผลิต</h6>
                  {charLocationStatus === "loaded" &&
                    charLocation.length > 0 && (
                      <CharActiveDevice
                        charLocation={charLocation}
                        allowChartUpdate={allowChartUpdate}
                      />
                    )}
                </div>
              </div>
            </div>
            <div className="card-body no-padding">
              <h6
                style={{
                  marginTop: "10px",
                  marginLeft: "20px",
                }}
              >
                การแจ้งเตือน
              </h6>

              <CharNotification allowChartUpdate={allowChartUpdate} />
            </div>
            <div className="card-body history-chart-box">
              <ChartHistory
                chartHistory={chartHistory}
                setChartHostory={setChartHostory}
                chartHistoryData={chartHistoryData}
                setChartHistoryData={setChartHistoryData}
                setActiveUserStatus={setActiveUserStatus}
              />
            </div>
          </div>
          <div className="grid-story lower-container">
            <div className="card-body ">
              <div className="row">
                <div className="col-sm-4">
                  {charLocationStatus === "loaded" &&
                    charLocation.length > 0 && (
                      <ChartSubLocation
                        setDataColor={setDataColor}
                        setDataPlantName={setDataPlantName}
                        charLocation={charLocation}
                        allowChartUpdate={allowChartUpdate}
                        setAllowChartUpdate={setAllowChartUpdate}
                      />
                    )}
                </div>
                <div className="col-sm-8 ">
                  {dataZoneStatus === "loaded" && (
                    <ChartArea
                      dataZone={dataZone}
                      dataColor={dataColor}
                      dataPlantName={dataPlantName}
                      allowChartUpdate={allowChartUpdate}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AntdLayout>
  );
};
