import React from "react";
import { Link, useNavigate } from "react-router-dom";
import classnames from "classnames";
import Swal from "sweetalert2";
import { Checkbox } from "antd";
import { THS_API } from "../../../constants";

import "./index.css";

export const RoleCreate: React.FC = () => {
  const navigate = useNavigate();
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");
  //view
  const [dashboard, setDashboard] = React.useState<any>(false);
  const [monitoring, setMonitoring] = React.useState(false);
  const [boundary, setBoundary] = React.useState(false);
  const [report, setReport] = React.useState(false);
  const [workPermit, setWorkPermit] = React.useState(false);
  const [role, setRole] = React.useState(false);
  const [log, setlog] = React.useState(false);
  const [notification, setNotification] = React.useState(false);

  const [isSubmitRole, setIsSubmitRole] = React.useState(false);
  const [status, setStatus] = React.useState<string>("active");
  const handleName = (event: any) => {
    const value = event.target.value;
    setName(value);
  };
  const handleDescription = (event: any) => {
    const value = event.target.value;
    setDescription(value);
  };
  //view
  const handleDashboard = (event: any) => {
    const value = event.target.checked;
    setDashboard(value);
  };
  const handleMonitoring = (event: any) => {
    const value = event.target.checked;
    setMonitoring(value);
  };
  const handleBoundary = (event: any) => {
    const value = event.target.checked;
    setBoundary(value);
  };
  const handleReport = (event: any) => {
    const value = event.target.checked;
    setReport(value);
  };
  const handleWorkPermit = (event: any) => {
    const value = event.target.checked;
    setWorkPermit(value);
  };
  const handleRole = (event: any) => {
    const value = event.target.checked;
    setRole(value);
  };
  const handlelog = (event: any) => {
    const value = event.target.checked;
    setlog(value);
  };
  const handleNotification = (event: any) => {
    const value = event.target.checked;
    setNotification(value);
  };

  const handleStatusAction = (event: any) => {
    if (event.target.checked) {
      setStatus("active");
    }
  };
  const handleStatusInaction = (event: any) => {
    if (event.target.checked) {
      setStatus("inactive");
    }
  };

  const roleCreate = async () => {
    setIsSubmitRole(true);

    if (name === "") {
      return;
    }
    const checkboxValidate = {
      dashboard,
      monitoring,
      boundary,
      report,
      workPermit,
      role,
      log,
      notification,
    };
    const isSubmit = Object.values(checkboxValidate).some(
      (allow: any) => allow
    );

    if (isSubmit) {
      try {
        const res = await THS_API.post("/roles", {
          name,
          description,
          viewUsers: true,
          createUsers: true,
          updateUsers: true,
          deleteUsers: true,
          viewDashboard: dashboard,
          createDashboard: dashboard,
          updateDashboard: dashboard,
          deleteDashboard: dashboard,
          viewMapMonitoring: monitoring,
          createMapMonitoring: monitoring,
          updateMapMonitoring: monitoring,
          deleteMapMonitoring: monitoring,
          viewMapBoundary: boundary,
          createMapBoundary: boundary,
          updateMapBoundary: boundary,
          deleteMapBoundary: boundary,
          viewDeviceManager: notification,
          createDeviceManager: notification,
          updateDeviceManager: notification,
          deleteDeviceManager: notification,
          viewReport: report,
          createReport: report,
          updateReport: report,
          deleteReport: report,
          viewRoles: role,
          createRoles: role,
          updateRoles: role,
          deleteRoles: role,
          viewEventLog: log,
          createEventLog: log,
          updateEventLog: log,
          deleteEventLog: log,
          viewWorkPermit: workPermit,
          createWorkPermit: workPermit,
          updateWorkPermit: workPermit,
          deleteWorkPermit: workPermit,
          type: status,
        });
        if (res.data.statusCode === 201) {
          Swal.fire({
            icon: "success",
            title: "บันทึกสำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            navigate("/role");
          }, 1000);
        }
      } catch (error: any) {
        console.log(error.message);
        if (error.message === "Request failed with status code 403") {
          Swal.fire({
            icon: "warning",
            title: "สิทธิ์ซ้ำ",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    } else {
      Swal.fire({
        icon: "info",
        text: "Please check role!",
      });
    }
  };

  return (
    <section className="create-role">
      <div>
        <div className="header-topic ">
          <h1>สร้างสิทธิ์การใช้งาน</h1>
        </div>
        <div className="card-body">
          <form>
            <div className="form-detail content-form">
              <div className="form-group row">
                <label htmlFor="brand" className="col-sm-2 col-form-label">
                  ชื่อสิทธิ์การใช้งาน{" "}
                  <span style={{ color: "blue" }}>(Role Key)</span>{" "}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className={classnames("form-control", {
                      error: name === "" && isSubmitRole,
                    })}
                    id="role-name"
                    placeholder="ชื่อสิทธิการใช้งาน"
                    onChange={handleName}
                  />
                  {name === "" && isSubmitRole && (
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                      style={{ fontSize: "10px", color: "red" }}
                    >
                      กรูณาใส่ชื้อสิทธิ์การใช้งาน
                    </label>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="brand" className="col-sm-2 col-form-label">
                  ชื่อสิทธิที่ใช้แสดง{" "}
                  <span style={{ color: "blue" }}>(Role Name)</span>{" "}
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control"
                    id="role-description"
                    placeholder="ชื่อสิทธิที่ใช้แสดง"
                    onChange={handleDescription}
                  />
                </div>
              </div>
              <div className="form-group row permission-row">
                <label htmlFor="brand" className="col-sm-3 col-form-label">
                  การอนุญาต{" "}
                </label>
                <div className="col-sm-9">
                  <div className="grid-story2 permission-list">
                    <div>
                      <div className="form-check ">
                        <Checkbox onChange={handleDashboard}>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Dashboard
                          </label>
                        </Checkbox>
                      </div>
                      <div className="form-check">
                        <Checkbox onChange={handleMonitoring}>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            People Monitoring
                          </label>
                        </Checkbox>
                      </div>
                      <div className="form-check">
                        <Checkbox onChange={handleBoundary}>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            สร้างขอบเขตการทำงาน
                          </label>
                        </Checkbox>
                      </div>
                      <div className="form-check">
                        <Checkbox onChange={handleNotification}>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            รายการอุปกรณ์
                          </label>
                        </Checkbox>
                      </div>
                      <div className="form-check">
                        <Checkbox onChange={handleRole}>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            สิทธิ์การเข้าใช้งาน
                          </label>
                        </Checkbox>
                      </div>
                      <div className="form-check">
                        <Checkbox onChange={handlelog}>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            บันทึกการใช้งานระบบ
                          </label>
                        </Checkbox>
                      </div>
                      <div className="form-check">
                        <Checkbox onChange={handleWorkPermit}>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            คนและอุปกรณ์
                          </label>
                        </Checkbox>
                      </div>
                      <div className="form-check">
                        <Checkbox onChange={handleReport}>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            รายงาน
                          </label>
                        </Checkbox>
                      </div>
                    </div>
                    {/* <div>
                      <p>Edit</p>
                      <div className="form-check">
                        <Checkbox onChange={handleEditDashboard}>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Dashboard
                          </label>
                        </Checkbox>
                      </div>
                      <div className="form-check">
                        <Checkbox onChange={handleEditMonitoring}>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Map Monitoring
                          </label>
                        </Checkbox>
                      </div>
                      <div className="form-check">
                        <Checkbox onChange={handleEditBoundary}>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Map Boundary
                          </label>
                        </Checkbox>
                      </div>
                      <div className="form-check">
                        <Checkbox
                          onChange={(e) =>
                            setEditDeviceManager(e.target.checked)
                          }
                        >
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Device Manager
                          </label>
                        </Checkbox>
                      </div>
                      <div className="form-check">
                        <Checkbox onChange={handleEditReport}>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Report
                          </label>
                        </Checkbox>
                      </div>

                      <div className="form-check">
                        <Checkbox onChange={handleEditRole}>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Role Management
                          </label>
                        </Checkbox>
                      </div>
                      <div className="form-check">
                        <Checkbox onChange={handleEditlog}>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Event log
                          </label>
                        </Checkbox>
                      </div>
                      <div className="form-check">
                        <Checkbox onChange={handleEditWorkPermit}>
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          >
                            Work Permit
                          </label>
                        </Checkbox>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="form-group row status-row">
                <label
                  htmlFor="SubLocation"
                  className="col-sm-3 col-form-label"
                >
                  สถานะ
                </label>
                <div className="col-sm-9">
                  <div className="check">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                        onChange={handleStatusAction}
                      >
                        กำลังใช้งาน
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        onChange={handleStatusInaction}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault2"
                      >
                        ไม่ได้ใช้งาน
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid-story2 btn-form">
              <Link to="/role" style={{ color: "white" }}>
                <div className="btn btn-back" style={{ color: "white" }}>
                  กลับ
                </div>
              </Link>
              <div
                className="btn btn-save"
                data-bs-toggle="modal"
                data-bs-target="#saveDone"
                onClick={roleCreate}
              >
                บันทึก
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
