import { configureStore } from "@reduxjs/toolkit";

import mapBoundaryReducers from "reducers/mapBoundaryReducers";
import mapMonitoringReducers from "reducers/mapMonitoring";
import mapMonitoringDeviceReducer from "reducers/mapMonitoringDeviceReducer";
import notificationReducers from "reducers/notificationReducers";
import rolesReducer from "reducers/rolesReducer";
import userReducer from "reducers/userReducer";
import dashboardReducer from "reducers/dashboard";
export const store = configureStore({
  reducer: {
    geometry: mapBoundaryReducers,
    mapMonitoring: mapMonitoringReducers,
    mapdevice: mapMonitoringDeviceReducer,
    notification: notificationReducers,
    role: rolesReducer,
    user: userReducer,
    dashboard: dashboardReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
