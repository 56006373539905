import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";

import { useDispatch } from "react-redux";
import { setDashboard } from "../../reducers/dashboard";

export default function ChartSubLocation({
  setDataColor,
  setDataPlantName,
  charLocation,
  allowChartUpdate,
  setAllowChartUpdate,
}) {
  // const [drilldown, setdrilldown] = React.useState("PL000002");

  const dispatch = useDispatch();
  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      height: 370,

      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },

    title: {
      text: "จำนวนบุคคลในแต่ละพื้นที่กระบวนการผลิต",
      style: { fontSize: 16 },
    },

    tooltip: {
      pointFormat: " <b>{series.name} {point.y} คน</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      series: {
        allowPointSelect: true,
        cursor: "pointer",
        // colors: ["#3182CE ", "#D1E9F8 ", "#40A1B7 ", "#00ADEE"],
        point: {
          events: {
            click: function (e) {
              dataBuilding(
                e.point.color,
                e.point.drilldown,
                e.point.options.name,
                e.point.options.y
              );
            },
          },
        },
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>  {point.y} คน ", //"<b>{point.name}</b>: {point.y} "

          distance: 10,
          style: {
            fontSize: 14,
          },
        },
      },
    },
    series: [
      {
        name: "จำนวน",
        colorByPoint: true,
        data: charLocation,
        //   showInLegend: true,
      },
    ],
  };

  const dataBuilding = async (color, drilldown, name, total) => {
    // const params = {
    //   unitid: drilldown,
    // };

    // const res = await THS_API.get("dashboard/map-graph-activeuser/plant", {
    //   params,
    // });

    // const DataZone = res.data.data;
    // let datadata = DataZone?.chart?.series[0].data;

    // let counter = 0;
    // for (let i = 0; i < datadata.length; i++) {
    //   counter = counter + datadata[i].y;
    // }
    // let counttotal = total - counter;

    // const plant = { name: name, y: counttotal };

    // DataZone?.chart?.series[0].data.push(plant);
    // setDataZone(DataZone);

    setAllowChartUpdate(true);

    dispatch(
      setDashboard({
        dataDrilldown: drilldown,
        dataPlantName: name,
        dataPlantTotal: total,
        isClick: true,
      })
    );

    setDataColor(color);
    setDataPlantName(name);
  };

  return (
    <PieChart
      allowChartUpdate={allowChartUpdate}
      highcharts={Highcharts}
      options={options}
    />
  );
}
