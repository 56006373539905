import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { THS_API } from "../constants";

const ID_APP = process.env.REACT_APP_ID_APP || "";

const initialState = {
  id: "",
  name: "",
  email: "",
  role: "",
  apiStatus: "",
};

export const getUserProfile = createAsyncThunk(
  "user/getUserProfileAPI",
  async () => {
    const response = await THS_API.get("/users/profile");
    return response.data.data.user;
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.apiStatus = "loading";
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        const { email, registrations, firstName, lastName, id } =
          action.payload;

        const currentUser = registrations.find(
          (obj: any) => obj.applicationId === ID_APP
        ) || [{ roles: [""] }];

        const role = currentUser.roles[0];
        state.name = `${firstName} ${lastName}`;
        state.id = id;
        state.email = email;
        state.role = role;
        state.apiStatus = "success";
      })
      .addCase(getUserProfile.rejected, (state) => {
        state.apiStatus = "rejected";
      });
  },
});

export const selectUser = (state: any) => state.user;

export default userSlice.reducer;
