import moment from "moment";
import ExcelJS from "exceljs";
import Swal from "sweetalert2";

import * as FileSaver from "file-saver";
import { THS_API } from "../../../constants";

const handleExportFile = async ({
  startDate,
  endDate,
  reportTypeQuery,
  record,
  description,
  userData,
}: any) => {
  let dataStartDate = moment(startDate).format("YYYY-MM-DD");
  let dataEndDate = moment(endDate).format("YYYY-MM-DD");
  const { holder_cid, dev_eui, wpm_id, wpm_no, first_name, last_name } = record;

  const urlObj: any = {
    cid: `/report/notification/cid/${holder_cid}/xlsx`,
    dev_eui: `/report/notification/dev_eui/${dev_eui}/xlsx`,
    wpm: `/report/notification/workpermit-id/${wpm_id}/xlsx`,
  };

  Swal.showLoading();
  const res = await THS_API.get(urlObj[reportTypeQuery], {
    params: {
      start_date: dataStartDate,
      end_date: dataEndDate,
    },
    //  responseType: "blob",
  });

  // const { data: apiData, status } = res;

  let workbook = new ExcelJS.Workbook(); //creating workbook
  const worksheet = workbook.addWorksheet("User");
  // workbook.getCell("A1").value = "ffffff" // ชี้ไปยัง Cell ที่เลือกเพื่อใส่ค่าเข้าไปใน Cell
  const rowUser = worksheet.getRow(3);
  //rowUser.values = header
  rowUser.height = 25;
  const rowHeaderName = worksheet.getColumn(5);
  rowHeaderName.header = "รายงานสรุปการแจ้งเตือน Application ";
  rowHeaderName.width = 40;
  worksheet.getColumn(5).font = {
    family: 4,
    name: "Calibri (Body)",
    size: 16,
    bold: true,
  };
  const rowNameReport = worksheet.getRow(4);
  rowNameReport.getCell(2).value = `ผู้ออกรายงาน : '${userData.name}`;
  const rowRoleReport = worksheet.getRow(5);
  rowRoleReport.getCell(2).value = `สิทธิ์ผู้ออกรายงาน : '${description}`;
  const rowtimeReport = worksheet.getRow(6);
  rowtimeReport.getCell(2).value = `วันที่ออกรรายงาน : '${moment(
    new Date()
  ).format("DD-MM-YYYY")}`;
  const rowtime = worksheet.getRow(7);
  rowtime.getCell(2).value = `เวลาเริ่ม : '${moment(dataStartDate).format(
    "DD-MM-YYYY"
  )}`;
  rowtime.getCell(4).value = `เวลาสิ้นสุด : '${moment(dataEndDate).format(
    "DD-MM-YYYY"
  )}`;
  const rowType = worksheet.getRow(8);
  if (reportTypeQuery === "cid") {
    rowType.getCell(2).value = `ประเภทการค้นหา : บุคคล`;
  } else if (reportTypeQuery === "wpm") {
    rowType.getCell(2).value = `ประเภทการค้นหา : ใบอนุญาตทำงาน`;
  } else if (reportTypeQuery === "dev_eui") {
    rowType.getCell(2).value = `ประเภทการค้นหา : หมายเลขอุปกรณ์`;
  }
  const rows = worksheet.getRow(11);
  const columns = [
    "ลำดับ",
    "วัน",
    "เวลา",
    "ชื่อ-นามสกุล",
    "ประเภทการแจ้งเตือน",
    "ใบอนุญาตทำงาน",
    "ผู้ควบคุมงาน",
  ];
  rows.values = columns;
  const worksheetColumn = [
    { key: "no", width: 5 },
    { key: "dateDate", width: 10 },
    { key: "time", width: 10 },
    { key: "name", width: 15 },
    { key: "type", width: 40, outlineLevel: 1 },
    { key: "wpm_no", width: 30 },
    { key: "sup_name", width: 20 },
  ];
  worksheet.columns = worksheetColumn;
  rows.eachCell(function (cell) {
    cell.alignment = {
      vertical: "middle",
      horizontal: "center",
    };
    cell.font = { color: { argb: "FFFFFFFF" }, name: "Calibri (Body)" };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "121b1464" },
    };
  });

  const dataReport = res.data.data.data.map((obj: any, index: number) => {
    const date = moment(obj.datetime).format("DD-MM-YYYY");
    const time = moment(obj.datetime).format("HH:mm");
    return {
      ...obj,
      dateDate: date,
      time: time,
      no: index + 1,
    };
  });

  worksheet.addRows(dataReport);
  const buffer = await workbook.xlsx.writeBuffer();
  const cid = `${first_name} ${last_name}`;
  const title: any = {
    cid,
    dev_eui,
    wpm: wpm_no,
  };
  FileSaver.saveAs(
    new Blob([buffer]),
    `${title[reportTypeQuery]}_${moment(new Date()).format("DD-MM-YYYY")}.xlsx`
  );

  Swal.close();
  // if (status === 200 && apiData) {
  //   const blob = new Blob([apiData], {
  //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
  //   });

  //   const fileType: any = {
  //     cid: "cid",
  //     dev_eui: "dev-eui",
  //     wpm: "wpm",
  //   };
  //   const name = wpm_no || dev_eui || `${first_name} ${last_name}`;

  //   const fileName = `report-${fileType[reportTypeQuery]}-${name}_${dataStartDate}-${dataEndDate}.xlsx`;

  //   FileSaver.saveAs(blob, fileName);
  // }
};
export const defaultColumn = ({
  startDate,
  endDate,
  reportTypeQuery,
  description,
  userData,
}: any) => {
  let typeTitle = "ข้อมูลรายบุคคล";
  if (reportTypeQuery === "cid") {
    typeTitle = "ข้อมูลรายบุคคล";
  } else if (reportTypeQuery === "wpm") {
    typeTitle = "ข้อมูลหมายเลขใบอนุญาตทำงาน";
  } else if (reportTypeQuery === "dev_eui") {
    typeTitle = "ข้อมูลอุปกรณ์";
  }

  return [
    {
      title: typeTitle,
      sorter: (recordA: any, recordB: any) => {
        const fullNameA: any =
          recordA.first_name && recordA.last_name
            ? `${recordA.first_name} ${recordA.last_name}`
            : "-";
        const fullNameB: any =
          recordB.first_name && recordB.last_name
            ? `${recordB.first_name} ${recordB.last_name}`
            : "-";
        if (recordA.wpm_no) return recordA.wpm_no - recordB.wpm_no;
        else if (recordA.dev_eui) return recordA.dev_eui - recordB.dev_eui;

        return fullNameA.localeCompare(fullNameB);
      },
      render: (record: any) => {
        const { wpm_no, dev_eui, first_name, last_name } = record;
        const fullName =
          first_name && last_name ? `${first_name} ${last_name}` : "-";
        return (
          <>
            {reportTypeQuery === "cid" && fullName}
            {wpm_no}
            {dev_eui}
          </>
        );
      },
    },

    {
      title: "ดาวน์โหลด",
      render: (record: any) => {
        return (
          <img
            className="xls-file"
            src="/images/icon/xls.svg"
            alt="xls"
            onClick={() =>
              handleExportFile({
                startDate,
                endDate,
                reportTypeQuery,
                record,
                description,
                userData,
              })
            }
          />
        );
      },
    },
  ];
};
