import Swal from "sweetalert2";

import { AuthProvider } from "@pankod/refine-core";

import { AxiosInstance } from "axios";
import { THS_API_URL, THS_TOKEN_KEY, THS_EXPIRED, THS_API } from "./constants";

const ID_APP = process.env.REACT_APP_ID_APP || "";

export const authProvider = (axiosInstance: AxiosInstance): AuthProvider => {
  return {
    login: async ({ username, password }) => {
      try {
        const authLogin = `${THS_API_URL}/auth/login`;
        Swal.showLoading();
        const res = await axiosInstance.post(authLogin, {
          username,
          password,
        });

        if (res.data.statusCode === 200) {
          const {
            access_token,
            tokenExpirationInstant: expired,
            user: { registrations },
          } = res.data.data;

          const currentUser = registrations.filter(
            ({ applicationId }: any) => applicationId === ID_APP
          )[0];

          const thsRole = currentUser.roles[0] || "security";
          localStorage.setItem(THS_TOKEN_KEY, access_token);

          localStorage.setItem(THS_EXPIRED, expired);
          THS_API.defaults.headers.common.Authorization = `Bearer ${access_token}`;

          const roleNameWithParams = `${process.env.REACT_APP_API}/roles/name/${thsRole}`;

          const res1 = await THS_API.get(roleNameWithParams);

          if (res1.data.data.type !== "active") {
            Swal.fire({
              text: "ชื่อผู้ใช้ยังไม่มีสิทธิ์การใช้งาน",
              imageUrl: "assets/img/icon/remove.svg",
              imageHeight: 70,
              confirmButtonColor: "#1b1464",
              confirmButtonText: "ตกลง",
            });
            return Promise.reject();
          } else Swal.close();

          return Promise.resolve();
        } else if (res.data.statusCode === 403) {
          Swal.fire({
            text: "ชื่อผู้ใช้หรือรหัสผ่านของคุณไม่ถูกต้อง",
            imageUrl: "assets/img/icon/remove.svg",
            imageHeight: 70,
            confirmButtonColor: "#1b1464",
            confirmButtonText: "ตกลง",
          });
        } else if (res.data.statusCode === 203) {
          Swal.fire({
            text: "บัญชีของท่านไม่ได้รับสิทธิ์การเข้าถึงระบบ People Motion Tracking โปรดติดต่อหน่วยงานที่เกี่ยวข้องหากต้องการใช้งาน",
            imageUrl: "assets/img/icon/warning.svg",
            imageHeight: 70,
            confirmButtonColor: "#1b1464",
            confirmButtonText: "ตกลง",
          });
          return Promise.reject();
        }
      } catch (error: any) {
        console.log("error.message", error.message);

        if (error.message === "Request failed with status code 423") {
          Swal.fire({
            text: "บัญชีของท่านไม่ได้รับสิทธิ์การเข้าถึงระบบ People Motion Tracking โปรดติดต่อหน่วยงานที่เกี่ยวข้องหากต้องการใช้งาน",
            imageUrl: "assets/img/icon/warning.svg",
            imageHeight: 70,
            confirmButtonColor: "#1b1464",
            confirmButtonText: "ตกลง",
          });
          return Promise.reject();
        } else if (error.message === "Network Error") {
          Swal.fire({
            text: "สิทธิ์สำหรับการเข้าใช้งาน ต้องเชื่อมต่อ VPN ของปตท.(GSP) ก่อนใช้งานระบบเท่านั้น",
            imageUrl: "assets/img/icon/warning.svg",
            imageHeight: 70,
            confirmButtonColor: "#1b1464",
            confirmButtonText: "ตกลง",
          });
          return Promise.reject();
        }
        localStorage.removeItem(THS_TOKEN_KEY);
        localStorage.removeItem(THS_EXPIRED);
        return Promise.reject();
      }
    },
    logout: () => {
      localStorage.removeItem(THS_TOKEN_KEY);
      localStorage.removeItem(THS_EXPIRED);
      delete THS_API.defaults.headers.common.Authorization;

      return Promise.resolve();
    },
    checkError: () => Promise.resolve(),

    checkAuth: async () => {
      const token = localStorage.getItem(THS_TOKEN_KEY);
      THS_API.defaults.headers.common.Authorization = `Bearer ${token}`;

      const { search, pathname } = window.location;
      const tokenUrl = search.substring(1).split("=");
      const key = String(tokenUrl[0]);
      const dateToken = String(tokenUrl[1]);

      if (pathname === "/" && key === "token") {
        try {
          const authLoginToken = `${THS_API_URL}/auth/login/token`;
          const res = await axiosInstance.post(authLoginToken, {
            access_token: dateToken,
          });

          if (res.data.statusCode === 200) {
            const { access_token, tokenExpirationInstant } = res.data.data;

            localStorage.setItem(THS_TOKEN_KEY, access_token);

            localStorage.setItem(THS_EXPIRED, tokenExpirationInstant);
            THS_API.defaults.headers.common.Authorization = `Bearer ${access_token}`;

            return Promise.resolve();
          }
        } catch (err) {
          localStorage.removeItem(THS_TOKEN_KEY);
          localStorage.removeItem(THS_EXPIRED);
          delete THS_API.defaults.headers.common.Authorization;
          return Promise.reject();
        }
        return Promise.resolve();
      } else if (token) {
        const expire_time = Number(localStorage.getItem(THS_EXPIRED)) || 0;
        const currTime = new Date().getTime();
        const expireTime = new Date(expire_time).getTime();

        //condition when token is expired
        if (currTime >= expireTime) {
          localStorage.removeItem(THS_TOKEN_KEY);
          localStorage.removeItem(THS_EXPIRED);
          delete THS_API.defaults.headers.common.Authorization;

          return Promise.reject();
        }
        //if token is not expired code below will refresh token
        else {
          THS_API.defaults.headers.common.Authorization = `Bearer ${token}`;

          return Promise.resolve();
        }
      }

      return Promise.reject();
    },
    getPermissions: () => {
      return Promise.resolve();
    },
    getUserIdentity: async () => {
      const token = localStorage.getItem(THS_TOKEN_KEY);

      if (!token) {
        //check
        return Promise.reject();
      }

      return Promise.resolve({
        id: 1,
      });
    },
  };
};
