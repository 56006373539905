import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mapData: [],
};

export const geometrySlice = createSlice({
  name: "geometry",
  initialState,
  reducers: {
    setMap: (state, action) => {
      state.mapData = action.payload;
    },
  },
});

export const selectMap = (state) => state.geometry.mapData;

export const { setMap } = geometrySlice.actions;

export default geometrySlice.reducer;
