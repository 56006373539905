import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { THS_API } from "../constants";

const initialState = {
  status: "idle",
  data: {},
  description: "",
};

export const roleAPI = createAsyncThunk("resume/getRole", async (role: any) => {
  try {
    const url = `/roles/name/${role}`;
    const response = await THS_API.get(url);

    return response;
  } catch (error) {
    throw error;
  }
});

const roleSlice = createSlice({
  name: "role",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(roleAPI.pending, (state) => {
        state.status = "loading";
      })
      .addCase(roleAPI.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
        state.description = action.payload.data.data.description;
      })
      .addCase(roleAPI.rejected, (state, action) => {
        state.status = "rejected";
      });
  },
});

export const selectRole = (state: any) => state.role.data;
export const selectDescription = (state: any) => state.role.description;
export default roleSlice.reducer;
