import React from "react";
import moment from "moment";

import { Select } from "antd";

import SortingTable from "components/SortingTable";

import { StartDate, EndDate } from "components/datepicker";
import { useSelector } from "react-redux";
import { defaultColumn } from "./_tableColumn";
import { useReportListFilterNoti } from "./_hooks";
import { THS_API } from "../../../constants";
import { selectDescription } from "reducers/rolesReducer";
import { selectUser } from "reducers/userReducer";
import "./index.css";

const NotificationReport = ({
  setStartDateWholeReport,
  setEndDateWholeReport,
}: any) => {
  const [startDateNoti, setStartDateNoti] = React.useState(new Date());
  const [endDateNoti, setEndDateNoti] = React.useState(new Date());
  const [apiStatusNoti, setApiStatusNoti] = React.useState("start");
  const [queryNoti, setQueryNoti] = React.useState([]);
  const [selectWorpermit, setSelectWorpermit] = React.useState([]);
  const [reportTypeQueryNoti, setReportTypeQueryNoti] = React.useState("cid");

  const [reportTypeNoti, setReportTypeNoti] = React.useState("cid");
  const [reportInputNoti, setReportInputNoti] = React.useState("");
  const [reportInputWorkpermitSelect, setReportInputWorkpermitSelect] =
    React.useState("");

  const userData = useSelector(selectUser);
  const description = useSelector(selectDescription);
  const reportAPINoti = async (report: string, searchType: string) => {
    let dataStartDate = moment(startDateNoti).format("YYYY-MM-DD");
    let dataEndDate = moment(endDateNoti).format("YYYY-MM-DD");
    setApiStatusNoti("loading");
    setQueryNoti([]);

    const params: any = {
      start_date: dataStartDate,
      end_date: dataEndDate,
      type: reportTypeNoti,
    };

    if (searchType) {
      if (searchType === "dateWPM" && reportInputNoti) {
        params[searchType] = reportInputNoti.split("|")[0];
      } else params[searchType] = reportInputNoti;
    }

    const res = await THS_API.get(report, {
      params,
    });

    const { status, data: payload } = res;
    if (status === 200) {
      const { data } = payload;
      setQueryNoti(data);

      setApiStatusNoti("success");
    }
  };

  const handleReportListFilterNoti = () => {
    if (reportTypeNoti === "cid" && reportInputNoti) {
      if (parseInt(reportInputNoti[0]))
        reportAPINoti("/report/notification-filter", "dataCid");
      else reportAPINoti("/report/notification-filter", "fullName");
    } else if (reportTypeNoti === "cid" && !reportInputNoti)
      reportAPINoti("/report/notification-filter", "");
    else if (reportTypeNoti === "wpm")
      reportAPINoti("/report/notification-filter", "dateWPM");
    else if (reportTypeNoti === "dev_eui" && !reportInputNoti)
      reportAPINoti("/report/notification-filter", "");
    else if (reportTypeNoti === "dev_eui" && reportInputNoti)
      reportAPINoti("/report/notification-filter", "dataEui");

    setReportTypeQueryNoti(reportTypeNoti);
  };

  const resetFilter = async () => {
    const date = new Date();

    const dataStartDate = moment(date).format("YYYY-MM-DD");
    const dataEndDate = moment(date).format("YYYY-MM-DD");

    setStartDateNoti(date);
    setEndDateNoti(date);
    setReportTypeQueryNoti("cid");
    setReportInputNoti("");
    setReportInputWorkpermitSelect("");
    setApiStatusNoti("loading");
    setQueryNoti([]);

    const params = {
      start_date: dataStartDate,
      end_date: dataEndDate,
      type: "cid",
    };
    const res = await THS_API.get("/report/notification-filter", {
      params,
    });

    const { status, data: payload } = res;
    if (status === 200) {
      const { data } = payload;
      setQueryNoti(data);
      setApiStatusNoti("success");
    }
  };

  useReportListFilterNoti({ handleReportListFilterNoti });

  React.useEffect(() => {
    const selectWorkmit = async () => {
      const date = new Date();
      const dataDate = moment(date).format("YYYY-MM-DD");
      const params = {
        type: "wpm",
        start_date: dataDate,
        end_date: dataDate,
      };

      const res = await THS_API.get(`/report/notification-filter`, {
        params,
      });

      setSelectWorpermit(res.data.data);
    };
    selectWorkmit();
  }, []);

  return (
    <>
      <div className="notification-report card-body search-bar">
        <form className="form">
          <div className="grid-story6">
            <div className="form-group">
              <div className="mt-3 mb-3">
                <select
                  className="form-control"
                  onChange={(e) => {
                    setReportTypeNoti(e.target.value);
                    setReportInputNoti("");
                    setReportInputWorkpermitSelect("");
                  }}
                  defaultValue={reportTypeNoti}
                >
                  <option value="cid">ข้อมูลรายบุคคล</option>
                  <option value="wpm">ข้อมูลหมายเลขใบอนุญาตทำงาน</option>
                  <option value="dev_eui">ข้อมูลอุปกรณ์</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              {reportTypeNoti === "dev_eui" && (
                <div className="mt-3 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="หมายเลขอุปกรณ์"
                    value={reportInputNoti}
                    onChange={(e) => setReportInputNoti(e.target.value)}
                  />
                </div>
              )}
              {reportTypeNoti === "cid" && (
                <div className="mt-3 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={
                      reportTypeNoti === "cid"
                        ? "ชื่อ - นามสกุล / บัตรประชาชน"
                        : "เลข workpermit id"
                    }
                    value={reportInputNoti}
                    onChange={(e) => setReportInputNoti(e.target.value)}
                  />
                </div>
              )}
              {reportTypeNoti === "wpm" && (
                <div className="mt-3 mb-3">
                  <Select
                    className="form-control"
                    showSearch
                    placeholder="หมายเลขใบอนุญาตทำงาน"
                    onChange={(value) => {
                      setReportInputNoti(value.split("|")[1]);
                      setReportInputWorkpermitSelect(value);
                    }}
                    value={reportInputWorkpermitSelect}
                  >
                    <Select.Option value="">เลือกใบอนุญาตทำงาน</Select.Option>
                    {selectWorpermit?.map((work: any, key: number) => {
                      const { wpm_no, wpm_id } = work;
                      return (
                        <Select.Option key={key} value={`${wpm_no}|${wpm_id}`}>
                          {wpm_no}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              )}
            </div>
            <div className="form-group">
              <div className="mt-3 mb-3">
                <StartDate
                  startDate={startDateNoti}
                  endDate={endDateNoti}
                  onChange={(date: any) => {
                    const start = moment(date);
                    const end = moment(endDateNoti);
                    setStartDateNoti(date);
                    setStartDateWholeReport(date);
                    if (start.diff(end) > 0) setEndDateNoti(date);
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="mt-3 mb-3">
                <EndDate
                  startDate={startDateNoti}
                  endDate={endDateNoti}
                  onChange={(date: any) => {
                    setEndDateNoti(date);
                    setEndDateWholeReport(date);
                  }}
                />
              </div>
            </div>
            <div className="btn btn-save" onClick={handleReportListFilterNoti}>
              ค้นหา
            </div>
            <div className="btn btn-save" onClick={resetFilter}>
              ล้าง
            </div>
          </div>
        </form>
      </div>
      <SortingTable
        apiStatus={apiStatusNoti}
        query={queryNoti}
        columns={defaultColumn({
          startDate: startDateNoti,
          endDate: endDateNoti,
          reportTypeQuery: reportTypeQueryNoti,
          description,
          userData,
        })}
      />
    </>
  );
};
export default NotificationReport;
