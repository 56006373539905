import { loadModules } from "esri-loader";

const initMap = async ({
  mapDiv,
  setMapZoom,
  setZoomLvl,
  viewMap,
  role,
}: any) => {
  const [Map, MapView, urlUtils, MapImageLayer, GraphicsLayer, LayerList] =
    await loadModules([
      "esri/Map",
      "esri/views/MapView",
      "esri/core/urlUtils",
      "esri/layers/MapImageLayer",
      "esri/layers/GraphicsLayer",
      "esri/widgets/LayerList",
    ]);

  let layerList;
  const url = process.env.REACT_APP_MAP_API;
  const proxy = process.env.REACT_APP_MAP_PROXY;

  const mapServer = `${url}/PTT_SHIP/PTT_SHIP_MAP/MapServer`;
  const proxyUrl = `${proxy}/${btoa(
    "user=trackingmove&system=map"
  )}/api/AppProxy`;

  urlUtils.addProxyRule({
    urlPrefix: url,
    proxyUrl,
  });
  let mapImageLayerIntegrate = new MapImageLayer({
    url: mapServer,
    sublayers: [
      // { title: "Plant", id: 11, visible: true },
      { title: "Plant", id: 9, visible: false },
      {
        title: "Area",
        id: 8,
        visible: false,
      },
      {
        title: "อาคาร",
        id: 7,
        visible: false,
      },
      { title: "จุดจอดรถ", id: 6, visible: false },
      // {
      //   id: 0,
      //   visible: true,
      // },
      { title: "pipe Rack", id: 4, visible: false },
      { title: "pipe Bridge", id: 3, visible: false },
      {
        title: "ถนน",
        id: 2,
        visible: true,
      },
      {
        title: "ป้ายจราจร",
        id: 1,
        visible: false,
      },
      {
        title: "อุปกรณ์",
        id: 0,
        visible: true,
      },
    ],
  });

  let zoom = 17;
  if (role === "ths silom") zoom = 18;

  const restrictAreaLayer = new GraphicsLayer({
    title: "restrict",
    id: "restrict-layer",
  });
  const pttSearchGeometryLayer = new GraphicsLayer({
    title: "ptt-search-geometry",
    id: "ptt-search-geometry-layer",
  });
  const pttSearchGeometryTextLayer = new GraphicsLayer({
    title: "ptt-search-geometry-layer-text",
    id: "ptt-search-geometry-layer-text",
  });
  const pttResiponsibleGeometryLayer = new GraphicsLayer({
    title: "ptt-responsible-geometry-layer",
    id: "ptt-responsible-geometry-layer",
  });
  const pttResiponsibleGeometryTextLayer = new GraphicsLayer({
    title: "ptt-responsible-geometry-layer-text",
    id: "ptt-responsible-geometry-layer-text",
  });
  const employeeLayer = new GraphicsLayer({
    title: "employee",
    id: "employee-layer",
  });

  const map = new Map({
    basemap: viewMap,
    layers: [
      mapImageLayerIntegrate,
      pttResiponsibleGeometryLayer,
      pttResiponsibleGeometryTextLayer,
      pttSearchGeometryLayer,
      pttSearchGeometryTextLayer,
      restrictAreaLayer,
      employeeLayer,
    ],
  });

  const view = new MapView({
    map,
    container: mapDiv.current,
    zoom,
    center: [101.14724047605345, 12.720144961950934],
    constraints: {
      minZoom: 16,
    },
    rotation: 58,
  });

  layerList = new LayerList({
    id: "choose-map-component",
    view,
  });

  view.watch("zoom", (newValue: number) => {
    setMapZoom(newValue);
    if (newValue >= 16 && newValue < 17) setZoomLvl("PLANT");
    else if (newValue >= 17 && newValue <= 18) setZoomLvl("AREA");
    else if (newValue > 18) setZoomLvl("SUBAREA");
  });

  return [map, view, layerList];
};

export default initMap;
