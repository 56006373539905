import DatePicker, { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-GB";
export default function StartDateFrom({ startDate, endDate, ...rest }) {
  registerLocale("el", en);

  return (
    <DatePicker
      className="form-control"
      selected={startDate}
      maxDate={endDate}
      locale="el"
      dateFormat="P"
      {...rest}
    />
  );
}
