import { loadModules } from "esri-loader";

const createGeometry = ({ view, vertices }: any) => {
  return {
    type: "polygon",
    rings: vertices,
  };
};

const orderedPttGeometry = (pttGeometry: any) => {
  return pttGeometry
    .map((polygon: any) => {
      const arrOrder: any = {
        AREA: 1,
        BUILDING: 2,
        PLANT: 0,
      };
      return { ...polygon, order: arrOrder[polygon.layer_name] };
    })

    .sort((a: any, b: any) => a.order - b.order);
};

const pttGeometryAddText = ({
  pttGeometryTextLayer,
  Graphic,
  pttGeometry,
  mapZoom,
}: any) => {
  orderedPttGeometry(pttGeometry).map(async (polygon: any) => {
    const { shape_geometry, layer_name, zone, subunitname, active } = polygon;
    const vertices = shape_geometry;

    let polygonTxt;

    if (layer_name === "PLANT") polygonTxt = zone;
    else if (layer_name === "AREA" || layer_name === "BUILDING")
      polygonTxt = subunitname;
    let fontSize;
    let total = 0;
    if (
      (mapZoom === 16 && layer_name === "PLANT") ||
      (mapZoom > 16 && mapZoom < 18 && layer_name === "AREA") ||
      (mapZoom >= 18 && layer_name === "BUILDING")
    ) {
      fontSize = 10;
      const backGroundText = polygonTxt.length;
      total = backGroundText * 10 + 20;
    }

    const textSymbol = {
      type: "text", // autocasts as new TextSymbol()
      color: "white",
      haloColor: "black",
      haloSize: "5px",
      text: `${polygonTxt} : ${active}`,
      xoffset: 0,
      yoffset: -3,
      font: {
        // autocast as new Font()
        size: fontSize,
        family: "sans-serif",
        weight: "bold",
      },
    };
    let symbolBackground = {
      type: "picture-marker", // autocasts as new PictureFillSymbol()
      url: "./images/black-color.jpg",
      width: `${total}px`,
      height: "25px",
      outline: {
        style: "solid",
      },
    };
    const geometry = createGeometry({ vertices });
    const blackGround = new Graphic({
      geometry,
      symbol: symbolBackground,
    });

    const newDevelopmentGraphicText = new Graphic({
      geometry,
      symbol: textSymbol,
    });

    pttGeometryTextLayer?.addMany([blackGround, newDevelopmentGraphicText]);
    return null;
  });
};

const initPttPolygonTextAPI = async ({
  pttGeometry,
  pttGeometryTextLayer,
  mapZoom,
}: any) => {
  const [Graphic] = await loadModules(["esri/Graphic"]);
  if (pttGeometry?.length)
    pttGeometryAddText({
      pttGeometryTextLayer,
      Graphic,
      pttGeometry,
      mapZoom,
    });
};

export default initPttPolygonTextAPI;
