/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import moment from "moment";

import { Select, Checkbox } from "antd";

import type { ColumnsType } from "antd/es/table";

import SortingTable from "components/SortingTable";

import { StartDate, EndDate } from "components/datepicker";

import { THS_API } from "../../constants";
import { SocketContext } from "../../context/socket";

import "./index.css";

const setCountDate = (date: any) => {
  // if (date.usedTime !== 0) {
  //   return date.usedTime || 0;
  // }
  if (date.exchange_card_type_status === 2) {
    if (date.date_exchange_card_out && date.date_exchange_card) {
      const rentDay = moment(date.date_exchange_card_out).diff(
        moment(date.date_exchange_card),
        "days"
      );
      const currentDay = rentDay + 1;

      return currentDay || 0;
    }
  } else {
    const rentDay = moment(date.updated).diff(moment(date.created), "days");

    const currentDay = rentDay + 1;
    return currentDay || 0;
  }

  const rentDay = moment(new Date()).diff(moment(date.created), "days");
  const currentDay = rentDay + 1;
  return currentDay || 0;
};
const dateSet = (dataDate: any) => {
  const date = new Date(dataDate);

  return date.valueOf() || 0;
};
const installColor = (exchange_card_type_status: any) => {
  if (exchange_card_type_status === 1) return "green";
  if (exchange_card_type_status === 2) return "red";
  if (exchange_card_type_status === -1) return "yellow";
  if (exchange_card_type_status === -2) return "gray";

  return "";
};
const installText = (exchange_card_type_status: any) => {
  if (exchange_card_type_status === 1) return "ยืมอุปกรณ์";
  if (exchange_card_type_status === 2) return "คืนอุปกรณ์";
  if (exchange_card_type_status === -1) return "สูญหาย";
  if (exchange_card_type_status === -2) return "เสียหาย";
  return "";
};
interface DataType {
  Datadate: {
    date_exchange_card: any;
    time_exchange_card: any;
  };
  countDate: {
    exchange_card_type_status: any;
  };
  fullName: {
    first_name: any;
    last_name: any;
  };
  exchange_card_type_status: any;
  device_track_human_no: any;
  position: any;
}
const COLUMNS: ColumnsType<DataType> = [
  {
    title: "วันที่และเวลาที่ยืมล่าสุด",
    sorter: (a: any, b: any) => {
      return dateSet(a.date_exchange_card) - dateSet(b.date_exchange_card);
    },
    render: (row) => {
      return (
        <>
          {moment(row.date_exchange_card).format("DD/MM/YYYY")}{" "}
          {row.time_exchange_card}
        </>
      );
    },
    align: "center",
  },
  {
    title: "วันที่และเวลาที่คืนล่าสุด",
    sorter: (a: any, b: any) => {
      const dataSetA = dateSet(a.date_exchange_card_out);
      const dataSetB = dateSet(b.date_exchange_card_out);
      return dataSetA - dataSetB;
    },
    render: (row) => {
      return (
        <>
          {row.date_exchange_card_out !== "-" &&
            moment(row.date_exchange_card_out).format("DD/MM/YYYY")}{" "}
          {row.time_exchange_card_out}
        </>
      );
    },
    align: "center",
  },

  {
    title: "ถูกใช้งาน(วัน)",
    sorter: (a: any, b: any) => {
      // const { created, usedTime } = countDate;
      // const setCountDate = (date: any) => {
      //   if (date.usedTime !== 0) {
      //     return date.usedTime;
      //   }
      //   const rentDay = moment(new Date()).diff(moment(date.created), "days");
      //   const currentDay = rentDay + 1;
      //   return currentDay;
      // };

      return setCountDate(a) - setCountDate(b);
    },
    render: (countDate) => {
      return (
        <div
          className={setCountDate(countDate) > 1 ? "red" : "black"}
          style={{ textAlign: "center" }}
        >
          {setCountDate(countDate)}
        </div>
      );
    },
    align: "center",
  },
  {
    title: "รหัสอุปกรณ์",

    //  dataIndex: "DeviceTrackHumanNo",
    sorter: (a: any, b: any) => {
      return a.device_track_human_no.localeCompare(b.device_track_human_no);
    },

    render: (countDate) => {
      return (
        <div
          style={{ textAlign: "center" }}
        >{`${countDate.device_track_human_no}`}</div>
      );
    },
    align: "center",
  },
  {
    title: "หมายเลขใบอนุญาตทำงาน",
    // dataIndex: "WorkPermitNo",
    sorter: (a: any, b: any) => {
      return a.wpm_no?.join(", ").localeCompare(b.wpm_no?.join(", "));
    },
    render: (row) => {
      const workPermitNo = row?.wpm_no?.join(", ");

      return <div style={{ textAlign: "center" }}>{workPermitNo}</div>;
    },
    align: "center",
  },
  {
    title: "ชื่อ - นามสกุล",
    // dataIndex: "FirstName",
    sorter: (a: any, b: any) => a.first_name.localeCompare(b.first_name),

    render: (fullName) => {
      return (
        <div
          style={{ textAlign: "center" }}
        >{`${fullName.first_name}  ${fullName.last_name}`}</div>
      );
    },
    align: "center",
  },
  {
    title: "พื้นที่ปฎิบัติงาน",
    //  dataIndex: "Position",
    sorter: (a, b) =>
      a?.position?.toString().localeCompare(b?.position?.toString()),
    render: (countDate) => {
      const { position } = countDate;
      return (
        <div style={{ textAlign: "center" }}>{` ${position?.toString()}`}</div>
      );
    },
    align: "center",
  },
  {
    title: "พื้นที่ปฎิบัติงานย่อย",
    //    dataIndex: "SubPosition",
    sorter: (a: any, b: any) =>
      a?.sub_position?.toString().localeCompare(b?.sub_position?.toString()),
    render: (countDate) => {
      return (
        <div
          style={{
            textAlign: "center",
            wordBreak: "keep-all",
            width: 220,
          }}
        >{` ${countDate?.sub_position?.join(", ")}`}</div>
      );
    },
    align: "center",
  },
  {
    title: "บริษัท",
    //   dataIndex: "WorkPermitID",
    sorter: (a: any, b: any) => {
      // const onlyCompany = (obj: any) =>
      //   obj.id.map((o: any) => o.WorkPermitID?.CompanyName).join();

      return a.company_name.localeCompare(b.company_name);
    },
    render: (row: any) => {
      const { company_name } = row;
      // const companyName = id
      // .map((user: any) => user.WorkPermitID?.CompanyName || "")
      // .join(", ");
      return <div style={{ textAlign: "center" }}>{company_name}</div>;
    },
    align: "center",
  },
  {
    title: "ตำแหน่ง",
    //   dataIndex: "PeopleType",
    sorter: (a: any, b: any) => a.people_type.localeCompare(b.people_type),
    render: (countDate: any) => {
      const { people_type } = countDate;
      return <div style={{ textAlign: "center" }}>{` ${people_type}`}</div>;
    },
    align: "center",
  },
  {
    title: "ผู้ควบคุมงาน",
    //  dataIndex: "EmpFirstName",
    sorter: (a: any, b: any) =>
      a.emp_first_name.localeCompare(b.emp_first_name),
    render: (countDate: any) => {
      const { emp_first_name, emp_last_name } = countDate;
      return (
        <div
          style={{ textAlign: "center" }}
        >{` ${emp_first_name} ${emp_last_name}`}</div>
      );
    },
    align: "center",
  },
  {
    title: "สถานะ",
    // dataIndex: "ExchangeCardTypeStatus",
    sorter: (a: any, b: any) => {
      return installText(a.exchange_card_type_status).localeCompare(
        installText(b.exchange_card_type_status)
      );
    },

    render: (countDate) => {
      const { exchange_card_type_status } = countDate;

      return (
        <div
          className={installColor(exchange_card_type_status)}
          style={{ textAlign: "center" }}
        >
          {installText(exchange_card_type_status)}
        </div>
      );
    },
    align: "center",
  },
];
export const WorkPermit: React.FC = () => {
  const [dataSource, setDataSource] = React.useState([]);
  const [filter, setFilter] = React.useState<any>([]);
  const [filterSubLocation, setFilterSubLocation] = React.useState<any>([]);
  const [location, setLocation] = React.useState<any>("");
  const [showedSelectBoxLocation, setShowedSelectBoxLocation] =
    React.useState<any>("");
  const [apiStatus, setApiStatus] = React.useState("success");
  const [searchPosition, setSearchPosition] = React.useState("");
  const [showedSelectBoxPosition, setShowedSelectBoxPosition] =
    React.useState("");
  const [searchEmp, setSearchEmp] = React.useState("");
  const [searchWPM, setSearchWPM] = React.useState("");
  const [showedSelectBoxWPM, setShowedSelectBoxWPM] = React.useState("");
  const [searchCompany, setSearchCompany] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [showCompany, setShowCompany] = React.useState("");
  const [subLocation, setSubLocation] = React.useState<any>("");
  const [showedSelectBoxSubLocation, setShowedSelectBoxSubLocation] =
    React.useState<any>("");
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());

  const [isDateFilter, setIsDateFilter] = React.useState(false);

  const genRandomKey = async () => {
    const res = await THS_API.get("/work-permit", {
      params: {
        limit: 5000,
      },
    });
    if (res.status === 200) {
      setDataSource(res.data.data);
    }
  };

  const socket = React.useContext(SocketContext);
  React.useEffect(() => {
    // as soon as the component is mounted, do the following tasks:
    socket.on("access_control", () => {
      if (
        searchWPM === "" &&
        location === "" &&
        subLocation === "" &&
        searchCompany === "" &&
        searchPosition === "" &&
        searchEmp === "" &&
        fullName === "" &&
        !isDateFilter &&
        startDate === new Date() &&
        endDate === new Date()
      )
        genRandomKey();

      // const index = _.findIndex(
      //   pttEmployee,
      //   (o: any) => o.dev_eui === device.data.dev_eui
      // );

      // if (index > -1) {
      //   const newPttEmployee = [...pttEmployee];
      //   newPttEmployee.splice(index, 1, device.data);

      //   dispatch(setEmployee(newPttEmployee));
      //   setCurrentUsedPttEmployee(newPttEmployee);
      // }
    });

    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      socket.off("device_location");
    };
  }, [socket]);
  React.useEffect(() => {
    const callFunc = async () => {
      setApiStatus("loading");
      await genRandomKey();
      setApiStatus("success");
      dataFilter();
    };
    callFunc();
  }, []);
  const dataFilter = async () => {
    const res = await THS_API.get("/work-permit/filter");

    setFilter(res.data.data);
    setFilterSubLocation(res.data.data.sub_location);
  };

  const searchWorkpermit = async () => {
    setApiStatus("loading");

    const params: any = {
      wpm_id: searchWPM,
      company_code: searchCompany,
      people_type_id: searchPosition,
      emp_full_name: searchEmp,
      location_id: location,
      sub_location_id: subLocation,
      full_name: fullName,
    };

    if (isDateFilter) {
      params.start_date = moment(startDate).format("YYYY-MM-DD");
      params.end_date = moment(endDate).format("YYYY-MM-DD");
    }

    const res = await THS_API.get("/work-permit", { params });
    if (res.status === 200) {
      setDataSource(res.data.data);
      setApiStatus("success");
    }
  };
  const setLocationFilter = async (location: string) => {
    setLocation(location);
    const params = {
      unitid: location,
    };
    const res = await THS_API.get("/work-permit/filter", { params });

    setFilterSubLocation(res.data.data.sub_location);
  };

  const reset = async () => {
    setLocation("");
    setShowedSelectBoxLocation("");
    setSearchPosition("");
    setShowedSelectBoxPosition("");
    setSearchEmp("");
    setSearchCompany("");
    setShowCompany("");
    setSubLocation("");
    setShowedSelectBoxSubLocation("");
    setSearchWPM("");
    setShowedSelectBoxWPM("");
    setStartDate(new Date());
    setEndDate(new Date());
    setFullName("");
    setIsDateFilter(false);
    setApiStatus("loading");
    await genRandomKey();
    setApiStatus("success");
    dataFilter();
  };

  const selectedWorkpermitSelectBoxFunc = ({
    filterArr,
    keyForSearch,
    wantedValue,
    selectedTxt,
    setSelectTxtFunc,
    setSelectValFunc,
  }: any) => {
    const selectedWpmArr = filterArr?.filter(
      (wpm: any) => wpm[keyForSearch] === selectedTxt
    );

    let value;
    if (selectedWpmArr.length) value = selectedWpmArr[0][wantedValue];
    else value = "";
    setSelectTxtFunc(selectedTxt);
    setSelectValFunc(value);
  };
  const changeWorkpermit = (selectedTxt: string) => {
    selectedWorkpermitSelectBoxFunc({
      filterArr: filter?.wpm,
      keyForSearch: "workpermit_work_permit_no",
      wantedValue: "workpermit_work_permit_id",
      selectedTxt,

      setSelectTxtFunc: setShowedSelectBoxWPM,
      setSelectValFunc: setSearchWPM,
    });
  };
  const changeLocation = (value: string) => {
    const textArr = value.split(" ");
    const locationValueRaw = textArr[textArr.length - 1];
    textArr.pop();
    const text = textArr.join(" ");
    const locationValue = locationValueRaw.slice(
      1,
      locationValueRaw.length - 1
    );
    setLocationFilter(locationValue);
    setShowedSelectBoxLocation(text);
    setShowedSelectBoxSubLocation("");
    setSubLocation("");
  };

  const changeSubLocation = (rawValue: string) => {
    if (filterSubLocation.length) {
      const lastฺBracket = rawValue.lastIndexOf("(");
      const selectedTxt = rawValue.slice(0, lastฺBracket - 1);

      selectedWorkpermitSelectBoxFunc({
        filterArr: filterSubLocation,
        keyForSearch: "sub_location",
        wantedValue: "sub_location_id",
        selectedTxt,

        setSelectTxtFunc: setShowedSelectBoxSubLocation,
        setSelectValFunc: setSubLocation,
      });
    }
  };

  const changeCompany = (value: string) => {
    const textArr = value.split(" ");
    const locationValueRaw = textArr[textArr.length - 1];
    textArr.pop();
    const text = textArr.join(" ");
    const locationValue = locationValueRaw.slice(
      1,
      locationValueRaw.length - 1
    );
    setSearchCompany(locationValue);
    setShowCompany(text);
  };

  const changePosition = (selectedTxt: any) => {
    selectedWorkpermitSelectBoxFunc({
      filterArr: filter?.position,
      keyForSearch: "people_type",
      wantedValue: "people_type_id",
      selectedTxt,
      setSelectTxtFunc: setShowedSelectBoxPosition,
      setSelectValFunc: setSearchPosition,
    });
  };

  return (
    <section className="workpermit">
      <div>
        <div className="header-topic">
          <h1>คนและอุปกรณ์</h1>
        </div>
        <div className="card-body search-bar">
          <form>
            <div className="row">
              {" "}
              <div
                className="form-group col-sm-4"
                style={{ marginTop: "20px" }}
              >
                <Select
                  className="form-control"
                  id="#"
                  showSearch
                  placeholder="ชื่อใบอนุญาติทำงาน"
                  value={showedSelectBoxWPM}
                  onChange={changeWorkpermit}
                >
                  <Select.Option value="">
                    กรุณาเลือกหมายเลขใบอนุญาตทำงาน
                  </Select.Option>

                  {filter?.wpm?.map((wpm: any, key: number) => {
                    const { workpermit_work_permit_no } = wpm;

                    return (
                      <Select.Option
                        key={key}
                        value={workpermit_work_permit_no}
                      >
                        {workpermit_work_permit_no}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <div
                className="form-group col-sm-4"
                style={{ marginTop: "20px" }}
              >
                <Select
                  className="form-control"
                  value={showedSelectBoxLocation}
                  showSearch
                  placeholder="พื้นที่ปฎิบัติงาน"
                  onChange={changeLocation}
                  id="#"
                >
                  <Select.Option value="">
                    กรุณาเลือกพื้นที่ปฏิบัติงาน
                  </Select.Option>
                  {filter?.location?.map((dropLocation: any, key: number) => {
                    const { location_id, location } = dropLocation;

                    return (
                      <Select.Option
                        key={key}
                        value={`${location} (${location_id})`}
                      >
                        {`${location} (${location_id})`}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <div
                className="form-group col-sm-4"
                style={{ marginTop: "20px" }}
              >
                <Select
                  className="form-control"
                  id="#"
                  showSearch
                  value={showedSelectBoxSubLocation}
                  placeholder="พื้นที่ปฎิบัติงานรายละเอียด"
                  onChange={changeSubLocation}
                >
                  <Select.Option value="">
                    กรุณาเลือกพื้นที่ปฏิบัติงานรายละเอียด
                  </Select.Option>
                  {filterSubLocation?.map(
                    (dropSubLocation: any, key: number) => {
                      const { sub_location, location } = dropSubLocation;
                      return (
                        <Select.Option
                          key={key}
                          value={`${sub_location} (${location})`}
                        >
                          {`${sub_location} (${location})`}
                        </Select.Option>
                      );
                    }
                  )}
                </Select>
              </div>
              <div
                className="form-group col-sm-4"
                style={{ marginTop: "20px" }}
              >
                <Select
                  showSearch
                  placeholder="ชื่อบริษัท"
                  className="form-control"
                  value={showCompany}
                  onChange={changeCompany}
                  id="#"
                >
                  <Select.Option value="">กรุณาเลือกชื่อบริษัท</Select.Option>
                  {filter?.company?.map((obj: any, key: number) => {
                    const { company_code, company_name } = obj;
                    return (
                      <Select.Option
                        key={key}
                        value={`${company_name} (${company_code})`}
                      >
                        {`${company_name} (${company_code})`}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <div
                className="form-group col-sm-4"
                style={{ marginTop: "20px" }}
              >
                <Select
                  showSearch
                  placeholder="ประเภทบุคคล"
                  className="form-control"
                  id="#"
                  value={showedSelectBoxPosition}
                  onChange={changePosition}
                >
                  <Select.Option value="">กรุณาเลือกตำแหน่ง</Select.Option>
                  {filter?.position?.map((dropPosition: any, key: number) => {
                    const { people_type } = dropPosition;
                    if (people_type !== "")
                      return (
                        <Select.Option key={key} value={people_type}>
                          {people_type}
                        </Select.Option>
                      );
                    return <></>;
                  })}
                </Select>
              </div>
              <div
                className="form-group col-sm-4"
                style={{ marginTop: "20px" }}
              >
                <Select
                  className="form-control"
                  id="#"
                  showSearch
                  placeholder="ชื่อผู้ควบคุมงาน"
                  value={searchEmp}
                  onChange={setSearchEmp}
                >
                  <Select.Option value="">
                    กรุณาเลือกชื่อผู้ควบคุมงาน
                  </Select.Option>
                  {filter?.emp?.map((empObj: any, key: number) => {
                    const { emp_first_name, emp_last_name } = empObj;
                    const fullName = `${emp_first_name} ${emp_last_name}`;
                    return (
                      <Select.Option key={key} value={fullName}>
                        {`${emp_first_name}  ${emp_last_name}`}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <div
                className="form-group col-sm-4"
                style={{ marginTop: "20px" }}
              >
                <Select
                  className="form-control"
                  id="#"
                  showSearch
                  placeholder="ชื่อผู้ควบคุมงาน"
                  value={fullName}
                  onChange={setFullName}
                >
                  <Select.Option value="">
                    กรุณาเลือกชื่อ-นามสกุล(ผู้ถืออุปกรณ์)
                  </Select.Option>
                  {filter?.name?.map((nameArr: any, key: number) => {
                    const { first_name, last_name } = nameArr;
                    const fullName = `${first_name} ${last_name}`;
                    if (first_name && last_name)
                      return (
                        <Select.Option key={key} value={fullName}>
                          {fullName}
                        </Select.Option>
                      );
                    return <></>;
                  })}
                </Select>
              </div>
              <div
                className="form-group col-sm-8"
                style={{ marginTop: "20px" }}
              />
              <div className="form-group col-sm-1 mt-2">
                <Checkbox
                  checked={isDateFilter}
                  onChange={() => setIsDateFilter(!isDateFilter)}
                />
              </div>
              <div className="form-group col-sm-1">
                <div className="mt-3 mb-3">
                  <label className="form-check-label" htmlFor="startDate">
                    เริ่มต้น
                  </label>
                </div>
              </div>
              <div className="form-group col-sm-2">
                <div className="mt-3 mb-3">
                  <StartDate
                    id="startDate"
                    disabled={!isDateFilter}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(date: any) => {
                      const start = moment(date);
                      const end = moment(endDate);
                      setStartDate(date);
                      if (start.diff(end) > 0) setEndDate(date);
                    }}
                  />
                </div>
              </div>
              <div className="form-group col-sm-1">
                <div className="mt-3 mb-3">
                  <label className="form-check-label" htmlFor="endDate">
                    สิ้นสุด
                  </label>
                </div>
              </div>
              <div className="form-group col-sm-2">
                <div className="mt-3 mb-3">
                  <EndDate
                    id="endDate"
                    disabled={!isDateFilter}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(date: any) => setEndDate(date)}
                  />
                </div>
              </div>
              <div className="form-group col-sm-1" />
              <div
                className="form-group col-sm-4 row"
                style={{
                  justifyContent: "flex-end",
                }}
              >
                <div className=" col-sm-4">
                  <div
                    className="btn btn-save"
                    style={{
                      width: "100%",
                      marginRight: "-20px",
                      marginTop: "20px",
                    }}
                    onClick={searchWorkpermit}
                  >
                    ค้นหา
                  </div>
                </div>
                <div className=" col-sm-4">
                  <div
                    className="btn btn-save"
                    style={{
                      width: "100%",
                      marginRight: "-20px",
                      marginTop: "20px",
                    }}
                    onClick={reset}
                  >
                    ล้าง
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <SortingTable
          columns={COLUMNS}
          apiStatus={apiStatus}
          query={dataSource}
        />
      </div>
    </section>
  );
};
