import React from "react";
import "./index.css";

export const Footer: React.FC = () => {
  return (
    <footer id="footer" style={{ zIndex: 20 }}>
      <div className="company">
        <p>PTT PUBLIC COMPANY LIMITED</p>
        <p>55 Vibhavadi Rangsit Road, Chatuchak Bangkok 10900 Thailand</p>
      </div>
      <div className="contact">
        <p>CONTACT CENTER</p>
        <p>SHIPCare@pttdigital.com</p>
      </div>
    </footer>
  );
};
