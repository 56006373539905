export const filterBoxDefaultValue = {
  dateType: "time",
  locationSelectVal: "",
  subLocationSelectVal: "",
  chosenLocationBoxName: "",
  chosenSubLocationBoxName: "",
  checkWorkpermitdata: false,
  startDate: new Date(),
  startTime: new Date(),
  endTime: new Date(),
  deviceSearchType: "deviceNo",
  cid: "",
  workCid: "",
  deviceEUI: "",
  firstName: "",
  lastName: "",
  hasPttGeometry: false,
  hasPttGeometryPast: false,
};

export const buttonGroupDefaultValue = {
  isSubmit: false,
  chosenMap: "time",
  selectedWorkpermit: {
    workpermit: "",
    supervisor: "",
    location: { value: "", text: "" },
    subLocation: { value: "", text: "" },
    company: "",
  },
  isModalVisible: false,
  modalBaseMap: false,
  viewMap: "satellite",
  isFilterBoxBoundaryActive: false,
  sideBarFilter: false,
  empModalCurrentTab: "dev_eui",
  restrictArea: "",
  safetyArea: "",
  typeSearchBounary: "restricted",
  center: [],
  filterboxPriority: false,
  filterBoxBoundaryPriority: false,
};
