import React from "react";
import { useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { roleAPI } from "reducers/rolesReducer";
import { selectUser } from "reducers/userReducer";
import { THS_API } from "../../../constants";
import "./index.css";
export const RoleEdit: React.FC = () => {
  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");

  //view
  const [dashboard, setDashboard] = React.useState<any>(false);
  const [monitoring, setMonitoring] = React.useState(false);
  const [boundary, setBoundary] = React.useState(false);
  const [report, setReport] = React.useState(false);
  const [workPermit, setWorkPermit] = React.useState(false);
  const [role, setRole] = React.useState(false);
  const [log, setlog] = React.useState(false);
  const [notification, setNotification] = React.useState(false);

  const [status, setStatus] = React.useState<string>("active");

  let { id } = useParams();
  const dispatch: any = useDispatch();

  const userData = useSelector(selectUser);
  React.useEffect(() => {
    if (id) {
      const getRolebyid = async () => {
        try {
          const res = await THS_API.get(`/roles/${id}`);

          const { data: payload, statusCode } = res.data;

          const {
            name,
            description,
            viewDashboard,
            viewMapMonitoring,
            viewMapBoundary,
            viewReport,
            viewWorkPermit,
            viewRoles,
            viewEventLog,
            viewDeviceManager,
            type,
          } = payload;
          if (statusCode === 200) {
            setName(name);
            setDescription(description);
            setDashboard(viewDashboard);
            setMonitoring(viewMapMonitoring);
            setBoundary(viewMapBoundary);
            setReport(viewReport);
            setWorkPermit(viewWorkPermit);
            setRole(viewRoles);
            setlog(viewEventLog);
            setNotification(viewDeviceManager);
            setStatus(type);
          }
        } catch (error) {
          alert(error);
        }
      };

      getRolebyid();
    }
    // run it, run it

    return () => {
      // this now gets called when the component unmounts
    };
  }, [id]);
  const handleDescription = (event: any) => {
    const value = event.target.value;
    setDescription(value);
  };
  //view
  const handleDashboard = (event: any) => {
    const value = event.target.checked;
    setDashboard(value);
  };
  const handleMonitoring = (event: any) => {
    const value = event.target.checked;
    setMonitoring(value);
  };
  const handleBoundary = (event: any) => {
    const value = event.target.checked;
    setBoundary(value);
  };
  const handleReport = (event: any) => {
    const value = event.target.checked;
    setReport(value);
  };
  const handleWorkPermit = (event: any) => {
    const value = event.target.checked;
    setWorkPermit(value);
  };
  const handleRole = (event: any) => {
    const value = event.target.checked;
    setRole(value);
  };
  const handlelog = (event: any) => {
    const value = event.target.checked;
    setlog(value);
  };
  const handleNotification = (event: any) => {
    const value = event.target.checked;
    setNotification(value);
  };
  //edit
  // const handleEditDashboard = (event: any) => {
  //   const value = event.target.checked;
  //   setEditDashboard(value);
  // };
  // const handleEditMonitoring = (event: any) => {
  //   const value = event.target.checked;
  //   setEditMonitoring(value);
  // };
  // const handleEditBoundary = (event: any) => {
  //   const value = event.target.checked;
  //   setEditBoundary(value);
  // };
  // const handleEditReport = (event: any) => {
  //   const value = event.target.checked;
  //   setEditReport(value);
  // };
  // const handleEditWorkPermit = (event: any) => {
  //   const value = event.target.checked;
  //   setEditWorkPermit(value);
  // };
  // const handleEditRole = (event: any) => {
  //   const value = event.target.checked;
  //   setEditRole1(value);
  // };
  // const handleEditlog = (event: any) => {
  //   const value = event.target.checked;
  //   setEditLog1(value);
  // };
  // const handleEditNotification = (event: any) => {
  //   const value = event.target.checked;
  //   setEditDeviceManager(value);
  // };

  const handleStatusAction = (event: any) => {
    if (event.target.checked) {
      setStatus("active");
    }
  };
  const handleStatusInaction = (event: any) => {
    if (event.target.checked) {
      setStatus("inactive");
    }
  };

  const roleUpdate = async () => {
    if (name === "") {
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "กรุณากรอก Role Name",
      });
      return;
    }
    const checkboxValidate = {
      dashboard,
      monitoring,
      boundary,
      report,
      workPermit,
      role,
      log,
      notification,
    };
    const isSubmit = Object.values(checkboxValidate).some(
      (allow: any) => allow
    );

    if (isSubmit) {
      try {
        const url = `/roles/${id}`;
        const res = await THS_API.put(url, {
          description,
          viewUsers: true,
          createUsers: true,
          updateUsers: true,
          deleteUsers: true,
          viewDashboard: dashboard,
          createDashboard: dashboard,
          updateDashboard: dashboard,
          deleteDashboard: dashboard,
          viewMapMonitoring: monitoring,
          createMapMonitoring: monitoring,
          updateMapMonitoring: monitoring,
          deleteMapMonitoring: monitoring,
          viewMapBoundary: boundary,
          createMapBoundary: boundary,
          updateMapBoundary: boundary,
          deleteMapBoundary: boundary,
          viewDeviceManager: notification,
          createDeviceManager: notification,
          updateDeviceManager: notification,
          deleteDeviceManager: notification,
          viewReport: report,
          createReport: report,
          updateReport: report,
          deleteReport: report,
          viewRoles: role,
          createRoles: role,
          updateRoles: role,
          deleteRoles: role,
          viewEventLog: log,
          createEventLog: log,
          updateEventLog: log,
          deleteEventLog: log,
          viewWorkPermit: workPermit,
          createWorkPermit: workPermit,
          updateWorkPermit: workPermit,
          deleteWorkPermit: workPermit,
          type: status,
        });

        if (res.data.statusCode === 201) {
          await dispatch(roleAPI(userData?.role));
          Swal.fire({
            icon: "success",
            title: "แก้ไขสำเร็จ!",
            showConfirmButton: false,
            timer: 1500,
          });

          setTimeout(() => {
            window.location.href = "/role";
          }, 1000);
        }
      } catch (error) {}
    } else {
      Swal.fire({
        icon: "info",
        text: "Please check role!",
      });
    }
  };
  return (
    <div>
      <section className="update-Rrole">
        <div>
          <div className="header-topic">
            <h1>แก้ไข สิทธิ์การใช้งาน</h1>
          </div>
          <div className="card-body">
            <form>
              <div className="form-detail content-form">
                <div className="form-group row ">
                  <label htmlFor="brand" className="col-sm-2 col-form-label">
                    ชื่อสิทธิ์การใช้งาน{" "}
                    <span style={{ color: "blue" }}>(Role Key)</span>{" "}
                  </label>
                  <div className="col-sm-9">
                    <p>{name}</p>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="brand" className="col-sm-2 col-form-label">
                    ชื่อสิทธิที่ใช้แสดง{" "}
                    <span style={{ color: "blue" }}>(Role Name)</span>{" "}
                  </label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      className="form-control"
                      id="role-description"
                      value={description}
                      placeholder="ชื่อสิทธิที่ใช้แสดง"
                      onChange={handleDescription}
                    />
                  </div>
                </div>
                <div className="form-group row permission-row">
                  <label htmlFor="brand" className="col-sm-3 col-form-label">
                    การอนุญาต{" "}
                  </label>
                  <div className="col-sm-9">
                    <div className="grid-story2 permission-list">
                      <div>
                        <div className="form-check ">
                          <Checkbox
                            checked={dashboard}
                            onChange={handleDashboard}
                          >
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Dashboard
                            </label>
                          </Checkbox>
                        </div>
                        <div className="form-check">
                          <Checkbox
                            checked={monitoring}
                            onChange={handleMonitoring}
                          >
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              People Monitoring
                            </label>
                          </Checkbox>
                        </div>
                        <div className="form-check">
                          <Checkbox
                            checked={boundary}
                            onChange={handleBoundary}
                          >
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              สร้างขอบเขตการทำงาน
                            </label>
                          </Checkbox>
                        </div>
                        <div className="form-check">
                          <Checkbox
                            checked={notification}
                            onChange={handleNotification}
                          >
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              รายการอุปกรณ์
                            </label>
                          </Checkbox>
                        </div>

                        <div className="form-check">
                          <Checkbox checked={role} onChange={handleRole}>
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              สิทธิ์การเข้าใช้งาน
                            </label>
                          </Checkbox>
                        </div>
                        <div className="form-check">
                          <Checkbox checked={log} onChange={handlelog}>
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              บันทึกการใช้งานระบบ
                            </label>
                          </Checkbox>
                        </div>
                        <div className="form-check">
                          <Checkbox
                            checked={workPermit}
                            onChange={handleWorkPermit}
                          >
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              คนและอุปกรณ์
                            </label>
                          </Checkbox>
                        </div>
                        <div className="form-check">
                          <Checkbox checked={report} onChange={handleReport}>
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              รายงาน
                            </label>
                          </Checkbox>
                        </div>
                      </div>
                      {/* <div>
                        <p>Edit</p>
                        <div className="form-check">
                          <Checkbox
                            checked={editDashboard}
                            onChange={handleEditDashboard}
                          >
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            >
                              Dashboard
                            </label>
                          </Checkbox>
                        </div>
                        <div className="form-check">
                          <Checkbox
                            checked={editMonitoring}
                            onChange={handleEditMonitoring}
                          >
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Map Monitoring
                            </label>
                          </Checkbox>
                        </div>
                        <div className="form-check">
                          <Checkbox
                            checked={editBoundary}
                            onChange={handleEditBoundary}
                          >
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Map Boundary
                            </label>
                          </Checkbox>
                        </div>
                        <div className="form-check">
                          <Checkbox
                            checked={editDeviceManager}
                            onChange={handleEditNotification}
                          >
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Device Manager
                            </label>
                          </Checkbox>
                        </div>
                        <div className="form-check">
                          <Checkbox
                            checked={editReport}
                            onChange={handleEditReport}
                          >
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Report
                            </label>
                          </Checkbox>
                        </div>
                        <div className="form-check">
                          <Checkbox
                            checked={editRole1}
                            onChange={handleEditRole}
                          >
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Role
                            </label>
                          </Checkbox>
                        </div>
                        <div className="form-check">
                          <Checkbox checked={editLog1} onChange={handleEditlog}>
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Event log
                            </label>
                          </Checkbox>
                        </div>
                        <div className="form-check">
                          <Checkbox
                            checked={editWorkPermit}
                            onChange={handleEditWorkPermit}
                          >
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckChecked"
                            >
                              Work Permit
                            </label>
                          </Checkbox>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="form-group row status-row">
                  <label
                    htmlFor="SubLocation"
                    className="col-sm-3 col-form-label"
                  >
                    สถานะ
                  </label>
                  <div className="col-sm-9">
                    <div className="check">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked={status === "active"}
                          onChange={handleStatusAction}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          กำลังใช้งาน
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          onChange={handleStatusInaction}
                          checked={status !== "active"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          ไม่ได้ใช้งาน
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid-story2 btn-form">
                <Link to="/role" style={{ color: "white" }}>
                  <div className="btn btn-back" style={{ color: "white" }}>
                    กลับ
                  </div>
                </Link>
                <div
                  className="btn btn-save"
                  data-bs-toggle="modal"
                  data-bs-target="#saveDone"
                  onClick={roleUpdate}
                >
                  บันทึก
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};
