import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts-drilldown";

export default function ChartArea({
  dataZone,
  dataColor,
  dataPlantName,
  allowChartUpdate,
}) {
  drilldown(Highcharts);

  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      height: 330,
      type: "column",
    },
    title: {
      text: "จำนวนบุคคลแยกในแต่ละพื้นที่กระบวนการผลิต",
      style: { fontSize: 16 },
    },
    accessibility: {
      announceNewData: {
        enabled: true,
      },
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      type: "category",
      scrollbar: {
        enabled: true,
      },
    },
    yAxis: {
      title: {
        text: "จำนวนบุคคลที่อยุ๋ในพื้นที่",
        style: { fontSize: 14 },
      },
      gridLineWidth: 0,
    },
    plotOptions: {
      series: {
        colors: [dataColor],
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: "{point.y} คน",
        },
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: "<span>{point.name}</span>: จำนวน <b>{point.y}</b>คน<br/>",
    },
    series: [
      {
        name: dataPlantName,
        colorByPoint: true,
        data: dataZone?.chart?.series[0].data,
      },
    ],
    drilldown: {
      series: dataZone?.chart?.drilldown?.series,
    },
  };
  return (
    <HighchartsReact
      allowChartUpdate={allowChartUpdate}
      highcharts={Highcharts}
      options={options}
    />
  );
}
