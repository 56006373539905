import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";

export default function CharActiveUser({ charLocation, allowChartUpdate }) {
  const personInProductionArea =
    charLocation?.reduce((total, obj) => total + obj.y, 0) || 0;

  Highcharts.setOptions({
    lang: {
      thousandsSep: ",",
    },
  });
  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      height: 200,
      width: 200,
      spacingTop: -60,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: `บุคคลในพื้นที่<br>${personInProductionArea} `,
      align: "center",
      verticalAlign: "middle",
      y: 75,
      style: {
        fontSize: 16,
      },
    },

    tooltip: {
      pointFormat: "",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    // legend: {
    //   verticalAlign: "top",
    // },
    plotOptions: {
      pie: {
        thickness: 20,
        allowPointSelect: true,
        colors: ["#00ADEE"],
        cursor: "pointer",
        dataLabels: {
          enabled: false,
          formatter: function () {
            return this.point.y === 0 ? "" : this.point.y;
          },

          distance: -20,
        },
        center: ["50%", "75%"],
        size: "100%",
        //showInLegend: false,
      },
    },
    series: [
      {
        name: "จำนวน",
        innerSize: "50%",
        colorByPoint: true,
        data: [
          {
            name: `จำนวนบุคคลในพื้นที่ ${personInProductionArea || 0}`,
            y: personInProductionArea || 0,
          },
        ],
        showInLegend: false,
      },
    ],
  };

  return (
    <div
      className="doughnut-graph"
      style={{
        margin: "0 auto",
        overflow: "hidden",
        borderRadius: 8,
      }}
    >
      <PieChart
        allowChartUpdate={allowChartUpdate}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
}
