import React from "react";

import { useSelector } from "react-redux";
import { selectMap } from "reducers/mapBoundaryReducers";

import { setDefaultOptions } from "esri-loader";

import { useInitMap, useLayerMap } from "./_hooks";

import "./index.css";

setDefaultOptions({ css: true });

const EsriMap = ({
  setView,
  view,
  viewMap,
  setDataGeometry,
  setMeasurement,
  setListShipPTT,
  setMapLayer,
  mapLayer,
}: any) => {
  // const [mapLayer, setMapLayer] = React.useState<any>(null);
  const mapDiv = React.useRef(null);

  const polygonArr: any = useSelector(selectMap);

  useInitMap({
    mapDiv,
    setView,
    viewMap,
    setMapLayer,
    setDataGeometry,
    setMeasurement,
    setListShipPTT,
  });

  useLayerMap({
    view,
    polygonArr,
    mapLayer,
  });

  return (
    <div className="map-boundary-map" ref={mapDiv}>
      <title>Mark something</title>
    </div>
  );
};

export default EsriMap;
