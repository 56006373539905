import Swal from "sweetalert2";
import classnames from "classnames";

import { Table } from "antd";
import { useSelector } from "react-redux";

import SortingTable from "components/SortingTable";

import { selectUser } from "reducers/userReducer";
import { THS_API } from "../../../constants";

const statusColor = (status: any) => {
  if (status === "Online") return "yellow status ";
  if (status === "Offline") return "gray status ";
  if (status === "Occupied") return "green status ";
  if (status === "Broken") return "red status ";
  if (status === "Lost") return "orange status";
  return "";
};

const statusText = (battery_level: any) => {
  if (battery_level === "Occupied") return "กำลังใช้งาน";
  if (battery_level === "Online") return "พร้อมใช้งาน";
  if (battery_level === "Offline") return "ไม่พร้อมใช้งาน";
  if (battery_level === "Broken") return "อุปกรณ์เสีย";
  if (battery_level === "Lost") return "อุปกรณ์สูญหาย";
  return "status";
};

const batteryStatus = (battery: any, status: any) => {
  if (status !== "Offline") {
    if (battery >= 1 && battery <= 30) return "red status ";
    if (battery >= 31 && battery <= 60) return "yellow status ";
    if (battery >= 61 && battery <= 100) return "green status ";
  }
  return "";
};
const batteryLevel = (battery: any, status: any) => {
  if (status !== "Offline") {
    if (battery >= 1 && battery <= 30) return `ระดับต่ำ (${battery})`;
    if (battery >= 31 && battery <= 60) return `ระดับกลาง (${battery})`;
    if (battery >= 61 && battery <= 100) return `ระดับสูง (${battery})`;
  }
  return "ไม่มีสัญญาณ";
};
const removeDevice = async (data: any, setQuery: any) => {
  Swal.fire({
    text: `คุณต้องการแลกอุปกรณ์ หมายเลข ${data?.dev_eui} ออกใช่หรือไม่`,
    imageUrl: "/assets/img/icon/warning.svg",
    showCancelButton: true,

    confirmButtonColor: "#d33",
    cancelButtonColor: "#ABB2B9",
    confirmButtonText: "ใช่",
    cancelButtonText: "ไม่ใช่",
  }).then(async (result: any) => {
    if (result.isConfirmed) {
      try {
        const res = await THS_API.post(
          "/work-permit/exchangeTypeCardOutDevice",
          {
            cid: data?.occupied_id,
            device_track_human_no: data?.dev_eui,
          }
        );
        if (res.data.statusCode === 200) {
          const res = await THS_API.get("/devices");
          setQuery(res?.data?.data);

          Swal.fire({
            iconColor: "#f7bd21",
            icon: "success",
            color: "#f7bd21",
            title: "แลกออกอุปกรณ์สำเร็จ",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "info",
          title: "เกิดข้อผิดพลาด " + error,
        });
      }
    }
  });
};

const DeviceListTable = ({
  apiStatus,
  query,
  setQuery,
  currentColumn,
  setCurrentColumn,
}: any) => {
  const { role } = useSelector(selectUser);
  const deviceColumns = [
    {
      title: "ลำดับ",
      sorter: (recordA: any, recordB: any) => {
        return parseInt(recordA.id) - parseInt(recordB.id);
      },
      render: (record: any) => <>{record.id}</>,
    },
    {
      title: "หมายเลขอุปกรณ์",
      sorter: (recordA: any, recordB: any) => {
        return recordA.dev_eui.localeCompare(recordB.dev_eui);
      },
      render: (record: any) => <>{record.dev_eui}</>,
    },
    {
      title: "ชื่ออุปกรณ์",
      sorter: (recordA: any, recordB: any) => {
        return recordA.dev_name.localeCompare(recordB.dev_name);
      },
      render: (record: any) => {
        return <>{record.dev_name}</>;
      },
    },
    {
      title: "สถานะ",
      sorter: (recordA: any, recordB: any) => {
        return recordA.status.localeCompare(recordB.status);
      },
      render: (record: any) => {
        return (
          <p className={statusColor(record.status)}>
            {statusText(record.status)}
          </p>
        );
      },
    },
    {
      title: "ระดับแบตเตอรี่",
      sorter: (recordA: any, recordB: any) => {
        return recordA.battery_level - recordB.battery_level;
      },
      render: (record: any) => {
        return (
          <p
            className={batteryStatus(record.battery_level, record.status)}
            style={{ textAlign: "center" }}
          >
            {batteryLevel(record.battery_level, record.status)}
          </p>
        );
      },
    },
    {
      render: (a: any) => {
        if (role === "admin")
          return (
            <div className="command">
              <div
                className="btn btn-warning"
                onClick={() => {
                  removeDevice(a, setQuery);
                }}
              >
                แลกออก
              </div>
            </div>
          );
        return null;
      },
    },
    Table.EXPAND_COLUMN,
  ];

  return (
    <SortingTable
      apiStatus={apiStatus}
      query={query}
      columns={deviceColumns}
      expandRowByClick={true}
      expandedRowKeys={currentColumn}
      onExpand={(expanded: boolean, record: any) => {
        if (expanded) setCurrentColumn([record.id]);
        else setCurrentColumn([]);
      }}
      onChange={() => setCurrentColumn([])}
      rowKey={({ id }: any) => id}
      expandable={{
        expandedRowRender: (record: any) => (
          <div
            className={classnames("accordion-collapse collapse show")}
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <div className="row">
                <div className="col-sm-2">
                  <h1>ใบอนุญาตทำงาน</h1>
                  <p>{record.work_permit_no}</p>
                </div>
                <div className="col-sm-2">
                  <h1>ชื่อ-นามสกุล</h1>
                  <p>
                    {record.firstname} {record.lastname}
                  </p>
                </div>
                <div className="col-sm-2">
                  <div>
                    <h1>เลขบัตรประชาชน</h1>
                    <p>{record.occupied_id}</p>
                  </div>
                </div>
                <div className="col-sm-2">
                  <h1>ตำแหน่ง</h1>
                  <p>{record.job}</p>
                </div>
                <div className="col-sm-2">
                  <h1>บริษัท</h1>
                  <p>{record.company_name}</p>
                </div>

                <div className="col-sm-2">
                  <h1>ชื่อ-นามสกุล ผู้ควบคุมงาน</h1>
                  <p>
                    {record.supervisor_f_name} {record.supervisor_l_name}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ),
      }}
    />
  );
};

export default DeviceListTable;
