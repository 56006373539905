import { loadModules } from "esri-loader";

const createSymbol = ({ color, style, width, outlineColor }: any) => {
  return {
    type: "simple-fill",
    style,
    color,
    outline: {
      color: outlineColor,
      width,
    },
  };
};

const createGeometry = ({ vertices }: any) => {
  return {
    type: "polygon",
    rings: vertices,
  };
};
const orderedPttGeometry = (pttGeometry: any) => {
  return pttGeometry
    .filter((polygon: any) => polygon.display)
    .map((polygon: any) => {
      const arrOrder: any = {
        AREA: 1,
        BUILDING: 2,
        PLANT: 0,
      };
      return { ...polygon, order: arrOrder[polygon.layer_name] };
    })

    .sort((a: any, b: any) => a.order - b.order);
};
const initSearchGeometry = async ({
  pttSearchGeometryLayer = null,
  pttGeometry,
  mapZoom,
}: any) => {
  const [Graphic] = await loadModules(["esri/Graphic"]);

  const ordered = orderedPttGeometry(pttGeometry);

  return ordered.map((polygon: any) => {
    const { layer_name, shape_geometry } = polygon;
    const vertices = shape_geometry;
    const arrColor: any = {
      AREA: [8, 255, 4, 0.3],
      BUILDING: [255, 142, 4, 0.3],
      PLANT: [4, 188, 255, 0.3],
    };

    let color = "transparent";

    if (
      layer_name === "PLANT" ||
      (mapZoom > 16 && mapZoom <= 21 && layer_name === "AREA") ||
      (mapZoom > 18 && layer_name === "BUILDING")
    ) {
      color = arrColor[layer_name];
    }

    const geometry = createGeometry({ vertices });

    const symbol = createSymbol({
      color,
      style: "solid",
      width: 1,
      outlineColor: color,
    });
    const newDevelopmentGraphic = new Graphic({
      geometry,
      symbol,
    });
    if (pttSearchGeometryLayer)
      pttSearchGeometryLayer?.add(newDevelopmentGraphic);
    return newDevelopmentGraphic;
  });
};
export default initSearchGeometry;
