import { CrudFilters, CrudOperators, DataProvider } from "@pankod/refine-core";
import restDataProvider from "@pankod/refine-simple-rest";
import { stringify } from "query-string";
import { AxiosInstance } from "axios";

//TEMP URL
//const THS_API_URL = "https://refine-real-world.herokuapp.com/api";

import { THS_API_URL } from "./constants";

const mapOperator = (operator: CrudOperators): string => {
  switch (operator) {
    case "eq":
      return "";
    default:
      throw new Error(`Operator ${operator} is not supported`);
  }
};

const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: string } = {};
  if (filters) {
    filters.map((filter) => {
      if (filter.operator !== "or") {
        const { field, operator, value } = filter;

        const mappedOperator = mapOperator(operator);
        queryFilters[`${field}${mappedOperator}`] = value;
      }
      return true;
    });
  }

  return queryFilters;
};

export const dataProvider = (axios: AxiosInstance): DataProvider => {
  return {
    ...restDataProvider(THS_API_URL || "", axios),
    getList: async ({ resource, pagination, filters }) => {
      const queryDatabase = `${THS_API_URL}/${resource}`;
      // pagination

      const current = pagination?.current || 1;
      const pageSize = pagination?.pageSize || 5;

      const queryFilters = generateFilter(filters);

      const query: {
        limit: number;
        offset: number;
      } = {
        offset: (current - 1) * pageSize,
        limit: pageSize,
      };

      const { data: payload } = await axios.get(
        `${queryDatabase}?${stringify(query)}&${stringify(queryFilters)}`
      );

      const { data, metadata } = payload;
      return {
        data,
        total: metadata?.total || 0,
      };
    },
    getOne: async ({ resource, id, metaData }) => {
      const getOneBackend = metaData?.getComments
        ? `${THS_API_URL}/${resource}/${id}/comments`
        : `${THS_API_URL}/${resource}/${id}`;

      const { data } = await axios.get(getOneBackend);

      return {
        data: data[metaData?.resource || resource],
      };
    },
    update: async ({ resource, id, variables, metaData }) => {
      const update = metaData?.URLSuffix
        ? `${THS_API_URL}/${resource}/${id}/${metaData.URLSuffix}`
        : `${THS_API_URL}/${resource}/${id}`;

      const { data } = metaData?.URLSuffix
        ? await axios.post(update)
        : await axios.put(update, variables);

      return {
        data,
      };
    },

    deleteOne: async ({ resource, id, variables, metaData }) => {
      const deleteOne = metaData?.URLSuffix
        ? `${THS_API_URL}/${resource}/${id}/${metaData.URLSuffix}`
        : `${THS_API_URL}/${resource}/${id}`;

      const { data } = await axios.delete(deleteOne, variables);

      return {
        data,
      };
    },
  };
};
