import React from "react";
import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";
import { THS_API } from "../../constants";

export default function ChartHistory({
  chartHistory,
  setChartHostory,
  chartHistoryData,
  setChartHistoryData,
  setActiveUserStatus,
}) {
  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      height: 200,
    },
    title: {
      text: "จำนวนอุปกรณ์ที่มีการแลก",
      style: {
        fontSize: 16,
      },
    },
    xAxis: {
      categories: chartHistory?.labels,
    },

    yAxis: [
      {
        allowDecimals: false,

        title: {
          text: "จำนวนคนที่เข้าในแต่ละช่วงเวลา",
        },
      },
    ],

    series: [
      {
        name: "ช่วงเวลา",
        type: "line",
        data: chartHistoryData,
      },
    ],
  };

  React.useEffect(() => {
    const fetchDataOnlyOne = async () => {
      setActiveUserStatus("loading");
      try {
        const res = await THS_API.get("/dashboard/active-user");

        const { data } = res.data;

        const newArr = data?.datasets[0].data.map(Number);

        setChartHostory(data);
        setChartHistoryData(newArr);
      } finally {
        setActiveUserStatus("loaded");
      }
    };
    fetchDataOnlyOne();
  }, [setActiveUserStatus, setChartHistoryData, setChartHostory]);

  return (
    <div style={{ width: "100%", height: "173px" }}>
      <PieChart highcharts={Highcharts} options={options} />
    </div>
  );
}
