/* eslint-disable jsx-a11y/alt-text */
import React from "react";
//import Swal from "sweetalert2";
//import { THS_API } from "../../constants";
import { Link } from "react-router-dom";
import "./index.css";
export default function ChangePassWord() {
  return (
    <section className="change-passWord">
      <div>
        <div className="header-topic">
          <h1>Profile</h1>
        </div>
        <div className="card-body search-bar">
          <form>
            <div className="grid-story">
              <div className="form-group row passWord-row">
                <label className="col-sm-2 col-form-label font">
                  Old Password
                </label>
                <div className="col-sm-7">
                  <input
                    type="password"
                    className="form-control"
                    id="LocationName"
                    // placeholder="Location Name"
                  />
                </div>
              </div>
            </div>
            <div className="grid-story">
              <div className="form-group row passWord-row">
                <label className="col-sm-2 col-form-label font">
                  New Password
                </label>
                <div className="col-sm-7">
                  <input
                    type="password"
                    className="form-control"
                    id="LocationName"
                    // placeholder="Location Name"
                  />
                </div>
              </div>
            </div>
            <div className="grid-story">
              <div className="form-group row passWord-row">
                <label className="col-sm-2 col-form-label font">
                  Confirm Password
                </label>
                <div className="col-sm-7">
                  <input
                    type="password"
                    className="form-control"
                    id="LocationName"
                    // placeholder="Location Name"
                  />
                </div>
              </div>
            </div>

            <div className="grid-story2">
              <Link to="/devices">
                <div className="btn btn-back">
                  {/* <a href="#"> */}
                  Back
                  {/* </a> */}
                </div>
              </Link>
              <div className="btn btn-save">
                {/* <a href="#"> */}
                Save
                {/* </a> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
