import React from "react";

import { LayoutProps } from "@pankod/refine-core";
import { AntdLayout } from "@pankod/refine-antd";

export const Layout: React.FC<LayoutProps> = ({
  children,
  Sider,
  Header,
  Footer,
  OffLayoutArea,
}) => {
  return (
    <AntdLayout style={{ minHeight: "100vh", flexDirection: "row" }}>
      {Sider && <Sider />}
      {Header && <Header />}
      <AntdLayout.Content>
        <div
          style={{
            minHeight: 360,
          }}
        >
          {children}
        </div>
        {OffLayoutArea && <OffLayoutArea />}
      </AntdLayout.Content>
      {Footer && <Footer />}
    </AntdLayout>
  );
};
