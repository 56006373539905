/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import moment from "moment";
import Swal from "sweetalert2";
import en from "date-fns/locale/en-GB";

import * as R from "ramda";

import DatePicker, { registerLocale } from "react-datepicker";

import { Select, Tabs } from "antd";

import type { ColumnsType } from "antd/es/table";

import SortingTable from "components/SortingTable";

import { THS_API } from "../../constants";

import "./index.css";

const installColor = (priority: any) => {
  if (priority === "low") return "green";
  if (priority === "high") return "red";
  if (priority === "medium") return "yellow";

  return "";
};
const buttonColor = (priority: any) => {
  if (priority === "low") return "btn btn-success";
  if (priority === "high") return "btn btn-danger";
  if (priority === "medium") return "btn btn-warning";

  return "";
};

const installpriority = (priority: any) => {
  if (priority === "low") return "ต่ำ";
  if (priority === "high") return "สูง";
  if (priority === "medium") return "ปานกลาง";

  return "";
};
const dateSet = (dataDate: any) => {
  const date = new Date(dataDate);

  return date.valueOf();
};

interface DataType {}

const columns: ColumnsType<DataType> = [
  {
    title: "ลำดับความสำคัญ",
    sorter: (a: any, b: any) => {
      return a.priority.localeCompare(b.priority);
    },
    render: (a) => {
      return (
        <div
          className={installColor(a.priority)}
          style={{ textAlign: "center" }}
        >
          {installpriority(a.priority)}
        </div>
      );
    },
    align: "center",
  },
  {
    title: "เลขบัตรประชาชน",
    sorter: (a: any, b: any) => {
      return a.cid.localeCompare(b.cid);
    },
    render: (a: any) => {
      return (
        <div style={{ textAlign: "center" }}>
          <span className={`icon ${a.priority}`}></span>
          {a.cid}
        </div>
      );
    },
    align: "center",
  },
  {
    title: "คำอธิบาย",
    sorter: (a: any, b: any) => {
      return a.description.localeCompare(b.description);
    },
    render: (a) => {
      return <div style={{ textAlign: "left" }}>{a.description}</div>;
    },
    align: "center",
  },
  {
    title: "วันที่",
    sorter: (a: any, b: any) => {
      return (
        dateSet(a.Notification_Users_updated) -
        dateSet(b.Notification_Users_updated)
      );
    },
    render: (a) => {
      return (
        <div style={{ textAlign: "center" }}>
          {a.Notification_Users_updated &&
            moment(a.Notification_Users_updated).format("DD/MM/YYYY")}
          {!a.Notification_Users_updated && "-"}
        </div>
      );
    },
    align: "center",
  },
  {
    title: "เวลา",
    sorter: (a: any, b: any) => {
      return (
        dateSet(a.Notification_Users_updated) -
        dateSet(b.Notification_Users_updated)
      );
    },
    render: (a) => {
      return (
        <div style={{ textAlign: "center" }}>
          {a.Notification_Users_updated &&
            moment(a.Notification_Users_updated).format("HH:mm")}
          {!a.Notification_Users_updated && "-"}
        </div>
      );
    },
    align: "center",
  },
  {
    title: "ผู้ควบคุมงาน",
    sorter: (a: any, b: any) => {
      const fullNameA = `${a.sup_first_name} ${a.sup_last_name}`;
      const fullNameB = `${b.sup_first_name} ${b.sup_last_name}`;
      return fullNameA.localeCompare(fullNameB);
    },
    render: (a: any) => {
      return (
        <div
          style={{ textAlign: "left" }}
        >{`${a.sup_first_name} ${a.sup_last_name}`}</div>
      );
    },
    align: "center",
  },
  {
    title: "เลข Work",
    sorter: (a: any, b: any) => {
      return a.notificationon
        .toString()
        .localeCompare(b.notificationon.toString());
    },
    render: (a: any) => {
      return (
        <div style={{ textAlign: "left" }}>{a.notificationon.toString()}</div>
      );
    },
    align: "center",
  },
  // {
  //   title: "สถานะการแจ้งเตือน",
  //   sorter: (a: any, b: any) => {
  //     return dateSet(a.created) - dateSet(b.created);
  //   },
  //   render: (a: any) => {
  //     return (
  //       <div>
  //         <div style={{ textAlign: "center" }}>
  //           <Tag color={a.muted ? "red" : "green"}>
  //             {a.muted ? "ปิด" : "เปิด"}
  //           </Tag>
  //         </div>
  //       </div>
  //     );
  //   },
  //   align: "center",
  // },

  {
    title: "ผู้ส่ง",
    sorter: (a: any, b: any) => {
      return a.Notification_Users_firstname.localeCompare(
        b.Notification_Users_firstname
      );
    },
    render: (a) => {
      return (
        <div style={{ textAlign: "left" }}>
          {a.Notification_Users_firstname} {a.Notification_Users_lastname}
        </div>
      );
    },
    align: "center",
  },
];

export default function Notification() {
  const [dataNotiApprove, setDataNotiApprove] = React.useState<any>([]);
  const [dataNoti, setDataNoti] = React.useState<any>([]);
  const [cardHolderSelectBox, setCardHolderSelectBox] = React.useState<any>([]);
  const [superviserSelectBox, setSuperviserSelectBox] = React.useState<any>([]);
  const [workpermitSelectBox, setWorkpermitSelectBox] = React.useState<any>([]);

  const [startDate, setStartDate] = React.useState(new Date());
  const [startDateAprrove, setStartDateAprrove] = React.useState(new Date());
  const [apiStatus, setApiStatus] = React.useState("success");
  const [endDate, setEndDate] = React.useState(new Date());
  const [endDateAprrove, setEndDateAprrove] = React.useState(new Date());
  const [priority, setPriority] = React.useState("");
  const [priorityAprrove, setPriorityAprrove] = React.useState("");
  const [cardHolder, setCardHolder] = React.useState("");
  const [cardHolderAprrove, setCardHolderAprrove] = React.useState("");
  const [superviser, setSuperviser] = React.useState("");
  const [superviserAprrove, setSuperviserAprrove] = React.useState("");
  const [workpermitNo, setWorkpermitNo] = React.useState("");
  const [workpermitNoAprrove, setWorkpermitNoAprrove] = React.useState("");
  const [workpermitID, setWorkpermitID] = React.useState("");
  const [workpermitIDAprrove, setWorkpermitIDAprrove] = React.useState("");
  const [notiSummaryTable, setNotiSummaryTable] = React.useState({
    allowHighNoti: 0,
    allowMediumNoti: 0,
    allowLowNoti: 0,
    allHighNoti: 0,
    allMediumNoti: 0,
    allLowNoti: 0,
  });
  const [latest3DataNoti, setLatest3DataNoti] = React.useState<any>([]);

  const resetSearchData = () => {
    setStartDate(new Date());
    setStartDateAprrove(new Date());
    //moment().subtract(7, "days").format("YYYY-MM-DD")
    setEndDate(new Date());
    setEndDateAprrove(new Date());
    setPriority("");
    setPriorityAprrove("");
    setCardHolder("");
    setCardHolderAprrove("");
    setSuperviser("");
    setSuperviserAprrove("");
    setWorkpermitNo("");
    setWorkpermitNoAprrove("");
    setWorkpermitID("");
    setWorkpermitIDAprrove("");
    notiInit();
  };

  const notiInit = async () => {
    setApiStatus("loading");

    const dataEndDate = moment(new Date()).format("YYYY-MM-DD");
    const dataStartDate = moment(new Date()).format("YYYY-MM-DD");

    const params: any = {
      check_acknowledge: false,
      priority: "",
      start_date: dataStartDate,
      end_date: dataEndDate,
      limit: 5000,
    };
    const dataNotiRes = THS_API.get("/notification", { params });

    params.check_acknowledge = true;
    const dataNotiApproveRes = THS_API.get("/notification", { params });

    const unAllowNotiRes = THS_API.get("/notification/countNotification", {
      params: {
        check_acknowledge: false,
        start_date: dataStartDate,
        end_date: dataEndDate,
      },
    });

    const allowNotiRes = THS_API.get("/notification/countNotification", {
      params: {
        check_acknowledge: true,
        start_date: dataStartDate,
        end_date: dataEndDate,
      },
    });

    const [
      dataNotiPayload,
      dataNotiApprovePayload,
      unAllowNotiPayload,
      allowNotiPayload,
    ] = await Promise.all([
      dataNotiRes,
      dataNotiApproveRes,
      unAllowNotiRes,
      allowNotiRes,
    ]);

    const dataNoti = dataNotiPayload?.data?.data || [];
    const dataNotiApprove = dataNotiApprovePayload?.data?.data || [];

    const allowHighNoti = Number(
      R.pathOr(0, ["data", "data", "data", "notiHigh"], allowNotiPayload) || 0
    );
    const allowMediumNoti = Number(
      R.pathOr(0, ["data", "data", "data", "notiMedium"], allowNotiPayload) || 0
    );
    const allowLowNoti = Number(
      R.pathOr(0, ["data", "data", "data", "notiLow"], allowNotiPayload) || 0
    );

    const unAllowHighNoti = Number(
      R.pathOr(0, ["data", "data", "data", "notiHigh"], unAllowNotiPayload) || 0
    );
    const unAllowMediumNoti = Number(
      R.pathOr(0, ["data", "data", "data", "notiMedium"], unAllowNotiPayload) ||
        0
    );
    const unAllowLowNoti = Number(
      R.pathOr(0, ["data", "data", "data", "notiLow"], unAllowNotiPayload) || 0
    );

    const allHighNoti = allowHighNoti + unAllowHighNoti;
    const allMediumNoti = allowMediumNoti + unAllowMediumNoti;
    const allLowNoti = allowLowNoti + unAllowLowNoti;

    setNotiSummaryTable({
      allowHighNoti,
      allowMediumNoti,
      allowLowNoti,
      allHighNoti,
      allMediumNoti,
      allLowNoti,
    });

    setLatest3DataNoti(dataNoti.slice(0, 3));

    setDataNoti(dataNoti);
    setDataNotiApprove(dataNotiApprove);
    setApiStatus("success");
  };

  const handleWorkpermit = (value: any) => {
    setWorkpermitNo(value);
    const selectedArr = workpermitSelectBox?.filter(
      (obj: any) => obj.workpermit_work_permit_no === value
    );
    setWorkpermitID(selectedArr[0]?.workpermit_work_permit_id);
  };

  const handleWorkpermitApprove = (value: any) => {
    setWorkpermitNoAprrove(value);
    const selectedArr = workpermitSelectBox?.filter(
      (obj: any) => obj.workpermit_work_permit_no === value
    );
    setWorkpermitIDAprrove(selectedArr[0]?.workpermit_work_permit_id);
  };

  const useNotiInitFirst = () => {
    const callApi = async () => {
      notiInit();

      const res = await THS_API.get("/notification/notificationFilter", {
        params: {
          check_acknowledge: false,
        },
      });

      const { emp, superviser, workpermit } = res.data.data;

      setCardHolderSelectBox(emp);
      setSuperviserSelectBox(superviser);
      setWorkpermitSelectBox(workpermit);
    };
    React.useEffect(() => {
      callApi();
    }, []);
  };

  useNotiInitFirst();

  registerLocale("el", en);

  const notiSearch = async () => {
    const start_date = moment(startDate).format("YYYY-MM-DD");
    const end_date = moment(endDate).format("YYYY-MM-DD");

    const params: any = {
      check_acknowledge: false,
      start_date,
      end_date,
      priority,
      superviser,
      cid: cardHolder,
      workpermit_id: workpermitID,
    };

    const res = await THS_API.get("/notification", { params });

    setDataNoti(res.data.data);
    return res.data.data || [];
  };

  const notiSearchWithLoading = () => {
    setApiStatus("loading");
    notiSearch();
    setApiStatus("success");
  };

  const notiSearchApprove = async () => {
    const start_date = moment(startDateAprrove).format("YYYY-MM-DD");
    const end_date = moment(endDateAprrove).format("YYYY-MM-DD");

    const params: any = {
      check_acknowledge: true,
      start_date,
      end_date,
      priority: priorityAprrove,
      superviser: superviserAprrove,
      cid: cardHolderAprrove,
      workpermit_id: workpermitIDAprrove,
    };
    const res = await THS_API.get("/notification", { params });
    setDataNotiApprove(res.data.data);
  };

  const notiSearchApproveWithLoading = () => {
    setApiStatus("loading");
    notiSearchApprove();
    setApiStatus("success");
  };

  const notiCheck = async ({ id }: any) => {
    try {
      setApiStatus("loading");
      const res = await THS_API.post("/notification/acknowledge", {
        notification_id: id,
        muted: false,
      });
      if (res.data.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: "อนุมัติการแจ้งเตือน",
          showConfirmButton: false,
          timer: 1500,
        });
        notiInit();
      }
    } catch (error) {
    } finally {
      setApiStatus("success");
    }
  };
  const notiCheckAll = async () => {
    try {
      setApiStatus("loading");
      let dataEndDate = moment(endDate).format("YYYY-MM-DD");
      let dataStartDate = moment(startDate).format("YYYY-MM-DD");
      const res = await THS_API.post("/notification/acknowledgeAll", {
        start_date: dataStartDate,
        end_date: dataEndDate,
        check_acknowledge: "false",
      });
      if (res.data.statusCode === 200) {
        Swal.fire({
          icon: "success",
          title: "อนุมัติการแจ้งเตือน",
          showConfirmButton: false,
          timer: 1500,
        });
        notiInit();
      }
    } catch (error) {
    } finally {
      setApiStatus("success");
    }
  };
  const columnsNoti: ColumnsType<DataType> = [
    {
      render: (a: any) => {
        return (
          <div style={{ textAlign: "center" }}>
            {a.priority === "high" && (
              <img
                src="/images/icon/alarm.svg"
                width={30}
                height={30}
                alt="alarm"
              />
            )}
          </div>
        );
      },
      align: "center",
    },

    {
      title: "ลำดับความสำคัญ",
      sorter: (a: any, b: any) => {
        return a.priority.localeCompare(b.priority);
      },
      render: (a: any) => {
        return (
          <div
            className={installColor(a.priority)}
            style={{ textAlign: "center" }}
          >
            {installpriority(a.priority)}
          </div>
        );
      },
      align: "center",
    },
    {
      title: "เลขบัตรประชาชน",
      sorter: (a: any, b: any) => {
        return a.cid.localeCompare(b.cid);
      },
      render: (a: any) => {
        return (
          <div style={{ textAlign: "center" }}>
            <span className={`icon ${a.priority}`}></span>
            {a.cid}
          </div>
        );
      },
      align: "center",
    },
    {
      title: "คำอธิบาย",
      sorter: (a: any, b: any) => {
        return a.description.localeCompare(b.description);
      },
      render: (a: any) => {
        return <div style={{ textAlign: "left" }}>{a.description}</div>;
      },
      align: "center",
    },
    {
      title: "วันที่",
      sorter: (a: any, b: any) => {
        return dateSet(a.created) - dateSet(b.created);
      },
      render: (a: any) => {
        return (
          <div style={{ textAlign: "center" }}>
            {moment(a.created).format("DD/MM/YYYY")}
          </div>
        );
      },
      align: "center",
    },
    {
      title: "เวลา",
      sorter: (a: any, b: any) => {
        return dateSet(a.created) - dateSet(b.created);
      },
      render: (a: any) => {
        return (
          <div style={{ textAlign: "center" }}>
            {moment(a.created).format("HH:mm")}
          </div>
        );
      },
      align: "center",
    },
    {
      title: "ผู้ควบคุมงาน",
      sorter: (a: any, b: any) => {
        const fullNameA = `${a.sup_first_name} ${a.sup_last_name}`;
        const fullNameB = `${b.sup_first_name} ${b.sup_last_name}`;
        return fullNameA.localeCompare(fullNameB);
      },
      render: (a: any) => {
        return (
          <div
            style={{ textAlign: "left" }}
          >{`${a.sup_first_name} ${a.sup_last_name}`}</div>
        );
      },
      align: "center",
    },
    {
      title: "เลข Work",
      sorter: (a: any, b: any) => {
        return a.notificationon
          .toString()
          .localeCompare(b.notificationon.toString());
      },
      render: (a: any) => {
        return (
          <div style={{ textAlign: "left" }}>{a.notificationon.toString()}</div>
        );
      },
      align: "center",
    },
    {
      title: "การอนุมัติ",
      render: (a: any) => {
        return (
          <button
            type="button"
            // className="btn btn-save"
            className={buttonColor(a.priority)}
            onClick={() => {
              notiCheck({ id: a.id });
            }}
            style={{ color: "white" }}
          >
            การแจ้งเตือน
          </button>
        );
      },
      align: "center",
    },
  ];

  React.useEffect(() => {
    const interval = setInterval(async () => {
      const today = moment(new Date()).format("YYYY-MM-DD");
      const startDateNoti = moment(startDate).format("YYYY-MM-DD");
      const endDateNoti = moment(endDate).format("YYYY-MM-DD");

      const startDateApproveNoti =
        moment(startDateAprrove).format("YYYY-MM-DD");
      const endDateApproveNoti = moment(endDateAprrove).format("YYYY-MM-DD");

      if (
        startDateNoti === today &&
        endDateNoti === today &&
        priority === "" &&
        superviser === "" &&
        cardHolder === "" &&
        workpermitID === ""
      ) {
        const dataNoti = await notiSearch();

        setLatest3DataNoti(dataNoti.slice(0, 3));
      } else {
        const params = {
          check_acknowledge: false,
          start_date: today,
          end_date: today,
        };
        const res = await THS_API.get("/notification", {
          params,
        });

        const dataNoti = res.data.data;

        setLatest3DataNoti(dataNoti.slice(0, 3));
      }

      if (
        startDateApproveNoti === today &&
        endDateApproveNoti === today &&
        priorityAprrove === "" &&
        superviserAprrove === "" &&
        cardHolderAprrove === "" &&
        workpermitIDAprrove === ""
      )
        notiSearchApprove();
    }, 1000 * 15);

    return () => {
      // before the component is destroyed
      // unbind all event handlers used in this component
      clearInterval(interval);
    };
  }, [
    startDate,
    endDate,
    priority,
    superviser,
    cardHolder,
    workpermitID,

    startDateAprrove,
    startDateAprrove,
    priorityAprrove,
    superviserAprrove,
    cardHolderAprrove,
    workpermitIDAprrove,
  ]);

  return (
    <div className="notification">
      <section>
        <div>
          <div className="header-topic">
            <h1>การแจ้งเตือน</h1>
          </div>
          <div className="sub header-topic">
            <h1>การแจ้งเตือน (วันนี้)</h1>
          </div>
          <div className="notification-today">
            <div className="row">
              <div className="col-sm-4">
                <div className="overall-table">
                  <div className="row">
                    <div className="box high col-sm-3">
                      {notiSummaryTable.allowHighNoti}
                      <br />
                      ----
                      <br />
                      {notiSummaryTable.allHighNoti}
                    </div>
                    <div className="box medium col-sm-3">
                      {notiSummaryTable.allowMediumNoti}
                      <br />
                      ----
                      <br />
                      {notiSummaryTable.allMediumNoti}
                    </div>
                    <div className="box low col-sm-3">
                      {notiSummaryTable.allowLowNoti}
                      <br />
                      ----
                      <br />
                      {notiSummaryTable.allLowNoti}
                    </div>
                  </div>
                </div>
              </div>

              <div className="latest-noti col-sm-8">
                {latest3DataNoti?.map((obj: any) => (
                  <div className="row">
                    <div className="col col-sm-1 box-container">
                      <div className={`box ${obj.priority}`} />
                    </div>
                    <div className="col col-sm-3">{obj.cid}</div>
                    <div className="col col-sm-6">{obj.description}</div>
                    <div className="col col-sm-2">
                      {`เวลา ${moment(obj.created).format("HH:mm")} น.`}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="sub header-topic">
            <h1>ค้นหาการแจ้งเตือน</h1>
          </div>
          <Tabs type="card">
            <Tabs.TabPane tab="การแจ้งเตือน" key="time">
              <div className="card-body search-bar">
                <form>
                  <div className="row">
                    <div className="form-group col-sm-6">
                      <div className="row">
                        <label className="col-sm-4 col-form-label">
                          ลำดับความสำคัญ:
                        </label>

                        <div className="col-sm-8">
                          <Select
                            id="#"
                            style={{ marginTop: "5px", width: "100%" }}
                            value={priority}
                            onChange={setPriority}
                          >
                            <Select.Option value="">
                              ลำดับความสำคัญ
                            </Select.Option>
                            <Select.Option value="High">สูง</Select.Option>
                            <Select.Option value="Medium">
                              ปานกลาง
                            </Select.Option>
                            <Select.Option value="Low">ต่ำ</Select.Option>
                          </Select>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-sm-6">
                      <div className="row">
                        <label className="col-sm-4 col-form-label">
                          ผู้ถืออุปกรณ์:
                        </label>

                        <div className="col-sm-8">
                          <Select
                            showSearch
                            className="form-control"
                            id="#"
                            style={{ marginTop: "5px" }}
                            value={cardHolder}
                            onChange={setCardHolder}
                          >
                            <Select.Option value="">
                              ผู้ถืออุปกรณ์
                            </Select.Option>
                            {cardHolderSelectBox.map(
                              (obj: any, key: number) => (
                                <Select.Option key={key} value={obj.cid}>
                                  {`${obj.fullnameemp} (${obj.cid})`}
                                </Select.Option>
                              )
                            )}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-6">
                      <div className="row">
                        <label className="col-sm-4 col-form-label">
                          เลข Work:
                        </label>

                        <div className="col-sm-8">
                          <Select
                            showSearch
                            id="#"
                            style={{ marginTop: 5, width: "100%" }}
                            value={workpermitNo}
                            onChange={handleWorkpermit}
                          >
                            <Select.Option value="">เลือกใบงาน</Select.Option>
                            {workpermitSelectBox.map(
                              (obj: any, key: number) => (
                                <Select.Option
                                  value={obj.workpermit_work_permit_no}
                                  key={key}
                                >
                                  {obj.workpermit_work_permit_no}
                                </Select.Option>
                              )
                            )}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-sm-6">
                      <div className="row">
                        <label className="col-sm-4 col-form-label">
                          เลือกผู้คุมงาน:
                        </label>

                        <div className="col-sm-8">
                          <Select
                            id="#"
                            className="form-control"
                            showSearch
                            style={{ marginTop: "5px" }}
                            value={superviser}
                            onChange={setSuperviser}
                          >
                            <Select.Option value="">
                              เลือกผู้คุมงาน
                            </Select.Option>

                            {superviserSelectBox.map(
                              (obj: any, key: number) => (
                                <Select.Option
                                  value={obj.fullnamesuperviser}
                                  key={key}
                                >
                                  {obj.fullnamesuperviser}
                                </Select.Option>
                              )
                            )}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-6">
                      <div className="row">
                        <label className="col-sm-4 col-form-label">
                          เริ่ม:
                        </label>

                        <div className="col-sm-8">
                          {" "}
                          <DatePicker
                            className="form-control"
                            selected={startDate}
                            onChange={(date: any) => {
                              const start = moment(date);
                              const end = moment(endDate);
                              setStartDate(date);
                              //   setStartDateWholeReport(date);
                              if (start.diff(end) > 0) setEndDate(date);
                            }}
                            maxDate={endDate}
                            locale="el"
                            dateFormat="P"
                          />
                          <div id="myDatePicker" />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-sm-6">
                      <div className="row">
                        <label className="col-sm-4 col-form-label">
                          สิ้นสุด:
                        </label>

                        <div className="col-sm-8">
                          <DatePicker
                            className="form-control"
                            selected={endDate}
                            onChange={(date: any) => {
                              setEndDate(date);
                            }}
                            maxDate={new Date()}
                            locale="el"
                            dateFormat="P"
                            minDate={startDate}
                          />
                          <div id="myDatePicker" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-4"></div>
                    <div className="form-group col-sm-4">
                      <div className="row">
                        <div className=" col-sm-6">
                          <button
                            type="button"
                            className="btn btn-save"
                            style={{ marginTop: "3px" }}
                            onClick={notiSearchWithLoading}
                          >
                            ค้นหา
                          </button>
                        </div>
                        <div className=" col-sm-6">
                          <button
                            type="button"
                            className="btn btn-save"
                            style={{ marginTop: "3px" }}
                            onClick={resetSearchData}
                          >
                            ล้าง
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className="float-right">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={notiCheckAll}
                  style={{ color: "white" }}
                >
                  แจ้งเตือนทั้งหมด
                </button>
              </div>

              <SortingTable
                apiStatus={apiStatus}
                query={dataNoti}
                columns={columnsNoti}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="อนุมัติการแจ้งเตือน" key="period">
              <div className="card-body search-bar">
                <form>
                  <div className="row">
                    <div className="form-group col-sm-6">
                      <div className="row">
                        <label className="col-sm-4 col-form-label">
                          ลำดับความสำคัญ:
                        </label>

                        <div className="col-sm-8">
                          <Select
                            id="#"
                            value={priorityAprrove}
                            style={{ marginTop: "5px", width: "100%" }}
                            onChange={setPriorityAprrove}
                          >
                            <Select.Option value="">
                              ลำดับความสำคัญ
                            </Select.Option>
                            <Select.Option value="High">สูง</Select.Option>
                            <Select.Option value="Medium">
                              ปานกลาง
                            </Select.Option>
                            <Select.Option value="Low">ต่ำ</Select.Option>
                          </Select>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-sm-6">
                      <div className="row">
                        <label className="col-sm-4 col-form-label">
                          ผู้ถืออุปกรณ์:
                        </label>

                        <div className="col-sm-8">
                          <Select
                            showSearch
                            className="form-control"
                            id="#"
                            style={{ marginTop: "5px" }}
                            value={cardHolderAprrove}
                            onChange={setCardHolderAprrove}
                          >
                            <Select.Option value="">
                              ผู้ถืออุปกรณ์
                            </Select.Option>
                            {cardHolderSelectBox.map(
                              (obj: any, key: number) => (
                                <Select.Option key={key} value={obj.cid}>
                                  {`${obj.fullnameemp} (${obj.cid})`}
                                </Select.Option>
                              )
                            )}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-6">
                      <div className="row">
                        <label className="col-sm-4 col-form-label">
                          เลข Work:
                        </label>

                        <div className="col-sm-8">
                          <Select
                            showSearch
                            id="#"
                            style={{ marginTop: 5, width: "100%" }}
                            value={workpermitNoAprrove}
                            onChange={handleWorkpermitApprove}
                          >
                            <Select.Option value="">เลือกใบงาน</Select.Option>
                            {workpermitSelectBox.map(
                              (obj: any, key: number) => (
                                <Select.Option
                                  value={obj.workpermit_work_permit_no}
                                  key={key}
                                >
                                  {obj.workpermit_work_permit_no}
                                </Select.Option>
                              )
                            )}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-sm-6">
                      <div className="row">
                        <label className="col-sm-4 col-form-label">
                          เลือกผู้คุมงาน:
                        </label>

                        <div className="col-sm-8">
                          <Select
                            id="#"
                            className="form-control"
                            showSearch
                            style={{ marginTop: "5px" }}
                            value={superviserAprrove}
                            onChange={setSuperviserAprrove}
                          >
                            <Select.Option value="">
                              เลือกผู้คุมงาน
                            </Select.Option>

                            {superviserSelectBox.map(
                              (obj: any, key: number) => (
                                <Select.Option
                                  value={obj.fullnamesuperviser}
                                  key={key}
                                >
                                  {obj.fullnamesuperviser}
                                </Select.Option>
                              )
                            )}
                          </Select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-6">
                      <div className="row">
                        <label className="col-sm-4 col-form-label">
                          เริ่ม:
                        </label>

                        <div className="col-sm-8">
                          <DatePicker
                            className="form-control"
                            selected={startDateAprrove}
                            onChange={(date: any) => {
                              const start = moment(date);
                              const end = moment(endDate);
                              setStartDateAprrove(date);
                              if (start.diff(end) > 0) setEndDateAprrove(date);
                            }}
                            maxDate={endDateAprrove}
                            locale="el"
                            dateFormat="P"
                          />
                          <div id="myDatePicker" />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-sm-6">
                      <div className="row">
                        <label className="col-sm-4 col-form-label">
                          สิ้นสุด:
                        </label>

                        <div className="col-sm-8">
                          <DatePicker
                            className="form-control"
                            selected={endDateAprrove}
                            onChange={(date: any) => {
                              setEndDateAprrove(date);
                            }}
                            maxDate={new Date()}
                            locale="el"
                            dateFormat="P"
                            minDate={startDateAprrove}
                          />
                          <div id="myDatePicker" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-4"></div>
                    <div className="form-group col-sm-4">
                      <div className="row">
                        <div className="col-sm-6">
                          <button
                            type="button"
                            className="btn btn-save"
                            style={{ marginTop: "3px" }}
                            onClick={notiSearchApproveWithLoading}
                          >
                            ค้นหา
                          </button>
                        </div>
                        <div className="col-sm-6">
                          <button
                            type="button"
                            className="btn btn-save"
                            style={{ marginTop: "3px" }}
                            onClick={resetSearchData}
                          >
                            ล้าง
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <SortingTable
                apiStatus={apiStatus}
                query={dataNotiApprove}
                columns={columns}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </section>
    </div>
  );
}
