import React from "react";
import moment from "moment";
import en from "date-fns/locale/en-GB";
import * as FileSaver from "file-saver";
import ExcelJS from "exceljs";
import { Tabs } from "antd";
import { registerLocale } from "react-datepicker";
import { DefaultReport, NotificationReport } from "components/report";
import { THS_API } from "../../../constants";
import { useSelector } from "react-redux";
import { selectDescription } from "reducers/rolesReducer";
import { selectUser } from "reducers/userReducer";

import "./index.css";

export const ReportList: React.FC = () => {
  const [startDateWholeReport, setStartDateWholeReport] = React.useState(
    new Date()
  );
  const [endDateWholeReport, setEndDateWholeReport] = React.useState(
    new Date()
  );

  const [reportType, setReportType] = React.useState("people");
  const [reportInput, setReportInput] = React.useState("");
  const [reportInputWorkpermitSelect, setReportInputWorkpermitSelect] =
    React.useState("");

  const [currentTab, setCurrentTab] = React.useState("time");

  const userData = useSelector(selectUser);
  const description = useSelector(selectDescription);

  const reportAllAPI = async (reportMoveTracking: string) => {
    let dataStartDate = moment(startDateWholeReport).format("YYYY-MM-DD");
    let dataEndDate = moment(endDateWholeReport).format("YYYY-MM-DD");

    const res = await THS_API.get(reportMoveTracking, {
      params: {
        start_date: dataStartDate,
        end_date: dataEndDate,
      },
      // responseType: "blob",
    });

    const { status } = res;
    if (status === 200) {
      // const blob = new Blob([apiData], {
      //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
      // });

      // const fileName = `report-move-tracking_${dataStartDate}-${dataEndDate}.xlsx`;

      // FileSaver.saveAs(blob, fileName);
      // ? "/report/move_tracking/xlsx"
      // : "report/notificationAll";
      if (currentTab === "noti") {
        let workbook = new ExcelJS.Workbook(); //creating workbook
        const worksheet = workbook.addWorksheet("User");
        // workbook.getCell("A1").value = "ffffff" // ชี้ไปยัง Cell ที่เลือกเพื่อใส่ค่าเข้าไปใน Cell
        const rowUser = worksheet.getRow(3);
        //rowUser.values = header
        rowUser.height = 25;
        const rowHeaderName = worksheet.getColumn(5);
        rowHeaderName.header = "รายงานสรุปการแจ้งเตือน Application ";
        rowHeaderName.width = 40;
        worksheet.getColumn(5).font = {
          family: 4,
          name: "Calibri (Body)",
          size: 16,
          bold: true,
        };
        const rowNameReport = worksheet.getRow(4);
        rowNameReport.getCell(2).value = `ผู้ออกรายงาน : '${userData.name}`;
        const rowRoleReport = worksheet.getRow(5);
        rowRoleReport.getCell(2).value = `สิทธิ์ผู้ออกรายงาน : '${description}`;
        const rowtimeReport = worksheet.getRow(6);
        rowtimeReport.getCell(2).value = `วันที่ออกรรายงาน : '${moment(
          new Date()
        ).format("DD-MM-YYYY")}`;
        const rowtime = worksheet.getRow(7);
        rowtime.getCell(2).value = `เวลาเริ่ม : '${moment(dataStartDate).format(
          "DD-MM-YYYY"
        )}`;
        rowtime.getCell(4).value = `เวลาสิ้นสุด : '${moment(dataEndDate).format(
          "DD-MM-YYYY"
        )}`;
        const rows = worksheet.getRow(10);
        const columns = [
          "ลำดับ",
          "วัน",
          "เวลา",
          "ชื่อ-นามสกุล",
          "ประเภทการแจ้งเตือน",
          "ใบอนุญาตทำงาน",
          "ผู้ควบคุมงาน",
        ];
        rows.values = columns;
        const worksheetColumn = [
          { key: "no", width: 5 },
          { key: "dateDate", width: 10 },
          { key: "time", width: 10 },
          { key: "name", width: 15 },
          { key: "type", width: 40, outlineLevel: 1 },
          { key: "wpm_no", width: 30 },
          { key: "sup_name", width: 20 },
        ];
        worksheet.columns = worksheetColumn;
        rows.eachCell(function (cell) {
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          cell.font = { color: { argb: "FFFFFFFF" }, name: "Calibri (Body)" };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "121b1464" },
          };
        });

        const dataReport = res.data.data.data.map((obj: any, index: number) => {
          const date = moment(obj.datetime).format("DD-MM-YYYY");
          const time = moment(obj.datetime).format("HH:mm");
          return {
            ...obj,
            dateDate: date,
            time: time,
            no: index + 1,
          };
        });

        worksheet.addRows(dataReport);
        const buffer = await workbook.xlsx.writeBuffer();

        FileSaver.saveAs(
          new Blob([buffer]),
          `รายงานการแจ้งเตือนทั้งหมด_${moment(new Date()).format(
            "DD-MM-YYYY"
          )}.xlsx`
        );
      } else {
        let workbook = new ExcelJS.Workbook(); //creating workbook
        const worksheet = workbook.addWorksheet("User");
        // workbook.getCell("A1").value = "ffffff" // ชี้ไปยัง Cell ที่เลือกเพื่อใส่ค่าเข้าไปใน Cell
        const rowUser = worksheet.getRow(3);
        //rowUser.values = header
        rowUser.height = 25;
        const rowHeaderName = worksheet.getColumn(8);
        rowHeaderName.header = "รายงานสรุปผู้ใช้งาน Application ";
        rowHeaderName.width = 40;
        worksheet.getColumn(8).font = {
          family: 4,
          name: "Calibri (Body)",
          size: 16,
          bold: true,
        };

        const rowNameReport = worksheet.getRow(4);
        rowNameReport.getCell(2).value = `ผู้ออกรายงาน : '${userData.name}`;
        const rowRoleReport = worksheet.getRow(5);
        rowRoleReport.getCell(2).value = `สิทธิ์ผู้ออกรายงาน : '${description}`;
        const rowtimeReport = worksheet.getRow(6);
        rowtimeReport.getCell(2).value = `วันที่ออกรรายงาน : '${moment(
          new Date()
        ).format("YYYY-MM-DD")}`;
        const rowtime = worksheet.getRow(7);
        rowtime.getCell(2).value = `เวลาเริ่ม : '${dataStartDate}`;
        rowtime.getCell(4).value = `เวลาสิ้นสุด : '${dataEndDate}`;
        const rows = worksheet.getRow(10);
        const columns = [
          "ลำดับ",
          "วัน",
          "เวลา",
          "ชื่อ-นามสกุล",
          "ละติจูด",
          "ลองติจูด",
          "อาคาร",
          "พื้นที่ปฏิบัติงาน",
          "เขตปฏิบัติงาน",
          "ผู้ควบคุมงาน",
          "ใบอนุญาตทำงาน",
        ];
        rows.values = columns;
        const worksheetColumn = [
          { key: "no", width: 5 },
          { key: "dateDate", width: 10 },
          { key: "time", width: 10 },
          { key: "name", width: 15 },
          { key: "lat", width: 20, outlineLevel: 1 },
          { key: "long", width: 20 },
          { key: "building", width: 20 },
          { key: "plant", width: 20 },
          { key: "zone", width: 20 },
          { key: "supervisor", width: 15 },
          { key: "workpermit", width: 40 },
        ];
        worksheet.columns = worksheetColumn;
        rows.eachCell(function (cell) {
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          cell.font = { color: { argb: "FFFFFFFF" }, name: "Calibri (Body)" };
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "121b1464" },
          };
        });

        const dataReport = res.data.data.map((obj: any, index: number) => {
          const date = moment(obj.datetime).format("DD-MM-YYYY");
          const time = moment(obj.datetime).format("HH:mm");
          return {
            ...obj,
            dateDate: date,
            time: time,
            no: index + 1,
          };
        });

        worksheet.addRows(dataReport);
        const buffer = await workbook.xlsx.writeBuffer();

        FileSaver.saveAs(
          new Blob([buffer]),
          `รายงานการใช้งานระบบทั้งหมด_${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.xlsx`
        );
      }
    }
  };

  registerLocale("el", en);

  return (
    <section className="report">
      <div>
        <div className="header-topic">
          <div className="row">
            <div className="col-sm-6">
              <h1>รายงานการใช้งานระบบ</h1>
            </div>
            <div className="col-sm-6">
              <button
                className="btn btn-save"
                onClick={() => {
                  const url =
                    currentTab === "time"
                      ? "/report/move_tracking/xlsx"
                      : "report/notificationAll";
                  reportAllAPI(url);
                }}
              >
                ดาวน์โหลดทั้งหมด
              </button>
            </div>
          </div>
        </div>
        <Tabs type="card" onChange={setCurrentTab}>
          <Tabs.TabPane tab="รายงานการใช้งานระบบ" key="time">
            <DefaultReport
              reportType={reportType}
              setReportType={setReportType}
              reportInput={reportInput}
              reportInputWorkpermitSelect={reportInputWorkpermitSelect}
              setReportInput={setReportInput}
              setReportInputWorkpermitSelect={setReportInputWorkpermitSelect}
              setStartDateWholeReport={setStartDateWholeReport}
              setEndDateWholeReport={setEndDateWholeReport}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="รายงานการแจ้งเตือน" key="noti">
            <NotificationReport
              setStartDateWholeReport={setStartDateWholeReport}
              setEndDateWholeReport={setEndDateWholeReport}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </section>
  );
};
