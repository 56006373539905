/* eslint-disable react-hooks/exhaustive-deps */
import classnames from "classnames";

import { Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";

import { selectUser } from "reducers/userReducer";
import {
  selectMapMonitoring,
  setButtonGroupValue,
} from "reducers/mapMonitoring";

import "./index.css";

export default function FilterBaseMap({
  isOpen,
  setModalBaseMap,
  viewMap,
  setViewMap,
}: any) {
  const userData = useSelector(selectUser);
  const dispatch = useDispatch<any>();

  const { buttonGroupValue } = useSelector(selectMapMonitoring);

  const handleCancel = () => {
    setModalBaseMap(false);
    // jsonApi();
    dispatch(
      setButtonGroupValue({
        ...buttonGroupValue,
        userId: userData.id,
        modalBaseMap: false,
      })
    );
  };

  const handleBaseMap = async (viewMap: string) => {
    setViewMap(viewMap);
    setModalBaseMap(false);

    await dispatch(
      setButtonGroupValue({
        ...buttonGroupValue,
        userId: userData.id,
        viewMap,
        modalBaseMap: false,
      })
    );
    window.location.reload();
  };

  return (
    <div className="map-boundary-mapBase">
      <Modal
        className="map-boundary-basemap"
        title={
          <div
            style={{
              width: "500px",
              cursor: "move",
            }}
          />
        }
        open={isOpen}
        onCancel={handleCancel}
      >
        <h5 className="accordion-header">เลือกประเภท baseMap</h5>

        <div
          className={classnames("card-body", {
            active: viewMap === "satellite",
          })}
          onClick={() => handleBaseMap("satellite")}
        >
          <div className="row">
            <div className="col-sm-4 ">
              <img src="/images/satellite.jpg" width={50} height={50} alt="" />
            </div>
            <div className="col-sm-8 type-basemap">satellite</div>
          </div>
        </div>
        <div
          className={classnames("card-body", {
            active: viewMap === "streets-vector",
          })}
          onClick={() => handleBaseMap("streets-vector")}
        >
          <div className="row">
            <div className="col-sm-4 ">
              <img src="/images/street.jpeg" width={50} height={50} alt="" />
            </div>
            <div className="col-sm-8 type-basemap">street-vector</div>
          </div>{" "}
        </div>
        <div
          className={classnames("card-body", {
            active: viewMap === "streets-night-vector",
          })}
          onClick={() => handleBaseMap("streets-night-vector")}
        >
          <div className="row">
            <div className="col-sm-4 ">
              <img
                src="/images/streets-night-vector.jpeg"
                width={50}
                height={50}
                alt=""
              />
            </div>
            <div className="col-sm-8 type-basemap">streets-night-vector</div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
