/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { THS_API } from "../../constants";
import { Link } from "react-router-dom";

export default function ImportDevice() {
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = React.useState<any>({ name: "" });
  const uploadFile = async () => {
    if (!selectedFile) {
      Swal.fire({
        icon: "info",
        title: "Please select file to upload",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      let fileType = selectedFile.name.split(".").pop();
      if (fileType === "csv") {
        const data = new FormData();
        data.append("file", selectedFile, selectedFile.name);
        try {
          const res = await THS_API.post("/devices/import", data);

          if (res.data.statusCode === 201) {
            if (
              res.data.message ===
              "Import device successfully with some reject devices."
            ) {
              let reject = [];

              for (let i = 0; i < res.data.data.length; i++) {
                reject[i] = "EUI " + res.data.data[i].dev_eui;
              }
              Swal.fire({
                icon: "warning",
                title: "Import device successfully with some reject devices",
                html:
                  '<ul style="list-style: none">' +
                  reject
                    .map((EUI) => `<li style="color:#facea8">${EUI}</li> `)
                    .join("") +
                  "</ul>",
                confirmButtonText: "ตกลง",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate("../devices");
                }
              });
            } else {
              Swal.fire({
                icon: "success",
                title: "Import file Done!",
                showConfirmButton: false,
                timer: 1500,
              });
              navigate("../devices");
            }
          }
        } catch (error) {
          Swal.fire({
            icon: "info",
            title: "เกิดข้อผิดพลาด " + error,
          });
        }
      } else {
        Swal.fire({
          icon: "info",
          title:
            "The file must be a file type .CSV Please select file to upload again",
        });
      }
    }

    //
  };
  const onSelectFile = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };
  return (
    <section className="import-device">
      <div>
        <div className="header-topic">
          <h1>Register Device</h1>
        </div>
        <div className="card-body search-bar">
          <form>
            <div
              style={{
                marginBottom: "20px",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Import File
            </div>
            <div className="import">
              <img src="/images/icon/excel.png" width={80} height={80} />
              <div className="mb-3">
                <label htmlFor="formFileSm" className="form-label" />
                <input
                  className="form-control form-control-sm"
                  id="formFileSm"
                  type="file"
                  onChange={onSelectFile}
                />
              </div>
            </div>
            <div className="grid-story2">
              <Link to="/devices">
                <div className="btn btn-back">
                  {/* <a href="#"> */}
                  Back
                  {/* </a> */}
                </div>
              </Link>
              <div className="btn btn-save" onClick={uploadFile}>
                {/* <a href="#"> */}
                <img
                  src="/images/icon/import.svg"
                  style={{ marginRight: "10px" }}
                  width={25}
                  height={25}
                />
                Import
                {/* </a> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
