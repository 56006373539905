import React from "react";
import _ from "lodash";
import en from "date-fns/locale/en-GB";

import { registerLocale } from "react-datepicker";
import { useSelector, useDispatch } from "react-redux";
import { Select, Modal, Tabs } from "antd";

import {
  getPTTGeometryBoundary,
  selectPTTGeometryBoundary,
} from "reducers/mapMonitoring";

import initSearchGeometry from "./searchGeometry";

import { THS_API } from "../../../constants";

import "./index.css";

const FilterBoxboundary = ({
  map,
  view,
  isModalVisibleSearch,
  setIsModalVisibleSearch,
}: any) => {
  const [dateType, setDateType] = React.useState("time");

  const [locationSelectVal, setLocationSelectVal] = React.useState("");
  const [chosenLocationBoxName, setChosenLocationBoxName] = React.useState("");
  const [subLocationSelectVal, setSubLocationSelectVal] = React.useState("");
  const [chosenSubLocationBoxName, setChosenSubLocationBoxName] =
    React.useState("");
  // const [zoneSelectFilter, setZoneSelectFilter] = React.useState<string>(
  //   "พื้นที่กระบวนการผลิต"
  // );
  // const [locationSelectFilter, setLocationSelectFilter] =
  //   React.useState<string>("");
  // const [subLocationSelectFilter, setSubLocationSelectFilter] =
  //   React.useState<string>("");

  const [locationSelectBox, setLocationSelectBox] = React.useState([]);
  const [subLocationSelectBox, setSubLocationSelectBox] = React.useState([]);

  const pttGeometry = useSelector(selectPTTGeometryBoundary);

  registerLocale("el", en);
  const dispatch = useDispatch<any>();

  const handleCancel = () => {
    setIsModalVisibleSearch(false);
  };

  const funcSubLocation = (e: any) => {
    const value = e;

    if (!e) {
      setSubLocationSelectVal("");
    } else {
      const subLocation = value.split("|")[0];
      const valueData = JSON.parse(value.split("|")[1]);
      const subName = value.split("|")[2];
      const center: any = [];
      let subArr: any = [];
      valueData.map((point: any, index: number) => {
        if (index % 2 === 0) subArr.push(point);
        else {
          subArr.push(point);
          center.push(subArr);
          subArr = [];
        }
        return null;
      });

      setSubLocationSelectVal(subLocation);
      setChosenSubLocationBoxName(subName);
    }
  };

  const funcLocation = (e: any) => {
    const value = e;

    setSubLocationSelectVal("");

    if (!e) {
      setLocationSelectVal("");
      setSubLocationSelectBox([]);
    } else {
      setChosenSubLocationBoxName("");
      const location = value.split("|")[0];
      const valueData = JSON.parse(value.split("|")[1]);
      const name = value.split("|")[2];
      const center: any = [];
      let subArr: any = [];
      valueData.map((point: any, index: number) => {
        if (index % 2 === 0) subArr.push(point);
        else {
          subArr.push(point);
          center.push(subArr);
          subArr = [];
        }
        return null;
      });

      const subLocation = _.filter(
        locationSelectBox,
        (o: any) => location === o.unitid
      )[0]?.sub_location;

      setLocationSelectVal(location);
      setChosenLocationBoxName(name);
      setSubLocationSelectBox(subLocation);
    }
  };

  const handleSearchMapMonitoring = async () => {
    if (dateType === "time") {
      //  setZoneSelectFilter("พื้นที่กระบวนการผลิต");
      //  setLocationSelectFilter(locationSelectVal);
      //  setSubLocationSelectFilter(subLocationSelectVal);
    }

    setIsModalVisibleSearch(false);

    const input: any = {
      zone: "พื้นที่กระบวนการผลิต",
      unitid: locationSelectVal,
      subunitid: subLocationSelectVal,
    };
    dispatch(getPTTGeometryBoundary(input));
  };

  React.useEffect(() => {
    const funcFilter = async () => {
      const res = await THS_API.get("/map-monitoring/filter");

      const { location } = _.filter(
        res.data.data,
        (o: any) => "พื้นที่กระบวนการผลิต" === o.name
      )[0];

      setLocationSelectBox(location);
    };

    funcFilter();
  }, []);

  React.useEffect(() => {
    const callApi = async () => {
      if (pttGeometry.length) {
        const polygonSearchTextLayer = map?.findLayerById(
          "ptt-geometry-search-layer-text"
        );
        polygonSearchTextLayer?.removeAll();

        if (view) {
          const target = await initSearchGeometry({
            pttGeometry,
            polygonSearchTextLayer,
          });

          view?.goTo({
            target,
          });
        }
      }
    };
    callApi();
  }, [map, pttGeometry, view]);

  // React.useEffect(() => {
  //   const callApi = async () => {
  //     const polygonSearchLayer = map?.findLayerById("active-restrict-layer");

  //     const polygonSearchTextLayer = map?.findLayerById(
  //       "ptt-geometry-search-layer-text"
  //     );
  //     polygonSearchLayer?.removeAll();
  //     polygonSearchTextLayer?.removeAll();
  //     const [Graphic] = await loadModules(["esri/Graphic"]);
  //     pttGeometryAddGraphic({
  //       polygonSearchLayer,
  //       Graphic,
  //       pttGeometry,
  //     });
  //     pttGeometryAddText({
  //       polygonSearchTextLayer,
  //       Graphic,
  //       pttGeometry,
  //     });
  //   };
  //   callApi();
  // }, [pttGeometry]);

  const resetValue = (value: any) => {
    setDateType(value);

    setSubLocationSelectBox([]);
    setChosenLocationBoxName("");
    setChosenSubLocationBoxName("");
    setLocationSelectVal("");
    setSubLocationSelectVal("");
  };

  const handleResetMapMonitoring = () => {
    setSubLocationSelectBox([]);
    setChosenLocationBoxName("");
    setChosenSubLocationBoxName("");
    setLocationSelectVal("");
    setSubLocationSelectVal("");
    // setWorkPermitType(value);
  };

  return (
    <Modal
      className="map-monitoring-modal"
      title={
        <div
          style={{
            width: "700px",
            cursor: "move",
          }}
        />
      }
      open={isModalVisibleSearch}
      onCancel={handleCancel}
    >
      <h3 className="search-modal">ค้นหาพื้นที่</h3>
      <form>
        <Tabs activeKey={dateType} onChange={resetValue}>
          <Tabs.TabPane tab="" key="time">
            <div className="form-group">
              <label htmlFor="campaign" className=" col-form-label">
                เลือกพื้นที่การผลิต
              </label>
            </div>
            <div className="form-group">
              <Select
                className="form-control"
                showSearch
                placeholder="Select location"
                onChange={funcLocation}
                value={chosenLocationBoxName}
              >
                <Select.Option value="">เลือก พื้นที่</Select.Option>
                {locationSelectBox?.map((opt: any, key: number) => {
                  return (
                    <Select.Option
                      key={key}
                      value={`${opt.unitid}|[${opt.shape_geometry[0]}]|${opt.unitname}`}
                    >
                      {opt.unitname}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <div className="form-group">
              <Select
                className="form-control"
                showSearch
                placeholder="Select sub location"
                onChange={funcSubLocation}
                value={chosenSubLocationBoxName}
              >
                <Select.Option value="">เลือก พื้นที่ย่อย</Select.Option>
                {subLocationSelectBox?.map((opt: any, key: number) => (
                  <Select.Option
                    key={key}
                    value={`${opt.subunitid}|[${opt.shape_geometry[0]}]|${opt.subunitname}`}
                  >
                    {opt.subunitname}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Tabs.TabPane>
        </Tabs>
        <div className="row">
          {" "}
          <div className="btn btn-save" onClick={handleSearchMapMonitoring}>
            ค้นหา
          </div>
          <div className="btn btn-save" onClick={handleResetMapMonitoring}>
            ล้าง
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default FilterBoxboundary;
