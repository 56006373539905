import React from "react";
import Swal from "sweetalert2";

import { Link, useNavigate } from "react-router-dom";

import {
  AntdLayout,
  Row,
  Col,
  Form,
  Card,
  Typography,
  Input,
  Button,
} from "@pankod/refine-antd";

import type { IForgotForm } from "./auth";
import "./index.css";

import axios from "axios";
const { Title } = Typography;

const CardDetails = (
  <Title
    level={3}
    className="sub-title"
    style={{ color: "#fff", fontWeight: 300 }}
  >
    โปรดระบุอีเมลที่ต้องการรีเซ็ตรหัสผ่าน
  </Title>
);

const FORGET_API = axios.create({
  baseURL: process.env.REACT_APP_API,
});

export const ForgetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<IForgotForm>();

  const sendEmail = async (email: String) => {
    try {
      const res = await FORGET_API.post("/users/forgot-password", {
        email: email,
      });

      if (res.data.statusCode === 200) {
        Swal.fire({
          text: "ส่งอีเมลเพื่อรีเซ็ตรหัสผ่านถึงคุณเรียบร้อย",
          imageUrl: "correct.svg",
          imageHeight: 70,
          confirmButtonColor: "#1b1464",
          confirmButtonText: "ตกลง",
        }).then((result) => {
          if (result.isConfirmed) navigate("/auth/login");
        });
      }
    } catch (error) {
      Swal.fire({
        text: "อีเมลของคุณไม่มีในระบบ",
        imageUrl: "assets/img/icon/remove.svg",
        imageHeight: 70,
        confirmButtonColor: "#1b1464",
        confirmButtonText: "ตกลง",
      });
    }
  };
  return (
    <AntdLayout className="layout forget-password">
      <Row justify="center" align="middle">
        <Col
          xs={15}
          style={{ justifyContent: "flex-start", alignItems: "start" }}
        >
          <div className="">
            <img width={200} height={92} src="/images/logoptt.png" alt="logo" />
          </div>
        </Col>
        <Col xs={15}>
          <div className="container">
            <Title level={1} className="title font-color txtHeader">
              รีเซ็ตรหัสผ่าน
            </Title>
          </div>
        </Col>
        <Col xs={22}>
          <div className="container box-container">
            <div className="bgForgot">
              <Card
                title={CardDetails}
                headStyle={{ borderBottom: 0 }}
                style={{ background: "transparent", border: 0 }}
              >
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={(form) => {
                    sendEmail(form.email);
                  }}
                  requiredMark={false}
                  initialValues={{
                    remember: false,
                  }}
                >
                  <Form.Item
                    className="email-box"
                    name="email"
                    label={<label style={{ color: "#fff" }}>อีเมล</label>}
                    rules={[{ required: true }]}
                  >
                    <Input
                      size="large"
                      placeholder="example@example.com"
                      style={{
                        borderRadius: 6,
                      }}
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    style={{
                      backgroundColor: "#1b1464",
                      borderColor: "#1b1464",
                      borderRadius: 6,
                      marginBottom: "20px",
                    }}
                  >
                    ดำเนินการต่อ
                  </Button>
                </Form>

                <Link to="/" title="go to login" className="frogot-home">
                  กลับไปสู่หน้าเข้าระบบ
                </Link>
              </Card>
            </div>
          </div>
        </Col>

        <Col xs={15}></Col>
        <Col xs={15}></Col>
      </Row>
    </AntdLayout>
  );
};
