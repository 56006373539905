/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import classnames from "classnames";

import { useSelector, useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

// import { FilterList } from "components/mapMonitoring";
import {
  selectPTTGeometry,
  selectPTTGeometryPast,
  setButtonGroupValue,
  selectMapMonitoring,
} from "reducers/mapMonitoring";
import { selectUser } from "reducers/userReducer";

import "./index.css";
const ButtonGroup = ({
  setIsModalVisible,
  setIsSubmit,
  modalBaseMap,
  setModalBaseMap,
  setModalSearchBounary,
  modalSearchBounary,
  clearMapResult,
  viewMap,
  setViewMap,
  zoomLvl,
  isSubmit,
  // sideBarFilter,
  // setSideBarFilter,
  isModalVisible,
  isFindEmpModal,
  setIsFindEmpModal,
  setMapShipPTT,
  mapShipPTT,
  chosenMap,
  selectedWorkpermit,
  setSelectedWorkpermit,
  isFilterBoxBoundaryActive,
  setIsFilterBoxBoundaryActive,
  setIsClear,
}: any) => {
  const pttGeometry = useSelector(selectPTTGeometry);
  const pttGeometryPast = useSelector(selectPTTGeometryPast);
  const userData = useSelector(selectUser);

  const { buttonGroupValue } = useSelector(selectMapMonitoring);
  const dispatch = useDispatch<any>();

  const [scroll, setScroll] = React.useState<number>(0);

  window.addEventListener("scroll", () => {
    setScroll(window.pageYOffset);
  });

  return (
    <div className="button-group">
      <style>
        {`
        .esri-zoom .esri-widget {
          position:absolute;
        }
        .esri-zoom .esri-widget:first-child {
          top: ${scroll >= 88 ? 88 : scroll}px;
        }
        .esri-zoom .esri-widget:last-child {
          top: ${32 + scroll >= 120 ? 120 : 32 + scroll}px;
        }
        `}
      </style>
      <button
        title="ค้นหาพื้นที่"
        style={{ top: 90 + scroll >= 170 ? 170 : 90 + scroll }}
        className={classnames("search esri-button", {
          active:
            isModalVisible ||
            isSubmit ||
            (pttGeometry.length && chosenMap === "time") ||
            (pttGeometryPast.length && chosenMap === "past"),
        })}
        onClick={() => {
          setIsModalVisible(true);
          setIsSubmit(false);
          setMapShipPTT(false);
          dispatch(
            setButtonGroupValue({
              ...buttonGroupValue,
              userId: userData.id,
              isModalVisible: true,
              isSubmit: false,
            })
          );
        }}
      >
        <FontAwesomeIcon icon={solid("magnifying-glass")} />
      </button>
      {chosenMap !== "period" && (
        <button
          title="ค้นหาอุปกรณ์"
          style={{ top: 123 + scroll >= 203 ? 203 : 123 + scroll }}
          className={classnames("person esri-button", {
            active:
              isFindEmpModal ||
              selectedWorkpermit?.workpermit !== "" ||
              selectedWorkpermit?.supervisor !== "" ||
              selectedWorkpermit?.location?.value !== "" ||
              selectedWorkpermit?.subLocation?.value !== "" ||
              selectedWorkpermit?.company !== "",
          })}
          onClick={() => {
            setIsFindEmpModal(true);
            setIsSubmit(false);
            dispatch(
              setButtonGroupValue({
                ...buttonGroupValue,
                userId: userData.id,
                isSubmit: false,
              })
            );
          }}
        >
          <FontAwesomeIcon icon={solid("person")} />
        </button>
      )}
      <button
        title="เลือกประเภทการแสดงแผนที่"
        style={{ top: 210 + scroll >= 290 ? 290 : 210 + scroll }}
        className={classnames("togle esri-button", {
          active: modalBaseMap || viewMap !== "satellite",
        })}
        onClick={() => {
          setModalBaseMap(true);
          setMapShipPTT(false);
          dispatch(
            setButtonGroupValue({
              ...buttonGroupValue,
              userId: userData.id,
              modalBaseMap: true,
            })
          );
        }}
      >
        <FontAwesomeIcon
          icon={
            viewMap !== "satellite" ? solid("toggle-on") : solid("toggle-off")
          }
        />
      </button>
      {chosenMap === "time" && (
        <button
          title="ค้นหาพื้นที่ห้ามเข้าหรือพื้นที่อพยพ"
          style={{ top: 250 + scroll >= 330 ? 330 : 250 + scroll }}
          className={classnames("searchBoudary esri-button", {
            active:
              modalSearchBounary ||
              isFilterBoxBoundaryActive ||
              buttonGroupValue?.area,
          })}
          onClick={() => {
            setModalSearchBounary(!modalSearchBounary);
            setMapShipPTT(false);

            dispatch(
              setButtonGroupValue({
                ...buttonGroupValue,
                userId: userData.id,
              })
            );
          }}
        >
          <FontAwesomeIcon icon={solid("map-pin")} />
        </button>
      )}
      <button
        title="ล้างข้อมูลการค้นหา"
        style={{ top: 290 + scroll >= 370 ? 370 : 290 + scroll }}
        className="resetMap esri-button"
        onClick={() => {
          clearMapResult();
          setMapShipPTT(false);
          setIsFilterBoxBoundaryActive(false);
          setViewMap("satellite");
          setSelectedWorkpermit({
            workpermit: "",
            supervisor: "",
            location: { value: "", text: "" },
            subLocation: { value: "", text: "" },
            company: "",
          });
          setIsClear(true);
        }}
      >
        <FontAwesomeIcon icon={solid("arrow-rotate-left")} />
      </button>

      <button
        title="แผนที่ ปตท"
        style={{ top: 340 + scroll >= 420 ? 420 : 330 + scroll }}
        className="ship-ptt esri-button"
        onClick={() => {
          setMapShipPTT(!mapShipPTT);
        }}
      >
        <FontAwesomeIcon icon={solid("map")} />
      </button>
      {chosenMap === "past" && (
        <p className="past-map">{`หน้าแผนที่ย้อนหลัง`}</p>
      )}
      <p className="zoom-level">{`Zoom level: ${zoomLvl}`}</p>
      {/* 
      {isSubmit && (
        <>
          {chosenMap !== "period" && (
            <button
              style={{
                top: 18 + scroll >= 217 ? 217 : 18 + scroll,
              }}
              className={classnames("filter-button esri-button", {
                active: sideBarFilter,
              })}
              onClick={() => {
                setSideBarFilter(!sideBarFilter);
              }}
            >
              <FontAwesomeIcon icon={solid("filter")} />
            </button>
          )}
          <FilterList
            sideBarFilter={sideBarFilter}
            setSideBarFilter={setSideBarFilter}
          />
        </>
      )} */}
    </div>
  );
};
export default ButtonGroup;
