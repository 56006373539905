import React from "react";
import classnames from "classnames";

import { Select } from "antd";
import { AntdLayout } from "@pankod/refine-antd";

import DeviceListTable from "./_deviceListTable";
import ChartDevice from "./chartDevice";

import { THS_API } from "../../../constants";

import "./index.css";

export const DeviceList: React.FC = () => {
  const [currentColumn, setCurrentColumn] = React.useState<any>([]);
  const [apiStatus, setApiStatus] = React.useState("start");
  const [query, setQuery] = React.useState([]);

  const [placeholder, setPlaceholder] = React.useState(
    "กรุณาใส่หมายเลขอุปกรณ์"
  );

  const [deviceIDTypeFilter, setDeviceIDTypeFilter] = React.useState("dev_eui");
  const [deviceIDFilter, setDeviceIDFilter] = React.useState("");
  const [statusFilter, setStatusFilter] = React.useState("");
  // const [typeFilter, setTypeFilter] = React.useState("");
  const [, setTypeFilter] = React.useState("");
  const [statusTPE, setStatusTPE] = React.useState([]);
  const [batteryLevelFilter, setBatteryLevelFilter] = React.useState("");
  const [deviceFilter, setDeviceFilter] = React.useState<any>([]);
  const [, setDeviceTotalList] = React.useState<any>([]);
  React.useEffect(() => {
    funcTotalDeviceList();
    funcTPE();
    filterDevice();
  }, []);

  const filterDevice = async () => {
    const res = await THS_API.get("/devices/filter-device");

    setDeviceFilter(res.data.data);
  };
  const funcTotalDeviceList = async () => {
    const res = await THS_API.get("/dashboard/summary-device");

    setDeviceTotalList(res.data.data);
  };
  const funcTPE = async () => {
    const res = await THS_API.get("/base-station");
    setStatusTPE(res.data.data);
  };

  const handleIDType = (event: any) => {
    const { value } = event.target;

    setDeviceIDTypeFilter(value);
    if (value === "dev_eui") {
      setTypeFilter(value);
      setPlaceholder(`กรุณาใส่หมายเลขอุปกรณ์`);
    } else if (value === "wpm_no") {
      setTypeFilter(value);
      setPlaceholder(`กรุณาใส่หมายเลขใบงาน`);
    } else if (value === "occupied_id") {
      setTypeFilter(value);
      setPlaceholder(`กรุณาใส่เลขบัตรประชาชน`);
    }

    setDeviceIDFilter("");
  };

  const handleStatus = (event: any) => {
    const { value } = event.target;
    setStatusFilter(value);
  };

  const handleDeviceID = (event: any) => {
    setDeviceIDFilter(event);
  };
  const handleBatteryLevelFilter = (event: any) => {
    const { value } = event.target;
    setBatteryLevelFilter(value);
  };

  const resetDeviceListFilter = async () => {
    setDeviceIDTypeFilter("dev_eui");
    setDeviceIDFilter("");
    setStatusFilter("");
    setBatteryLevelFilter("");

    const res = await THS_API.get("/devices");
    const { status, data: payload } = res;
    if (status === 200) {
      const { data } = payload;

      setQuery(data);
      setApiStatus("success");
    }
  };

  const deviceAPI = async () => {
    setApiStatus("loading");
    setQuery([]);
    const params: any = {};
    if (statusFilter) params.status = statusFilter;

    if (batteryLevelFilter) {
      const batteryMin = parseInt(batteryLevelFilter.split("-")[0]);
      const batteryMax = parseInt(batteryLevelFilter.split("-")[1]);

      params.battery_max = batteryMax;
      params.battery_min = batteryMin;
    }
    if (deviceIDFilter) {
      params[deviceIDTypeFilter] = deviceIDFilter;
    }

    const res = await THS_API.get("/devices", {
      params,
    });
    const { status, data: payload } = res;
    if (status === 200) {
      const { data } = payload;
      setQuery(data);
      setApiStatus("success");
    }
  };
  const handleDeviceListFilter = () => {
    deviceAPI();
  };

  const useDeviceAPI = () => {
    React.useEffect(() => {
      deviceAPI();
    }, []);
  };
  useDeviceAPI();
  return (
    <AntdLayout className="layout device">
      <div>
        <div className="header-topic">
          <h1>รายการอุปกรณ์</h1>
        </div>
        <div className="grid-story">
          <div className="row">
            <div className="col-sm-4 ">
              <div className="card-body ">
                <ChartDevice />
              </div>
            </div>
            <div className="col-sm-4 ">
              <div className="card-body ">
                <div className="status-tpe grid-story2">
                  {statusTPE?.map((TPE: any, key: any) => {
                    const { name, administrationState } = TPE;
                    return (
                      <>
                        {(administrationState === "SUSPENDED" || !TPE) && (
                          <div className={`card-body ${key}`}>
                            <img
                              className="icon"
                              src="/images/icon/offline.svg"
                              alt=""
                            />
                            <label className="form-label">{name || 0}</label>
                          </div>
                        )}
                        {administrationState === "ACTIVE" && (
                          <div className={`card-body ${key}`}>
                            <img
                              className="icon"
                              src="/images/icon/online.svg"
                              alt=""
                            />
                            <label className="form-label">{name}</label>
                          </div>
                        )}
                        {administrationState === "VALIDATING" && (
                          <div className={`card-body ${key}`}>
                            <img
                              className="icon"
                              src="/images/icon/warning.svg"
                              alt=""
                            />
                            <label className="form-label">{name}</label>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-sm-4 ">
              <div className="card-body">
                <h4 className="find-device-header">ค้นหาอุปกรณ์</h4>
                <div className="grid-story2" style={{ marginTop: "45px" }}>
                  <div className="form-group">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="form-label"
                      ></label>
                      <select
                        className="form-control"
                        onChange={handleIDType}
                        value={deviceIDTypeFilter}
                        id="#"
                      >
                        <option value="dev_eui">หมายเลขอุปกรณ์</option>
                        <option value="wpm_no">หมายเลขใบงาน </option>
                        <option value="occupied_id">เลขบัตรประชาชน</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="mb-3">
                      {deviceIDTypeFilter === "dev_eui" && (
                        <Select
                          className="form-control searchdevice"
                          id="#"
                          showSearch
                          placeholder={placeholder}
                          value={deviceIDFilter}
                          onChange={handleDeviceID}
                        >
                          <Select.Option value="">
                            กรุณาเลือกหมายเลขอุปกรณ์
                          </Select.Option>
                          {deviceFilter?.device?.map(
                            (device: any, key: number) => {
                              const { dev_eui } = device;

                              return (
                                <Select.Option key={key} value={`${dev_eui}`}>
                                  {`${dev_eui}`}
                                </Select.Option>
                              );
                            }
                          )}
                        </Select>
                      )}
                      {deviceIDTypeFilter === "wpm_no" && (
                        <Select
                          className="form-control searchdevice"
                          id="#"
                          showSearch
                          placeholder={placeholder}
                          value={deviceIDFilter}
                          onChange={handleDeviceID}
                        >
                          <Select.Option value="">
                            กรุณาเลือกหมายเลขใบอนุญาตทำงาน
                          </Select.Option>
                          {deviceFilter?.wpm?.map((wpm: any, key: number) => {
                            const { work_permit_no } = wpm;

                            return (
                              <Select.Option
                                key={key}
                                value={`${work_permit_no}`}
                              >
                                {`${work_permit_no}`}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      )}
                      {deviceIDTypeFilter === "occupied_id" && (
                        <Select
                          className="form-control searchdevice"
                          id="#"
                          showSearch
                          placeholder={placeholder}
                          value={deviceIDFilter}
                          onChange={handleDeviceID}
                        >
                          <Select.Option value="">
                            กรุณาเลือกหมายเลขบัตรประชาชน
                          </Select.Option>
                          {deviceFilter?.citizen_id?.map(
                            (citizen_id: any, key: number) => {
                              const { cid } = citizen_id;

                              return (
                                <Select.Option key={key} value={`${cid}`}>
                                  {`${cid}`}
                                </Select.Option>
                              );
                            }
                          )}
                        </Select>
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="form-label"
                      ></label>
                      <select
                        value={statusFilter}
                        onChange={handleStatus}
                        className="form-control"
                        id="#"
                      >
                        <option value=""> สถานะ</option>
                        <option value="Online">พร้อมใช้งาน</option>
                        <option value="Occupied">กำลังใช้งาน</option>
                        <option value="Offline">ไม่พร้อมใช้งาน</option>
                        <option value="Broken">อุปกรณ์เสีย</option>
                        <option value="Lost">อุปกรณ์สูญหาย</option>
                        {/* <option>Broken</option> */}
                      </select>
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="form-label"
                      ></label>
                      <select
                        value={batteryLevelFilter}
                        onChange={handleBatteryLevelFilter}
                        className="form-control"
                        id="#"
                      >
                        <option value="">ระดับแบตเตอรี่</option>
                        <option value="1-30">ต่ำ (1-30 %)</option>
                        <option value="31-60">ปานกลาง (31-60%)</option>
                        <option value="61-100">สูง (61-100%)</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="button-box">
                  <div
                    className="btn btn-save"
                    onClick={handleDeviceListFilter}
                  >
                    ค้นหา
                  </div>
                  <div className="btn btn-save" onClick={resetDeviceListFilter}>
                    ล้าง
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="accordion device-detail" id="accordionExample">
          <DeviceListTable
            apiStatus={apiStatus}
            query={query}
            setQuery={setQuery}
            expandRowByClick={true}
            expandedRowKeys={currentColumn}
            onExpand={(expanded: boolean, record: any) => {
              if (expanded) setCurrentColumn([record.id]);
              else setCurrentColumn([]);
            }}
            onChange={() => setCurrentColumn([])}
            rowKey={({ id }: any) => id}
            expandable={{
              expandedRowRender: (record: any) => (
                <div
                  className={classnames("accordion-collapse collapse show")}
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="row">
                      <div className="col-sm-2">
                        <h1>ใบอนุญาตทำงาน</h1>
                        <p>{record.work_permit_no}</p>
                      </div>
                      <div className="col-sm-2">
                        <h1>ชื่อ-นามสกุล</h1>
                        <p>
                          {record.firstname} {record.lastname}
                        </p>
                      </div>
                      <div className="col-sm-2">
                        <div>
                          <h1> เลขบัตรประชาชน</h1>
                          <p>{record.occupied_id}</p>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <h1>ตำแหน่ง</h1>
                        <p>{record.job}</p>
                      </div>
                      <div className="col-sm-2">
                        <h1>บริษัท</h1>
                        <p>{record.company_name}</p>
                      </div>

                      <div className="col-sm-2">
                        <h1>ชื่อ-นามสกุล ผู้ควบคุมงาน</h1>
                        <p>
                          {record.supervisor_f_name} {record.supervisor_l_name}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ),
            }}
          />
        </div>
      </div>
    </AntdLayout>
  );
};
