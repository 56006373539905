/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Swal from "sweetalert2";

import { useSelector } from "react-redux";

import { selectDashboard, setDashboard } from "reducers/dashboard";

import { THS_API } from "../../../constants";

const useChartZone = ({ dispatch, setDataZone }: any) => {
  const { isClick, dataDrilldown, dataPlantName, dataPlantTotal }: any =
    useSelector(selectDashboard);

  React.useEffect(() => {
    const setChartZone = async () => {
      if (isClick) {
        const params = {
          unitid: dataDrilldown,
        };
        Swal.showLoading();
        const res = await THS_API.get("dashboard/map-graph-activeuser/plant", {
          params,
        });
        const dataZone = res.data.data;
        let payload = dataZone?.chart?.series[0].data;
        let counter = 0;
        for (let i = 0; i < payload.length; i++) {
          counter = counter + payload[i].y;
        }
        let countTotal = dataPlantTotal - counter;
        const plant = {
          name: dataPlantName,
          y: countTotal,
        };
        dataZone?.chart?.series[0].data.push(plant);

        setDataZone(dataZone);
        dispatch(
          setDashboard({
            dataDrilldown,
            dataPlantName,
            dataPlantTotal,
            isClick: false,
          })
        );
        Swal.close();
      }
    };
    setChartZone();
  }, [isClick, setDataZone]);
};

export default useChartZone;
