import React from "react";
import Swal from "sweetalert2";

import { useNavigate } from "react-router-dom";
import {
  AntdLayout,
  Row,
  Col,
  Form,
  Card,
  Typography,
  Input,
  Button,
} from "@pankod/refine-antd";

import type { IResetForm, IResetObject } from "./auth";

import axios from "axios";

import "./index.css";

const { Title } = Typography;
const CardDetails = (
  <Title
    level={3}
    className="sub-title"
    style={{ color: "#fff", fontWeight: 300 }}
  >
    โปรดระบุรหัสที่ต้องการรีเซ็ตรหัสผ่าน
  </Title>
);

const urlSearchParams = new URLSearchParams(window.location.search);
const params = Object.fromEntries(urlSearchParams.entries());

const AUTH_SERVICE = axios.create({
  baseURL: process.env.REACT_APP_API,
});

export const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<IResetForm>();
  const [password, setPassword] = React.useState("");
  form.setFieldsValue({ code: params.code });

  const resetPass = async (form: IResetObject) => {
    const { password, passwordConfirmation } = form;
    if (password === "") {
      Swal.fire({
        icon: "info",
        title: "กรุณาใส่ รหัสใหม่",
      });
    } else {
      if (passwordConfirmation === "") {
        Swal.fire({
          icon: "info",
          title: "กรุณาใส่ รหัสยืนยัน",
        });
      } else {
        if (password !== passwordConfirmation) {
          Swal.fire({
            icon: "info",
            title: "รหัสไม่ตรงกัน กรุณาใส่รหัสใหม่",
          });
        } else {
          try {
            let token = window.location.search.substring(1).split("=");

            const key = String(token[1]);

            const res = await AUTH_SERVICE.post("/auth/login", {
              password,
              key,
            });
            if (res.data.statusCode === 200) {
              Swal.fire({
                icon: "success",
                title: "เปลี่ยนรหัสสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              });
              navigate("/auth/login");
            }
          } catch (error) {
            alert("เกิดข้อผิดพลาดของระบบ");
          }
        }
      }
    }
  };

  return (
    <AntdLayout className="layout reset-password">
      <Row justify="center" align="middle">
        <Col
          xs={15}
          style={{ justifyContent: "flex-start", alignItems: "start" }}
        >
          <div className="">
            <img width={200} height={92} src="/images/logoptt.png" alt="logo" />
          </div>
        </Col>
        <Col xs={22}>
          <div className="container" style={{ maxWidth: 900 }}>
            <div className="bgForgot">
              <Card
                title={CardDetails}
                headStyle={{ borderBottom: 0 }}
                style={{ background: "transparent", border: 0 }}
              >
                <Form<IResetForm>
                  layout="vertical"
                  form={form}
                  onFinish={async (form) => {
                    resetPass(form);
                  }}
                  requiredMark={false}
                  initialValues={{
                    remember: false,
                  }}
                >
                  <Form.Item
                    className="password-box"
                    name="password"
                    label={
                      <label style={{ color: "#fff" }}>รหัสผ่านใหม่</label>
                    }
                    rules={[{ required: true }]}
                  >
                    <Input.Password
                      size="large"
                      placeholder="●●●●●●●●"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    className="password-box"
                    name="passwordConfirmation"
                    label={
                      <label style={{ color: "#fff" }}>ยืนยันรหัสผ่าน</label>
                    }
                    rules={[
                      { required: true },
                      {
                        validator: async (_, confirmPass) => {
                          if (password !== confirmPass) {
                            return Promise.reject(
                              new Error(
                                "Password is not same as confirm password"
                              )
                            );
                          }
                        },
                      },
                    ]}
                    style={{ marginBottom: "12px" }}
                  >
                    <Input.Password size="large" placeholder="●●●●●●●●" />
                  </Form.Item>

                  <Form.Item name="code" style={{ display: "none" }}>
                    <Input type="hidden" />
                  </Form.Item>
                  <Button
                    className="submit-button"
                    type="primary"
                    size="large"
                    htmlType="submit"
                  >
                    ดำเนินการต่อ
                  </Button>
                </Form>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </AntdLayout>
  );
};
