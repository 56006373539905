import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  filterBoxDefaultValue,
  buttonGroupDefaultValue,
} from "./_mapMonitoringObj";
import { THS_API } from "../constants";

const initialState = {
  positionApiStatus: "idle",
  mapApiStatus: "idle",
  mapInitApiStatus: "idle",
  mapBoundaryApiStatus: "idle",
  pttEmployee: [],
  pttEmployeePast: [],
  restrictArea: [],
  pttGeometry: [],
  pttGeometryPast: [],
  pttGeometryBoundary: [],
  filter: {
    zone: [],
  },
  isFilterList: false,
  filterBoxValue: filterBoxDefaultValue,
  buttonGroupValue: buttonGroupDefaultValue,
  isSaveMonitoringLoaded: false,
};

export const setFilterButtonClear = createAsyncThunk(
  "mapMonitoring/setFilterButtonClearAPI",
  async (input: any) => {
    const { userid } = input;
    const response = await THS_API.delete(`/save-monitoring/${userid}`, {
      data: {},
    });

    return response.data.data;
  }
);

export const getMapMonitoring = createAsyncThunk(
  "mapMonitoring/getMapMonitoringAPI",
  async (input: any) => {
    const { userid } = input;
    const params: any = {
      userid,
    };

    const response = await THS_API.get("/save-monitoring", {
      params,
    });

    return {
      ...response.data.data,
      button: {
        ...response.data.data.button,
        modalBaseMap: false,
      },
    };
  }
);
export const setButtonGroupValue = createAsyncThunk(
  "mapMonitoring/setButtonGroupValueAPI",
  async (button: any, { getState }) => {
    const userid = button.userId;
    delete button.userId;

    const state: any = getState();

    const { filterBoxValue: filterbox } = state.mapMonitoring;
    const params: any = {
      userid,
      filterbox,
      button,
    };

    const response = await THS_API.post("/save-monitoring", {
      ...params,
    });

    return response.data.data;
  }
);

export const setFilterBoxValue = createAsyncThunk(
  "mapMonitoring/setFilterBoxValueAPI",
  async (filterbox: any, { getState }) => {
    const userid = filterbox.userId;
    delete filterbox.userId;
    const state: any = getState();

    const { buttonGroupValue: button } = state.mapMonitoring;
    const params: any = {
      userid,
      filterbox,
      button,
    };

    const response = await THS_API.post("/save-monitoring", {
      ...params,
    });

    return response.data.data;
  }
);

export const getPTTGeometry = createAsyncThunk(
  "mapMonitoring/getPTTGeometryAPI",
  async (input: any) => {
    const { zone, unitid, subunitid } = input;
    const params: any = {
      check_owner: true,
    };

    if (zone) params.zone = zone;
    if (unitid) params.unitid = unitid;
    if (subunitid) params.subunitid = subunitid;

    const response = await THS_API.get("/map-monitoring/map", {
      params,
    });

    return response.data.data.map((a: any) => {
      return {
        display: true,
        ...a,
      };
    });
  }
);

export const getPTTGeometryInit = createAsyncThunk(
  "mapMonitoring/getPTTGeometryInitAPI",
  async (input: any) => {
    const { zone, unitid, subunitid } = input;
    const params: any = {
      check_owner: true,
    };

    if (zone) params.zone = zone;
    if (unitid) params.unitid = unitid;
    if (subunitid) params.subunitid = subunitid;

    const response = await THS_API.get("/map-monitoring/map", {
      params,
    });

    return response.data.data.map((a: any) => {
      return {
        display: true,
        ...a,
      };
    });
  }
);

export const getPTTGeometryPast = createAsyncThunk(
  "mapMonitoring/getPTTGeometryPastAPI",
  async (input: any) => {
    const { unitid, subunitid, startTime, startDate } = input;
    const params: any = {
      check_owner: "true",
    };

    if (unitid) params.unitid = unitid;
    if (subunitid) params.subunitid = subunitid;
    if (startTime) params.start_time = startTime;
    if (startDate) params.start_date = startDate;

    const response = await THS_API.get("/map-monitoring/mappass", {
      params,
    });

    return response.data.data.map((a: any) => {
      return {
        display: true,
        ...a,
      };
    });
  }
);

export const getPTTGeometryPastInit = createAsyncThunk(
  "mapMonitoring/getPTTGeometryPastInitAPI",
  async (input: any) => {
    const { unitid, subunitid, startTime, startDate } = input;
    const params: any = {
      check_owner: "true",
    };

    if (unitid) params.unitid = unitid;
    if (subunitid) params.subunitid = subunitid;
    if (startTime) params.start_time = startTime;
    if (startDate) params.start_date = startDate;

    const response = await THS_API.get("/map-monitoring/mappass", {
      params,
    });

    return response.data.data.map((a: any) => {
      return {
        display: true,
        ...a,
      };
    });
  }
);

export const getPTTGeometryBoundary: any = createAsyncThunk(
  "mapMonitoring/getPTTGeometryBoundaryAPI",
  async (input: any) => {
    const { zone, unitid, subunitid } = input;
    const params: any = {
      check_owner: true,
    };

    if (zone) params.zone = zone;
    if (unitid) params.unitid = unitid;
    if (subunitid) params.subunitid = subunitid;

    const response = await THS_API.get("/map-monitoring/map", {
      params,
    });

    return response.data.data.map((a: any) => {
      return {
        display: true,
        ...a,
      };
    });
  }
);

const getFilter = (mapMonitoringArray: any) => {
  if (mapMonitoringArray.length) {
    const order = ["zone", "unitname", "subunitname"];
    const result: any = [];
    const levels = { result };

    mapMonitoringArray?.forEach((o: any) => {
      order.reduce((r: any, e: any) => {
        const name = o[e];

        let filterVal = o.zone;
        if (e === "unitname") filterVal = o.unitid;
        else if (e === "subunitname") filterVal = o.subunitid;
        if (!r[name]) {
          const value = { name, filterVal, display: o.display, children: [] };
          r[name] = { result: value.children };
          r.result.push(value);
        }

        return r[name];
      }, levels);
    });
    return result;
  }
  return null;
};

const mapMonitoringSlice = createSlice({
  name: "mapMonitoring",
  initialState,
  reducers: {
    setIsFilterList: (state, action) => {
      state.isFilterList = action.payload;
    },
    setEmployee: (state, action) => {
      state.pttEmployee = action.payload;
    },
    setRestrictArea: (state, action) => {
      state.restrictArea = action.payload;
    },
    setEmployeePast: (state, action) => {
      state.pttEmployeePast = action.payload;
    },
    setPttGeometry: (state, action) => {
      state.pttGeometry = action.payload;
    },
    setPttGeometryBoundary: (state, action) => {
      state.pttGeometryBoundary = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPTTGeometry.pending, (state) => {
        state.mapApiStatus = "loading";
      })
      .addCase(getPTTGeometry.fulfilled, (state, action) => {
        state.mapApiStatus = "done";
        state.pttGeometry = action.payload;
        state.filter = getFilter(action.payload);
      })
      .addCase(getPTTGeometry.rejected, (state) => {
        state.mapApiStatus = "done";
      })
      .addCase(getPTTGeometryPast.pending, (state) => {
        state.mapInitApiStatus = "loading";
      })
      .addCase(getPTTGeometryPast.fulfilled, (state, action) => {
        state.mapInitApiStatus = "done";
        state.pttGeometryPast = action.payload;
        state.filter = getFilter(action.payload);
      })
      .addCase(getPTTGeometryPast.rejected, (state) => {
        state.mapInitApiStatus = "done";
      })
      .addCase(getPTTGeometryInit.pending, (state) => {
        state.mapInitApiStatus = "loading";
      })
      .addCase(getPTTGeometryInit.fulfilled, (state, action) => {
        state.mapInitApiStatus = "done";
        state.pttGeometry = action.payload;
        state.filter = getFilter(action.payload);
      })
      .addCase(getPTTGeometryInit.rejected, (state) => {
        state.mapInitApiStatus = "done";
      })
      .addCase(getPTTGeometryPastInit.pending, (state) => {
        state.mapInitApiStatus = "loading";
      })
      .addCase(getPTTGeometryPastInit.fulfilled, (state, action) => {
        state.mapInitApiStatus = "done";
        state.pttGeometryPast = action.payload;
        state.filter = getFilter(action.payload);
      })
      .addCase(getPTTGeometryPastInit.rejected, (state) => {
        state.mapInitApiStatus = "done";
      })
      .addCase(getPTTGeometryBoundary.pending, (state) => {
        state.mapBoundaryApiStatus = "loading";
      })
      .addCase(getPTTGeometryBoundary.fulfilled, (state, action) => {
        state.mapBoundaryApiStatus = "done";
        state.pttGeometryBoundary = action.payload;
        state.filter = getFilter(action.payload);
      })
      .addCase(getPTTGeometryBoundary.rejected, (state) => {
        state.mapBoundaryApiStatus = "done";
      })
      .addCase(getMapMonitoring.fulfilled, (state, action) => {
        const { button, filterbox } = action.payload;

        state.filterBoxValue = {
          ...filterBoxDefaultValue,
          ...filterbox,
        };
        state.buttonGroupValue = {
          ...buttonGroupDefaultValue,
          ...button,
        };
        state.isSaveMonitoringLoaded = true;
      })
      .addCase(getMapMonitoring.rejected, (state) => {
        state.filterBoxValue = filterBoxDefaultValue;
        state.buttonGroupValue = buttonGroupDefaultValue;
        state.isSaveMonitoringLoaded = true;
      })

      .addCase(setFilterBoxValue.fulfilled, (state, action) => {
        const { button, filterbox } = action.payload;

        state.filterBoxValue = filterbox;
        state.buttonGroupValue = button;
      })

      .addCase(setButtonGroupValue.fulfilled, (state, action) => {
        const { button, filterbox } = action.payload;

        state.filterBoxValue = filterbox;
        state.buttonGroupValue = button;
      })
      .addCase(setFilterButtonClear.fulfilled, (state) => {
        state.filterBoxValue = filterBoxDefaultValue;
        state.buttonGroupValue = buttonGroupDefaultValue;
        state.pttGeometry = [];
      });
  },
});

export const selectMapMonitoring = (state: any) => state.mapMonitoring;

export const selectPTTGeometry = (state: any) =>
  state.mapMonitoring.pttGeometry;
export const selectPTTGeometryPast = (state: any) =>
  state.mapMonitoring.pttGeometryPast;
export const selectPTTGeometryBoundary = (state: any) =>
  state.mapMonitoring.pttGeometryBoundary;

export const {
  setIsFilterList,
  setEmployee,
  setEmployeePast,
  setPttGeometry,
  setPttGeometryBoundary,
  setFilter,
  setRestrictArea,
} = mapMonitoringSlice.actions;

export default mapMonitoringSlice.reducer;
