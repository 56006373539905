import { Table } from "antd";
import "./index.css";

const SortingTable = ({ apiStatus, query, ...props }: any) => {
  if (apiStatus === "success")
    return (
      <Table
        {...props}
        className="report-table table table-striped table-responsive"
        dataSource={query}
      />
    );
  return (
    <div className="report-table loading">
      <img src="/images/icon/loading-icon.gif" alt="loading" />
    </div>
  );
};
export default SortingTable;
