import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationNum: 0,
  notification: [],
  chartNotification: [],
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
    setNotificationNum: (state, action) => {
      state.notificationNum = action.payload;
    },
    setChartNotification: (state, action) => {
      state.chartNotification = action.payload;
    },
  },
});

export const selectNotification = (state) => state.notification.notification;

export const selectChartNotification = (state) =>
  state.notification.chartNotification;
export const { setNotification, setChartNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;
