import DatePicker, { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-GB";
export default function EndDateFrom({ startDate, endDate, ...rest }) {
  registerLocale("el", en);
  return (
    <DatePicker
      className="form-control"
      selected={endDate}
      maxDate={new Date()}
      locale="el"
      dateFormat="P"
      minDate={startDate}
      {...rest}
    />
  );
}
