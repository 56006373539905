import { loadModules } from "esri-loader";

const createGeometry = ({ vertices }: any) => {
  return {
    type: "polygon",
    rings: vertices,
  };
};

const orderedPttGeometry = (pttGeometry: any) => {
  return pttGeometry
    .filter((polygon: any) => polygon.display)
    .map((polygon: any) => {
      const arrOrder: any = {
        AREA: 1,
        BUILDING: 2,
        PLANT: 0,
      };
      return { ...polygon, order: arrOrder[polygon.layer_name] };
    })

    .sort((a: any, b: any) => a.order - b.order);
};

const initSearchGeometryText = async ({
  pttSearchGeometryTextLayer,
  pttGeometry,
  mapZoom,
}: any) => {
  const [Graphic] = await loadModules(["esri/Graphic"]);

  const orderGeometry = orderedPttGeometry(pttGeometry);
  orderGeometry.map((polygon: any) => {
    const { shape_geometry, layer_name, unitname, subunitname, active } =
      polygon;

    const vertices = shape_geometry;

    let polygonTxt;
    if (layer_name === "PLANT") polygonTxt = unitname;
    else if (layer_name === "AREA") polygonTxt = subunitname;

    let fontSize;
    let total = 0;

    if (
      (mapZoom >= 14 && mapZoom <= 19 && layer_name === "PLANT") ||
      (mapZoom > 16 && mapZoom <= 21 && layer_name === "AREA")
    ) {
      fontSize = 10;
      const backgroundText = polygonTxt.length;
      total = backgroundText * 10 + 20;
    }

    const textSymbol = {
      type: "text", // autocasts as new TextSymbol()
      color: "white",
      haloColor: "black",
      haloSize: "5px",
      text: `${polygonTxt} : ${active}`,
      xoffset: 0,
      yoffset: -3,
      font: {
        // autocast as new Font()
        size: fontSize,
        family: "sans-serif",
        weight: "bold",
      },
    };
    let symbolBackground = {
      type: "picture-marker", // autocasts as new PictureFillSymbol()
      url: "./images/black-color.jpg",
      width: `${total}px`,
      height: "25px",
      outline: {
        style: "solid",
      },
    };
    const geometry = createGeometry({ vertices });
    const blackGround = new Graphic({
      geometry,
      symbol: symbolBackground,
    });

    const newDevelopmentGraphicText = new Graphic({
      geometry,
      symbol: textSymbol,
    });

    pttSearchGeometryTextLayer?.addMany([
      blackGround,
      newDevelopmentGraphicText,
    ]);
    return null;
  });
};
export default initSearchGeometryText;
