import axios, { AxiosRequestConfig } from "axios";
import { SocketContext, socket } from "context/socket";

import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-antd";

import routerProvider from "@pankod/refine-react-router-v6";

import notification from "pages/notification";
import importDevice from "pages/importDevice";

import { Dashboard } from "pages/dashboard";
import { DeviceList } from "pages/devices";
import { ForgetPassword } from "pages/forgetPassword";
import { Login } from "pages/login";
import { Callback } from "pages/callback";
import { MapBoundary } from "pages/mapBoundary";
import { MapMonitoring } from "pages/mapMonitoring";
import { MapMonitoringV2 } from "pages/mapMonitoringV2";
import { ResetPassword } from "pages/resetPassword";
import { RoleList, RoleEdit, RoleCreate } from "pages/role";
import { WorkPermit } from "pages/workPermit";
import { ReportList } from "pages/report";
import Profile from "pages/profile";
import ChangePassWord from "pages/changePassword";
import { EventLog } from "pages/eventLog";
import {
  Title,
  Header,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";

import { authProvider } from "./authProvider";
import { dataProvider } from "./dataProvider";
import { THS_TOKEN_KEY } from "./constants";

import "styles/antd.less";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
  const token = localStorage.getItem(THS_TOKEN_KEY);
  if (token) {
    if (request.headers) {
      request.headers["Authorization"] = `Bearer ${token}`;
    } else {
      request.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
  }

  return request;
});

const resources = [
  {
    name: "dashboard",
    list: Dashboard,
  },
  {
    name: "map-monitoring",
    list: MapMonitoring,
  },
  {
    name: "map-monitoring-v2",
    list: MapMonitoringV2,
  },
  {
    name: "map-boundary",
    list: MapBoundary,
  },
  {
    name: "devices",
    list: DeviceList,
  },
  {
    name: "notification",
    list: notification,
  },
  {
    name: "import-device",
    list: importDevice,
  },
  {
    name: "role",
    list: RoleList,
    edit: RoleEdit,
    create: RoleCreate,
  },
  {
    name: "work-permit",
    list: WorkPermit,
  },
  {
    name: "report",
    list: ReportList,
  },
  {
    name: "event-log",
    list: EventLog,
  },
  {
    name: "profile",
    list: Profile,
  },
  {
    name: "change-password",
    list: ChangePassWord,
  },
];

function App() {
  return (
    <SocketContext.Provider value={socket}>
      <Refine
        notificationProvider={notificationProvider}
        ReadyPage={ReadyPage}
        catchAll={<ErrorComponent />}
        routerProvider={{
          ...routerProvider,
          routes: [
            {
              element: <Callback />,
              path: "/callback",
            },
            {
              element: <Login />,
              path: "/login",
            },
            {
              element: <ForgetPassword />,
              path: "/forget-password",
            },
            {
              element: <ResetPassword />,
              path: "/reset-password",
            },
          ],
        }}
        dataProvider={dataProvider(axiosInstance)}
        authProvider={authProvider(axiosInstance)}
        LoginPage={Login}
        resources={resources}
        Title={Title}
        Header={Header}
        Footer={Footer}
        Layout={Layout}
        OffLayoutArea={OffLayoutArea}
      />
    </SocketContext.Provider>
  );
}

export default App;
