/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

const useReportListFilterNoti = ({ handleReportListFilterNoti }: any) => {
  React.useEffect(() => {
    handleReportListFilterNoti();
  }, []);
};

export default useReportListFilterNoti;
