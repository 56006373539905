import React from "react";
import moment from "moment";

import { loadModules } from "esri-loader";

import { addGraphicsActive, addGraphics } from "../../mapBoundary";

const useLayerMap = ({ view, polygonArr, mapLayer }: any) => {
  React.useEffect(() => {
    loadModules(["esri/Graphic"])
      .then(([Graphic]) => {
        const active = mapLayer?.findLayerById("active-restrict-layer");
        const expired = mapLayer?.findLayerById("expired-restrict-layer");

        if (active && expired && view && polygonArr.length) {
          active?.removeAll();
          expired?.removeAll();
          const filterArr = polygonArr.filter(
            (a: any) => a.checked && a.coordinate.length
          );

          //remove filter function after clear data properly

          const activeArr = filterArr
            .filter((a: any) => !a.expired || a.type === "safety_mustering")
            .map((a: any) => {
              let dataEndDate = moment(a.updated).format("DD/MM/YYYY:HH.mm");

              return { ...a, updated: dataEndDate };
            });
          const expiredArr = filterArr
            .filter((a: any) => a.expired && a.type === "restricted_area")
            .map((a: any) => {
              let dataEndDate = moment(a.updated).format("DD/MM/YYYY:HH.mm");

              return { ...a, updated: dataEndDate };
            });

          addGraphicsActive({
            active,
            Graphic,
            polygonArr: activeArr,
          });
          addGraphics({
            expired,
            Graphic,
            polygonArr: expiredArr,
          });
        }
      })
      .catch((err) => {
        // handle any script or module loading errors
        console.error(err);
      });
  }, [view, mapLayer, polygonArr]);
};
export default useLayerMap;
