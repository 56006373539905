import React from "react";

import { setDefaultOptions, loadModules } from "esri-loader";
import { useSelector } from "react-redux";

import {
  selectMapMonitoring,
  selectPTTGeometryPast,
} from "reducers/mapMonitoring";
import { selectUser } from "reducers/userReducer";

import { initMap, initEmployee } from "./_functions";

import "./index.css";

setDefaultOptions({ css: true });
const createSymbol: any = ({ color, style, width, outlineColor }: any) => {
  return {
    type: "simple-fill",
    style,
    color,
    outline: {
      color: outlineColor,
      width,
    },
  };
};

const createGeometry = ({ vertices }: any) => {
  return {
    type: "polygon",
    rings: vertices,
  };
};

const orderedPttGeometry = (pttGeometry: any) => {
  return pttGeometry
    .filter((polygon: any) => polygon.display)
    .map((polygon: any) => {
      const arrOrder: any = {
        AREA: 1,
        BUILDING: 2,
        PLANT: 0,
      };
      return { ...polygon, order: arrOrder[polygon.layer_name] };
    })

    .sort((a: any, b: any) => a.order - b.order);
};

export const pttGeometryAddGraphic = async ({
  pttGeometryLayer = null,
  pttGeometry,
  mapZoom,
}: any) => {
  const [Graphic] = await loadModules(["esri/Graphic"]);
  const ordered = orderedPttGeometry(pttGeometry);

  return ordered.map((polygon: any) => {
    const { layer_name, shape_geometry } = polygon;
    const vertices = shape_geometry;
    const arrColor: any = {
      AREA: [0, 255, 0, 0.3],
      BUILDING: "transparent",
      PLANT: [4, 188, 255, 0.3],
    };

    let color = "transparent";

    if (
      (mapZoom >= 14 && mapZoom <= 19 && layer_name === "PLANT") ||
      (mapZoom > 17 && mapZoom <= 21 && layer_name === "AREA") ||
      (mapZoom > 18 && layer_name === "BUILDING")
    )
      color = arrColor[layer_name];

    const geometry = createGeometry({ vertices });

    const symbol = createSymbol({
      color,
      style: "solid",
      width: 1,
      outlineColor: color,
    });
    const newDevelopmentGraphic = new Graphic({
      geometry,
      symbol,
    });

    pttGeometryLayer?.add(newDevelopmentGraphic);
    return newDevelopmentGraphic;
  });
};

const pttGeometryAddText = async ({
  pttGeometryTextLayer,
  pttGeometry,
  mapZoom,
}: any) => {
  const [Graphic] = await loadModules(["esri/Graphic"]);
  orderedPttGeometry(pttGeometry).map(async (polygon: any) => {
    const { shape_geometry, layer_name, unitname, subunitname, active } =
      polygon;

    const vertices = shape_geometry;

    let polygonTxt;
    if (layer_name === "PLANT") polygonTxt = unitname;
    else if (layer_name === "AREA" || layer_name === "BUILDING")
      polygonTxt = subunitname;

    let fontSize;
    let total = 0;

    if (
      (mapZoom >= 14 && mapZoom <= 19 && layer_name === "PLANT") ||
      (mapZoom > 17 && mapZoom <= 21 && layer_name === "AREA") ||
      (mapZoom > 18 && layer_name === "BUILDING")
    ) {
      fontSize = 10;
      const backgroundtext = polygonTxt.length;
      total = backgroundtext * 10 + 20;
    }

    const textSymbol = {
      type: "text", // autocasts as new TextSymbol()
      color: "white",
      haloColor: "black",
      haloSize: "5px",
      text: `${polygonTxt} : ${active}`,
      xoffset: 0,
      yoffset: -3,
      font: {
        // autocast as new Font()
        size: fontSize,
        family: "sans-serif",
        weight: "bold",
      },
    };
    let symbolbackground = {
      type: "picture-marker", // autocasts as new PictureFillSymbol()
      url: "./images/black-color.jpg",
      width: `${total}px`,
      height: "25px",
      outline: {
        style: "solid",
      },
    };
    const geometry = createGeometry({ vertices });
    const blackground = new Graphic({
      geometry,
      symbol: symbolbackground,
    });

    const newDevelopmentGraphicText = new Graphic({
      geometry,
      symbol: textSymbol,
    });

    pttGeometryTextLayer?.addMany([blackground, newDevelopmentGraphicText]);
    return null;
  });
};

const EmpPastMap = ({
  mapZoom,
  setMapZoom,
  setZoomLvl,
  viewMap,
  pastMap,
  pastView,
  setPastMap,
  setPastView,
  setListPastShipPTT,
  selectedWorkpermit,
}: any) => {
  const { pttEmployeePast } = useSelector(selectMapMonitoring);

  const userData = useSelector(selectUser);

  const pttGeometry = useSelector(selectPTTGeometryPast);
  const mapDiv = React.useRef(null);

  React.useEffect(() => {
    const installMap = async () => {
      const [map, view, layerList] = await initMap({
        mapDiv,
        setMapZoom,
        setZoomLvl,
        viewMap,
        setListPastShipPTT,
        role: userData?.role,
      });
      setPastMap(map);
      setPastView(view);
      setListPastShipPTT(layerList);
    };
    installMap();
  }, [
    setListPastShipPTT,
    setMapZoom,
    setPastMap,
    setPastView,
    setZoomLvl,
    userData?.role,
    viewMap,
  ]);

  React.useEffect(() => {
    if (pastView) {
      pastView?.when(() => {
        const employeeLayer = pastMap?.findLayerById("employee-layer");
        employeeLayer?.removeAll();
        initEmployee({
          employeeLayer,
          pttEmployee: pttEmployeePast,
          selectedWorkpermit,
        });
      });
    }
  }, [pastMap, pastView, pttEmployeePast, selectedWorkpermit]);

  React.useEffect(() => {
    const initPastGeometry = async () => {
      if (pastView) {
        const pttGeometryLayer = pastMap?.findLayerById("ptt-geometry-layer");

        const pttGeometryTextLayer = pastMap?.findLayerById(
          "ptt-geometry-layer-text"
        );

        pttGeometryLayer?.removeAll();
        pttGeometryTextLayer?.removeAll();

        pttGeometryAddGraphic({
          pttGeometry,
          pttGeometryLayer,
          mapZoom,
        });
        pttGeometryAddText({
          pttGeometry,
          pttGeometryTextLayer,
          mapZoom,
        });
      }
    };
    initPastGeometry();
  }, [pastMap, pastView, pttGeometry, mapZoom]);

  return <div className="mapDiv esri-map-past-ssr" ref={mapDiv}></div>;
};
export default EmpPastMap;
