import React from "react";
import Swal from "sweetalert2";

import { loadModules } from "esri-loader";
import { useDispatch } from "react-redux";

import { setMap } from "reducers/mapBoundaryReducers";

import { setUpGraphicClickHandler } from "../../mapBoundary";
import { THS_API } from "../../../../constants";

const createSymbol = ({ color, style, width, outlineColor }: any) => {
  return {
    type: "simple-fill",
    style,
    color,
    outline: {
      color: outlineColor,
      width,
    },
  };
};
const createGeometry = ({ vertices }: any) => {
  return {
    type: "polygon",
    rings: vertices,
  };
};

const chkPolygonIntersect = ({
  restrictArr,
  drawGeometry,
  Graphic,
  geometryEngine,
  webMercatorUtils,
}: any) =>
  restrictArr.some((restrict: any) => {
    const vertices = restrict.coordinate.map((co: any) =>
      webMercatorUtils.lngLatToXY(co[0], co[1])
    );
    const geometry = createGeometry({
      vertices,
    });
    const symbol = createSymbol({
      color: "#FF0000",
      style: "diagonal-cross",
      width: 2,
      outlineColor: "#FF0000",
    });

    const newDevelopmentGraphic = new Graphic({
      geometry,
      symbol,
    });

    const isIntersect = geometryEngine.intersects(
      newDevelopmentGraphic.geometry,
      drawGeometry
    );
    return isIntersect;
  });

const resetMap = async (dispatch: any) => {
  const res = await THS_API.get("/map-boundarys");

  const apiArr = res?.data?.data || [];
  const filterArr = apiArr.map((a: any) => {
    return { ...a, checked: true };
  });
  dispatch(setMap(filterArr));
};
const useInitMap = ({
  mapDiv,
  setView,
  setMapLayer,
  viewMap,
  setDataGeometry,
  setMeasurement,
  setListShipPTT,
}: any) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const deleteMapBoundary = async (id: number) => {
      const mapBoundaryId = `/map-boundarys/${id}`;

      const res = await THS_API.delete(mapBoundaryId);

      if (res.data.statusCode === 200) resetMap(dispatch);
    };
    const funcGeometry = async (data: any, validation: any = {}) => {
      const { isIntersect, evt, sketch } = validation;
      if (!evt.graphics[0].attributes) {
        // if (
        //   !isIntersect &&
        //   evt.graphics[0].geometry &&
        //   (evt.state === "start" ||
        //     (evt.state === "active" &&
        //       (evt.toolEventInfo.type === "scale-stop" ||
        //         evt.toolEventInfo.type === "move-stop")))
        // ) {
        //   // const isIntersect
        //   Swal.fire({
        //     imageUrl: "assets/img/icon/remove.svg",
        //     title: "ห้ามวาดพื้นที่นอกขอบเขต",
        //   }).then((result) => {
        //     if (result.isConfirmed) {
        //       sketch.delete();
        //     }
        //   });

        //   setDataGeometry({
        //     id: "",
        //     uid: "",
        //     ring: [],
        //     isIntersect,
        //     isContained: false,
        //   });
        // }
        if (
          isIntersect &&
          evt.graphics[0].geometry &&
          (evt.state === "start" ||
            (evt.state === "active" &&
              (evt.toolEventInfo.type === "scale-stop" ||
                evt.toolEventInfo.type === "move-stop")))
        ) {
          // const isIntersect
          Swal.fire({
            imageUrl: "assets/img/icon/remove.svg",
            title: "ห้ามวาดเขตซ้อนทับกัน",
          }).then((result) => {
            if (result.isConfirmed) {
              sketch.delete();
            }
          });
          setDataGeometry({
            id: "",
            uid: "",
            ring: [],
            isIntersect: true,
            isContained: false,
          });
        } else if (
          evt.graphics[0].geometry &&
          (evt.state === "start" ||
            (evt.state === "active" &&
              (evt.toolEventInfo.type === "scale-stop" ||
                evt.toolEventInfo.type === "move-stop")))
        ) {
          setDataGeometry(data);
        }
      } else {
        // if (
        //   !isContained &&
        //   evt.graphics[0].geometry &&
        //   evt.state === "complete"
        // ) {
        //   // const isIntersect
        //   Swal.fire({
        //     imageUrl: "assets/img/icon/remove.svg",
        //     title: "ห้ามวาดพื้นที่นอกขอบเขต",
        //   }).then((result) => {
        //     if (result.isConfirmed) resetMap(dispatch);
        //   });

        //   setDataGeometry({
        //     id: "",
        //     uid: "",
        //     ring: [],
        //     isIntersect,
        //     isContained: false,
        //   });
        // }
        if (
          isIntersect &&
          evt.graphics[0].geometry &&
          evt.state === "complete"
        ) {
          // const isIntersect
          Swal.fire({
            imageUrl: "assets/img/icon/remove.svg",
            title: "ห้ามวาดเขตซ้อนทับกัน",
          }).then((result) => {
            if (result.isConfirmed) resetMap(dispatch);
          });

          setDataGeometry({
            id: "",
            uid: "",
            ring: [],
            isIntersect,
            isContained: false,
          });
        } else if (evt.graphics[0].geometry && evt.state === "complete") {
          setDataGeometry(data);
        }
      }
    };
    loadModules([
      "esri/Map",
      "esri/views/MapView",
      "esri/Graphic",
      "esri/layers/GraphicsLayer",
      "esri/widgets/ScaleBar",
      "esri/widgets/Sketch",
      "esri/widgets/Sketch/SketchViewModel",
      "esri/core/urlUtils",
      "esri/layers/MapImageLayer",
      "esri/geometry/geometryEngine",
      "esri/geometry/support/webMercatorUtils",
      "esri/widgets/Measurement",
      "esri/widgets/LayerList",
    ])
      .then(
        ([
          Map,
          MapView,
          Graphic,
          GraphicsLayer,
          ScaleBar,
          Sketch,
          SketchViewModel,
          urlUtils,
          MapImageLayer,
          geometryEngine,
          webMercatorUtils,
          Measurement,
          LayerList,
        ]) => {
          const url = process.env.REACT_APP_MAP_API;
          const proxy = process.env.REACT_APP_MAP_PROXY;
          let layerList;
          const mapServer = `${url}/PTT_SHIP/PTT_SHIP_MAP/MapServer`;
          const proxyUrl = `${proxy}/${btoa(
            "user=trackingmove&system=map"
          )}/api/AppProxy`;

          urlUtils.addProxyRule({
            urlPrefix: url,
            proxyUrl,
          });

          let mapImageLayerIntegrate = new MapImageLayer({
            url: mapServer,
            sublayers: [
              { title: "Plant", id: 9, visible: false },
              {
                title: "Area",
                id: 8,
                visible: false,
              },
              {
                title: "อาคาร",
                id: 7,
                visible: false,
              },
              { title: "จุดจอดรถ", id: 6, visible: false },
              // {
              //   id: 5,
              //   visible: true,
              // },
              { title: "pipe Rack", id: 4, visible: false },
              { title: "pipe Bridge", id: 3, visible: false },
              {
                title: "ถนน",
                id: 2,
                visible: true,
              },
              {
                title: "ป้ายจราจร",
                id: 1,
                visible: false,
              },
              {
                title: "อุปกรณ์",
                id: 0,
                visible: true,
              },
            ],
          });
          const pttResiponsibleGeometryLayer = new GraphicsLayer({
            title: "ptt-responsible-geometry-layer",
            id: "ptt-geometry-search-layer",
          });
          const pttResiponsibleGeometryTextLayer = new GraphicsLayer({
            title: "ptt-responsible-geometry-layer-text",
            id: "ptt-geometry-search-layer-text",
          });
          const activeLayer = new GraphicsLayer({
            id: "active-restrict-layer",
            title: "ขอบเขตการทำงานที่ทำงานอยู่",
          });
          const expiredLayer = new GraphicsLayer({
            id: "expired-restrict-layer",
            title: "ขอบเขตการทำงานที่หมดเวลา",
          });
          const measurement = new Measurement();
          let baseMap = "satellite";
          let minZoom = 17;

          if (viewMap === "satellite") {
            baseMap = "satellite";
            minZoom = 16;
          } else if (viewMap === "streets-vector") {
            baseMap = "streets-vector";
            minZoom = 15;
          } else {
            baseMap = "streets-night-vector";
            minZoom = 15;
          }
          const map = new Map({
            basemap: baseMap,
            layers: [
              mapImageLayerIntegrate,
              activeLayer,
              expiredLayer,
              pttResiponsibleGeometryLayer,
              pttResiponsibleGeometryTextLayer,
            ],
          });
          const view = new MapView({
            map,
            container: mapDiv.current,
            zoom: 16,
            center: [101.14618948093876, 12.71955891000194],
            // scale: 24000,
            constraints: {
              minZoom,
            },
            rotation: 58,
          });
          const polygonSymbol = {
            type: "simple-fill",
            color: [82, 214, 76],
            outline: {
              color: [76, 105, 214],
              width: 3,
            },
          };

          const sketchViewModel = new SketchViewModel({
            view,
            layer: expiredLayer,
            polygonSymbol,
          });

          setUpGraphicClickHandler(view, sketchViewModel);

          layerList = new LayerList({
            view: view,
          });

          view.when(() => {
            const sketch = new Sketch({
              layer: expiredLayer,
              view,

              creationMode: "update",
              availableCreateTools: [
                "polyline",
                "polygon",
                "rectangle",
                "circle",
              ],
            });

            view.ui.add(sketch, "top-right");
            view.ui.add(measurement, "bottom-right");
            measurement.view = view;
            setMeasurement(measurement);

            view.on("click", function (event: any) {
              console.log("map boundary onClick", event);
            });
            view.popup.on("trigger-action", (event: any) => {
              // Execute the measureThis() function if the measure-this action is clicked
              if (event.action.id === "Delete") {
                const id = view.popup.selectedFeature.attributes.id;

                deleteMapBoundary(id);
              }
            });
            sketch.on("create", (event: any) => {
              if (event.state === "complete") {
                if (event.graphic.geometry.type === "polygon") {
                  const { geometry } = event.graphic;

                  const { latitude, longitude } = geometry.centroid;
                  const centroid = [longitude, latitude];

                  const data = {
                    id: "",
                    color: "#428bca",
                    name: "",
                    remark: "",
                    restrict_date_start: "",
                    restrict_date_end: "",
                    restrict_time_start: "",
                    restrict_time_end: "",
                    type: "restricted_area",
                    access_type: "",
                    uid: "",
                    ring: geometry.rings,
                    centroid,
                  };

                  funcGeometry(data);
                } else {
                  const { geometry } = event.graphic;

                  const { latitude, longitude } = geometry.extent.center;
                  const centroid = [longitude, latitude];

                  const data = {
                    id: "",
                    color: "#428bca",
                    name: "",
                    remark: "",
                    restrict_date_start: "",
                    restrict_date_end: "",
                    restrict_time_start: "",
                    restrict_time_end: "",
                    type: "restricted_area",
                    access_type: "",
                    uid: "",
                    ring: geometry.paths,
                    centroid,
                  };
                  funcGeometry(data);
                }
              }
            });
            sketch.on("update", async (evt: any) => {
              let isIntersect;
              let isContained;
              if (!evt.aborted) {
                if (evt.graphics[0].attributes) {
                  const vertice = evt.graphics[0].geometry.rings[0];
                  const pttArea = [
                    [
                      [11258880.081968216, 1427293.8217321963],
                      [11258985.052228376, 1427529.9239086425],
                      [11259158.328398217, 1427926.2991383008],
                      [11259329.646007936, 1428338.1879768511],
                      [11259437.731017161, 1428529.3757385258],
                      [11259638.235820549, 1428413.9504480127],
                      [11260272.990313208, 1428036.7324248764],
                      [11259865.039715156, 1427373.820114523],
                      [11259526.861603579, 1426857.660501125],
                      [11259459.548779875, 1426870.909420509],
                      [11259054.889560355, 1427127.367505418],
                      [11258915.839603709, 1427249.5459099307],
                      [11258879.654592806, 1427294.4914783705],
                      [11258879.733039929, 1427294.1244262794],
                    ],
                  ];
                  const geometryRing = {
                    type: "polygon",
                    rings: vertice,
                  };
                  const geometryPTT = {
                    type: "polygon",
                    rings: pttArea,
                  };
                  const symbol = createSymbol({
                    color: "#FF0000",
                    style: "diagonal-cross",
                    width: 2,
                    outlineColor: "#FF0000",
                  });

                  const polygonGeometry = new Graphic({
                    geometry: geometryRing,
                    symbol,
                  });
                  const polygonPTT = new Graphic({
                    geometry: geometryPTT,
                    symbol,
                  });

                  isContained = geometryEngine.contains(
                    polygonPTT.geometry,
                    polygonGeometry.geometry
                  );

                  const restrictArr = await (
                    await THS_API.get("/map-boundarys")
                  ).data.data;

                  const filterRestrictArr = restrictArr.filter(
                    (res: any) => res.id !== evt.graphics[0].attributes.id
                  );

                  isIntersect = chkPolygonIntersect({
                    restrictArr: filterRestrictArr,
                    drawGeometry: polygonGeometry.geometry,
                    Graphic,
                    geometryEngine,
                    webMercatorUtils,
                  });
                  const { uid, geometry, attributes } = evt.graphics[0];

                  const { latitude, longitude } = geometry.centroid;
                  const centroid = [longitude, latitude];

                  const data = {
                    id: attributes?.id,
                    color: attributes?.color || "#428bca",
                    name: attributes?.name || "",
                    remark: attributes?.remark,
                    restrict_date_start: attributes?.restrict_date_start,
                    restrict_date_end: attributes?.restrict_date_end,
                    restrict_time_start: attributes?.restrict_time_start,
                    restrict_time_end: attributes?.restrict_time_end,
                    type: attributes?.type || "restricted_area",
                    uid,
                    access_type: attributes?.access_type || "",
                    ring: geometry.rings,
                    centroid,
                    isIntersect,
                    isContained,
                  };
                  funcGeometry(data, {
                    isContained,
                    isIntersect,
                    evt,
                    sketch,
                  });
                } else {
                  const pttArea = [
                    [11258880.081968216, 1427293.8217321963],
                    [11258985.052228376, 1427529.9239086425],
                    [11259158.328398217, 1427926.2991383008],
                    [11259329.646007936, 1428338.1879768511],
                    [11259437.731017161, 1428529.3757385258],
                    [11259638.235820549, 1428413.9504480127],
                    [11260272.990313208, 1428036.7324248764],
                    [11259865.039715156, 1427373.820114523],
                    [11259526.861603579, 1426857.660501125],
                    [11259459.548779875, 1426870.909420509],
                    [11259054.889560355, 1427127.367505418],
                    [11258915.839603709, 1427249.5459099307],
                    [11258879.654592806, 1427294.4914783705],
                    [11258879.733039929, 1427294.1244262794],
                  ];

                  const geometryPTT = {
                    type: "polygon",
                    rings: pttArea,
                  };

                  const symbol = createSymbol({
                    color: "#FF0000",
                    style: "diagonal-cross",
                    width: 2,
                    outlineColor: "#FF0000",
                  });
                  const polygonPTT = new Graphic({
                    geometry: geometryPTT,
                    symbol,
                  });

                  isContained = geometryEngine.contains(
                    polygonPTT.geometry,
                    evt.graphics[0].geometry
                  );

                  const restrictArr = await (
                    await THS_API.get("/map-boundarys")
                  ).data.data;

                  isIntersect = chkPolygonIntersect({
                    restrictArr,
                    drawGeometry: evt.graphics[0].geometry,
                    Graphic,
                    geometryEngine,
                    webMercatorUtils,
                  });

                  const { type } = evt.graphics[0].geometry;

                  if (type === "polygon") {
                    const { geometry } = evt.graphics[0];

                    const { centroid: geometryCentroid, rings } = geometry;
                    const { latitude, longitude } = geometryCentroid;
                    const centroid = [longitude, latitude];

                    const data = {
                      id: "",
                      color: "#428bca",
                      name: "",
                      remark: "",
                      restrict_date_start: "",
                      restrict_date_end: "",
                      restrict_time_start: "",
                      restrict_time_end: "",
                      type: "restricted_area",
                      access_type: "",
                      uid: "",
                      ring: rings,
                      centroid,
                      isIntersect,
                      isContained,
                    };

                    funcGeometry(data, {
                      isContained,
                      isIntersect,
                      evt,
                      sketch,
                    });
                  } else {
                    const { geometry } = evt.graphics[0];

                    const { latitude, longitude } = geometry.extent.center;
                    const centroid = [longitude, latitude];

                    const data = {
                      id: "",
                      color: "#428bca",
                      name: "",
                      remark: "",
                      restrict_date_start: "",
                      restrict_date_end: "",
                      restrict_time_start: "",
                      restrict_time_end: "",
                      type: "restricted_area",
                      access_type: "",
                      uid: "",
                      ring: geometry.paths,
                      centroid,
                      isIntersect,
                      isContained,
                    };
                    funcGeometry(data, {
                      isContained,
                      isIntersect,
                      evt,
                      sketch,
                    });
                  }
                }
              }
            });
            sketch.on("delete", async (evt: any) => {
              evt.graphics.map(async (graphic: any) => {
                if (graphic.attributes) {
                  const { id } = graphic.attributes;

                  const url = `/map-boundarys/${id}`;
                  const res = await THS_API.delete(url);
                  if (res.data.statusCode === 200) {
                    const res = await THS_API.get("/map-boundarys");

                    const apiArr = res?.data?.data || [];
                    const filterArr = apiArr
                      .filter((po: any) => po?.coordinate?.length)
                      .map((a: any) => {
                        return { ...a, checked: true };
                      });

                    dispatch(setMap(filterArr));
                  }
                }
              });
            });
            var scaleBar = new ScaleBar({
              view: view,
              unit: "metric",
            });
            view.ui.add(scaleBar, "bottom-left");
          });

          setMapLayer(map);
          setView(view);
          setListShipPTT(layerList);
        }
      )
      .catch((err) => {
        // handle any script or module loading errors
        console.error(err);
      });
  }, [
    dispatch,
    mapDiv,
    setListShipPTT,
    setMapLayer,
    setMeasurement,
    setDataGeometry,
    setView,
    viewMap,
  ]);
};

export default useInitMap;
