import { loadModules } from "esri-loader";

const createSymbol = ({ color, style, width, outlineColor }: any) => {
  return {
    type: "simple-fill",
    style,
    color,
    outline: {
      color: outlineColor,
      width,
    },
  };
};

const createGeometry = ({ view, vertices }: any) => {
  return {
    type: "polygon",
    rings: vertices,
  };
};

const orderedPttGeometry = (pttGeometry: any) => {
  return pttGeometry
    .map((polygon: any) => {
      const arrOrder: any = {
        AREA: 1,
        BUILDING: 2,
        PLANT: 0,
      };
      return { ...polygon, order: arrOrder[polygon.layer_name] };
    })

    .sort((a: any, b: any) => a.order - b.order);
};

const pttGeometryAddGraphic = ({
  view,
  pttGeometryLayer,
  Graphic,
  pttGeometry,
}: any) => {
  return orderedPttGeometry(pttGeometry).map((polygon: any) => {
    const { layer_name, shape_geometry } = polygon;
    const vertices = shape_geometry;

    const arrColor: any = {
      AREA: [0, 255, 0, 0.3],
      BUILDING: "transparent",
      PLANT: [4, 188, 255, 0.3],
    };
    // let color = "transparent";

    // if (
    //   (mapZoom >= 15 && mapZoom <= 17 && layer_name === "PLANT") ||
    //   (mapZoom > 17 && mapZoom <= 21 && layer_name === "AREA") ||
    //   (mapZoom > 21 && layer_name === "BUILDING")
    // )
    const color = arrColor[layer_name];

    const geometry = createGeometry({ view, vertices });

    const symbol = createSymbol({
      color,
      style: "solid",
      width: 1,
      outlineColor: color,
    });
    const newDevelopmentGraphic = new Graphic({
      geometry,
      symbol,
      // attributes: {
      //   ...polygon
      // },
      //     popupTemplate: {
      //     title: "คำบรรยาย",
      //     content: [
      //       {
      //         type: "fields",
      //         fieldInfos: [
      //           {
      //             fieldName: "access_type",
      //           },
      //           {
      //             fieldName: "restrict_date_start",
      //           },
      //         ]
      //     }]
      // }
    });

    pttGeometryLayer.add(newDevelopmentGraphic);
    return newDevelopmentGraphic;
  });
};

const initPttPolygonAPI = async ({
  view,
  pttGeometry,
  pttGeometryLayer,
}: any) => {
  const [Graphic] = await loadModules(["esri/Graphic"]);

  if (pttGeometry?.length) {
    pttGeometryLayer.removeAll();

    const graphicArr = pttGeometryAddGraphic({
      view,
      pttGeometryLayer,
      Graphic,
      pttGeometry,
    });

    view.goTo({
      target: graphicArr,
    });
  }
};

export default initPttPolygonAPI;
