/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Swal from "sweetalert2";
import _ from "lodash";
import moment from "moment";
import en from "date-fns/locale/en-GB";
import classnames from "classnames";
import Draggable from "react-draggable";

import DatePicker, { registerLocale } from "react-datepicker";

import { selectUser } from "reducers/userReducer";
import { useSelector, useDispatch } from "react-redux";
import { Select, Modal, Tabs } from "antd";

import type { DraggableData, DraggableEvent } from "react-draggable";

import {
  selectPTTGeometry,
  selectPTTGeometryPast,
  selectMapMonitoring,
  setIsFilterList,
  setFilterButtonClear,
} from "reducers/mapMonitoring";

import { handleSearchMapMonitoringFunc } from "./_function";

import {
  useSaveMonitoringAPI,
  useAddPttGeometryMap,
  useGoToMapFunction,
  useFuncFilter,
  useFuncFilterWorkPermit,
  useGoToMapFunctionStart,
} from "./_hooks";
import { THS_API } from "../../../constants";

import "./index.css";

const FilterBox = ({
  map,
  view,
  pastView,
  mapZoom,
  isModalVisible,
  setIsModalVisible,
  setSideBarFilter,
  chosenMap,
  setChosenMap,
  setDataMapDevice,
  setIsSubmit,
  setStartDatePast,
  isClear,
  clearMapResult,
}: any) => {
  const [isApiLoading, setIsApiLoading] = React.useState(false);
  const [startDate, setStartDate] = React.useState(new Date());
  const [isStartDateLoading, setIsStartDateLoading] = React.useState(false);
  const [isAllowStateDateChange, setIsAllowStateDateChange] =
    React.useState(false);
  const [startTime, setStartTime] = React.useState(new Date());
  const [endTime, setEndTime] = React.useState(new Date());
  const [deviceEUI, setDeviceEUI] = React.useState("");
  const [workCid, setWorkCid] = React.useState("");
  const [cid, setCid] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [workPermitType, setWorkPermitType] = React.useState("workPermit");
  const [deviceSearchType, setDeviceSearchType] = React.useState("deviceNo");
  const [dateType, setDateType] = React.useState("time");
  const [checkDevice, setCheckDevice] = React.useState(true);
  const [checkWorkpermit, setCheckWorkpermit] = React.useState(true);
  const [checkWorkpermitdata, setCheckWorkpermitdata] = React.useState(false);

  const [locationSelectVal, setLocationSelectVal] = React.useState("");
  const [chosenLocationBoxName, setChosenLocationBoxName] = React.useState("");
  const [subLocationSelectVal, setSubLocationSelectVal] = React.useState("");
  const [chosenSubLocationBoxName, setChosenSubLocationBoxName] =
    React.useState("");

  const [dataNoWorkpermit, setDataNoWorkpermit] = React.useState([]);
  const [locationSelectBox, setLocationSelectBox] = React.useState([]);
  const [subLocationSelectBox, setSubLocationSelectBox] = React.useState([]);
  const [dataEUI, setDataEUI] = React.useState([]);
  const [dataEUIStatus, setDataEUIStatus] = React.useState("idle");

  const [dataWorkPermit, setDataWorkPermit] = React.useState([]);
  const [bounds, setBounds] = React.useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const [isMapFinishGoTo, setIsMapFinishGoTo] = React.useState(false);
  const [isFilterBoxSubmit, setIsFilterBoxSubmit] = React.useState(false);
  const [selectedWorkpermitNo, setSelectedWorkpermitNo] = React.useState("");

  const draggleRef = React.useRef<HTMLDivElement>(null);

  const pttGeometry = useSelector(selectPTTGeometry);
  const pttGeometryPast = useSelector(selectPTTGeometryPast);
  const userData = useSelector(selectUser);

  const {
    isFilterList,
    filterBoxValue,
    buttonGroupValue,
    isSaveMonitoringLoaded,
    mapInitApiStatus,
  } = useSelector(selectMapMonitoring);

  registerLocale("el", en);
  const dispatch = useDispatch<any>();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const funcSubLocation = (e: any) => {
    const value = e;

    if (!e) {
      setSubLocationSelectVal("");
      setChosenSubLocationBoxName("");
    } else {
      const subLocation = value.split("|")[0];
      const valueData = JSON.parse(value.split("|")[1]);
      const subName = value.split("|")[2];
      const center: any = [];
      let subArr: any = [];
      valueData.map((point: any, index: number) => {
        if (index % 2 === 0) subArr.push(point);
        else {
          subArr.push(point);
          center.push(subArr);
          subArr = [];
        }
        return null;
      });

      setSubLocationSelectVal(subLocation);
      setChosenSubLocationBoxName(subName);
    }
  };

  const funcLocation = (e: any) => {
    const value = e;

    setSubLocationSelectVal("");

    setChosenSubLocationBoxName("");
    if (!e) {
      setLocationSelectVal("");
      setSubLocationSelectBox([]);

      setChosenLocationBoxName("");
    } else {
      const location = value.split("|")[0];
      const valueData = JSON.parse(value.split("|")[1]);
      const name = value.split("|")[2];
      const center: any = [];
      let subArr: any = [];
      valueData.map((point: any, index: number) => {
        if (index % 2 === 0) subArr.push(point);
        else {
          subArr.push(point);
          center.push(subArr);
          subArr = [];
        }
        return null;
      });

      const subLocation = _.filter(
        locationSelectBox,
        (o: any) => location === o.unitid
      )[0]?.sub_location;

      setLocationSelectVal(location);
      setChosenLocationBoxName(name);
      setSubLocationSelectBox(subLocation);
    }
  };

  const handleSearchMapMonitoring = async () => {
    handleSearchMapMonitoringFunc({
      dateType,
      cid,
      deviceEUI,
      workCid,
      firstName,
      lastName,
      startDate,
      startTime,
      endTime,
      locationSelectVal,
      subLocationSelectVal,
      userData,
      filterBoxValue,
      buttonGroupValue,
      chosenLocationBoxName,
      chosenSubLocationBoxName,
      deviceSearchType,
      setSideBarFilter,
      setIsApiLoading,
      setChosenMap,
      setCheckWorkpermit,
      checkWorkpermitdata,
      setCheckDevice,
      setDataMapDevice,
      setIsModalVisible,
      setCid,
      setFirstName,
      setLastName,
      setWorkPermitType,
      setDeviceEUI,
      setWorkCid,
      setIsSubmit,
      dispatch,
    });

    setIsFilterBoxSubmit(true);
  };

  const funcEUI = async (work_permit_no: string) => {
    setSelectedWorkpermitNo(work_permit_no);
    if (work_permit_no) {
      setCheckWorkpermit(true);
      setCheckWorkpermitdata(true);
    }
    let dataStartDate = moment(startDate).format("YYYY-MM-DD");
    const params = {
      work_permit_no,
      people: true,
      date: dataStartDate,
    };
    setDataEUIStatus("loading");
    const res = await THS_API.get("map-monitoring/movetracking-filter", {
      params,
    });

    setDataEUI(res.data.data);
    setDataEUIStatus("loaded");
  };

  const setDataName = (e: any) => {
    if (e) {
      setCheckDevice(true);
    }
    const value = e;
    const firstName = value.split("|")[0];
    const lastName = value.split("|")[1];
    setFirstName(firstName);
    setLastName(lastName);
  };
  const chagneCID = (e: any) => {
    if (e) {
      setCheckDevice(true);
    }
    setWorkCid(e);
  };
  const chagneDev = (e: any) => {
    if (e) {
      setCheckDevice(true);
    }
    setDeviceEUI(e);
  };
  const resetValue = (value: any) => {
    setDateType(value);

    setSubLocationSelectBox([]);
    setChosenLocationBoxName("");
    setChosenSubLocationBoxName("");
    setLocationSelectVal("");
    setSubLocationSelectVal("");
  };
  const resetValueDevice = (value: any) => {
    setWorkPermitType(value);
    setDeviceEUI("");
    setWorkCid("");
    setFirstName("");
    setLastName("");
    setDataEUI([]);
    setCheckWorkpermit(true);
    setCheckDevice(true);
  };
  const handleResetMapMonitoring = () => {
    dispatch(setIsFilterList(false));

    setSubLocationSelectBox([]);
    setChosenLocationBoxName("");
    setChosenSubLocationBoxName("");
    setLocationSelectVal("");
    setSubLocationSelectVal("");
    setDeviceEUI("");
    setWorkCid("");
    setFirstName("");
    setLastName("");
    setDataEUI([]);
    setCheckWorkpermit(true);
    setCheckDevice(true);
    setStartDate(new Date());
    setStartTime(new Date());
    setStartDatePast(new Date());
    setEndTime(new Date());
    setIsSubmit(false);

    setSelectedWorkpermitNo("");
    setDeviceSearchType("deviceNo");
  };

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  React.useEffect(() => {
    if (isClear) {
      handleResetMapMonitoring();
      setIsModalVisible(false);
      clearMapResult();

      setDateType("time");
      dispatch(
        setFilterButtonClear({
          userid: userData.id,
        })
      );
    }
  }, [isClear]);

  React.useEffect(() => {
    setCheckWorkpermit(true);
    setCheckDevice(true);
  }, [isModalVisible]);

  React.useEffect(() => {
    if (isApiLoading) Swal.showLoading();
    else Swal.close();
  }, [isApiLoading]);

  useSaveMonitoringAPI({
    isSaveMonitoringLoaded,
    filterBoxValue,
    setLocationSelectBox,
    setLocationSelectVal,
    setChosenLocationBoxName,
    setSubLocationSelectBox,
    setSubLocationSelectVal,
    setChosenSubLocationBoxName,
    setStartDate,
    setStartDatePast,
    setStartTime,
    setEndTime,
    setDateType,
    setChosenMap,
    setDeviceSearchType,
    setCheckWorkpermit,
    checkWorkpermitdata,
    setCheckWorkpermitdata,
    setCheckDevice,
    setWorkCid,
    setDeviceEUI,
    setDataWorkPermit,
    setFirstName,
    setLastName,
    setDataMapDevice,
    dispatch,
  });

  useGoToMapFunctionStart({
    view,
    pttGeometry,
    isFilterList,
    mapZoom,
    isMapFinishGoTo,
    setIsMapFinishGoTo,
    buttonGroupValue,
    isSaveMonitoringLoaded,
    mapInitApiStatus,
  });
  useGoToMapFunction({
    pttGeometry,
    pttGeometryPast,
    isFilterList,
    mapZoom,
    pastView,
    view,
    chosenMap,
    isFilterBoxSubmit,
  });
  useAddPttGeometryMap({ view, map, mapZoom, pttGeometry });
  useFuncFilter({ setLocationSelectBox });
  useFuncFilterWorkPermit({
    startDate,
    setDataWorkPermit,
    setDataNoWorkpermit,
    isAllowStateDateChange,
    setIsAllowStateDateChange,
    setIsStartDateLoading,
  });

  return (
    <Modal
      className="map-monitoring-modal"
      title={
        <div
          style={{
            width: "700px",
            cursor: "move",
          }}
        />
      }
      open={isModalVisible}
      onCancel={handleCancel}
      modalRender={(modal) => (
        <Draggable
          disabled={false}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <h3 className="search-modal">เลือกประเภทการค้นหา</h3>
      <form>
        <Tabs activeKey={dateType} onChange={resetValue}>
          <Tabs.TabPane tab="ค้นหาบุคคลตามเวลาปัจจุบัน" key="time">
            <div className="form-group">
              <label htmlFor="campaign" className=" col-form-label">
                เลือกพื้นที่การผลิต
              </label>
            </div>
            <div className="form-group">
              <Select
                className="form-control"
                showSearch
                placeholder="Select location"
                onChange={funcLocation}
                value={chosenLocationBoxName}
              >
                <Select.Option value="">เลือก พื้นที่</Select.Option>
                {locationSelectBox?.map((opt: any, key: number) => {
                  return (
                    <Select.Option
                      key={key}
                      value={`${opt.unitid}|[${opt.shape_geometry[0]}]|${opt.unitname}`}
                    >
                      {opt.unitname}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <div className="form-group">
              <Select
                className="form-control"
                showSearch
                placeholder="Select sub location"
                onChange={funcSubLocation}
                value={chosenSubLocationBoxName}
              >
                <Select.Option value="">เลือก พื้นที่ย่อย</Select.Option>
                {subLocationSelectBox?.map((opt: any, key: number) => (
                  <Select.Option
                    key={key}
                    value={`${opt.subunitid}|[${opt.shape_geometry[0]}]|${opt.subunitname}`}
                  >
                    {opt.subunitname}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="ค้นหาตำแหน่งบุคคลย้อนหลัง" key="past">
            <div className="form-group">
              <p className="col-form-label">วันและเวลาที่ต้องการค้นหา</p>
            </div>
            <div className="form-group row">
              <label htmlFor="campaign" className="col-sm-2 col-form-label">
                Date:
              </label>
              <div className="col-sm-10">
                <DatePicker
                  className="form-control date-time-input"
                  selected={startDate}
                  onChange={(date) => {
                    if (date) {
                      setStartDate(date);
                      setStartDatePast(date);
                    }
                  }}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="campaign" className="col-sm-2 col-form-label">
                Time:
              </label>
              <div className="col-sm-10">
                <DatePicker
                  selected={startTime}
                  className="form-control date-time-input"
                  onChange={(date: any) => setStartTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  locale="el"
                  timeFormat="p"
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="campaign" className=" col-form-label">
                เลือกพื้นที่การผลิต
              </label>
            </div>
            <div className="form-group">
              <Select
                className="form-control"
                showSearch
                placeholder="Select location"
                onChange={funcLocation}
                value={chosenLocationBoxName}
              >
                <Select.Option value="">เลือก พื้นที่</Select.Option>
                {locationSelectBox?.map((opt: any, key: number) => {
                  return (
                    <Select.Option
                      key={key}
                      selected={locationSelectVal === opt.unitid}
                      value={`${opt.unitid}|[${opt.shape_geometry[0]}]|${opt.unitname}`}
                    >
                      {opt.unitname}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <div className="form-group">
              <Select
                className="form-control"
                showSearch
                placeholder="Select sub location"
                onChange={funcSubLocation}
                value={chosenSubLocationBoxName}
              >
                <Select.Option value="">เลือก พื้นที่ย่อย</Select.Option>
                {subLocationSelectBox?.map((opt: any, key: number) => (
                  <Select.Option
                    key={key}
                    selected={subLocationSelectVal === opt.subunitid}
                    value={`${opt.subunitid}|[${opt.shape_geometry[0]}]|${opt.subunitname}`}
                  >
                    {opt.subunitname}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="ค้นหาบุคคลตามช่วงเวลา" key="period">
            <div className="form-group">
              <p className="col-form-label">วันและเวลาที่ต้องการค้นหา</p>
            </div>
            <div className="form-group row">
              <label htmlFor="campaign" className="col-sm-2 col-form-label">
                Date:
              </label>
              <div className="col-sm-10">
                <DatePicker
                  className="form-control"
                  selected={startDate}
                  onChange={(date: any) => {
                    setStartDate(date);
                  }}
                  onCalendarClose={() => {
                    setIsAllowStateDateChange(true);
                  }}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <div className="form-group row">
              <label htmlFor="campaign" className="col-sm-2 col-form-label">
                Time:
              </label>
              <div className="col-sm-4">
                <DatePicker
                  selected={startTime}
                  onChange={(time: any) => {
                    setStartTime(time);
                  }}
                  onCalendarClose={() => {
                    const start = moment(startTime);
                    const end = moment(endTime);
                    if (start.diff(end) > 0) setEndTime(startTime);
                  }}
                  minTime={new Date("January 1, 0001 00:00:00")}
                  maxTime={new Date("December 30, 9999 23:45:00")}
                  showTimeSelect
                  showTimeSelectOnly
                  locale="el"
                  timeFormat="p"
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                />
              </div>
              <div className="col-sm-2 align-center">-</div>
              <div className="col-sm-4">
                <DatePicker
                  selected={endTime}
                  onChange={(time: any) => {
                    setEndTime(time);
                  }}
                  onCalendarClose={() => {
                    const start = moment(startTime);
                    const end = moment(endTime);
                    if (start.diff(end) > 0) setStartTime(endTime);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  locale="el"
                  timeFormat="p"
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  minTime={new Date("January 1, 0001 00:00:00")}
                  maxTime={new Date("December 30, 9999 23:45:00")}
                />
              </div>
            </div>
            <div className="form-group mb-0">
              <p className="col-form-label">
                ค้นหาด้วย หมายเลข Work Permit หรือ ข้อมูลบุคคล
              </p>
            </div>
            <Tabs activeKey={workPermitType} onChange={resetValueDevice}>
              <Tabs.TabPane tab="ค้นหาแบบมีใบอนุญาตทำงาน" key="workPermit">
                <div className="form-group row">
                  <label className="col-sm-4 form-check-label">
                    หมายเลขใบอนุญาตทำงาน:
                    <span className="textalert">*</span>
                  </label>
                  <div className="col-sm-8">
                    {isStartDateLoading && (
                      <img
                        className="loading-icon"
                        src="/images/icon/loading-icon.gif"
                        alt="loading"
                      />
                    )}
                    {!isStartDateLoading && (
                      <Select
                        className={classnames("form-control", {
                          error: checkWorkpermit === false,
                        })}
                        showSearch
                        placeholder="กรุณาเลือกหมายเลขใบอนุญาตทำงาน"
                        value={selectedWorkpermitNo}
                        onChange={funcEUI}
                      >
                        <Select.Option value="">
                          {" "}
                          กรุณาเลือกหมายเลขใบอนุญาตทำงาน
                        </Select.Option>
                        {dataWorkPermit?.map((workPermit, key) => {
                          const { work_permit_no } = workPermit;

                          return (
                            <Select.Option key={key} value={work_permit_no}>
                              {work_permit_no}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    )}
                    {checkWorkpermit === false &&
                      workPermitType === "workPermit" && (
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                          style={{ fontSize: "14px", color: "red" }}
                        >
                          กรุณาเลือกข้อมูล หมายเลขใบอนุญาตทำงาน
                        </label>
                      )}
                  </div>
                </div>

                <div className="form-group row track-individual-people">
                  <div className="col-sm-4 form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={deviceSearchType === "deviceNo"}
                      onClick={() => setDeviceSearchType("deviceNo")}
                    />
                    <label>หมายเลขอุปกรณ์.</label>
                  </div>

                  <div className="col-sm-4 form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={deviceSearchType === "idCard"}
                      onClick={() => setDeviceSearchType("idCard")}
                    />
                    <label>เลขบัตรประชาชน</label>
                  </div>
                  <div className="col-sm-4 form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={deviceSearchType === "fullName"}
                      onClick={() => setDeviceSearchType("fullName")}
                    />
                    <label>ชื่อ-นามสกุล</label>
                  </div>
                </div>
                {deviceSearchType === "idCard" && (
                  <div className="form-group row">
                    {dataEUIStatus === "loading" && (
                      <img
                        className="loading-icon"
                        src="/images/icon/loading-icon.gif"
                        alt="loading"
                      />
                    )}
                    {dataEUIStatus !== "loading" && (
                      <>
                        <div className="col-sm-1">
                          <span className="textalert">*</span>
                        </div>
                        <div className="col-sm-11">
                          <Select
                            className={classnames("form-control", {
                              error: checkDevice === false,
                            })}
                            showSearch
                            placeholder="บัตรประชาชาชน"
                            value={workCid}
                            onChange={chagneCID}
                          >
                            <Select.Option value="">
                              เลือกบัตรประชาชน
                            </Select.Option>
                            {dataEUI?.map((workPermit, key) => {
                              const { cid } = workPermit;

                              return (
                                <Select.Option key={key} value={cid}>
                                  {cid}
                                </Select.Option>
                              );
                            })}
                          </Select>
                          {checkDevice === false && (
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                              style={{ fontSize: "14px", color: "red" }}
                            >
                              กรุณาเลือกบัตรประชาชน
                            </label>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )}
                {deviceSearchType === "deviceNo" && (
                  <div className="form-group row">
                    {dataEUIStatus === "loading" && (
                      <img
                        className="loading-icon"
                        src="/images/icon/loading-icon.gif"
                        alt="loading"
                      />
                    )}
                    {dataEUIStatus !== "loading" && (
                      <>
                        <div className="col-sm-1">
                          <span className="textalert">*</span>
                        </div>
                        <div className="col-sm-11">
                          <Select
                            className={classnames("form-control", {
                              error: checkDevice === false,
                            })}
                            showSearch
                            placeholder="หมายเลขอุปกรณ์"
                            value={deviceEUI}
                            onChange={chagneDev}
                          >
                            <Select.Option value="">
                              เลือกหมายเลขอุปกรณ์
                            </Select.Option>
                            {dataEUI?.map((workPermit, key) => {
                              const { dev_eui } = workPermit;

                              return (
                                <Select.Option key={key} value={dev_eui}>
                                  {dev_eui}
                                </Select.Option>
                              );
                            })}
                          </Select>
                          {checkDevice === false && (
                            <label
                              className="form-check-label"
                              htmlFor="flexRadioDefault1"
                              style={{ fontSize: "14px", color: "red" }}
                            >
                              กรุณาเลือกข้อมูลอุปกรณ์
                            </label>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                )}
                {deviceSearchType === "fullName" && (
                  <div className="form-group row">
                    {dataEUIStatus === "loading" && (
                      <img
                        className="loading-icon"
                        src="/images/icon/loading-icon.gif"
                        alt="loading"
                      />
                    )}
                    {dataEUIStatus !== "loading" && (
                      <div className="col-sm-12">
                        <Select
                          className={classnames("form-control", {
                            error: checkDevice === false,
                          })}
                          showSearch
                          placeholder="ชื่อ-นามสกุล"
                          onChange={setDataName}
                          value={`${firstName}|${lastName}`}
                        >
                          <Select.Option value={`|`}>
                            เลือกชื่อ-นามสกุล
                          </Select.Option>
                          {dataEUI?.map((people, key) => {
                            const { first_name, last_name } = people;

                            return (
                              <Select.Option
                                key={key}
                                value={`${first_name}|${last_name}`}
                              >
                                {first_name} {last_name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                        {checkDevice === false && (
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                            style={{ fontSize: "14px", color: "red" }}
                          >
                            กรุณาเลือกบุคคล
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane tab="ค้นหาแบบไม่มีใบอนุญาตทำงาน" key="noWorkPermit">
                <div className="form-group row track-individual-people">
                  <div className="col-sm-4 form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={deviceSearchType === "deviceNo"}
                      onClick={() => setDeviceSearchType("deviceNo")}
                    />
                    <label>หมายเลขอุปกรณ์</label>
                  </div>

                  <div className="col-sm-4 form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={deviceSearchType === "idCard"}
                      onClick={() => setDeviceSearchType("idCard")}
                    />
                    <label>เลขบัตรประชาชน</label>
                  </div>
                  <div className="col-sm-4 form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={deviceSearchType === "fullName"}
                      onClick={() => setDeviceSearchType("fullName")}
                    />
                    <label>ชื่อ-นามสกุล</label>
                  </div>
                </div>
                {deviceSearchType === "idCard" && (
                  <div className="form-group row">
                    <div className="col-sm-1">
                      <span className="textalert">*</span>
                    </div>
                    <div className="col-sm-11">
                      {isStartDateLoading && (
                        <img
                          className="loading-icon"
                          src="/images/icon/loading-icon.gif"
                          alt="loading"
                        />
                      )}
                      {!isStartDateLoading && (
                        <Select
                          className={classnames("form-control", {
                            error: checkDevice === false,
                          })}
                          showSearch
                          placeholder="บัตรประชาชาชน"
                          value={workCid}
                          onChange={chagneCID}
                        >
                          <Select.Option value="">
                            เลือกบัตรประชาชน
                          </Select.Option>
                          {dataNoWorkpermit?.map((workPermit, key) => {
                            const { cid } = workPermit;

                            return (
                              <Select.Option key={key} value={cid}>
                                {cid}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      )}
                      {checkDevice === false && (
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                          style={{ fontSize: "14px", color: "red" }}
                        >
                          กรุณาเลือกบัตรประชาชน
                        </label>
                      )}
                    </div>
                  </div>
                )}
                {deviceSearchType === "deviceNo" && (
                  <div className="form-group row">
                    <div className="col-sm-1">
                      <span className="textalert">*</span>
                    </div>
                    <div className="col-sm-11">
                      {isStartDateLoading && (
                        <img
                          className="loading-icon"
                          src="/images/icon/loading-icon.gif"
                          alt="loading"
                        />
                      )}
                      {!isStartDateLoading && (
                        <Select
                          className={classnames("form-control", {
                            error: checkDevice === false,
                          })}
                          showSearch
                          placeholder="หมายเลขอุปกรณ์"
                          value={deviceEUI}
                          onChange={chagneDev}
                        >
                          <Select.Option value="">
                            เลือกหมายเลขอุปกรณ์
                          </Select.Option>
                          {dataNoWorkpermit?.map((workPermit, key) => {
                            const { dev_eui } = workPermit;

                            return (
                              <Select.Option key={key} value={dev_eui}>
                                {dev_eui}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      )}
                      {checkDevice === false && (
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                          style={{ fontSize: "14px", color: "red" }}
                        >
                          กรุณาเลือกข้อมูลอุปกรณ์
                        </label>
                      )}
                    </div>
                  </div>
                )}
                {deviceSearchType === "fullName" && (
                  <div className="form-group row">
                    <div className="col-sm-1">
                      <span className="textalert">*</span>
                    </div>
                    <div className="col-sm-11">
                      {isStartDateLoading && (
                        <img
                          className="loading-icon"
                          src="/images/icon/loading-icon.gif"
                          alt="loading"
                        />
                      )}
                      {!isStartDateLoading && (
                        <Select
                          className={classnames("form-control", {
                            error: checkDevice === false,
                          })}
                          showSearch
                          placeholder="ชื่อ-นามสกุล"
                          onChange={setDataName}
                          value={`${firstName}|${lastName}`}
                        >
                          <Select.Option value={`|`}>
                            เลือกชื่อ-นามสกุล
                          </Select.Option>
                          {dataNoWorkpermit?.map((people, key) => {
                            const { first_name, last_name } = people;

                            return (
                              <Select.Option
                                key={key}
                                value={`${first_name}|${last_name}`}
                              >
                                {first_name} {last_name}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      )}
                      {checkDevice === false && (
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                          style={{ fontSize: "14px", color: "red" }}
                        >
                          กรุณาเลือกบุคคล
                        </label>
                      )}
                    </div>
                  </div>
                )}
              </Tabs.TabPane>
            </Tabs>
          </Tabs.TabPane>
        </Tabs>
        <div className="row">
          {" "}
          <div className="btn btn-save" onClick={handleSearchMapMonitoring}>
            ค้นหา
          </div>
          <div className="btn btn-save" onClick={handleResetMapMonitoring}>
            ล้าง
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default FilterBox;
