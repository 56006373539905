import { THS_API_URL, THS_API } from "../../constants";

const RedirectLoginUrl = async (role: string) => {
  const roleWithName = `${THS_API_URL}/roles/name/${role}`;
  const res = await THS_API.get(roleWithName);

  const {
    viewDashboard,
    viewMapMonitoring,
    viewMapBoundary,
    viewDeviceManager,
    viewReport,
    viewRoles,
    viewEventLog,
    viewWorkPermit,
  } = res.data.data;

  const chkPageAuth: any = {
    dashboard: viewDashboard,
    "map-monitoring": viewMapMonitoring,
    "map-boundary": viewMapBoundary,
    devices: viewDeviceManager,
    report: viewReport,
    role: viewRoles,
    "event-log": viewEventLog,
    "work-permit": viewWorkPermit,
  };

  const chkPageAuthKey = Object.keys(chkPageAuth);
  const chkPageAuthValue = Object.values(chkPageAuth);

  let url = "dashboard";
  for (let i = 0; i < chkPageAuthKey.length; i++) {
    if (chkPageAuthValue[i]) {
      url = chkPageAuthKey[i];
      break;
    }
  }

  return `/${url}`;
};
export default RedirectLoginUrl;
