import React from "react";
import Swal from "sweetalert2";
import classnames from "classnames";
import DatePicker, { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-GB";

import { Drawer, Pagination, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";

import { AntdLayout } from "@pankod/refine-antd";
import { useCustom, useApiUrl } from "@pankod/refine-core";

import FilterBaseMap from "components/mapBoundary/filterBaseMap";
import FilterBoxboundary from "components/mapBoundary/FilterBoxboundary";
import GoToBoundary from "components/mapBoundary/GoToBoundary";
import GSP from "components/gsp";

import { selectMap, setMap } from "reducers/mapBoundaryReducers";
import {
  EsriMap,
  FilterList,
  Measurement,
  ButtonGroup,
} from "components/mapBoundary";

import { addGeometry } from "./_function";

import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

const setOnlyTimeToDate = (time: string) => {
  const timeArr = time.split(":");
  const date = new Date();
  date.setHours(Number(timeArr[0]));
  date.setMinutes(Number(timeArr[1]));
  return date;
};

export const MapBoundary: React.FC = () => {
  const [startDate, setStartDate] = React.useState<Date | null>(new Date());
  const [endDate, setEndDate] = React.useState<Date | null>(new Date());
  const [startTime, setStartTime] = React.useState<any>(new Date());
  const [endTime, setEndTime] = React.useState<any>(new Date());
  const [locationName, setLocationName] = React.useState("");
  const [remark, setRemark] = React.useState("");
  const [checkStatus, setCheckStatus] = React.useState("restricted_area");
  const [checkStatusTime, setCheckStatusTime] = React.useState("one_time");
  const [blockPickerColor, setBlockPickerColor] = React.useState("#428bca");
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [isModalVisibleSearch, setIsModalVisibleSearch] = React.useState(false);
  const [isGoToBoundary, setIsGoToBoundary] = React.useState(false);
  const [isFilter, setIsFilter] = React.useState(true);

  const [dataGeometry, setDataGeometry] = React.useState<any>({
    id: "",
    uid: "",
    ring: [],
  });
  const [modalBaseMap, setModalBaseMap] = React.useState(false);
  const [modalIcon, setModalIcon] = React.useState(false);
  const [viewMap, setViewMap] = React.useState("satellite");
  const [mapLayer, setMapLayer] = React.useState<any>(null);
  const [openAddGoemetry, setOpenAddGoemetry] = React.useState(false);
  const [iconBoundary, setIconBoundary] = React.useState("X-ray");

  const [view, setView] = React.useState<any>();
  const [measurement, setMeasurement] = React.useState<any>();
  const [statusSave, setStatusSave] = React.useState<string>("idle");
  const [isRestrictDrawer, setIsRestrictDrawer] = React.useState(false);
  const [mapShipPTT, setMapShipPTT] = React.useState<boolean>(false);
  const [listShipPTT, setListShipPTT] = React.useState<any>(null);
  const [isClear, setIsClear] = React.useState<boolean>(false);

  const dispatch = useDispatch();
  const polygonArr = useSelector(selectMap);
  const apiUrl = useApiUrl();

  const { data, status, isLoading } = useCustom({
    url: `${apiUrl}/map-boundarys`,
    method: "get",
  });

  //Pagination
  const [listPolygon, setListPolygon] = React.useState<any>([]);
  let currentItems: any = [];
  let currentItemSelect: any = [];
  let total: any;
  let pageCount: any = 0;

  const itemOffset = 0;
  const itemsPerPage = 5;

  currentItemSelect = polygonArr;
  total = polygonArr.length;
  const endOffset = itemOffset + itemsPerPage;
  currentItems = polygonArr.slice(itemOffset, endOffset);
  pageCount = Math.ceil(polygonArr.length / itemsPerPage);

  const handlePageClick = (number: number) => {
    if ((number * itemsPerPage) / total >= 1) {
      const newset = number * itemsPerPage - itemsPerPage;
      currentItems = currentItemSelect.slice(newset, total);
      setListPolygon(currentItems);
    } else {
      const newOffset = (number * itemsPerPage) % total;
      const endOffset = newOffset - itemsPerPage;
      currentItems = currentItemSelect.slice(endOffset, newOffset);
      pageCount = Math.ceil(total / itemsPerPage);
      setListPolygon(currentItems);
    }
  };
  if (listPolygon.length) currentItems = listPolygon;

  let geoMetryData = dataGeometry.ring[0];

  const handleCancelCreateGeometry = () => {
    setOpenAddGoemetry(false);
  };
  const handleCancelIcon = () => {
    setModalIcon(false);
  };
  const funcIcon = (iconPolygon: any) => {
    setIconBoundary(iconPolygon);
    setModalIcon(false);
  };

  const addGeometryFunc = () => {
    addGeometry({
      setStatusSave,
      setIsSubmit,
      setDataGeometry,
      setBlockPickerColor,
      setRemark,
      setLocationName,
      setCheckStatus,
      setCheckStatusTime,
      setStartDate,
      setEndDate,
      setStartTime,
      setEndTime,
      setOpenAddGoemetry,
      dispatch,

      dataGeometry,
      geoMetryData,
      locationName,
      startDate,
      endDate,
      startTime,
      endTime,
      checkStatus,
      checkStatusTime,
      blockPickerColor,
      remark,
      polygonArr,
      iconBoundary,
    });
  };
  registerLocale("el", en);

  React.useEffect(() => {
    if (dataGeometry.uid) {
      const {
        name,
        color,
        restrict_date_start,
        restrict_date_end,
        restrict_time_start,
        restrict_time_end,
        access_type,
      } = dataGeometry;

      setLocationName(name);
      setCheckStatus("restricted_area");
      setBlockPickerColor(color);
      setCheckStatusTime(
        access_type === "พื้นที่หวงห้ามถาวร" ? "one_time" : "daily"
      );
      if (restrict_date_end) {
        setStartDate(new Date(`${restrict_date_start} ${restrict_time_start}`));
        setEndDate(new Date(`${restrict_date_end} ${restrict_time_end}`));
      } else if (restrict_time_end) {
        setStartDate(setOnlyTimeToDate(restrict_time_start));
        setEndDate(setOnlyTimeToDate(restrict_time_end));
      } else {
        setStartDate(new Date());
        setEndDate(new Date());
      }
    } else {
      setLocationName("");
      setCheckStatus("restricted_area");
      setBlockPickerColor("#428bca");
      setCheckStatusTime("one_time");
      setStartDate(new Date());
      setEndDate(new Date());
      setEndTime(new Date());
      setStartTime(new Date());
    }
  }, [dataGeometry, dataGeometry.uid]);
  React.useEffect(() => {
    if (dataGeometry.ring.length && !dataGeometry.isIntersect) {
      setOpenAddGoemetry(true);
    }
  }, [dataGeometry]);
  React.useEffect(() => {
    if (statusSave === "loading") Swal.showLoading();
    else if (statusSave === "success") Swal.close();
  }, [statusSave]);
  React.useEffect(() => {
    if (mapShipPTT) {
      view?.ui.add(listShipPTT, "bottom-left");
    } else {
      view?.ui.remove(listShipPTT);
    }
  }, [listShipPTT, mapShipPTT, view?.ui]);
  React.useEffect(() => {
    if (status === "success") {
      const apiArr = data?.data?.data || [];
      const filterArr = apiArr
        .filter((po: any) => po?.coordinate?.length)
        .map((a: any) => {
          return { ...a, checked: true };
        });
      dispatch(setMap(filterArr));
    }
  }, [status, data, dispatch]);

  React.useEffect(() => {
    setIsSubmit(false);
  }, [geoMetryData]);

  React.useEffect(() => {
    if (isLoading) Swal.showLoading();
    else Swal.close();
  }, [isLoading]);

  React.useEffect(() => {
    if (isClear) setIsClear(false);
  }, [isClear]);
  return (
    <AntdLayout className="layout map-boundary">
      <div className="size-map">
        <div className="map">
          <div className="map-point">
            {(status === "success" || status === "error") && (
              <EsriMap
                setView={setView}
                setMeasurement={setMeasurement}
                view={view}
                viewMap={viewMap}
                setDataGeometry={setDataGeometry}
                setListShipPTT={setListShipPTT}
                mapLayer={mapLayer}
                setMapLayer={setMapLayer}
              />
            )}
            {polygonArr.length > 0 && (
              <FilterList isOpen={isFilter} setIsFilter={setIsFilter} />
            )}
          </div>
        </div>

        <ButtonGroup
          polygonArr={polygonArr}
          setIsRestrictDrawer={setIsRestrictDrawer}
          viewMap={viewMap}
          setModalBaseMap={setModalBaseMap}
          setIsModalVisibleSearch={setIsModalVisibleSearch}
          setIsSubmit={setIsSubmit}
          isFilter={isFilter}
          setIsFilter={setIsFilter}
          mapShipPTT={mapShipPTT}
          setMapShipPTT={setMapShipPTT}
          isGoToBoundary={isGoToBoundary}
          setIsGoToBoundary={setIsGoToBoundary}
          view={view}
          mapLayer={mapLayer}
          setIsClear={setIsClear}
        />

        <FilterBaseMap
          isOpen={modalBaseMap}
          setModalBaseMap={setModalBaseMap}
          setViewMap={setViewMap}
        />
        <FilterBoxboundary
          isModalVisibleSearch={isModalVisibleSearch}
          setIsModalVisibleSearch={setIsModalVisibleSearch}
          view={view}
          map={mapLayer}
        />
        <GoToBoundary
          view={view}
          isGoToBoundary={isGoToBoundary}
          setIsGoToBoundary={setIsGoToBoundary}
          isClear={isClear}
        />
        <Drawer
          visible={isRestrictDrawer}
          placement="bottom"
          onClose={() => setIsRestrictDrawer(false)}
          maskStyle={{ background: "transparent" }}
          className="accordion location-detail"
        >
          {currentItems?.map((boundary: any, key: any) => {
            const {
              id,
              name,
              color,
              restrict_date_start,
              restrict_date_end,
              access_type,
              restrict_time_end,
              restrict_time_start,
              description,
              updated,
              remark,
              created_by,
              type,
            } = boundary;
            return (
              <GSP
                key={key}
                txt={name}
                color={color}
                id={id}
                restrict_date_start={restrict_date_start}
                restrict_date_end={restrict_date_end}
                access_type={access_type}
                description={description}
                updated={updated}
                remark={remark}
                created_by={created_by}
                restrict_time_end={restrict_time_end}
                restrict_time_start={restrict_time_start}
                type={type}
              />
            );
          })}
          {pageCount > 0 && (
            <Pagination
              defaultCurrent={1}
              total={pageCount * 10}
              onChange={(event) => handlePageClick(event)}
              style={{ bottom: "10px", float: "right", fontWeight: "bold" }}
            />
          )}
        </Drawer>
        <Measurement view={view} measurement={measurement} />
        <Modal
          title={
            <div
              style={{
                width: "900px",
                cursor: "move",
              }}
            />
          }
          width={900}
          className="map-boundary-basemap"
          onCancel={handleCancelCreateGeometry}
          open={openAddGoemetry}
        >
          <h5 className="accordion-header">สร้างขอบเขตการทำงาน</h5>

          <div className="card-body search-bar">
            <form>
              <div className="grid-story">
                <div className="form-group row">
                  <div className="col-sm-1 color-box-container">
                    {checkStatus === "restricted_area" && (
                      <div
                        className={classnames("color-box", {
                          Xray: iconBoundary === "Xray",
                          chemical: iconBoundary === "chemical",
                          maintenance: iconBoundary === "maintenance",
                          forbiddenPass: iconBoundary === "forbiddenPass",
                          obstruction: iconBoundary === "obstruction",
                        })}
                        onClick={() => {
                          setModalIcon(true);
                        }}
                      />
                    )}
                  </div>
                  <div className="col-sm-11">
                    <input
                      type="text"
                      className={classnames("form-control", {
                        error: locationName === "" && isSubmit,
                      })}
                      id="LocationName"
                      placeholder="ชื่อขอบเขตที่การทำงาน"
                      value={locationName}
                      onChange={(event: any) =>
                        setLocationName(event.target.value)
                      }
                    />
                    {locationName === "" && isSubmit && (
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault1"
                        style={{ fontSize: "14px", color: "red" }}
                      >
                        Please put LocationName
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="grid-story2">
                <div className="check">
                  <div className="form-check ">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked={checkStatus === "restricted_area"}
                      onChange={() => {
                        setCheckStatus("restricted_area");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      พื้นที่ห้ามเข้าชั่วคราว
                    </label>
                  </div>

                  <div className="form-check ">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault1"
                      checked={checkStatus === "safety_mustering"}
                      onChange={() => {
                        setCheckStatus("safety_mustering");
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault1"
                    >
                      จุดรวมพล
                    </label>
                  </div>
                </div>
                {checkStatus === "restricted_area" && (
                  <div className="check">
                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={checkStatusTime === "one_time"}
                        onChange={() => {
                          setCheckStatusTime("one_time");
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexRadioDefault3"
                      >
                        พื้นที่หวงห้ามชั่วคราว
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        checked={checkStatusTime === "daily"}
                        onChange={() => {
                          setCheckStatusTime("daily");
                        }}
                      />
                      <label
                        className="form-check-label "
                        htmlFor="flexRadioDefault4"
                      >
                        พื้นที่หวงห้ามถาวร
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div className="grid-story2">
                {checkStatus !== "safety_mustering" && (
                  <div className="form-group row">
                    <label
                      htmlFor="campaign"
                      className="col-sm-2 col-form-label"
                    >
                      Start
                    </label>
                    <div className="col-sm-8 datatime">
                      {checkStatusTime === "one_time" && (
                        <DatePicker
                          className="form-control"
                          selected={startDate}
                          onChange={(date: any) => date && setStartDate(date)}
                          showTimeSelect
                          locale="el"
                          timeIntervals={5}
                          dateFormat="Pp"
                          timeFormat="p"
                          minDate={new Date()}
                          disabled={checkStatus !== "restricted_area"}
                        />
                      )}
                      {checkStatusTime === "daily" &&
                        checkStatus === "restricted_area" && (
                          <DatePicker
                            selected={startTime}
                            onChange={(date: any) => setStartTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            locale="el"
                            timeFormat="p"
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                          />
                        )}
                    </div>
                  </div>
                )}
                {checkStatus !== "safety_mustering" && (
                  <div className="form-group row">
                    <label
                      htmlFor="campaign"
                      className="col-sm-2 col-form-label"
                    >
                      End
                    </label>
                    <div className="col-sm-8 datatime">
                      {checkStatusTime === "one_time" && (
                        <DatePicker
                          className="form-control"
                          selected={endDate}
                          onChange={(date: any) => date && setEndDate(date)}
                          locale="el"
                          showTimeSelect
                          timeIntervals={5}
                          dateFormat="Pp"
                          timeFormat="p"
                          minDate={startDate}
                          disabled={checkStatus !== "restricted_area"}
                        />
                      )}
                      {checkStatusTime === "daily" &&
                        checkStatus === "restricted_area" && (
                          <DatePicker
                            selected={endTime}
                            onChange={(date: any) => setEndTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            locale="el"
                            timeFormat="p"
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="HH:mm"
                          />
                        )}
                    </div>
                  </div>
                )}
              </div>

              <div className="grid-story point-location">
                <div className="row">
                  <div className="col-sm-1">
                    <label htmlFor="remark" style={{ color: "#707070" }}>
                      หมายเหตุ
                    </label>
                  </div>
                  <div className="col-sm-11">
                    <textarea
                      style={{ padding: 5 }}
                      id="remark"
                      name="remark"
                      value={remark}
                      rows={4}
                      cols={50}
                      onChange={(event: any) => setRemark(event.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div
                className="btn btn-save btn-boundary"
                onClick={addGeometryFunc}
              >
                บันทึกขอบเขตการทำงาน
              </div>
            </form>
          </div>
        </Modal>

        <Modal
          title={
            <div
              style={{
                width: "500px",
                cursor: "move",
              }}
            />
          }
          className="map-boundary-basemap"
          onCancel={handleCancelIcon}
          open={modalIcon}
        >
          <div className="card-body " onClick={() => funcIcon("X-ray")}>
            {" "}
            <div className="row">
              <div className="col-sm-4 ">
                <img
                  src="/images/icon/X-ray.svg"
                  width={30}
                  height={30}
                  alt=""
                />
              </div>
              <div className="col-sm-8 type-basemap">X-ray</div>
            </div>
          </div>
          <div className="card-body " onClick={() => funcIcon("chemical")}>
            <div className="row">
              <div className="col-sm-4 ">
                <img
                  src="/images/icon/chemical.svg"
                  width={30}
                  height={30}
                  alt=""
                />
              </div>
              <div className="col-sm-8 type-basemap">สารเคมีรั่วไหล</div>
            </div>{" "}
          </div>
          <div className="card-body " onClick={() => funcIcon("maintenance")}>
            {" "}
            <div className="row">
              <div className="col-sm-4 ">
                <img
                  src="/images/icon/maintenance.svg"
                  width={30}
                  height={30}
                  alt=""
                />
              </div>
              <div className="col-sm-8 type-basemap">ซ่อมบำรุง</div>
            </div>
          </div>
          <div className="card-body " onClick={() => funcIcon("forbiddenPass")}>
            {" "}
            <div className="row">
              <div className="col-sm-4 ">
                <img
                  src="/images/icon/forbiddenPass.svg"
                  width={30}
                  height={30}
                  alt=""
                />
              </div>
              <div className="col-sm-8 type-basemap">ห้ามผ่านทาง</div>
            </div>
          </div>
          <div className="card-body " onClick={() => funcIcon("obstruction")}>
            {" "}
            <div className="row">
              <div className="col-sm-4 ">
                <img
                  src="/images/icon/obstruction.svg"
                  width={30}
                  height={30}
                  alt=""
                />
              </div>
              <div className="col-sm-8 type-basemap">สิ่งกีดขวาง</div>
            </div>
          </div>
        </Modal>
      </div>
    </AntdLayout>
  );
};
