import ColorConvert from "color-convert";
import moment from "moment";
//const dispatch = useDispatch();
export const createSymbol = ({ color, style, width, outlineColor }: any) => {
  return {
    type: "simple-fill",
    style,
    color,
    outline: {
      color: outlineColor,
      width,
    },
  };
};

export const createGeometry = ({ vertices }: any) => {
  return {
    type: "polygon",
    rings: vertices,
  };
};

export const setUpGraphicClickHandler = (view: any, sketchViewModel: any) => {
  view.on("click", (event: any) => {
    if (sketchViewModel?.state === "active") {
      console.log(sketchViewModel?.state);
      return;
    }
    // view.hitTest(event).then((response: any) => {
    //   const results = response.results;
    //   results.forEach((result: any) => {
    //     if (
    //       result.graphic.layer === sketchViewModel?.layer &&
    //       result.graphic.attributes &&
    //       result.graphic.attributes.newDevelopment
    //     ) {
    //       sketchViewModel.update([result.graphic], {
    //         tool: "reshape",
    //       });
    //     }
    //   });
    // });
  });
};

export const addGraphics = ({ expired, Graphic, polygonArr = [] }: any) => {
  polygonArr.map((polygon: any) => {
    const { type, access_type } = polygon;

    const vertices = polygon.coordinate;

    // const color = ColorConvert.hex.rgb(polygon.color);
    // const greyColor = ColorConvert.hex.rgb("#AAA");

    const geometry = createGeometry({ vertices });

    let conclusionColor;
    // expiredValue && type === "restricted_area" ? greyColor : color;

    if (type === "restricted_area" && expired)
      conclusionColor = ColorConvert.hex.rgb("#AAA");
    else if (type === "restricted_area" && !expired)
      conclusionColor = ColorConvert.hex.rgb("#ffcd00");
    else conclusionColor = ColorConvert.hex.rgb("#0BFC00");

    const measureThisAction = {
      title: "Delete",
      id: "Delete",
      image: "./images/icon/bin.svg",
    };
    let popup: any = [];
    if (type === "restricted_area") {
      popup = [
        {
          type: "fields",
          fieldInfos: [
            {
              fieldName: "name",
              label: "ชื่อพื้นที่",
            },
            {
              fieldName: "type",
              label: "ชนิดของพื้นที่",
            },
            {
              fieldName: "access_type",
              label: "ชนิดของเวลา",
            },
            {
              fieldName: "restrict_date_start",
              label: "วันที่เริ่มต้น",
            },
            {
              fieldName: "restrict_date_end",
              label: "วันที่สิ้นสุด",
            },
            {
              fieldName: "restrict_time_start",
              label: "เวลาเริ่มต้น",
            },
            {
              fieldName: "restrict_time_end",
              label: "เวลาสิ้นสุด",
            },
            {
              fieldName: "remark",
              label: "หมายเหตุ",
            },
            {
              fieldName: "created_by",
              label: "ผู้ที่ส้ราง",
            },
            {
              fieldName: "created_by_email",
              label: "อีเมล",
            },
            {
              fieldName: "created_by_phone",
              label: "เบอร์โทร",
            },
            {
              fieldName: "created_by_role",
              label: "ประเภทบัญชีของผู้สร้าง",
            },
            {
              fieldName: "updated",
              label: "วันเวลาที่ส้ราง",
            },
          ],
        },
      ];
    } else {
      popup = [
        {
          type: "fields",
          fieldInfos: [
            {
              fieldName: "name",
              label: "ชื่อพื้นที่",
            },
            {
              fieldName: "type",
              label: "ชนิดของพื้นที่",
            },
            {
              fieldName: "remark",
              label: "หมายเหตุ",
            },
            {
              fieldName: "created_by",
              label: "ผู้ที่ส้ราง",
            },
            {
              fieldName: "created_by_email",
              label: "อีเมล",
            },
            {
              fieldName: "created_by_phone",
              label: "เบอร์โทร",
            },
            {
              fieldName: "created_by_role",
              label: "ประเภทบัญชีของผู้สร้าง",
            },

            {
              fieldName: "updated",
              label: "วันเวลาที่ส้ราง",
            },
          ],
        },
      ];
    }
    const symbol = createSymbol({
      color: conclusionColor,
      style: "diagonal-cross",
      width: 2,
      outlineColor: conclusionColor,
    });
    const newDevelopmentGraphic = new Graphic({
      geometry,
      symbol,
      attributes: {
        ...polygon,
        type:
          type === "restricted_area" ? "พื้นที่ห้ามเข้าชั่วคราว" : "จุดรวมพล",
        access_type:
          access_type === "one_time"
            ? "พื้นที่หวงห้ามชั่วคราว"
            : "พื้นที่หวงห้ามถาวร",
      },
      popupTemplate: {
        title: "คำบรรยาย",
        content: popup,
        actions: [measureThisAction],
      },
    });

    expired?.add(newDevelopmentGraphic);
    return null;
  });
};
export const addGraphicsActive = ({
  active,
  Graphic,
  polygonArr = [],
}: any) => {
  polygonArr.map((polygon: any) => {
    const { expired, type, coordinate, access_type } = polygon;

    // const color = ColorConvert.hex.rgb(polygonColor);
    // const greyColor = ColorConvert.hex.rgb("#AAA");

    const geometry = createGeometry({ vertices: coordinate });

    let conclusionColor;
    //  expired && type === "restricted_area" ? greyColor : color;
    if (type === "restricted_area" && expired)
      conclusionColor = ColorConvert.hex.rgb("#AAA");
    else if (type === "restricted_area" && !expired)
      conclusionColor = ColorConvert.hex.rgb("#ffcd00");
    else conclusionColor = ColorConvert.hex.rgb("#0BFC00");
    const symbol = createSymbol({
      color: conclusionColor,
      style: "diagonal-cross",
      width: 2,
      outlineColor: conclusionColor,
    });

    const measureThisAction = {
      title: "Delete",
      id: "Delete",
      image: "./images/icon/bin.svg",
    };

    let popup: any = [];
    if (type === "restricted_area") {
      if (access_type === "one_time") {
        popup = [
          {
            type: "fields",
            fieldInfos: [
              {
                fieldName: "name",
                label: "ชื่อพื้นที่",
              },
              {
                fieldName: "type",
                label: "ชนิดของพื้นที่",
              },
              {
                fieldName: "access_type",
                label: "ชนิดของเวลา",
              },
              {
                fieldName: "restrict_date_start",
                label: "วันที่เริ่มต้น",
              },
              {
                fieldName: "restrict_date_end",
                label: "วันที่สิ้นสุด",
              },
              {
                fieldName: "restrict_time_start",
                label: "เวลาเริ่มต้น",
              },
              {
                fieldName: "restrict_time_end",
                label: "เวลาสิ้นสุด",
              },
              {
                fieldName: "remark",
                label: "หมายเหตุ",
              },
              {
                fieldName: "created_by",
                label: "ผู้ที่สร้าง",
              },
              {
                fieldName: "created_by_email",
                label: "อีเมล",
              },
              {
                fieldName: "created_by_phone",
                label: "เบอร์โทร",
              },
              {
                fieldName: "created_by_role",
                label: "ประเภทบัญชีของผู้สร้าง",
              },

              {
                fieldName: "updated",
                label: "วันเวลาที่ส้ราง",
              },
            ],
          },
        ];
      } else {
        popup = [
          {
            type: "fields",
            fieldInfos: [
              {
                fieldName: "name",
                label: "ชื่อพื้นที่",
              },
              {
                fieldName: "type",
                label: "ชนิดของพื้นที่",
              },
              {
                fieldName: "access_type",
                label: "ชนิดของเวลา",
              },
              {
                fieldName: "restrict_time_start",
                label: "เวลาเริ่มต้น",
              },
              {
                fieldName: "restrict_time_end",
                label: "เวลาสิ้นสุด",
              },
              {
                fieldName: "remark",
                label: "หมายเหตุ",
              },
              {
                fieldName: "created_by",
                label: "ผู้ที่สร้าง",
              },
              {
                fieldName: "created_by_email",
                label: "อีเมล",
              },
              {
                fieldName: "created_by_phone",
                label: "เบอร์โทร",
              },
              {
                fieldName: "created_by_role",
                label: "ประเภทบัญชีของผู้สร้าง",
              },

              {
                fieldName: "updated",
                label: "วันเวลาที่ส้ราง",
              },
            ],
          },
        ];
      }
    } else {
      popup = [
        {
          type: "fields",
          fieldInfos: [
            {
              fieldName: "name",
              label: "ชื่อพื้นที่",
            },
            {
              fieldName: "type",
              label: "ชนิดของพื้นที่",
            },
            {
              fieldName: "remark",
              label: "หมายเหตุ",
            },
            {
              fieldName: "created_by",
              label: "ผู้ที่สร้าง",
            },
            {
              fieldName: "created_by_email",
              label: "อีเมล",
            },
            {
              fieldName: "created_by_phone",
              label: "เบอร์โทร",
            },
            {
              fieldName: "created_by_role",
              label: "ประเภทบัญชีของผู้สร้าง",
            },

            {
              fieldName: "updated",
              label: "วันเวลาที่ส้ราง",
            },
          ],
        },
      ];
    }
    const newDevelopmentGraphic = new Graphic({
      geometry,
      symbol,
      attributes: {
        ...polygon,
        restrict_date_start: moment(polygon.restrict_date_start).format(
          "DD/MM/YYYY"
        ),
        restrict_date_end: moment(polygon.restrict_date_end).format(
          "DD/MM/YYYY"
        ),
        type:
          type === "restricted_area" ? "พื้นที่ห้ามเข้าชั่วคราว" : "จุดรวมพล",
        access_type:
          access_type === "one_time"
            ? "พื้นที่หวงห้ามชั่วคราว"
            : "พื้นที่หวงห้ามถาวร",
      },
      popupTemplate: {
        title: "คำบรรยาย",
        content: popup,

        actions: [measureThisAction],
      },
    });
    const textSymbol = {
      type: "text", // autocasts as new TextSymbol()
      color: "white",
      haloColor: "black",
      haloSize: "1px",
      text: polygon.name,
      xoffset: -4,
      yoffset: -4,
      font: {
        // autocast as new Font()
        size: 15,
        family: "sans-serif",
      },
    };

    const textGraphic = new Graphic({
      geometry,
      symbol: textSymbol,
    });
    let total;
    const backgroundtext = polygon.name.length;
    total = backgroundtext * 10 + 20;
    let symbolbackground = {
      type: "picture-marker", // autocasts as new PictureFillSymbol()
      url: "./images/black-color.jpg",
      width: `${total}px`,
      height: "35px",
      outline: {
        style: "solid",
      },
    };

    let iconURL;
    if (polygon.area_type === "visitor") iconURL = "./images/icon/visitor.png";
    else if (polygon.area_type === "safety")
      iconURL = "./images/icon/safety.png";
    else if (polygon.area_type === "fireWatcher")
      iconURL = "./images/icon/fire-watcher.png";
    else if (polygon.area_type === "chemical")
      iconURL = "./images/icon/chemical.svg";
    else if (polygon.area_type === "maintenance")
      iconURL = "./images/icon/maintenance.svg";
    else if (polygon.area_type === "forbiddenPass")
      iconURL = "./images/icon/forbiddenPass.svg";
    else if (polygon.area_type === "obstruction")
      iconURL = "./images/icon/obstruction.svg";
    else if (polygon.area_type === "X-ray") iconURL = "./images/icon/X-ray.svg";
    let symbolIcon = {
      type: "picture-marker", // autocasts as new PictureFillSymbol()
      url: iconURL,
      width: `35px`,
      height: "35px",
      // xoffset: -,
      yoffset: 25,
    };
    const icon = new Graphic({
      geometry,
      symbol: symbolIcon,
    });
    const blackground = new Graphic({
      geometry,
      symbol: symbolbackground,
    });
    active?.addMany([newDevelopmentGraphic, blackground, icon, textGraphic]);

    return null;
  });
};
