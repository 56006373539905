/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Swal from "sweetalert2";

import { Link } from "react-router-dom";
import { Table } from "antd";

import { THS_API } from "../../../constants";

import type { ColumnsType } from "antd/es/table";

export const RoleList: React.FC<any> = () => {
  const [roleLists, setRoleList] = React.useState<any>([]);
  const [roleName, setRoleName] = React.useState<any>("");
  const [status, setStatus] = React.useState<any>("");

  React.useEffect(() => {
    funcRoleList();
  }, []);

  const funcRoleList = async () => {
    const res = await THS_API.get("/roles");

    setRoleList(res.data.data);
    // const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
  };
  const convertRole = (role: any) => {
    if (role === "active") return "กำลังใช้งาน";
    else if (role === "inactive") return "ไม่ได้ใช้งาน";
  };
  const funcRoleSearch = async () => {
    const params: any = {};
    if (status) {
      params.type = status;
    }
    if (roleName) {
      params.name = roleName;
    }
    const res = await THS_API.get("/roles", { params });
    setRoleList(res.data.data);
  };
  const handleRoleNam = (event: any) => {
    const { value } = event.target;

    setRoleName(value);
  };
  const handleStatus = (event: any) => {
    const { value } = event.target;

    setStatus(value);
  };
  const deleteRole = async (id: any) => {
    Swal.fire({
      title: "คุณแน่ใจที่จะลบ?",

      showCancelButton: true,

      confirmButtonColor: "#d33",
      cancelButtonColor: "#ABB2B9 ",
      confirmButtonText: "Delete",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(async (result: any) => {
      if (result.isConfirmed) {
        try {
          const url = `/roles/${id}`;
          const res = await THS_API.delete(url);
          if (res.data.statusCode === 200) {
            Swal.fire({
              iconColor: "#F71A0C",
              icon: "success",
              color: "#F71A0C",
              title: "ลบสำเร็จ!",
              showConfirmButton: false,
              timer: 1500,
            });
            const res = await THS_API.get("/roles");
            setRoleList(res.data.data);
          }
        } catch (error) {
          Swal.fire({
            icon: "info",
            title: "เกิดข้อผิดพลาด " + error,
          });
        }
      }
    });
  };
  interface DataType {}

  const columns: ColumnsType<DataType> = [
    {
      title: "ชื่อสิทธิ์",
      // dataIndex: "priority",
      sorter: (a: any, b: any) => {
        return a.description.localeCompare(b.name);
      },
      render: (a) => {
        return <div style={{ textAlign: "left" }}>{a.description}</div>;
      },
      align: "center",
      width: "46%",
    },
    {
      title: "สถานะ",
      //   dataIndex: "description",
      sorter: (a: any, b: any) => {
        return a.type.localeCompare(b.type);
      },
      render: (a) => {
        return (
          <div className={a.type === "active" ? "green" : "red"}>
            {convertRole(a.type)}
          </div>
        );
      },
      align: "center",
      width: "46%",
    },
    {
      render: (a) => {
        return (
          <div className="edit">
            {a.name !== "admin" && (
              <a
                data-bs-toggle="modal"
                data-bs-target="#deleteDone"
                onClick={() => deleteRole(a.id)}
              >
                <img src="/images/icon/bin.svg" width={20} height={20} alt="" />
              </a>
            )}
            {a.name !== "admin" && (
              <Link to={`/role/edit/${a.id}`}>
                <a>
                  <img
                    src="/images/icon/edit.svg"
                    width={20}
                    height={20}
                    alt=""
                  />
                </a>
              </Link>
            )}
          </div>
        );
      },
      align: "center",
      width: "8%",
    },
  ];
  return (
    <div>
      <section>
        <div>
          <div className="header-topic">
            <h1>สิทธิ์การเข้าใช้งาน</h1>
            <Link to="/role/create">
              <div className="btn btn-import">
                <a style={{ color: "white" }}> + สร้างสิทธิ์การใช้งาน</a>
              </div>
            </Link>
          </div>
          <div className="card-body search-bar">
            <form>
              <div className="grid-story3">
                <div className="form-group">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    ></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="ชื่อสิทธิ์ใช้งาน"
                      id="#"
                      onChange={handleRoleNam}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlTextarea1"
                      className="form-label"
                    ></label>
                    <select
                      className="form-control"
                      id="#"
                      onChange={handleStatus}
                    >
                      <option value="">สถานะ</option>
                      <option value="active">กำลังใช้งาน</option>
                      <option value="inactive">ไม่ได้ใช้งาน</option>
                    </select>
                  </div>
                </div>
                <div
                  className="btn btn-save"
                  style={{
                    marginTop: "15px",
                    width: "130px",
                    marginRight: 0,
                  }}
                  onClick={funcRoleSearch}
                >
                  ค้นหา
                </div>
              </div>
            </form>
          </div>
          {roleLists.length > 0 && (
            <Table columns={columns} dataSource={roleLists} />
          )}
        </div>
      </section>
    </div>
  );
};
