import React from "react";
import moment from "moment";

import { Select } from "antd";

import SortingTable from "components/SortingTable";

import { StartDate, EndDate } from "components/datepicker";

import { THS_API } from "../../../constants";

import { defaultColumns } from "./_tableColumn";
import { useReportListFilter } from "./hooks";
import { useSelector } from "react-redux";
import { selectDescription } from "reducers/rolesReducer";
import { selectUser } from "reducers/userReducer";
import "./index.css";

const DefaultReport = ({
  reportType,
  setReportType,
  reportInput,
  reportInputWorkpermitSelect,
  setReportInput,
  setReportInputWorkpermitSelect,
  setStartDateWholeReport,
  setEndDateWholeReport,
}: any) => {
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [apiStatus, setApiStatus] = React.useState("start");
  const [query, setQuery] = React.useState([]);
  const [selectWorpermit, setSelectWorpermit] = React.useState([]);
  const [reportTypeQuery, setReportTypeQuery] = React.useState("people");

  const userData = useSelector(selectUser);
  const description = useSelector(selectDescription);
  const reportAPI = async (report: string, searchType: string) => {
    const dataStartDate = moment(startDate).format("YYYY-MM-DD");
    const dataEndDate = moment(endDate).format("YYYY-MM-DD");
    setApiStatus("loading");
    setQuery([]);

    const params: any = {
      start_date: dataStartDate,
      end_date: dataEndDate,
      type: reportType,
    };

    if (searchType) {
      if (searchType === "wpmNo" && reportInput) {
        params[searchType] = reportInput.split("|")[0];
      } else params[searchType] = reportInput;
    }
    const res = await THS_API.get(report, {
      params,
    });

    const { status, data: payload } = res;
    if (status === 200) {
      const { data } = payload;
      setQuery(data);
      setApiStatus("success");
    }
  };

  const handleReportListFilter = () => {
    if (reportType === "people" && reportInput) {
      if (parseInt(reportInput[0])) reportAPI("/report/move_tracking", "cid");
      else reportAPI("/report/move_tracking", "fullName");
    } else if (reportType === "people" && !reportInput)
      reportAPI("/report/move_tracking", "");
    else if (reportType === "workpermit_id")
      reportAPI("/report/move_tracking", "wpmNo");
    else if (reportType === "dev_eui" && !reportInput)
      reportAPI("/report/move_tracking", "");
    else if (reportType === "dev_eui" && reportInput)
      reportAPI("/report/move_tracking", "eui");

    setReportTypeQuery(reportType);
  };

  const resetFilter = async () => {
    const date = new Date();

    const dataStartDate = moment(date).format("YYYY-MM-DD");
    const dataEndDate = moment(date).format("YYYY-MM-DD");

    setStartDate(date);
    setEndDate(date);
    setReportTypeQuery("people");
    setReportInput("");
    setReportInputWorkpermitSelect("");
    setApiStatus("loading");
    setQuery([]);

    const params = {
      start_date: dataStartDate,
      end_date: dataEndDate,
      type: "people",
    };
    const res = await THS_API.get("/report/move_tracking", {
      params,
    });

    const { status, data: payload } = res;
    if (status === 200) {
      const { data } = payload;
      setQuery(data);
      setApiStatus("success");
    }
  };

  useReportListFilter({ handleReportListFilter });

  React.useEffect(() => {
    const selectWorkmit = async () => {
      const date = new Date();
      const dataDate = moment(date).format("YYYY-MM-DD");
      const params = {
        start_date: dataDate,
        end_date: dataDate,
        type: "workpermit_id",
      };

      const res = await THS_API.get("/report/move_tracking", {
        params,
      });

      setSelectWorpermit(res.data.data);
    };
    selectWorkmit();
  }, []);

  return (
    <>
      <div className="default-report card-body search-bar">
        <form className="form">
          <div className="grid-story6">
            <div className="form-group">
              <div className="mt-3 mb-3">
                <select
                  className="form-control"
                  onChange={(e) => {
                    setReportType(e.target.value);
                    setReportInput("");
                    setReportInputWorkpermitSelect("");
                  }}
                  defaultValue={reportType}
                >
                  <option value="people">ข้อมูลรายบุคคล</option>
                  <option value="workpermit_id">
                    ข้อมูลหมายเลขใบอนุญาตทำงาน
                  </option>
                  <option value="dev_eui">ข้อมูลอุปกรณ์</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              {reportType === "dev_eui" && (
                <div className="mt-3 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="หมายเลขอุปกรณ์"
                    value={reportInput}
                    onChange={(e) => setReportInput(e.target.value)}
                  />
                </div>
              )}
              {reportType === "people" && (
                <div className="mt-3 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={
                      reportType === "people"
                        ? "ชื่อ - นามสกุล / บัตรประชาชน"
                        : "เลข workpermit id"
                    }
                    value={reportInput}
                    onChange={(e) => setReportInput(e.target.value)}
                  />
                </div>
              )}
              {reportType === "workpermit_id" && (
                <div className="mt-3 mb-3">
                  <Select
                    className="form-control"
                    showSearch
                    placeholder="หมายเลขใบอนุญาตทำงาน"
                    value={reportInputWorkpermitSelect}
                    onChange={(value) => {
                      setReportInput(value.split("|")[1]);
                      setReportInputWorkpermitSelect(value);
                    }}
                  >
                    <Select.Option value="">เลือกใบอนุญาตทำงาน</Select.Option>
                    {selectWorpermit?.map((work: any, key: number) => {
                      const { work_permit_no, work_permit_id } = work;
                      return (
                        <Select.Option
                          key={key}
                          value={`${work_permit_no}|${work_permit_id}`}
                        >
                          {work_permit_no}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              )}
            </div>
            <div className="form-group">
              <div className="mt-3 mb-3">
                <StartDate
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(date: any) => {
                    const start = moment(date);
                    const end = moment(endDate);
                    setStartDate(date);
                    setStartDateWholeReport(date);
                    if (start.diff(end) > 0) setEndDate(date);
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="mt-3 mb-3">
                <EndDate
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(date: any) => {
                    setEndDate(date);
                    setEndDateWholeReport(date);
                  }}
                />
              </div>
            </div>
            <div className="btn btn-save" onClick={handleReportListFilter}>
              ค้นหา
            </div>
            <div className="btn btn-save" onClick={resetFilter}>
              ล้าง
            </div>
          </div>
        </form>
      </div>
      <SortingTable
        apiStatus={apiStatus}
        query={query}
        columns={defaultColumns({
          startDate,
          endDate,
          reportTypeQuery,
          description,
          userData,
        })}
      />
    </>
  );
};
export default DefaultReport;
