import Highcharts from "highcharts/highstock";
import PieChart from "highcharts-react-official";

import { useSelector } from "react-redux";
import { selectChartNotification } from "reducers/notificationReducers";

import "chartjs-plugin-datalabels";

export default function CharNotification({ allowChartUpdate }) {
  const notiChart = useSelector(selectChartNotification);

  Highcharts.setOptions({
    lang: {
      thousandsSep: ",",
    },
  });
  const options = {
    credits: {
      enabled: false,
    },
    chart: {
      height: 240,
      width: 280,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: "",
    },

    tooltip: {
      pointFormat: " <b>{series.name}  {point.y}  การแจ้งเตือน</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    // legend: {
    //   verticalAlign: "top",
    // },
    plotOptions: {
      pie: {
        thickness: 40,
        allowPointSelect: true,
        colors: [
          "rgba(187,40,17, 1)",
          "rgba(246,200,65, 1)",
          "rgba(49,130,206, 1)",
        ],
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          formatter: function () {
            return this.point.y === 0 ? "" : this.point.y;
          },
          distance: -20,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "จำนวน",
        innerSize: "50%",
        colorByPoint: true,
        data: [
          {
            name: "สูง",
            y: notiChart[0],
          },
          {
            name: "ปานกลาง",
            y: notiChart[1],
          },
          {
            name: "ต่ำ",
            y: notiChart[2],
          },
        ],
        // showInLegend: true,
      },
    ],
  };
  // const socket = React.useContext(SocketContext);
  // React.useEffect(() => {
  //   fetchData();
  // }, []);
  // React.useEffect(() => {
  //   // as soon as the component is mounted, do the following tasks:
  //   socket.on('dashboard', (dashboard) => {
  //     fetchData();
  //     // setCharLocation(dashboard.data.summaryReport);
  //   });

  //   return () => {
  //     socket.off('dashboard');
  //   };
  // }, [socket]);
  // const fetchData = async () => {
  //   const res = await THS_API.get('/dashboard/summary-report');

  //   setCharLocationData(res.data.data);
  // };
  if (notiChart.length)
    return (
      <div
        style={{
          margin: "0 auto",
          overflow: "hidden",
          borderRadius: 8,
        }}
      >
        <PieChart
          allowChartUpdate={allowChartUpdate}
          highcharts={Highcharts}
          options={options}
        />
      </div>
    );
  return <></>;
}
