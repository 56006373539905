/* eslint-disable no-console */
import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import classnames from "classnames";

import { useNavigate } from "react-router-dom";

import { AntdLayout, Form, Card, Input, Button } from "@pankod/refine-antd";
import { useLogin } from "@pankod/refine-core";
import { useMsal } from "@azure/msal-react";

import { microsoftAD } from "../../authConfig";
import redirectLoginUrl from "../../components/redirectLoginUrl";
import { THS_TOKEN_KEY, THS_EXPIRED, THS_API } from "../../constants";
import type { ILoginForm } from "./auth";
import "./styles.css";

const LOGIN_API = axios.create({
  baseURL: process.env.REACT_APP_API,
});

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [form] = Form.useForm<ILoginForm>();
  const [passType, setPassType] = React.useState("password");

  const { mutate: login } = useLogin<ILoginForm>();

  const inputPass = () => {
    if (passType === "password") setPassType("text");
    else setPassType("password");
  };
  //  let token = window.location.search.substring(1).split("=");
  // const key = String(token[0]);

  const queryString: any = window.location.search;

  React.useEffect(() => {
    const token = window.location.search.substring(1).split("=");
    const key = String(token[0]);
    if (queryString !== "" && key !== "token") navigate("/login");
  }, [navigate, queryString, queryString.length]);

  React.useEffect(() => {
    const loginAD = async () => {
      if (accounts.length) {
        try {
          const { username: email } = accounts[0];

          const res = await LOGIN_API.post("/auth/login/ad", {
            email,
          });
          if (res.status === 200) {
            const { access_token, tokenExpirationInstant } = res.data.data;

            localStorage.setItem(THS_TOKEN_KEY, access_token);

            localStorage.setItem(THS_EXPIRED, tokenExpirationInstant);
            THS_API.defaults.headers.common.Authorization = `Bearer ${access_token}`;

            const response = await THS_API.get("/users/profile");

            const { registrations } = response.data.data.user;

            const currentUser = registrations.find(
              (obj: any) => obj.applicationId === process.env.REACT_APP_ID_APP
            ) || [{ roles: [""] }];

            const role = currentUser.roles[0];

            const roleUrl = encodeURI(
              `${process.env.REACT_APP_API}/roles/name/${role}`
            );
            const res1 = await THS_API.get(roleUrl);

            if (res1.status === 200) {
              const url = await redirectLoginUrl(role);

              window.location.href = url;
            } else {
              Swal.fire({
                text: "ชื่อผู้ใช้ยังไม่มีสิทธิ์การใช้งาน",
                imageUrl: "assets/img/icon/remove.svg",
                imageHeight: 70,
                confirmButtonColor: "#1b1464",
                confirmButtonText: "ตกลง",
              });
            }
          }
        } catch (error) {
          Swal.fire({
            text: "ชื่อผู้ใช้ยังไม่มีสิทธิ์การใช้งาน",
            imageUrl: "assets/img/icon/remove.svg",
            imageHeight: 70,
            confirmButtonColor: "#1b1464",
            confirmButtonText: "ตกลง",
          });
        }
      }
    };
    loginAD();
  }, [accounts]);

  const handleLogin = () => {
    instance
      .loginRedirect(microsoftAD)

      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <AntdLayout className="login-bg">
      <Card className="card-body">
        <div className="img-header">
          {" "}
          <img
            src="/images/icon/image_2022-11-02_10-15-47.png"
            alt="login-background"
          />
        </div>

        <h1 className="login-h1" style={{ marginTop: "30px" }}>
          PEOPLE &amp; MOTION TRACKING
        </h1>
        <h1 className="text-login login-h1" style={{ marginBottom: "30px" }}>
          CONTRACKTOR BOUNDARY
        </h1>

        <Form<ILoginForm>
          layout="vertical"
          form={form}
          onFinish={(values) => {
            login(values);
          }}
          requiredMark={false}
          initialValues={{
            remember: false,
          }}
        >
          <Form.Item
            name="username"
            className="form-group username-input"
            rules={[{ required: true }]}
          >
            <Input
              size="large"
              className="form-control"
              placeholder="Username"
            />
          </Form.Item>

          <div className="password-box">
            <Form.Item
              name="password"
              className="form-group password-input"
              rules={[{ required: true }]}
              style={{ marginBottom: "12px" }}
            >
              <Input
                type={passType}
                size="large"
                className="form-control"
                placeholder="●●●●●●●●"
              />
            </Form.Item>
            <img
              className={classnames("isShowPass", {
                show: passType === "text",
              })}
              onClick={inputPass}
              src="/images/icon/view.svg"
              alt="view"
            />
            <img
              className={classnames("isShowPass", {
                show: passType === "password",
              })}
              onClick={inputPass}
              src="/images/icon/hide.svg"
              alt="hide"
            />
          </div>
          <br />
          <Button
            className="btn btn-submit"
            type="primary"
            size="large"
            htmlType="submit"
          >
            Login
          </Button>
          <div className="row login-row">
            <div className="col-sm-5 login-line"></div>
            <div className="col-sm-2 login-text">or</div>
            <div className="col-sm-5 login-line"></div>
          </div>

          <br />
          <Button
            className="btn btn-submit"
            type="primary"
            size="large"
            onClick={handleLogin}
            style={{ backgroundColor: "#40A1B7" }}
          >
            เข้าสู่ระบบ PTT AD
          </Button>

          <br />
          <div className="row login-row">
            <div className="col-sm-3 login-line"></div>
            <div className="col-sm-6 login-text">กลับสู่หน้า WEB PORTAL</div>
            <div className="col-sm-3 login-line"></div>
          </div>
          <br />
          <Button
            className="btn btn-submit"
            type="primary"
            size="large"
            href={process.env.REACT_APP_USA_POR}
            style={{ backgroundColor: "#F9B32B" }}
          >
            PTT SHIP Portal
          </Button>
        </Form>
      </Card>
    </AntdLayout>
  );
};
