/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import moment from "moment";
import Swal from "sweetalert2";
import ColorConvert from "color-convert";

import { useSelector, useDispatch } from "react-redux";
import { setDefaultOptions, loadModules } from "esri-loader";

import {
  selectPTTGeometry,
  setEmployee,
  selectMapMonitoring,
} from "reducers/mapMonitoring";
import { selectUser } from "reducers/userReducer";

import { THS_API } from "../../../constants";

import {
  initMap,
  initPttEmployeeAPI,
  initPttPolygonAPI,
  initPttPolygonTextAPI,
  initSearchGeometry,
  initSearchGeometryText,
} from "./_functions";

import "./index.css";
setDefaultOptions({ css: true });
// import fi from 'date-fns/esm/locale/fi/index.js';
const createSymbol = ({ color, style, width, outlineColor }: any) => {
  return {
    type: "simple-fill",
    style,
    color,
    outline: {
      color: outlineColor,
      width,
    },
  };
};

const createGeometry = ({ vertices }: any) => {
  return {
    type: "polygon",
    rings: vertices,
  };
};

const addGraphics = ({
  restrictArr,
  restrictAreaLayer,
  Graphic,
  mapZoom,
}: any) => {
  restrictArr.map((polygon: any) => {
    const { name, coordinate, type, active, access_type } = polygon;
    const vertices = coordinate;
    const fulldatatext = `${name} : ${active} คน`;
    let color;
    if (type === "restricted_area") color = ColorConvert.hex.rgb("#ffcd00");
    else color = ColorConvert.hex.rgb("#0BFC00");

    const geometry = createGeometry({ vertices });

    const symbol = createSymbol({
      color,
      style: "diagonal-cross",
      width: 2,
      outlineColor: color,
    });
    let popup: any = [];
    if (type === "restricted_area") {
      if (access_type === "one_time") {
        popup = [
          {
            type: "fields",
            fieldInfos: [
              {
                fieldName: "name",
                label: "ชื่อพื้นที่",
              },
              {
                fieldName: "type",
                label: "ชนิดของพื้นที่",
              },
              {
                fieldName: "access_type",
                label: "ชนิดของเวลา",
              },
              {
                fieldName: "restrict_date_start",
                label: "วันที่เริ่มต้น",
              },
              {
                fieldName: "restrict_date_end",
                label: "วันที่สิ้นสุด",
              },
              {
                fieldName: "restrict_time_start",
                label: "เวลาเริ่มต้น",
              },
              {
                fieldName: "restrict_time_end",
                label: "เวลาสิ้นสุด",
              },
              {
                fieldName: "remark",
                label: "หมายเหตุ",
              },
              {
                fieldName: "created_by",
                label: "ผู้ที่สร้าง",
              },
              {
                fieldName: "created_by_email",
                label: "อีเมล",
              },
              {
                fieldName: "created_by_phone",
                label: "เบอร์โทร",
              },
              {
                fieldName: "created_by_role",
                label: "ประเภทบัญชีของผู้สร้าง",
              },

              {
                fieldName: "updated",
                label: "วันเวลาที่ส้ราง",
              },
            ],
          },
        ];
      } else {
        popup = [
          {
            type: "fields",
            fieldInfos: [
              {
                fieldName: "name",
                label: "ชื่อพื้นที่",
              },
              {
                fieldName: "type",
                label: "ชนิดของพื้นที่",
              },
              {
                fieldName: "access_type",
                label: "ชนิดของเวลา",
              },

              {
                fieldName: "restrict_time_start",
                label: "เวลาเริ่มต้น",
              },
              {
                fieldName: "restrict_time_end",
                label: "เวลาสิ้นสุด",
              },
              {
                fieldName: "remark",
                label: "หมายเหตุ",
              },
              {
                fieldName: "created_by",
                label: "ผู้ที่สร้าง",
              },
              {
                fieldName: "created_by_email",
                label: "อีเมล",
              },
              {
                fieldName: "created_by_phone",
                label: "เบอร์โทร",
              },
              {
                fieldName: "created_by_role",
                label: "ประเภทบัญชีของผู้สร้าง",
              },

              {
                fieldName: "updated",
                label: "วันเวลาที่ส้ราง",
              },
            ],
          },
        ];
      }
    } else {
      popup = [
        {
          type: "fields",
          fieldInfos: [
            {
              fieldName: "name",
              label: "ชื่อพื้นที่",
            },
            {
              fieldName: "type",
              label: "ชนิดของพื้นที่",
            },
            {
              fieldName: "remark",
              label: "หมายเหตุ",
            },
            {
              fieldName: "created_by",
              label: "ผู้ที่สร้าง",
            },
            {
              fieldName: "created_by_email",
              label: "อีเมล",
            },
            {
              fieldName: "created_by_phone",
              label: "เบอร์โทร",
            },
            {
              fieldName: "created_by_role",
              label: "ประเภทบัญชีของผู้สร้าง",
            },

            {
              fieldName: "updated",
              label: "วันเวลาที่ส้ราง",
            },
          ],
        },
      ];
    }

    const newDevelopmentGraphic = new Graphic({
      geometry,
      symbol,
      attributes: {
        ...polygon,
        restrict_date_start: moment(polygon.restrict_date_start).format(
          "DD/MM/YYYY"
        ),
        restrict_date_end: moment(polygon.restrict_date_end).format(
          "DD/MM/YYYY"
        ),
        type:
          type === "restricted_area" ? "พื้นที่ห้ามเข้าชั่วคราว" : "จุดรวมพล",
        access_type:
          access_type === "one_time"
            ? "พื้นที่หวงห้ามชั่วคราว"
            : "พื้นที่หวงห้ามถาวร",
      },
      popupTemplate: {
        title: "รายละเอียดของพื้นที่",
        content: popup,
      },
    });

    const textSymbol = {
      type: "text", // autocasts as new TextSymbol()
      color,
      haloColor: "black",
      haloSize: "1px",
      text: fulldatatext,
      xoffset: -4,
      yoffset: -4,
      font: {
        size: mapZoom >= 18 ? 13 : 0,
        family: "sans-serif",
      },
    };
    let total;
    const backgroundtext = polygon.name.length;
    total = backgroundtext * 18 + 13;
    let symbolbackground = {
      type: "picture-marker", // autocasts as new PictureFillSymbol()
      url: "./images/black-color.jpg",
      width: `${mapZoom >= 18 ? total : 0}px`,
      height: "35px",
      outline: {
        style: "solid",
      },
    };

    let iconURL;
    if (polygon.area_type === "visitor") iconURL = "./images/icon/visitor.png";
    else if (polygon.area_type === "safety")
      iconURL = "./images/icon/safety.png";
    else if (polygon.area_type === "fireWatcher")
      iconURL = "./images/icon/fire-watcher.png";
    else if (polygon.area_type === "chemical")
      iconURL = "./images/icon/chemical.svg";
    else if (polygon.area_type === "maintenance")
      iconURL = "./images/icon/maintenance.svg";
    else if (polygon.area_type === "forbiddenPass")
      iconURL = "./images/icon/forbiddenPass.svg";
    else if (polygon.area_type === "obstruction")
      iconURL = "./images/icon/obstruction.svg";
    else if (polygon.area_type === "X-ray") iconURL = "./images/icon/X-ray.svg";
    let symbolIcon = {
      type: "picture-marker", // autocasts as new PictureFillSymbol()
      url: iconURL,
      width: `35px`,
      height: "35px",
      // xoffset: -,
      yoffset: 25,
    };
    const icon = new Graphic({
      geometry,
      symbol: symbolIcon,
    });
    const blackground = new Graphic({
      geometry,
      symbol: symbolbackground,
    });
    const textGraphic = new Graphic({
      geometry,
      symbol: textSymbol,
    });
    restrictAreaLayer.addMany([
      newDevelopmentGraphic,
      blackground,
      icon,
      textGraphic,
    ]);
    return null;
  });
};

const EsriMapWithNoSSR = ({
  setMap,
  mapZoom,
  setMapZoom,
  setZoomLvl,
  map,
  view,
  setView,
  viewMap,
  restrictArr,
  responsibleArea,
  setListShipPTT,
  selectedWorkpermit,
  isFindEmpModal,
  isModalVisible,
  modalBaseMap,
  modalSearchBounary,
}: any) => {
  const [isViewMapFromButtonLoaded, setIsViewMapFromButtonLoaded] =
    React.useState(false);
  const [empStatus, setEmpStatus] = React.useState("idle");
  const pttGeometry = useSelector(selectPTTGeometry);

  const { pttEmployee, buttonGroupValue, isSaveMonitoringLoaded } =
    useSelector(selectMapMonitoring);

  const userData = useSelector(selectUser);
  const dispatch = useDispatch<any>();

  const mapDiv = React.useRef(null);

  React.useEffect(() => {
    const installMap = async () => {
      if (
        isSaveMonitoringLoaded &&
        ((buttonGroupValue?.viewMap === viewMap &&
          !isViewMapFromButtonLoaded) ||
          isViewMapFromButtonLoaded)
      ) {
        setEmpStatus("loading");
        try {
          const response2 = await THS_API.get("/map-monitoring");

          const pttEmployee = response2.data.data;

          const [map, view, layerList] = await initMap({
            mapDiv,
            setMapZoom,
            setZoomLvl,
            viewMap,
            role: userData?.role,
          });
          setMap(map);
          setView(view);
          setListShipPTT(layerList);
          dispatch(setEmployee(pttEmployee));
        } finally {
          setEmpStatus("loaded");
        }
        setIsViewMapFromButtonLoaded(true);
      }
    };

    installMap();
  }, [
    dispatch,
    setListShipPTT,
    setMap,
    setMapZoom,
    setView,
    setZoomLvl,
    userData?.role,
    viewMap,
    isSaveMonitoringLoaded,
  ]);

  React.useEffect(() => {
    const pttGeometryTextLayer = map?.findLayerById("ptt-geometry-layer-text");

    pttGeometryTextLayer?.removeAll();

    view?.when(() => {
      const pttGeometryLayer = map?.findLayerById(
        "ptt-responsible-geometry-layer"
      );
      const pttGeometryTextLayer = map?.findLayerById(
        "ptt-responsible-geometry-layer-text"
      );

      pttGeometryLayer?.removeAll();
      pttGeometryTextLayer?.removeAll();

      initPttPolygonAPI({
        view,
        pttGeometry: responsibleArea,
        pttGeometryLayer,
      });
      initPttPolygonTextAPI({
        pttGeometry: responsibleArea,
        pttGeometryTextLayer,
        mapZoom,
      });
    });
  }, [map, view, responsibleArea]);

  React.useEffect(() => {
    const addRestrictPolygon = async () => {
      const [Graphic] = await loadModules(["esri/Graphic"]);

      const restrictAreaLayer = map?.findLayerById("restrict-layer");
      restrictAreaLayer?.removeAll();

      view?.when(() => {
        const filterRestrictArr = restrictArr.map((a: any) => {
          const dataEndDate = moment(a.updated).format("DD/MM/YYYY:HH.mm");
          return { ...a, updated: dataEndDate };
        });
        addGraphics({
          restrictArr: filterRestrictArr,
          restrictAreaLayer,
          Graphic,
          mapZoom,
        });
      });
    };

    addRestrictPolygon();
  }, [map, view, restrictArr, mapZoom]);

  React.useEffect(() => {
    view?.when(() => {
      const employeeLayer = map?.findLayerById("employee-layer");

      initPttEmployeeAPI({
        employeeLayer,
        pttEmployee,
        selectedWorkpermit,
        isFindEmpModal,
        isModalVisible,
        modalBaseMap,
        modalSearchBounary,
      });
    });

    // view?.on("click", function (event: any) {
    //   // event is the event handle returned after the event fires.
    //   console.log("event.mapPoint");
    //   console.log(event.mapPoint);
    // });
  }, [
    map,
    view,
    pttEmployee,
    mapZoom,
    selectedWorkpermit,
    isFindEmpModal,
    isModalVisible,
    modalBaseMap,
    modalSearchBounary,
  ]);

  React.useEffect(() => {
    const callPttGeometry = async () => {
      const pttSearchGeometryLayer = map?.findLayerById(
        "ptt-search-geometry-layer"
      );
      pttSearchGeometryLayer?.removeAll();

      const pttSearchGeometryTextLayer = map?.findLayerById(
        "ptt-search-geometry-layer-text"
      );
      pttSearchGeometryTextLayer?.removeAll();

      initSearchGeometry({
        pttSearchGeometryLayer,
        pttGeometry,
        mapZoom,
      });
      initSearchGeometryText({
        pttSearchGeometryTextLayer,
        pttGeometry,
        mapZoom,
      });
    };
    callPttGeometry();
  }, [map, view, pttGeometry, mapZoom]);

  React.useEffect(() => {
    if (empStatus === "loading") Swal.showLoading();
    else Swal.close();
  }, [empStatus]);

  return <div className="mapDiv esri-map-no-ssr" ref={mapDiv}></div>;
};

export default EsriMapWithNoSSR;
