/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Draggable from "react-draggable";

import { useSelector, useDispatch } from "react-redux";
import { loadModules } from "esri-loader";
import { Select, Modal } from "antd";

import type { DraggableData, DraggableEvent } from "react-draggable";

import { selectUser } from "reducers/userReducer";
import {
  selectMapMonitoring,
  setButtonGroupValue,
} from "reducers/mapMonitoring";

import "./index.css";

export default function Index({
  view,
  setModalSearchBounary,
  modalSearchBounary,
  restrictArr,
  setIsFilterBoxBoundaryActive,
  isClear,
}: any) {
  const [restrictArea, setRestrictArea] = React.useState("");
  const [safetyArea, setSafetyArea] = React.useState("");
  const [bounds, setBounds] = React.useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const draggleRef = React.useRef<HTMLDivElement>(null);

  const safety = restrictArr.filter(
    (po: any) => po.coordinate.length && po.type === "safety_mustering"
  );
  const restricted = restrictArr.filter(
    (po: any) => po.coordinate.length && po.type === "restricted_area"
  );
  const [typeSearchBounary, setTypeSearchBounary] =
    React.useState("restricted");

  const dispatch = useDispatch<any>();
  const userData = useSelector(selectUser);

  const { buttonGroupValue, isSaveMonitoringLoaded } =
    useSelector(selectMapMonitoring);

  const handleCancel = () => {
    setModalSearchBounary(false);
  };

  const handleSubmit = async () => {
    if (view) {
      const [Graphic] = await loadModules(["esri/Graphic"]);
      const center: any = [];
      let subArr: any = [];
      const buttonInputDB = {
        userId: userData.id,
        center: [],
        restrictArea,
        safetyArea,
        typeSearchBounary,
        isFilterBoxBoundaryActive: false,
        filterboxPriority: false,
        filterBoxBoundaryPriority: false,
      };

      if (
        (safetyArea && typeSearchBounary === "safety") ||
        (restrictArea && typeSearchBounary === "restricted")
      ) {
        let area;
        if (typeSearchBounary === "safety") area = safetyArea;
        else area = restrictArea;
        const centerArr = JSON.parse(area);

        centerArr.map((point: any, index: any) => {
          if (index % 2 === 0) subArr.push(point);
          else {
            subArr.push(point);
            center.push(subArr);
            subArr = [];
          }
          return null;
        });

        const fillSymbol = {
          type: "simple-fill", // autocasts as new SimpleFillSymbol()
          color: [227, 139, 79, 0.8],
          outline: {
            // autocasts as new SimpleLineSymbol()
            color: [255, 255, 255],
            width: 1,
          },
        };

        const polygonGraphic = new Graphic({
          geometry: {
            type: "polygon",
            rings: center,
          },
          symbol: fillSymbol,
        });

        view.goTo({
          target: [polygonGraphic],
        });

        const { rings } = polygonGraphic.geometry;
        buttonInputDB.center = rings;

        buttonInputDB.filterBoxBoundaryPriority = true;
        buttonInputDB.isFilterBoxBoundaryActive = true;

        setIsFilterBoxBoundaryActive(true);
      } else setIsFilterBoxBoundaryActive(false);

      setModalSearchBounary(false);

      dispatch(
        setButtonGroupValue({
          ...buttonGroupValue,
          ...buttonInputDB,
        })
      );
    }
  };

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  React.useEffect(() => {
    if (isSaveMonitoringLoaded && Object.keys(buttonGroupValue).length) {
      const { restrictArea, safetyArea, typeSearchBounary } = buttonGroupValue;

      setRestrictArea(restrictArea);
      setSafetyArea(safetyArea);

      setTypeSearchBounary(typeSearchBounary);
    }
  }, [isSaveMonitoringLoaded]);

  React.useEffect(() => {
    if (isClear) {
      setRestrictArea("");
      setSafetyArea("");
      setTypeSearchBounary("restricted");
    }
  }, [isClear]);

  return (
    <Modal
      className="map-monitoring-modal"
      title={
        <div
          style={{
            width: "500px",
            cursor: "move",
          }}
        />
      }
      open={modalSearchBounary}
      onCancel={handleCancel}
      modalRender={(modal) => (
        <Draggable
          disabled={false}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      <h5 className="search-modal">เลือกประเภทการค้นหาพื้นที่ Boundary</h5>
      <form className="filterBoundary">
        <div className="form-group row track-individual-people">
          <div className="col-sm-6 form-check">
            <input
              className="form-check-input"
              type="radio"
              checked={typeSearchBounary === "restricted"}
              onClick={() => {
                setTypeSearchBounary("restricted");
              }}
            />
            <label>พื้นที่ห้ามเข้าชั่วคราว</label>
          </div>
          <div className="col-sm-6 form-check">
            <input
              className="form-check-input"
              type="radio"
              checked={typeSearchBounary === "safety"}
              onClick={() => {
                setTypeSearchBounary("safety");
              }}
            />
            <label>จุดรวมพล</label>
          </div>
        </div>
        {typeSearchBounary === "restricted" && (
          <div className="form-group row">
            <div className="col-sm-12">
              <Select
                className="form-control"
                showSearch
                placeholder="เลือก พื้นที่ห้ามเข้าชั่วคราว"
                value={restrictArea}
                onChange={setRestrictArea}
              >
                <Select.Option value="">
                  เลือก พื้นที่ห้ามเข้าชั่วคราว
                </Select.Option>
                {restricted?.map((restricted: any, key: any) => {
                  const { name, coordinate } = restricted;

                  return (
                    <Select.Option key={key} value={`[${coordinate}]`}>
                      {name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>
        )}
        {typeSearchBounary === "safety" && (
          <div className="form-group row">
            <div className="col-sm-12">
              <Select
                className="form-control"
                showSearch
                placeholder="เลือก จุดรวมพล"
                value={safetyArea}
                onChange={setSafetyArea}
              >
                <Select.Option value="">เลือก จุดรวมพล</Select.Option>
                {safety?.map((safety: any, key: any) => {
                  const { name, coordinate } = safety;

                  return (
                    <Select.Option key={key} value={`[${coordinate}]`}>
                      {name}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
          </div>
        )}
        <div className="btn btn-save" onClick={handleSubmit}>
          Search
        </div>
      </form>
    </Modal>
  );
}
