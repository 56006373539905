import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {
    dataDrilldown: "PL000001",
    dataPlantName: "",
    dataPlantTotal: 0,
    count: 0,
    isClick: false,
  },
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setDashboard: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const selectDashboard = (state: any) => state.dashboard.value;

export const { setDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
